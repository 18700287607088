import React, {useState, useEffect, useContext} from "react";
import { useGoogleLogin } from '@react-oauth/google';
import {Row, Col, Modal, Card, Form, InputGroup, Image, ListGroup, ListGroupItem, Badge, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DarkModeSwitcher from "./DarkModeSwitcher";
import ApiCall from '../../Helper/ApiCall.js';
import UserHelper from '../../Helper/UserHelper.js';
import TotpField from '../../Misc/TotpField.js';
import {ProgressBarContext} from '../../Provider/ProgressBarProvider.js';

export default function AccountProfile(props) {

    const progressBar = useContext(ProgressBarContext);

    const [{ ratesAllowed,googleCalendarAccessGranted,profileAvailable,profileAliasEmail,profileTitle,profileFirstName,profileLastName,profileBirthday,profileCountries,profileTimezone,profileEmail,profilePhone,
                profileSkype,profileCompany,profileHourlyRateEuro,profilePrimaryColor,profileSecondaryColor,mfaPreviouslyEnabled,twoFactorAuthenticationEnabled,qrImage,scratchCodes,
                 profileLogoUrl,profileCurrentPassword,profileNewPassword,profileConfirmNewPassword,whatsapp,telegram,viber,signal,titles,countries,timezones,showConfirmDeleteAccount}, setState] = useState(
        {
            ratesAllowed:true,
            googleCalendarAccessGranted: true,
            profileAvailable: true,
            profileAliasEmail:"",
            profileTitle: "",
            profileFirstName: "",
            profileLastName: "",
            profileBirthday:"",
            profileCountries:[],
            profileTimezone: "",
            profileEmail: "",
            profilePhone: "",
            profileSkype: "",
            profileCompany: "",
            profileHourlyRateEuro: "",
            profilePrimaryColor: "#002e5b",
            profileSecondaryColor: "#fde428",
            profileLogoUrl: "",
            profileCurrentPassword: "",
            profileNewPassword: "",
            profileConfirmNewPassword: "",
            whatsapp: false,
            telegram: false,
            viber: false,
            signal: false,
            twoFactorAuthenticationEnabled: false,
            titles: [],
            countries: [],
            timezones: [],
            showConfirmDeleteAccount:false
        }
    );

    function updateValue(name,value) {
        setState(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        progressBar.dispatch({type: 'update', progress: 0});

        UserHelper.getTitles().then(response => {
            if (response.titles && response.titles.length) {
                updateValue("profileTitle", response.titles[0].code);
                updateValue("titles", response.titles);
                progressBar.dispatch({type: 'increment', progress: 20});
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);
                updateValue("titles", []);
                progressBar.dispatch({type: 'update', progress: 0});
            }
        });

        UserHelper.getCountries().then(response => {
            if (response.countries && response.countries.length) {
                updateValue("countries", response.countries);
                progressBar.dispatch({type: 'increment', progress: 20});
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);
                updateValue("countries", []);
                progressBar.dispatch({type: 'update', progress: 0});
            }
        });

        ApiCall.get('commerce/i18n/timezones').then((response) => {
            let timeZoneOptions = [];

            response.data.forEach((tz)=> {
                timeZoneOptions.push({value:tz, label:tz});
            })
            updateValue('timezones', timeZoneOptions);

        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            progressBar.dispatch({type: 'update', progress: 0});
            progressBar.dispatch({type: 'update', progress: 100});
        });

        let isClient = UserHelper.isClient();
        let projection;
        if (isClient) {
            projection="supreme.contractors.backend.facade.dto.SupremeUserDetailsDtoDefinition";
        } else {
            projection="supreme.contractors.backend.facade.dto.ContractorUserDetailsDtoDefinition";
        }

        ApiCall.get('user/getCurrentUser', {
            projection: projection
        }).then((response) => {
            let currentUser = response.data;

            let countries = [];
            if (currentUser.locations) {
                currentUser.locations.forEach(t=> {
                    countries.push({value:t.code, label:t.name});
                });
            }

            if (response.data.code === 'anonymous') {
                UserHelper.showErrorToast('Your session has timed out. You need to login again.');
                UserHelper.logout(props.history);
            } else {
                    updateValue("ratesAllowed", (currentUser.department && currentUser.department.organization ? currentUser.department.organization.allowEmployeeRates : false));
                    updateValue("googleCalendarAccessGranted", currentUser.googleCalendarAccessGranted);
                    updateValue("profileAvailable", currentUser.available);
                    updateValue("profileTitle", (currentUser.title ? currentUser.title.code : ""));
                    updateValue("profileCountries", countries);
                    updateValue("profileTimezone", {value:currentUser.defaultTimezone, label:currentUser.defaultTimezone});
                    updateValue("profileAliasEmail", currentUser.aliasEmail);
                    updateValue("profileFirstName", currentUser.firstName);
                    updateValue("profileLastName", currentUser.lastName);
                    updateValue("profileBirthday", currentUser.birthday);
                    updateValue("profileCompany", (currentUser.department && currentUser.department.organization ? currentUser.department.organization.legalName : ""));
                    updateValue("profileEmail", currentUser.username);
                    updateValue("profilePhone", currentUser.phone);
                    updateValue("profileSkype", currentUser.skype);
                    updateValue("profileHourlyRateEuro", currentUser.contractorHourlyRate);
                    updateValue("viber", currentUser.viber);
                    updateValue("signal", currentUser.signal);
                    updateValue("whatsapp", currentUser.whatsapp);
                    updateValue("telegram", currentUser.telegram);
                    updateValue("profilePrimaryColor", (currentUser.department.organization.theme ? currentUser.department.organization.theme.primaryColor : "#002e5b"));
                    updateValue("profileSecondaryColor", (currentUser.department.organization.theme ? currentUser.department.organization.theme.secondaryColor : "#fde428"));
                    updateValue("profileLogoUrl", (currentUser.department.organization.logo ? currentUser.department.organization.logo.url : ""));
            }
            progressBar.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            progressBar.dispatch({type: 'update', progress: 0});
            progressBar.dispatch({type: 'update', progress: 100});
        });

        ApiCall.get('mfa/enabled').then(response => {
            updateValue('mfaPreviouslyEnabled', response.data);
            updateValue('twoFactorAuthenticationEnabled', response.data);
            progressBar.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            progressBar.dispatch({type: 'update', progress: 0});
        });

    }, []);


    const changeAvailability = (availability) => {
        //progressBar.dispatch({type: 'update', progress: 0});
        ApiCall.performPut('profile/availability', {}, progressBar, (result) => {
            updateValue("profileAvailable",availability);
            UserHelper.showSuccessToast("Your availability has been updated");
        }, { available: availability });

//        ApiCall.put('profile/availability', {}, {
//            available: availability
//        }).then((response) => {
//            updateValue("profileAvailable",availability);
//            UserHelper.showSuccessToast("Your availability has been updated");
//            progressBar.dispatch({type: 'update', progress: 100});
//        }).catch(error => {
//            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
//            progressBar.dispatch({type: 'update', progress: 0});
//        });
    }

    const getOauthToken = useGoogleLogin({
        onSuccess: async tokenResponse => {
            ApiCall.post('account/google/oauth', {code: tokenResponse.code}).then((response) => {
                UserHelper.showSuccessToast('Access granted successfully!');
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });

//          console.log(tokenResponse);
//          // fetching userinfo can be done on the client or the server
//          const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
//              headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
//          }).then(res => res.data);
//
//          console.log(userInfo);
        },
        redirect_uri: "http://localhost:3000",
        flow: 'auth-code',
        access_type: "offline",
        approval_prompt:"force",
        scope: 'openid email profile https://www.googleapis.com/auth/calendar.events.readonly '
    });


   const handleInputChange = (event) => {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                updateValue(name,value);
            } else {
                let newCountries = [];
                event.forEach(sel => {
                    newCountries.push({value:sel.value, label:sel.label});
                });
                updateValue("profileCountries", newCountries);
            }
        }
    }

    const handleSubmitUpdateProfileForm = (event) => {
        //progressBar.dispatch({type: 'update', progress: 20});
        event.preventDefault();

        let title = titles.find(title => {
            return title.code === profileTitle
        });

        let countries = [];
        profileCountries.forEach(t=> {
            countries.push({code:t.value});
        });

        let customer = {
            title: title,
            locations: countries,
            defaultTimezone: profileTimezone.value,
            firstName: profileFirstName,
            lastName: profileLastName,
            birthday: profileBirthday,
            phone: profilePhone,
            skype: profileSkype,
            username: profileEmail,
            contractorHourlyRate: profileHourlyRateEuro,
            whatsapp: whatsapp,
            telegram: telegram,
            viber: viber,
            signal: signal,
        };

        ApiCall.performPut('account/update', customer, progressBar, (result) =>  {
            UserHelper.showSuccessToast("Your profile has been updated");
            window.scrollTo(0, 0);
        });

//        ApiCall.put('account/update', customer).then(() => {
//            UserHelper.showSuccessToast("Your profile has been updated");
//            window.scrollTo(0, 0);
//            progressBar.dispatch({type: 'update', progress: 100});
//        }).catch(error => {
//            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
//        });
    }

    const handleSubmitUpdateTheme = (event) => {
        progressBar.dispatch({type: 'update', progress: 20});
        event.preventDefault();

        if (!validateUpdatePasswordForm()) {
            return;
        }

        ApiCall.post('account/theme', null, null, {
            primaryColor: profilePrimaryColor,
            secondaryColor: profileSecondaryColor,
            logoUrl: profileLogoUrl
        }).then(() => {
            UserHelper.showSuccessToast("Your theme was updated");
             progressBar.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const handleSubmitUpdatePassword = (event) => {
     progressBar.dispatch({type: 'update', progress: 20});
        event.preventDefault();

        if (!validateUpdatePasswordForm()) {
            return;
        }

        ApiCall.post('account/changePassword', null, null, {
            oldPassword: profileCurrentPassword,
            newPassword: profileNewPassword
        }).then(() => {
            UserHelper.showSuccessToast("Your password has been changed");
             progressBar.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const handleDeleteAccount = (event) => {
        event.preventDefault();

        ApiCall.delete('account/delete').then(() => {
            UserHelper.logout(props.history, { globalInfoMsgs: ["Your profile was deleted."] });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const validateUpdatePasswordForm = () => {
        if (profileNewPassword !== profileConfirmNewPassword) {
            UserHelper.showErrorToast("Password and password confirmation do not match");

            return false;
        }

        return true;
    }

    const testMfaCode = (val) => {
     progressBar.dispatch({type: 'update', progress: 20});
        ApiCall.get('security/totp/valid', {
            verificationCode : val
        }).then(res => {
            if (res.data) {
                UserHelper.showSuccessToast("Successfully tested the totp code.");
            } else {
                UserHelper.showErrorToast("Wrong code");
            }
             progressBar.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const toggleTotp = (checked) => {
     progressBar.dispatch({type: 'update', progress: 20});
        if (checked) {
            ApiCall.post('security/totp/create').then(res => {
                updateValue('twoFactorAuthenticationEnabled', checked);
                updateValue('scratchCodes', res.data.scratchCodes);
                UserHelper.showSuccessToast("Two-factor authentication is enabled.");

                ApiCall.get('account/security/totp/qr').then(res => {
                  updateValue('qrImage', "data:image/png;base64," + res.data);
                }).catch(error => {
                    UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                    updateValue('qrImage', "error-qr.png")
                });
                 progressBar.dispatch({type: 'update', progress: 100});
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                updateValue('twoFactorAuthenticationEnabled', false);
            });
        } else {
            ApiCall.delete('security/totp').then(res => {
                updateValue('mfaPreviouslyEnabled', false);
                updateValue('twoFactorAuthenticationEnabled', false);
                UserHelper.showSuccessToast("Two-factor authentication is disabled.");
                 progressBar.dispatch({type: 'update', progress: 100});
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }
    }

    let isContractor = UserHelper.isContractor();
    let isSales = UserHelper.isSales();
    let isClient = UserHelper.isClient();

    let titleOptions = titles.map(function(title, key){
        return <option key={key} value={title.code}>{title.name}</option>;
    });

    let optionCountries = [];

    countries.forEach(function(country){
        optionCountries.push({value:country.code,label:country.name});
    });

    return (
        <>
        <Row className="mt-3">
            <Col sm={12} md={6}>
                <Card className="mb-3">
                    <Card.Body>
                        <Card.Title className="text-uppercase fw-bold">
                            Profile
                        </Card.Title>

                        <p>Please use this form to update your personal details</p>

                        <p className="text-warning">Fields marked with * are required</p>

                        <Form onSubmit={handleSubmitUpdateProfileForm}>
                            <Form.Group as={Row} controlId="formProfileTitle" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Title*
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="user-tie" /></InputGroup.Text>
                                        <Form.Select name="profileTitle" value={profileTitle} required onChange={handleInputChange}>
                                            { titleOptions }
                                        </Form.Select>
                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formProfileFirstName" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Name*
                                </Form.Label>
                                <Form.Group as={Col} controlId="profileFirstName" className="mb-0">
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                            <Form.Control name="profileFirstName" type="text" placeholder="First Name" required
                                            defaultValue={profileFirstName} onChange={handleInputChange} />
                                        </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} controlId="profileLastName" className="mb-0">
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                            <Form.Control name="profileLastName" type="text" placeholder="Last Name" required
                                            defaultValue={profileLastName} onChange={handleInputChange} />
                                        </InputGroup>
                                </Form.Group>
                            </Form.Group>
                            {isContractor ?
                                <Form.Group as={Row} controlId="formProfileBirthday" className="mb-3">
                                    <Form.Label column sm={3} md={12} lg={3}>
                                        Birthday*
                                    </Form.Label>
                                    <Col sm={9} md={12} lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="cake-candles" /></InputGroup.Text>
                                            <Form.Control name="profileBirthday" defaultValue={profileBirthday} type="date" required
                                            placeholder="Birthday"
                                            onChange={handleInputChange}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            :null}
                            <Card.Title className="text-uppercase fw-bold pt-4">
                                I18N
                            </Card.Title>

                            {isContractor ?
                                <Form.Group as={Row} controlId="formProfileCountry" className="mb-3">
                                    <Form.Label column sm={3} md={12} lg={3}>
                                        Countries
                                    </Form.Label>
                                    <Col sm={9} md={12} lg={9}>
                                        <InputGroup>
                                            <Select name="countries" options={optionCountries} classNamePrefix="react-select"
                                                closeMenuOnSelect={false} isMulti className="w-100 react-select-container" value={profileCountries}
                                                onChange={handleInputChange}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            :''}
                            <Form.Group as={Row} controlId="formDefaultTimezone" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    TimeZone
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <Select name="timeZone" options={timezones} classNamePrefix="react-select"
                                            closeMenuOnSelect={true} className="w-100 react-select-container" value={profileTimezone}
                                            onChange={(e)=>updateValue("profileTimezone", e)}/>
                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            <Card.Title className="text-uppercase fw-bold pt-4">
                                Company
                            </Card.Title>

                            <Form.Group as={Row} controlId="formProfileCompany" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Company
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="building" /></InputGroup.Text>
                                        <Form.Control name="profileCompany" type="email" disabled placeholder="Company" required defaultValue={profileCompany} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>


                            <Card.Title className="text-uppercase fw-bold pt-4">
                                Settings
                            </Card.Title>

                            <Form.Group as={Row} controlId="formProfileEmail" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Email*
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="envelope" /></InputGroup.Text>
                                        <Form.Control name="profileEmail" type="email" disabled placeholder="Email" required defaultValue={profileEmail}/>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            {isContractor ?
                                <Form.Group as={Row} controlId="formProfileAliasEmail" className="mb-3">
                                    <Form.Label column sm={3} md={12} lg={3}>
                                        Alias Email*
                                    </Form.Label>
                                    <Col sm={9} md={12} lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="envelope" /></InputGroup.Text>
                                            <Form.Control name="profileAliasEmail" type="email" disabled placeholder="Alias Email" required defaultValue={profileAliasEmail}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            :null}
                            <Form.Group as={Row} controlId="formProfilePhone" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Phone*
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <PhoneInput
                                      country={'bg'}
                                      value={profilePhone}
                                      onChange={phone => handleInputChange({target: {name: 'profilePhone', value: phone}})}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formProfileSkype" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Skype
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon={["fab", "skype"]} /></InputGroup.Text>
                                        <Form.Control name="profileSkype" type="text" placeholder="Skype" defaultValue={profileSkype} onChange={handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formProfileCommunications" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Channels
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <Form.Check id="whatsap" name="whatsapp" className="custom-checkbox" label="Whatsapp" checked={whatsapp}
                                    onChange={handleInputChange}/>
                                    <Form.Check id="telegram" name="telegram" className="custom-checkbox" label="Telegram" checked={telegram}
                                    onChange={handleInputChange} />
                                    <Form.Check id="viber" name="viber" className="custom-checkbox" label="Viber" checked={viber}
                                    onChange={handleInputChange} />
                                    <Form.Check id="signal" name="signal" className="custom-checkbox" label="Signal" checked={signal}
                                    onChange={handleInputChange} />
                                </Col>
                            </Form.Group>

                            {ratesAllowed ?
                                <Form.Group as={Row} controlId="formProfileHourlyRate" className="mb-3">
                                    <Form.Label column sm={3} md={12} lg={3}>
                                        Hourly Rate (in EUR)*
                                    </Form.Label>
                                    <Col sm={9} md={12} lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="euro-sign" /></InputGroup.Text>
                                            <Form.Control name="profileHourlyRateEuro" type="number" placeholder="Hourly Rate" required defaultValue={profileHourlyRateEuro} onChange={handleInputChange} step={0.01} min={0} max={100} />
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            : null
                             }

                            <Form.Group as={Row} controlId="formUpdateProfile" className="mb-3">
                                <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                    <Button variant="primary" type="submit" className="float-end text-uppercase">Save updates</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                {isClient && !googleCalendarAccessGranted ?
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title className="text-uppercase fw-bold">
                                Google
                            </Card.Title>
                            <p className="text-warning">You must authorize the app to your calendar to display free slots</p>
                            <div className="text-center mx-auto">
                                <Button onClick={() => getOauthToken()} className="btn-google btn-block rounded-4 border mb-4 w-100" size="lg" variant="primary">
                                    <FontAwesomeIcon icon={["fab", "google"]} className="me-3" size="sm"/>
                                    Authorize access to your Google Calendar
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                :""}
            </Col>

            <Col sm={12} md={6} className="mt-3 mt-md-0">
                {isContractor ?
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title className="text-uppercase fw-bold">
                                Availability
                            </Card.Title>
                            <p>Here you can update your availability</p>
                            <Form onSubmit={handleSubmitUpdatePassword}>
                                <Form.Group as={Row} controlId="formProfileAvailable" className="mb-3">
                                    <Col sm={9} md={12} lg={9}>
                                        <InputGroup>
                                              <div className="toggle-availability-wrapper">
                                                  <label className="toggle-theme mb-0" htmlFor="availability-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      id="availability-checkbox"
                                                      onChange={(e) => {
                                                        changeAvailability(e.target.checked);
                                                      }}
                                                      checked={profileAvailable}
                                                    />
                                                    <div className={`slider round w-100 ${profileAvailable ? 'bg-success' : 'bg-danger' }`}></div>
                                                   </label>
                                                  <span role="img" className="ms-2 align-top">
                                                      <OverlayTrigger
                                                          placement="bottom"
                                                          delay={{ show: 250, hide: 400 }}
                                                          overlay={<Tooltip id="button-tooltip-2">
                                                          {profileAvailable ? "I am available for work. Please consider me for new opportunities." : "I have found a project somewhere else or I want to have a vacation. Either way, please don't bother me with new requests for work."}
                                                          </Tooltip>}>
                                                          <span><Badge pill bg={profileAvailable ? "success" : "danger"}  className="fs-5">{profileAvailable ? "Available" : "Busy"}</Badge></span>
                                                      </OverlayTrigger>
                                                  </span>
                                              </div>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                : null}
                {isSales || isClient ?
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title className="text-uppercase fw-bold">
                                Theme
                            </Card.Title>
                            <p>Update the look and feel of your profiles</p>
                            <p className="text-warning">Fields marked with * are required</p>
                            <Form onSubmit={handleSubmitUpdateTheme}>
                                <Form.Group as={Row} controlId="formProfilePrimaryColor" className="mb-3">
                                    <Form.Label column sm={3} md={12} lg={3}>
                                        Colors*
                                    </Form.Label>
                                    <Form.Group as={Col} controlId="profilePrimaryColor" className="mb-0">
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="palette" /></InputGroup.Text>
                                            <Form.Control name="profilePrimaryColor" type="color" placeholder="Primary color" required onChange={handleInputChange} value={profilePrimaryColor}/>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="profileSecondaryColor" className="mb-0">
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="palette" /></InputGroup.Text>
                                            <Form.Control name="profileSecondaryColor" type="color" placeholder="Secondary color" required onChange={handleInputChange} value={profileSecondaryColor}/>
                                        </InputGroup>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formProfileLogoUrl" className="mb-3">
                                    <Form.Label column sm={3} md={12} lg={3}>
                                        Logo url*
                                    </Form.Label>
                                    <Col sm={9} md={12} lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="link" /></InputGroup.Text>
                                            <Form.Control name="profileLogoUrl" type="text" placeholder="Logo url" required onChange={handleInputChange} value={profileLogoUrl}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formRegisterCreateAccount" className="mb-3">
                                    <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                        <Button variant="primary" type="submit" className="float-end text-uppercase">Update theme</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                :null}
                <Card className="mb-3">
                    <Card.Body>
                        <Card.Title className="text-uppercase fw-bold">
                            Mode
                        </Card.Title>
                        <p>Toggle between light and dark mode</p>
                        <DarkModeSwitcher />
                    </Card.Body>
                </Card>
                <Card className="mb-3">
                    <Card.Body>
                        <Card.Title className="text-uppercase fw-bold">
                            Update password
                        </Card.Title>

                        <p>Please use this form to update your account password</p>

                        <p className="text-warning">Fields marked with * are required</p>

                        <Form onSubmit={handleSubmitUpdatePassword}>
                            <Form.Group as={Row} controlId="formProfileCurrentPassword" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Current password*
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                        <Form.Control name="profileCurrentPassword" type="password" placeholder="Password" required onChange={handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formProfileNewPassword" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    New password*
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                        <Form.Control name="profileNewPassword" type="password" placeholder="Confirm password" required onChange={handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formProfileConfirmNewPassword" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Confirm new password*
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                        <Form.Control name="profileConfirmNewPassword" type="password" placeholder="Confirm password" required onChange={handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formRegisterCreateAccount" className="mb-3">
                                <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                    <Button variant="primary" type="submit" className="float-end text-uppercase">Update password</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
                <Card className="mb-3">
                    <Card.Body>
                        <Card.Title className="text-uppercase fw-bold">
                            Two Factor Authentication
                        </Card.Title>
                        <p>Toggle two-factor authentication</p>
                        <Row className="mb-4">
                            <Col sm={9} md={12} lg={9}>
                                <InputGroup>
                                      <div className="toggle-availability-wrapper">
                                          <label className="toggle-theme mb-0" htmlFor="totp-checkbox">
                                            <input
                                              type="checkbox"
                                              id="totp-checkbox"
                                              onChange={(e) => {
                                                toggleTotp(e.target.checked);
                                              }}
                                              checked={twoFactorAuthenticationEnabled}
                                            />
                                            <div className={`slider round w-100 ${twoFactorAuthenticationEnabled ? 'bg-success' : 'bg-danger' }`}></div>
                                           </label>
                                      </div>
                                </InputGroup>
                            </Col>
                        </Row>
                        {!mfaPreviouslyEnabled && twoFactorAuthenticationEnabled ?
                            <>
                                <Form.Group as={Row} controlId="qrImage" className="mb-4">
                                    <Col md={12}>
                                        <Image src= {qrImage} className="rounded-4"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="qrImage" className="mb-4">
                                    <Col md={12} className="my-auto">
                                        <ListGroup>
                                            {scratchCodes && scratchCodes.map(function(sc, i){
                                                return <ListGroupItem key={i} className="w-auto">{sc}</ListGroupItem>
                                            })}
                                        </ListGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="submit" className="mb-4">
                                    <Col sm={12} md={12} lg={12}>
                                        <InputGroup>
                                            <TotpField submit={(val)=>testMfaCode(val)} submitButton={true}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                            </>
                        :""}
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Card.Title className="text-uppercase fw-bold">
                            Delete account
                        </Card.Title>

                        <p>Clicking this button will delete your account.</p>
                        <p className="text-danger">This action is <b>irreversible</b>!</p>

                        <Form.Group as={Row} controlId="formDeleteAccount" className="mb-3">
                            <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                <Button variant="danger" type="submit" className="float-end text-uppercase" onClick={(event) => updateValue("showConfirmDeleteAccount",true)}>Delete Account</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Modal
            show={showConfirmDeleteAccount}
            onHide={()=>updateValue("showConfirmDeleteAccount",false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please confirm if you are sure you want to delete your account.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={()=>updateValue("showConfirmDeleteAccount",false)}>
                Close
              </Button>
              <Button variant="danger" onClick={(event)=>handleDeleteAccount(event)}>Delete</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}
