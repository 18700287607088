import React, {Component} from 'react';
import Chart from 'chart.js/auto';

import {Line, Bar} from 'react-chartjs-2';
import {Row, Col, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import './AccountReports.scss';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
        this.getReport = this.getReport.bind(this);
    }

    componentDidMount() {
        this.getReport();
    }

    render() {

        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Dashboard
                                </Card.Title>
                                <Row className="mt-3">
                                    <Col sm={3} md={3} className="mt-3 mt-md-0">
                                        <Card className="border-left-success shadow-hover h-100 py-2">
                                            <Card.Body>
                                                <Row className="no-gutters align-items-center">
                                                    <Col lg={10}>
                                                        <div className="text-xs fw-bold text-success text-uppercase mb-1">Contractors</div>
                                                        <div className="h5 mb-0 fw-bold text-gray-800">{this.state
                                                        .report? this.state.report.numberOfContractors : null}</div>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <FontAwesomeIcon icon={"hard-hat"} color="var(--bs-muted)" size="2x" />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={3} md={3} className="mt-3 mt-md-0">
                                        <Card className="border-left-primary shadow-hover h-100 py-2">
                                            <Card.Body>
                                                <Row className="no-gutters align-items-center">
                                                    <Col lg={10}>
                                                        <div className="text-xs fw-bold text-primary text-uppercase mb-1">Clients</div>
                                                        <div className="h5 mb-0 fw-bold text-gray-800">{this.state.report? this.state.report
                                                        .numberOfClients : null}</div>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <FontAwesomeIcon icon={"user-tie"} color="var(--bs-muted)" size="2x" />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={3} md={3} className="mt-3 mt-md-0">
                                        <Card className="border-left-warning shadow-hover h-100 py-2">
                                            <Card.Body>
                                                <Row className="no-gutters align-items-center">
                                                    <Col lg={10}>
                                                        <div className="text-xs fw-bold text-warning text-uppercase mb-1">Assignments</div>
                                                        <div className="h5 mb-0 fw-bold text-gray-800">{this.state.report? this.state.report
                                                        .numberOfAssignments : null}</div>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <FontAwesomeIcon icon={"file-contract"} color="var(--bs-muted)" size="2x" />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col sm={3} md={3} className="mt-3 mt-md-0">
                                        <Card className="border-left-danger shadow-hover h-100 py-2">
                                            <Card.Body>
                                                <Row className="no-gutters align-items-center">
                                                    <Col lg={10}>
                                                        <div className="text-xs fw-bold text-danger text-uppercase mb-1">Daily profit</div>
                                                        <div className="h5 mb-0 fw-bold text-gray-800">{this.state.report ? this.state.report
                                                        .dailyProfit : 0}</div>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <FontAwesomeIcon icon={"euro-sign"} color="var(--bs-muted)" size="2x" />
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-3">
                   <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card className="shadow-hover h-100 py-2">
                            {this.state.report ?
                                <Card.Body>
                                    <Line
                                      data={this.state.report.monthlyProfitHistory}
                                      options={{
                                        title:{
                                          display:true,
                                          text:'Balance History',
                                          fontSize:20
                                        },
                                        legend:{
                                          display:true,
                                          position:'bottom'
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true
                                                }
                                            }]
                                        }
                                      }}
                                    />
                                </Card.Body>
                            :''}
                        </Card>
                   </Col>
                </Row>
                <Row className="mt-3">
                   <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card className="shadow-hover h-100 py-2">
                            {this.state.report ?
                                <Card.Body>
                                    <Bar
                                      data={this.state.report.contractorProfitHistory}
                                      options={{
                                        indexAxis: 'y',
                                        title:{
                                          display:true,
                                          text:'Yearly Profit Per Contractor',
                                          fontSize:20
                                        },
                                        legend:{
                                          display:true,
                                          position:'bottom'
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true
                                                }
                                            }]
                                        }
                                      }}
                                    />
                                </Card.Body>
                            :''}
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                   <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card className="shadow-hover h-100 py-2">
                            {this.state.report ?
                                <Card.Body>
                                    <Bar
                                      data={this.state.report.clientProfitHistory}
                                      options={{
                                        title:{
                                          display:true,
                                          text:'Yearly Profit Per Client',
                                          fontSize:20
                                        },
                                        legend:{
                                          display:true,
                                          position:'bottom'
                                        },
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true
                                                }
                                            }]
                                        }
                                      }}
                                    />
                                </Card.Body>
                            :""}
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    getReport() {
        ApiCall.get('report').then((response) => {
            this.setState({
                report: response.data,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

}

export default withProgressBarContext(AccountReports);
