import React, {Component} from 'react';
import {Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EducationForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            locales: [],
            levels: [],
            educationForm: {
                code: null,
                startDate: null,
                endDate: null,
                degree:null,
                university:null
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeEducationForm = this.handleChangeEducationForm.bind(this);
        this.handleSubmitAddEditEducationForm = this.handleSubmitAddEditEducationForm.bind(this);
        this.clearEducationForm = this.clearEducationForm.bind(this);
    }

    componentDidMount() {
        if (this.props.editEducation && !UserHelper.isEquivalent(this.props.editEducation, this.state.educationForm)) {
            this.setState({
                    educationForm: {
                        code: this.props.editEducation ? this.props.editEducation.code : null,
                        startDate: this.props.editEducation ? this.props.editEducation.startDate : "",
                        endDate: this.props.editEducation ? this.props.editEducation.endDate : "",
                        degree: this.props.editEducation ? this.props.editEducation.degree : "",
                        university: this.props.editEducation ? this.props.editEducation.university : ""
                    }
                }
            );
        }
    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <React.Fragment>
                <Form id="educationForm" onSubmit={this.handleSubmitAddEditEducationForm}>
                    <Form.Group as={Row} controlId="locale" className="mb-3">
                        <Col md={6} lg={6}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="calendar" /></InputGroup.Text>
                                <DatePicker
                                  value={this.state.educationForm.startDate || ""}
                                  onChange={(date)=>this.handleInputChange({target:{name:'startDate', value:new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,7)}})}
                                  className="form-control calendar"
                                  dateFormat="YYYY-MMM"
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                  placeholderText="Start Date"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6} lg={6}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="calendar" /></InputGroup.Text>
                                <DatePicker
                                  value={this.state.educationForm.endDate || ""}
                                  onChange={(date)=>this.handleInputChange({target:{name:'endDate', value:new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,7)}})}
                                  className="form-control calendar"
                                  dateFormat="YYYY-MMM"
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                  placeholderText="End Date"
                                />
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="locale" className="mb-3">
                        <Col md={6} lg={6}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="user-graduate" /></InputGroup.Text>
                                <Form.Control name="degree" type="text" placeholder="Degree" value={this.state.educationForm.degree || ""}
                                required
                                onChange={this.handleInputChange}>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md={6} lg={6}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="building-columns" /></InputGroup.Text>
                                <Form.Control name="university" type="text" placeholder="University" value={this.state.educationForm.university || ""}
                                required
                                onChange={this.handleInputChange}>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formAddEditProject" className="mb-3">
                        <Col sm={11} md={11} lg={11}>
                            <Button variant="primary" type="submit" className="float-end text-uppercase">Save</Button>
                        </Col>
                        <Col sm={1} md={1} lg={1}>
                            <Button variant="link" onClick={() =>this.props.cancelEducation()} className="float-end">Cancel</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </React.Fragment>
        )
    }

    handleChangeEducationForm(field, value) {
        let newEducationForm = this.state.educationForm;
        newEducationForm[field] = value;

        this.setState({
            educationForm: newEducationForm
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.handleChangeEducationForm(name, value);
    }

    getLocales() {
        UserHelper.getLocales().then(response => {
            if (response.locales && response.locales.length) {
                this.setState({
                    locales: response.locales
                });
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);

                this.setState({
                    locales: []
                });
            }
        });
    }

    handleSubmitAddEditEducationForm(event) {
        event.preventDefault();

        let education = {
            code: this.state.educationForm.code,
            degree: this.state.educationForm.degree,
            university: this.state.educationForm.university,
            startDate: this.state.educationForm.startDate,
            endDate: this.state.educationForm.endDate
        };

        if (this.props.editEducation) {
            ApiCall.put('education', education).then(() => {
                this.clearEducationForm();
                this.props.afterEditEducation();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        } else {
            ApiCall.post('education', education).then(() => {
                this.clearEducationForm();

                UserHelper.showSuccessToast("Your education was added.");

                this.props.getEducations();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }
    }

    clearEducationForm() {
        this.setState({
            educationForm: {
                code: null,
                locale: null,
                level: null
            }
        });
    }
}

export default EducationForm;
