import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ApiCall from '../Helper/ApiCall.js';
import UserHelper from '../Helper/UserHelper.js';
import {Card, Form, InputGroup, Row, Col, Button, Alert} from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';

class Review extends Component {
    constructor(props){
        super(props);

        this.state = {
            assignment: null,
            rating: null,
            review: null,
            errorMsg: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getReviewRequest = this.getReviewRequest.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitReview = this.submitReview.bind(this);
    }

    componentDidMount() {
        if (this.props.location && this.props.location.hash) {
            this.getReviewRequest(this.props.location.hash.substring(1));
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <Card className="border-0">
                <Card.Body>
                    <Form onSubmit={this.handleSubmit} id="review">
                        {this.state.infoMsg &&
                            <Alert variant="info">{this.state.infoMsg}</Alert>
                        }
                        {this.state.errorMsg &&
                            <Alert variant="danger">{this.state.errorMsg}</Alert>
                        }
                        <Row>
                            <Col lg={12} className="d-flex justify-content-center">
                                <img src={this.state.assignment && this.state.assignment.user.avatar ? this.state.assignment.user.avatar.url : null}
                                className="rounded" alt="avatar"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="d-flex justify-content-center">
                                <h2>{this.state.assignment ? this.state.assignment.user.firstName: ''} {this.state.assignment ? this.state.assignment.user
                                .lastName : ''}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="d-flex justify-content-center">
                                <h4>{this.state.assignment ? this.state.assignment.user.role : null}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} className="d-flex text-center">
                                {this.state.assignment ? this.state.assignment.user.summary : null}
                            </Col>
                        </Row>
                        <Form.Group as={Row} controlId="rating" className="mb-3">
                            <Col sm={12} md={12} lg={12} >
                                <InputGroup className="d-flex justify-content-center">
                                    <Rating initialValue={this.state.rating} onClick={ (newValue, index, event)=> {this.handleRatingChange(newValue)}} emptyColor={UserHelper.isDark() ? "var(--bs-light)" : "var(--bs-primary)"} fillColor={"var(--bs-secondary)"} allowFraction={true}/>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="review" className="mb-3">
                            <Col sm={12} md={12} lg={12}>
                                <InputGroup>
                                    <Form.Control name="review" as="textarea" placeholder="Review" required defaultValue={this.state.review}
                                    onChange={this.handleInputChange} />
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formLoginRememberMeSignIn" className="mb-3">
                            <Col sm={12} md={12} lg={12} className="d-flex justify-content-center">
                                <Button variant="primary" type="submit" className="float-end text-uppercase mt-2 mt-lg-0">Submit</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleRatingChange(newValue) {
        this.setState({
            rating: newValue
        });
    }


    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.rating) {
            this.setState({
                errorMsg: 'Please hover over the stars to provide your rating!'
            });
            return;
        }

        if (!this.state.review) {
            this.setState({
                errorMsg: 'Please write a short review for the performance of the resource!'
            });
            return;
        }

        this.submitReview(this.state.rating, this.state.review);
    }

    submitReview(rating, review) {
        ApiCall.post('customerReview/customer/' + this.state.assignment.user.code, {
            content: review,
            rating: rating,
            user: {
                "@class":"io.nemesis.platform.facade.user.dto.UserDetailsDto",
                username: this.state.assignment.manager.code
            }
        }, null).then(() => {
            ApiCall.delete('reviewRequest/assignment/' + this.state.assignment.code).then(() => {
                this.props.history.push({
                    pathname: "/login",
                    state: { globalInfoMsgs: ["Thank you for your review!"] }
                });
            });
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.'
            });
        });

    }

    getReviewRequest(reviewRequestCode) {
        ApiCall.get('reviewRequest/' + reviewRequestCode, {
            projection: "supreme.contractors.backend.facade.dto.SupremeReviewableUserDetailsDtoDefinition"
        }).then((response) => {
            let assignment = response.data;

            this.setState({
                assignment: assignment,
                isLoading: false
            });
        }).catch(error => {
            if (error.response.status === 404) {
                window.location='http://supreme.contractors/';
            }
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.'
            });

            this.setState({
                assignment: null,
                isLoading: false
            });
        });
    }
}

export default withRouter(Review);
