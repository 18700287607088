import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import CmsPageProvider from '../../cms/CmsPageProvider.js';
import CmsTemplate from '../../cms/CmsTemplate.js';
import GlobalMessagesProvider from '../../Provider/GlobalMessagesProvider.js';
import GlobalMessages from '../../Misc/GlobalMessages.js';
import AccountNav from '../../Nav/AccountNav/AccountNav.js';
import AccountHome from '../../Account/View/AccountHome/AccountHome.js';
import AccountProfile from '../../Account/View/AccountProfile.js';
import AccountRequests from '../../Account/View/AccountRequests/AccountRequests.js';
import AccountNewRequest from '../../Account/View/AccountRequests/AccountNewRequest.js';
import AccountClients from '../../Account/View/AccountClients/AccountClients.js';
import AccountOffers from '../../Account/View/AccountOffers/AccountOffers.js';
import AccountNewOffer from '../../Account/View/AccountOffers/AccountNewOffer.js';
import AccountInterviews from '../../Account/View/AccountInterviews/AccountInterviews.js';
import AccountVacation from '../../Account/View/AccountVacation/AccountVacation.js';
import AccountExperience from '../../Account/View/AccountExperience/AccountExperience.js';
import AccountAssignments from '../../Account/View/AccountAssignments/AccountAssignments.js';
import AccountReviews from '../../Account/View/AccountReviews/AccountReviews.js';
import AccountReports from '../../Account/View/AccountReports/AccountReports.js'
import AccountSearch from '../../Account/View/AccountSearch/AccountSearch.js';
import AccountProjects from '../../Account/View/AccountProjects/AccountProjects.js';
import AccountLegal from '../../Account/View/AccountLegal/AccountLegal.js';
import AccountRates from '../../Account/View/AccountRates/AccountRates.js';
import AccountResource from '../../Account/View/AccountResource/AccountResource.js';
import AccountOrderHistory from '../../Account/View/AccountOrder/AccountOrderHistory.js';
import AccountOrderHistorySales from '../../Account/View/AccountOrder/AccountOrderHistorySales.js';
import ClientOrderHistory from '../../Account/View/AccountOrder/ClientOrderHistory.js';
import AccountOrderDetails from '../../Account/View/AccountOrder/AccountOrderDetails.js';
import AccountCreditNoteDetails from '../../Account/View/AccountOrder/AccountCreditNoteDetails.js';
import AccountOrderView from '../../Account/View/AccountOrder/AccountOrderView.js';
import SelectedResourceProvider from '../../Account/View/AccountSearch/SelectedResourceProvider.js';
import ResourceSelectedBar from '../../Account/View/AccountSearch/ResourceSelectedBar.js';
import UserHelper from '../../Helper/UserHelper.js';

export default class AccountPage extends Component {
    constructor(props) {
        super(props);

        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);

        this.state = {
            view: this.props.match.params.view,
            code: this.props.match.params.code
        };

        this.renderView = this.renderView.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.view !== prevProps.match.params.view
            || this.props.match.params.code !== prevProps.match.params.code) {
            this.setState({
                view: this.props.match.params.view,
                code: this.props.match.params.code
            });
        }
    }

    render() {
        return (
            <>
                <SelectedResourceProvider>
                    <CmsPageProvider props={{page:'account'}}>
                        <GlobalMessagesProvider>
                            <CmsTemplate>
                                <ResourceSelectedBar history={this.props.history}/>
                                <main>
                                    <Container className="mt-3 mb-3 ps-0 pe-0">
                                        <Row>
                                            <Col sm={12}>
                                                <GlobalMessages />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <h5 className="mb-3 text-uppercase fw-bold">My account</h5>
                                                <AccountNav selected={this.state.view}/>
                                            </Col>
                                        </Row>
                                        {this.renderView()}
                                    </Container>
                                </main>
                            </CmsTemplate>
                        </GlobalMessagesProvider>
                    </CmsPageProvider>
                </SelectedResourceProvider>
            </>
        )
    }

    renderView() {
        let isAccountant = UserHelper.isAccountant();
        let isSales = UserHelper.isSales();
        let isClient = UserHelper.isClient();

        switch (this.state.view) {
            case "profile":
                return <AccountProfile />;
            case "experience":
                return <AccountExperience />;
            case "legal":
                return <AccountLegal />;
            case "rates":
                return <AccountRates />;
            case "requests":
                return <AccountRequests />;
            case "request":
                return <AccountNewRequest />
            case "resource":
                return <AccountResource />;
            case "projects":
                return <AccountProjects />;
            case "clients":
                return <AccountClients />;
            case "client":
                return <AccountLegal />;
            case "assignments":
                return <AccountAssignments />;
            case "interviews":
                return <AccountInterviews />;
            case "offers":
                return <AccountOffers />;
            case "offer":
                return <AccountNewOffer />;
            case "search":
                return <AccountSearch />;
            case "reviews":
                return <AccountReviews />;
            case "reports":
                return <AccountReports />;
            case "vacation":
                return <AccountVacation />;
            case "orders":
                return isSales || isAccountant ? <AccountOrderHistorySales /> : (isClient ? <ClientOrderHistory/> : <AccountOrderHistory />);
            case "order":
                return <AccountOrderDetails orderCode={this.state.code} />;
            case "credit-note":
                return <AccountCreditNoteDetails orderCode={this.state.code} type="CREDIT_NOTE"/>;
            case "debit-note":
                return <AccountCreditNoteDetails orderCode={this.state.code} type="DEBIT_NOTE"/>;
            case "order-view":
                return <AccountOrderView/>;
            default:
                return <AccountHome />;
        }
    }

    handleOnAction (event) {
    }

    handleOnActive (event) {

    }

    handleOnIdle (event) {
    }
}
