import React from "react";
import {Container, Row, ListGroup, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function SocialWidget({widgetData}) {
    let className = widgetData.styleClass;
    return (
        <Container className={"text-end " + className}>
            <Row>
              <Col>
                <ListGroup horizontal className="float-lg-right">
                    <ListGroup.Item className="border-0"><h5 className="connect-text text-uppercase d-none d-sm-block">Connect:</h5></ListGroup.Item>
                    <ListGroup.Item className="social-icon border-0"><FontAwesomeIcon icon={["fab", "facebook"]} size="2x"/></ListGroup.Item>
                    <ListGroup.Item className="social-icon border-0"><FontAwesomeIcon icon={["fab", "twitter"]} size="2x"/></ListGroup.Item>
                    <ListGroup.Item className="social-icon border-0"><FontAwesomeIcon icon={["fab", "youtube"]} size="2x"/></ListGroup.Item>
                    <ListGroup.Item className="social-icon border-0"><FontAwesomeIcon icon={["fab", "instagram"]} size="2x"/></ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
        </Container>
    );
}
