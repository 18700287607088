import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import AccountResource from './AccountResource.js';

class AccountResourceModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null
        };
    }

    render() {
        return (
            <>
                {this.props.resource ?
                    <Modal centered size="lg" show={this.props.show} onHide={this.props.handleResourceModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-uppercase fw-bold">{this.props.resource.displayName} Preview CV</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AccountResource username={this.props.resource.code}/>
                        </Modal.Body>
                    </Modal>
                :null}
            </>
        )
    }
}

export default withRouter(AccountResourceModal);
