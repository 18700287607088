import React, {Component} from 'react';
import CmsSlot from "../cms/CmsSlot";
import {Container, Card, Button, Row, Col, Nav, Navbar} from 'react-bootstrap';

import './Footer.scss';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer mt-auto">
                <div>
                    <Container>
                        <div className="border-top my-3"></div>
                    </Container>
                    <Container className="text-center ps-0 pe-0">
                        <Row className="mx-0">
                          <Col lg={4} md={4} xs={12} className="mb-3">
                            <Card className="h-100">
                              <Card.Body>
                                <Card.Title className="fw-bold text-uppercase">For Coders</Card.Title>
                                <Card.Text>
                                  Supreme Contractors lists only the top talents in the IT industry. Register now and start earning money.
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer>
                                <Button variant="primary" href="/apply">Apply</Button>
                              </Card.Footer>
                            </Card>
                          </Col>
                          <Col lg={4} md={4} xs={12} className="mb-3">
                            <Card className="h-100">
                              <Card.Body>
                                <Card.Title className="fw-bold text-uppercase">For Managers</Card.Title>
                                <Card.Text>
                                    Manage your own supreme team with ease. Create CVs, plan capacity and send invoices - all from the same platform.
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer>
                                <Button variant="primary" href="/apply">Apply</Button>
                              </Card.Footer>
                            </Card>
                          </Col>
                          <Col lg={4} md={4} xs={12} className="mb-3">
                            <Card className="h-100">
                              <Card.Body>
                                <Card.Title className="fw-bold text-uppercase">For Sales</Card.Title>
                                <Card.Text>
                                    Create and combine your own supreme team for different projects.
                                </Card.Text>
                              </Card.Body>
                              <Card.Footer>
                                 <Button variant="primary" href="/apply">Apply</Button>
                              </Card.Footer>
                            </Card>
                          </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <Container className="text-center">
                        <CmsSlot position="Social"/>
                    </Container>
                </div>
                <Navbar expand="lg" className="bg-primary ">
                    <Container>
                        <Nav className="mx-auto ms-md-0">
                            <Nav.Link className="text-uppercase small text-white" href="/privacy">Privacy Policy</Nav.Link>
                            <Nav.Link className="text-uppercase small text-white" href="/terms-and-conditions">Terms &amp; Conditions</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </footer>
        )
    }
}
