import ApiCall from './ApiCall.js';
import UserHelper from './UserHelper.js';

export default class CommonFunctions {

    static createInterviewFromInterviewRequest(slotCode, progressBarContext, successCallback, errorCallback) {
        progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.post('interview/slot/' + slotCode).then((response) => {
            successCallback();

            progressBarContext.dispatch({type: 'update', progress: 100});
            UserHelper.showSuccessToast("Interview created!");
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }
}