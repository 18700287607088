import React, {Component} from 'react';
import ApiCall from '../../../Helper/ApiCall.js';
import {withRouter} from 'react-router-dom';
import Calendar from '@supreme-contractors/rc-year-calendar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Row, Col, Card, Button} from 'react-bootstrap';
import UserHelper from '../../../Helper/UserHelper.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountVacation extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedContractorCode : null,
            contractors:[],
            nonWorkingDays: [],
            dataSource: [],
            notificationStarted:false
        }
        this.getContractors = this.getContractors.bind(this);
        this.getVacation = this.getVacation.bind(this);
        this.getAllVacations = this.getAllVacations.bind(this);
        this.getPublicHolidays = this.getPublicHolidays.bind(this);
        this.rangeSelected = this.rangeSelected.bind(this);
        this.saveVacation = this.saveVacation.bind(this);
        this.deleteVacation = this.deleteVacation.bind(this);
        this.notifyManagerForVacationChanges = this.notifyManagerForVacationChanges.bind(this);
    }

    componentDidMount() {
        //this.getPublicHolidays();
        if (UserHelper.isContractor()) {
            this.getVacation(UserHelper.getCustomerCode());
        }
        if (UserHelper.isClient() || UserHelper.isSuperSales()) {
            this.getAllVacations()
        }
    }

    render() {
        let isContractor = UserHelper.isContractor();
        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                {this.state.dataSource ?
                                    <>
                                    <Card.Title className="text-uppercase fw-bold">
                                        Vacations/Public Holidays
                                        {isContractor?
                                            <Button variant="secondary" className="float-end text-uppercase" onClick={this.notifyManagerForVacationChanges}>
                                                <span className="icon icon-white me-1">
                                                    {this.state.notificationStarted ?
                                                        <FontAwesomeIcon icon="spinner" spin/>:<FontAwesomeIcon icon="envelope" />
                                                    }
                                                </span>
                                                <span className="text">Notify Manager</span>
                                            </Button>
                                        :""}
                                    </Card.Title>
                                    <p className="text-warning">Please select the days in the year that you will not be available for work (select multiple days by dragging,  right-click to delete).</p>
                                    <Calendar style="background" minDate={new Date()} weekStart="1" dataSource={this.state.dataSource}
                                    onYearChanged={(event) => this.currentYear = event.currentYear} enableRangeSelection="true" onRangeSelected={this
                                    .rangeSelected} enableContextMenu="true" onDayContextMenu={this.deleteVacation}
                                    />
                                    {this.state.dataSource && this.state.dataSource.length > 0 ?
                                        <>
                                            <hr/>
                                            <div id="events-log">
                                              <Card.Title className="text-uppercase fw-bold">Legend</Card.Title>
                                              {this.state.dataSource.map(event => <div key={event.code}><span className="border me-1" style={{'background' : event.color}}>&nbsp;&nbsp;&nbsp;&nbsp;</span>{event.name}: {event.startDate.toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}-{event.endDate.toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</div>)}
                                            </div>
                                        </>
                                    :""}
                                    </>
                                :""}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }

    notifyManagerForVacationChanges() {
        this.setState({notificationStarted:true});
        ApiCall.post('vacation/notify').then((response) => {
            UserHelper.showSuccessToast('Your manager was notified.');
            this.setState({notificationStarted:false});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({notificationStarted:false});
        });
    }

    deleteVacation(event) {
        event.preventDefault();
        let code = "";
        event.events.forEach(e=> {
            code = e.id;
        });

        ApiCall.delete('vacation/' + code).then((response) => {
            UserHelper.showSuccessToast('Success.');
            this.getVacation();
            //this.getPublicHolidays();
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    rangeSelected(event) {
        let isContractor = UserHelper.isContractor();
        if (isContractor) {
            this.saveVacation(event.startDate, event.endDate);
        }
    }

    saveVacation(startDate, endDate) {
        ApiCall.put('vacation', {}, {startDate:Math.floor( startDate / 1 ), endDate:Math.floor( endDate / 1 )}).then((response) => {
            UserHelper.showSuccessToast('Success.');
            this.getVacation();
            //this.getPublicHolidays();
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    getContractors() {
        ApiCall.get('supreme-customer/contractorgroup',{
                projection: "supreme.contractors.backend.facade.dto.BasicNameableDtoDefinition",
                size: 1000
            }).then((response) => {
            let contractors = [];
            response.data.content.forEach(r => {
                contractors.push({value: r.code, label: r.displayName});
            });
            this.setState({
                contractors: contractors
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    getVacation(contractorCode) {
        let url = "vacation";
        if (contractorCode) {
            url = url + "/user/" + contractorCode;
        }
        ApiCall.get(url, {year:this.currentYear}).then((response) => {
            let vacations = response.data;

            let dataSource = [];
            vacations.forEach(vacation=> {
                dataSource.push({id: vacation.code, name: vacation.name, startDate: new Date(vacation.startDate), endDate: new Date(vacation.endDate), color:
                "var(--bs-secondary)"});
            });

            this.setState({
                dataSource: dataSource
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    getAllVacations() {
        let url = "vacation/all";
        ApiCall.get(url, {year:this.currentYear}).then((response) => {
            let vacations = response.data;

            let dataSource = [];
            vacations.forEach(vacation=> {
                dataSource.push({id: vacation.code, name: vacation.name, startDate: new Date(vacation.startDate), endDate: new Date(vacation.endDate), color: '#' + (Math.floor(Math.random() * 2 ** 24)).toString(16).padStart(0, 6)});
            });
            this.setState({
                dataSource: dataSource
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    getPublicHolidays() {
        ApiCall.get('vacation/holidays/BG').then((response) => {
            let publicHolidays = response.data;

            let dataSource = this.state.dataSource;
            publicHolidays.forEach(ph=> {
                dataSource.push({id: ph.code, name: ph.name, startDate: new Date(ph.startDate), endDate: new Date(ph.endDate), color: "var(--bs-dark)"});
            });

            this.setState({
                dataSource: dataSource
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });


    }
}

export default withRouter(withProgressBarContext(AccountVacation));
