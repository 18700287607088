import React from "react";
import {ButtonGroup, Button} from 'react-bootstrap';
import FacetListerLoader from './FacetListerLoader.js';

export default function SearchAvailableFacet(props) {
    let facet = null;
    let termFacetContent = null;
    let foundSelectedFacetValue = false;

    if (!props.isLoading) {
        if (props.searchPageData && props.searchPageData.facets) {
            facet = props.searchPageData.facets['available'];
        }

        if (!facet) {
            return null;
        }

        if (facet.values) {
            termFacetContent = facet.values.map(function(facetValue, key) {
                if (facetValue.selected) {
                    foundSelectedFacetValue = true;
                }

                let facetValueVariant;
                if (facetValue.selected) {
                    facetValueVariant = facetValue.code==='true' ? "success" : "danger";
                } else {
                    facetValueVariant = facetValue.code==='true' ? "outline-success" : "outline-danger";
                }

                return <Button key={facetValue.code} variant={facetValueVariant} onClick={() => props.updateUrl(null, null, facetValue.currentQuery.url)}>{facetValue.code ==='true' ? 'Available':'Busy'}</Button>
            });

        }
    }

    return (
        <React.Fragment>
            {props.isLoading ?
                <FacetListerLoader width={'65%'} x={'35%'}/>
            :
                <ButtonGroup aria-label="Available" toggle="true" className="float-end mb-2">
                    <Button key={"all"} variant={foundSelectedFacetValue ? "outline-primary": "primary"} disabled={!foundSelectedFacetValue} onClick={() => props.updateUrl(null, null, facet.clearQuery.url)}>All</Button>
                    {termFacetContent}
                </ButtonGroup>
            }
        </React.Fragment>
    );
}
