import React from "react";
import {Card, Accordion} from 'react-bootstrap';

export default function Faq(props) {
    return (
        <Card>
            <Card.Header>
                <Card.Title className="text-uppercase fw-bold text-center">
                    Welcome
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>I just registered. Now what?</Accordion.Header>
                        <Accordion.Body>
                            <div className="embed-video">
                                <iframe src="https://www.youtube.com/embed/2Fxjj4TghoU" frameBorder="0" title="SupremeContractors Intro"
                                allow="accelerometer; autoplay;clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>I received email to update my timesheet. Where is the timesheet?</Accordion.Header>
                        <Accordion.Body>
                          The email from SupremeContractors about not forgetting to update your timesheet refers about the timesheet for the client. Usually the url for this timesheet is
                          provided by the client and that's where you need to put your hours in.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>What do the different invoice statuses mean?</Accordion.Header>
                        <Accordion.Body>
                            <ul>
                                <li>
                                    <code>CREATED</code> This is the first status of every invoice. It means the invoice is created or being created.
                                </li>
                                <li>
                                    <code>ACCEPTED</code> This means that the client has received our invoice, they have reviewed it, they have checked if the declared hours match with the reported hours in their timesheet,
                                    and they have <b>accepted</b> and downloaded the invoice. Now that this is done, the payment from our side to you can begin.
                                </li>
                                <li>
                                    <code>REJECTED</code> Something was wrong wih the invoice you have created and someone from our team will reach out to you to assist you.
                                </li>
                                <li>
                                    <code>PAID</code> Means the invoice was paid from our side and the money will be in your bank account soon.
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card.Body>
        </Card>
    );
}
