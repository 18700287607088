import React from 'react';
import {Container} from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

/**
 * This is the default layout when no frontendTemplateName is set and no CmsTemplate is used.
 * Should be used only for internal server error pages and so on.
 * @param props
 * @returns {*}
 * @constructor
 */
export default function NoServerResponseTemplate(props) {
    return (
        <>
            <div className="wrap">
                <Container>
                    <Header/>
                    {props.children}
                </Container>
            </div>
            <Footer/>
        </>
    );
}
