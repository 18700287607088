import * as React from 'react';
import * as changeCase from "change-case";

import {PageContext} from "./CmsPageProvider";

export default function CmsWidget(props) {
    return <PageContext.Consumer>
        {value => {
            if (props.widgetData) {
                try {
                        let Component = require('./' + changeCase.pascalCase(props.widgetData.entityName) + '.js').default;
                        return <Component {...props} />;
                    } catch (e) {
                        return <div> Component not found, please create a default component into components/cms/ called {changeCase.pascalCase(props.widgetData.entityName)} in a file {changeCase.pascalCase(props.widgetData.entityName)}.js</div>
                    }
                } else {
                    return null;
                }
            }
        }
    </PageContext.Consumer>
}
