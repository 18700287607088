import React, {Component} from 'react';
import {Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';

class LanguageForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            locales: [],
            levels: [],
            languageForm: {
                code: null,
                locale: -1,
                level: -1
            }
        };

        this.getLocales = this.getLocales.bind(this);
        this.getLevels = this.getLevels.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeLanguageForm = this.handleChangeLanguageForm.bind(this);
        this.handleSubmitAddEditLanguageForm = this.handleSubmitAddEditLanguageForm.bind(this);
        this.clearLanguageForm = this.clearLanguageForm.bind(this);
    }

    componentDidMount() {
        this.getLocales();
        this.getLevels();
        if (this.props.editLanguage && !UserHelper.isEquivalent(this.props.editLanguage, this.state.languageForm)) {
            this.setState({
                    languageForm: {
                        code: this.props.editLanguage ? this.props.editLanguage.code : null,
                        locale: this.props.editLanguage ? this.props.editLanguage.locale : -1,
                        level: this.props.editLanguage ? this.props.editLanguage.level : -1
                    }
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        let localeOptions = [];
        localeOptions.push(<option disabled value={-1} key={-1}>Please select...</option>);
        localeOptions.push(this.state.locales.map(function(locale, key){
            return <option key={locale.code} value={locale.code}>{locale.name}</option>;
        }));

        let levelOptions = [];
        levelOptions.push(<option disabled value={-1} key={-1}>Please select...</option>);
        levelOptions.push(this.state.levels.map(function(level, key){
            return <option key={key} value={level}>{level}</option>;
        }));

        return (
            <React.Fragment>
                <Form id="languageForm" onSubmit={this.handleSubmitAddEditLanguageForm}>
                    <Form.Group as={Row} controlId="locale" className="mb-3">
                        <Col md={4} lg={4}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="globe" /></InputGroup.Text>
                                <Form.Select name="locale" value={this.state.languageForm.locale} required onChange={this
                                .handleInputChange}>
                                    {localeOptions}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={4} lg={4}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="crown" /></InputGroup.Text>
                                <Form.Select name="level" defaultValue={this.state.languageForm.level} required onChange={this.handleInputChange}>
                                    {levelOptions}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={1} lg={1}>
                            <Button variant="primary" type="submit" className="float-end text-uppercase">Save</Button>
                        </Col>
                        <Col md={3} lg={3}>
                            <Button variant="link" onClick={() =>this.props.cancelLanguage()}>Cancel</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </React.Fragment>
        )
    }

    handleChangeLanguageForm(field, value) {
        let newLanguageForm = this.state.languageForm;
        newLanguageForm[field] = value;

        this.setState({
            languageForm: newLanguageForm
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.handleChangeLanguageForm(name, value);
    }

    getLocales() {
        UserHelper.getLocales().then(response => {
            if (response.locales && response.locales.length) {
                this.setState({
                    locales: response.locales
                });
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);

                this.setState({
                    locales: []
                });
            }
        });
    }

    getLevels() {
        UserHelper.getLevels().then(response => {
            if (response.levels && response.levels.length) {
                this.setState({
                    levels: response.levels
                });
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);

                this.setState({
                    levels: []
                });
            }
        });
    }

    handleSubmitAddEditLanguageForm(event) {
        event.preventDefault();

        if (this.state.languageForm.level && this.state.languageForm.level) {
            let language = {
                code: this.state.languageForm.code,
                locale: this.state.languageForm.locale,
                level: this.state.languageForm.level
            };

            if (this.props.editLanguage) {
                ApiCall.put('language', language).then(() => {
                    this.clearLanguageForm();
                    this.props.afterEditLanguageCompetence(language);
                }).catch(error => {
                    UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                });
            } else {
                ApiCall.post('language', language).then(() => {
                    this.clearLanguageForm();

                    UserHelper.showSuccessToast("Your language was added.");

                    this.props.getLanguages();
                }).catch(error => {
                    UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                });
            }
        }
    }

    clearLanguageForm() {
        this.setState({
            languageForm: {
                code: null,
                locale: "bg_BG",
                level: "ADVANCED"
            }
        });
    }
}

export default LanguageForm;
