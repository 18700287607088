import React from "react";
import {Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export default function FooterCmsWidget({widgetData}) {
    let className = widgetData.styleClass;
    return (
        <>
            {widgetData.navigationNodes.map((navNode, index) => {
                return <Col key={index}>
                     <h6 className={"text-uppercase mb-4 fw-bold " + className}>{navNode.name}</h6>
                     {navNode.links.map((navNodeLink, index1) => {
                        return <p key={navNodeLink.code}><Link to={navNodeLink.url} key={navNodeLink.code+"_link"}>{navNodeLink.linkName}</Link></p>
                     })}
                </Col>
            })}
        </>
    );
}
