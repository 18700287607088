import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import UserHelper from './UserHelper.js';

export const PrivateRoute = ({ component: Component,...rest}) => (
    <Route {...rest}
        render={(props => {
                return UserHelper.isUserLoggedIn() ? (<Component {...props} />) : (<Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                  }} />)
            }
    )} />
);
