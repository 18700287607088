import React, {Component} from 'react';
import ContentLoader from "react-content-loader";
import {Card, Row, Col} from 'react-bootstrap';

const ProductLoader = () => (
    <Card className="h-100">
        <Row className="p-3">
            <Col lg={4} xs={4}>
                <div className="position-relative text-start">
                    <ContentLoader
                        width={75}
                        height={75}
                        viewBox="0 0 150 150"
                        speed={1}
                        backgroundColor="var(--bs-body-bg)"
                        foregroundColor="var(--bs-muted)"
                    >
                        <circle cx="75" cy="75" r="75" />
                    </ContentLoader>
                </div>
            </Col>
            <Col lg={6} xs={6}>
                <ContentLoader
                    width="100"
                    height={60}
                    viewBox="0 0 100 60"
                    speed={1}
                    backgroundColor="var(--bs-body-bg)"
                    foregroundColor="var(--bs-muted)"
                >
                    <rect x="0" y="0" rx="3" ry="3" width="100" height="25" />
                    <rect x="0" y="30" rx="3" ry="3" width="75" height="25" />
                </ContentLoader>
            </Col>
            <Col lg={2} xs={2}>
                <ContentLoader
                    width={43}
                    height={38}
                    viewBox="0 0 43 38"
                    speed={1}
                    backgroundColor="var(--bs-body-bg)"
                    foregroundColor="var(--bs-muted)"
                    style={{"position":"absolute", "top":"15px", "right":"20px"}}
                >
                    <rect x="0" y="0" rx="3" ry="3" width="43" height="38" />
                </ContentLoader>
            </Col>
        </Row>
        <Card.Body className="position-relative">
        <Card.Title className="mx-auto text-center fw-bold my-2">
            <ContentLoader
                width="250"
                height={25}
                viewBox="0 0 250 25"
                speed={1}
                backgroundColor="var(--bs-body-bg)"
                foregroundColor="var(--bs-muted)"
            >
                <rect x="0" y="0" rx="3" ry="3" width="250" height="25" />
            </ContentLoader>
        </Card.Title>
        <Row className="my-2 text-center mx-auto">
            <Col>
                <ContentLoader
                    width={130}
                    height={25}
                    viewBox="0 0 130 25"
                    speed={1}
                    backgroundColor="var(--bs-body-bg)"
                    foregroundColor="var(--bs-muted)"
                >
                    <rect x="0" y="0" rx="3" ry="3" width="25" height="25" />
                    <rect x="35" y="0" rx="3" ry="3" width="25" height="25" />
                    <rect x="70" y="0" rx="3" ry="3" width="25" height="25" />
                    <rect x="105" y="0" rx="3" ry="3" width="25" height="25" />
                </ContentLoader>
            </Col>
        </Row>
        <Row className="mx-auto fs-5 my-4 text-center text-muted">
            <Col>
                <ContentLoader
                    width={190}
                    height={30}
                    viewBox="0 0 190 30"
                    speed={1}
                    backgroundColor="var(--bs-body-bg)"
                    foregroundColor="var(--bs-muted)"
                >
                    <rect x="0" y="0" rx="3" ry="3" width="50" height="30" />
                    <rect x="70" y="0" rx="3" ry="3" width="50" height="30" />
                    <rect x="140" y="0" rx="3" ry="3" width="50" height="30" />
                </ContentLoader>
            </Col>
        </Row>
        <div>
            <ContentLoader
                width={200}
                height={45}
                viewBox="0 0 200 45"
                speed={1}
                backgroundColor="var(--bs-body-bg)"
                foregroundColor="var(--bs-muted)"
            >
                <rect x="0" y="0" rx="3" ry="3" width="45" height="20" />
                <rect x="50" y="0" rx="3" ry="3" width="45" height="20" />
                <rect x="100" y="0" rx="3" ry="3" width="45" height="20" />
                <rect x="150" y="0" rx="3" ry="3" width="45" height="20" />

                <rect x="0" y="25" rx="3" ry="3" width="45" height="20" />
                <rect x="50" y="25" rx="3" ry="3" width="45" height="20" />
                <rect x="100" y="25" rx="3" ry="3" width="45" height="20" />
                <rect x="150" y="25" rx="3" ry="3" width="45" height="20" />
            </ContentLoader>
        </div>
    </Card.Body>
    </Card>
);

export default class ResourceListerGridItemLoader extends Component {
    render() {
        return (
            <ProductLoader />
        )
    }
}
