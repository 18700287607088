import React, {useContext} from "react";
import {Card, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ListerContext} from '../Provider/ListerPageProvider.js';

import './SearchFacetTermProfile.scss';

export default function SearchFacetTermProfile({widgetData}) {
    let listerContext = useContext(ListerContext);
    let facet = null;
    let termFacetContent = null;

    if (listerContext.state.searchPageData && listerContext.state.searchPageData.facets) {
        facet = listerContext.state.searchPageData.facets[widgetData.facetCode];
    }

    if (!facet) {
        return null;
    }

    if (facet.values) {
        if (facet.color) {
            termFacetContent = facet.values.map(function(facetValue, key) {
                return <div key={key} onClick={() => listerContext.getProducts(null, null, facetValue.currentQuery.url)}>
                    <span className="fa-layers fa-fw" style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon="square" size="lg" color={facetValue.code ? facetValue.code : "var(--bs-white)"} />
                        {facetValue.selected && 
                            <FontAwesomeIcon icon="check" size="xs" color="var(--bs-light)" />
                        }
                    </span>
                    <div className="d-inline ms-2"><small style={{cursor: "pointer"}}>{facetValue.name} ({facetValue.count})</small></div>
                </div>; 
            });
        } else {
            termFacetContent = facet.values.map(function(facetValue, key) {
                if (facet.multiSelect) {
                    return <div key={key} className="filters-body-wrapper">
                        <Form.Check type="checkbox" checked={facetValue.selected} label={facetValue.name + ' (' + facetValue.count + ')'} 
                                onChange={() => listerContext.getProducts(null, null, facetValue.currentQuery.url)}/>
                    </div>;
                } else return <div key={key} onClick={() => listerContext.getProducts(null, null, facetValue.currentQuery.url)}>
                    <small style={{cursor: "pointer"}}>{facetValue.name} ({facetValue.count})</small>
                </div>; 
            });
        }
    }

    return (
        <Card className={"mb-3 " + widgetData.styleClass}>
            <Card.Header className="fw-bold text-uppercase">{facet.name}</Card.Header>
            <Card.Body className="p-2">
                {termFacetContent}
            </Card.Body>
        </Card>
    );
}
