import React, {Component} from "react";

import CommerceHelper from '../Helper/CommerceHelper.js';
import { properties } from '../../properties.js';

export const ProductContext = React.createContext();

export default class ProductProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: null
        }

        this.getProduct = this.getProduct.bind(this);
    }

    componentDidMount() {
        this.getProduct();
    }

    componentDidUpdate(prevProps) {
        if (this.props.productCode !== prevProps.productCode) {
            this.getProduct();
        }
    }

    render() {
        return (
            <ProductContext.Provider value={{
                state: this.state,
                getProduct: this.getProduct
            }}>
                {this.props.children}
            </ProductContext.Provider>
        );
    }

    getProduct() {       
        CommerceHelper.getProduct(this.props.productCode, properties.productDetailsProjection).then(response => {
            if (response.errorMsg) {
                console.log(response.errorMsg);
            } else {
                this.setState({
                    product: response.product
                });
            }
        });
    }
}

const withProductContext = (Component) => {
    return (props) => (
        <ProductContext.Consumer>
                {context =>  <Component {...props} productContext={context} />}
        </ProductContext.Consumer>
    )
}

export {withProductContext}
