import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import UserHelper from '../../Helper/UserHelper.js';
import ApiCall from '../../Helper/ApiCall.js';
import {Card, Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import CalendarSlotSelector from '../../Misc/CalendarSlotSelector.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Apply.scss';
import {withProgressBarContext} from '../../Provider/ProgressBarProvider.js';

class Apply extends Component {

    constructor(props){
        super(props);

        this.state = {
            date: null,
            selectedTimeSlot: null,
            name: null,
            email: null,
            isLoading:false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitAssessment = this.submitAssessment.bind(this);
    }

    render() {
        return (
            <Card className="text-center">
                <div className="text-center">
                    <div className="videoWrapper">
                        <iframe width="560" height="315" className="mw-100 mt-3" src="https://www.youtube.com/embed/t4E4HhUrotg" frameBorder="0"
                        title="SupremeContractors" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
                <Card.Body>
                    <CalendarSlotSelector granularity={15} selectTimeSlot={(timeSlotsArray)=>this.selectTimeSlot(timeSlotsArray)} singleSelect={true}/>
                    <Row className="mt-3 text-center" ref={el => { this.detailsFormRef = el; }}>
                        {this.state.selectedTimeSlot ?
                            <Col lg={12} className="border-top">
                                <h4 className="mb-3 mt-3">Enter your name and email.</h4>
                                <h5 className="mb-3 mt-3">You will receive a calendar invite for the interview.(Please also check the spambox)</h5>
                                <Row>
                                    <Col>
                                        <Form onSubmit={this.handleSubmit} id="assessment-form">
                                            <Form.Group as={Row} controlId="formAssessmentName" className="mb-3">
                                                <Form.Label column sm={3} md={12} lg={3}>
                                                    Name*
                                                </Form.Label>
                                                <Col sm={9} md={12} lg={9}>
                                                    <InputGroup>
                                                        <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                                        <Form.Control name="name" placeholder="Name" required onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formAssessmentEmail" className="mb-3">
                                                <Form.Label column sm={3} md={12} lg={3}>
                                                    Email address*
                                                </Form.Label>
                                                <Col sm={9} md={12} lg={9}>
                                                    <InputGroup>
                                                        <InputGroup.Text><FontAwesomeIcon icon="envelope" /></InputGroup.Text>
                                                        <Form.Control name="email" type="email" placeholder="Email" required onChange={this.handleInputChange}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formAssessmentSubmit" className="mb-3">
                                                <Col sm={12} md={12} lg={12} className="float-end">
                                                    <Button variant="primary" type="submit" className="float-end text-uppercase mt-2 mt-lg-0" disabled={this
                                                    .state.isLoading}>Submit</Button>
                                                </Col>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        :null}
                    </Row>
                </Card.Body>
            </Card>
        )
    }

    selectTimeSlot(timeSlotsArray) {
        if (timeSlotsArray && timeSlotsArray.length === 1 && timeSlotsArray[0].timeSlot.available) {
            let timeSlotObj = timeSlotsArray[0];
            this.setState({date:timeSlotObj.date, selectedTimeSlot:timeSlotObj.timeSlot});
            this.detailsFormRef.scrollIntoView({ behavior: "smooth" });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.state.date) {
            UserHelper.showErrorToast('Please select a date!');
            return;
        }

        if (!this.state.selectedTimeSlot) {
            UserHelper.showErrorToast('Please select a time slot!');
            return;
        }

        if (!this.state.name) {
            UserHelper.showErrorToast('Please enter your name!');
            return;
        }

        if (!this.state.email) {
            UserHelper.showErrorToast('Please enter your email!');
            return;
        }

        this.submitAssessment();
    }

    submitAssessment() {
        this.setState({isLoading:true});
        let formattedDate = UserHelper.formatDate(this.state.date.getDate(),2)  + "-" + this.state.date.toLocaleString('en-us', { month: 'short' }) + "-" + this.state.date.getFullYear();
        ApiCall.post('calendar/assessment', {
            name: this.state.name,
            email: this.state.email,
            date: formattedDate,
            time: this.state.selectedTimeSlot.start
        }, null).then(() => {
            this.props.history.push({
                pathname: "/login",
                state: { globalInfoMsgs: ["Your calendar invitation was set!"] }
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });

    }
}

export default withRouter(withProgressBarContext(Apply));
