import React, {useContext, useEffect, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {Card, Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';
import AccountCompanyDetails from './AccountCompanyDetails.js';
import BootstrapModal from '../../../Misc/BootstrapModal.js';
import PreviewDocument from '../../../Misc/PreviewDocument.js';
import {ProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

export default function AccountLegal(props) {
    let history = useHistory();

    const progressBar = useContext(ProgressBarContext);
    const signatureRef = useRef(null);

    const styles = {
        marginLeft: 'auto',
        marginRight: 'auto',
        border: 'var(--bs-modal-border-width) solid var(--bs-modal-border-color)',
        borderRadius: "var(--bs-modal-border-radius)",
    };

    const [previewModal, setPreviewModal] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [signatureModal, setSignatureModal] = useState(false);
    const [downloadContractStarted, setDownloadContractStarted] = useState(false);
    const [downloadNdaStarted, setDownloadNdaStarted] = useState(false);
    const [downloadGdprStarted, setDownloadGdprStarted] = useState(false);
    const [countries, setCountries] = useState([]);
    const [companyDetails, setCompanyDetails] = useState({bankDetails:{}});

    const submitSignature = (event) => {
        signatureRef.current.exportImage("png").then(data => {
            ApiCall.post('account/signature', {data:data}).then((response) => {
                UserHelper.showSuccessToast("Signature updated!");
                setSignatureModal(false);
                setPreviewModal(false);
                setPreviewUrl(null);
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const downloadContract = (event) => {
     progressBar.dispatch({type: 'update', progress: 20});
        setDownloadContractStarted(true);
        fetch(process.env.REACT_APP_FACADE_URL + 'supreme-organization/contract', {
            method: "GET",
            headers: {
              'Accept': "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-Master-Service-Agreement.pdf';
                    a.click();
                });
                setDownloadContractStarted(false);
                progressBar.dispatch({type: 'update', progress: 100});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    const downloadNda = (event) => {
     progressBar.dispatch({type: 'update', progress: 20});
        setDownloadNdaStarted(true);
        fetch(process.env.REACT_APP_FACADE_URL + 'supreme-organization/nda', {
            method: "GET",
            headers: {
              'Accept': "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-Non-Disclosure-Agreement.pdf';
                    a.click();
                });
               setDownloadNdaStarted(false);
               progressBar.dispatch({type: 'update', progress: 100});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    const downloadGdpr = (event) => {
        progressBar.dispatch({type: 'update', progress: 20});
        setDownloadGdprStarted(true);
        fetch(process.env.REACT_APP_FACADE_URL + 'supreme-organization/gdpr', {
            method: "GET",
            headers: {
              'Accept': "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-GDPR-Data-Processing-Agreement.pdf';
                    a.click();
                });
                setDownloadGdprStarted(false);
                progressBar.dispatch({type: 'update', progress: 100});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    const handleInputChange = (event) => {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                let currentBankDetailsDetails = companyDetails.bankDetails;
                currentBankDetailsDetails[name] = value;

                setCompanyDetails((prevState) => {
                    prevState.bankDetails = currentBankDetailsDetails;
                    return({
                      ...prevState
                    })
                });
            } else {
                let newClientEmployees = [];
                event.forEach(sel => {
                    newClientEmployees.push({value:sel.value, label:sel.label});
                });
                this.setState({recipients : newClientEmployees});
            }
        }
    }

    const handleSubmitUpdateBank = (event) => {
        progressBar.dispatch({type: 'update', progress: 20});
        event.preventDefault();
        let country = countries.find(country => {
            return country.code === companyDetails.bankDetails.bankAddressCountryCode
        });

        let bank = {
            code: companyDetails.bankDetails.bankCode,
            name: companyDetails.bankDetails.bankName,
            iban: companyDetails.bankDetails.bankIban,
            swift: companyDetails.bankDetails.bankSwift,
            address: {
                "@class":"io.nemesis.platform.facade.user.dto.AddressDto",
                code: companyDetails.bankDetails.bankAddressCode,
                line1: companyDetails.bankDetails.bankAddressLine1,
                line2: companyDetails.bankDetails.bankAddressLine2,
                town: companyDetails.bankDetails.bankAddressCity,
                postalcode: companyDetails.bankDetails.bankAddressPostCode,
                country: country
            }
        };

        let url = null;
        let isSales = UserHelper.isSales();
        if (!isSales) {
            url = "bank/contractor";
        } else {
            if (companyDetails.companyCode) {
                url = "bank/" + companyDetails.companyCode;
            }
        }
        if (url) {
            ApiCall.put(url, bank).then(() => {
                UserHelper.showSuccessToast("Your bank details were updated");
                 progressBar.dispatch({type: 'update', progress: 100});
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                 progressBar.dispatch({type: 'update', progress: 100});
            });
        }
    }

    let countryOptions = countries.map(function(country, key){
        return <option key={key} value={country.code}>{country.name}</option>;
    });

    let isManager = UserHelper.isManager();
    let isClient = UserHelper.isClient();
    let isIndividualContractor = UserHelper.isIndividualContractor();

    let companyDetailsValid = companyDetails.companyName && companyDetails.companyRegisterNumber &&
                              companyDetails.companyAddressLine1 && companyDetails.companyAddressPostCode &&
                              companyDetails.companyAddressCity && companyDetails.companyAddressCountryCode;


    useEffect(() => {
        progressBar.dispatch({type: 'update', progress: 20});

        let url = null;

        let projection = "supreme.contractors.backend.facade.dto.SupremeOrganizationDtoDefinition";
        if (history.location && history.location.hash && UserHelper.isSales()) {
            let organizationCode = history.location.hash.substring(1);
            url = "client-organization/" + organizationCode;
        } else {
            url = "supreme-organization";
        }

        if (url != null) {
            ApiCall.get(url, {projection:projection}).then((response) => {
                let organization = response.data;

                let recipients = [];
                if (organization.defaultRecipients) {
                    organization.defaultRecipients.forEach(customer => {
                        recipients.push({value:customer.code, label:customer.displayName});
                    });
                }

                let accountants = [];
                if (organization.accountants) {
                    organization.accountants.forEach(customer => {
                        accountants.push({value:customer.code, label:customer.displayName});
                    });
                }
                setCompanyDetails({
                    companyCode: organization.code,
                    companyName: organization.legalName,
                    companyRegisterNumber: organization.vatCode,
                    recipients: recipients,
                    accountants: accountants,
                    companyDirectorFirstName: organization.contactPoint ? organization.contactPoint.firstName : "",
                    companyDirectorLastName: organization.contactPoint ? organization.contactPoint.lastName : "",
                    companyAddressCode: organization.contactPoint ? organization.contactPoint.code : "",
                    companyAddressLine1: organization.contactPoint ? organization.contactPoint.line1 : "",
                    companyAddressLine2: organization.contactPoint ? organization.contactPoint.line2 : "",
                    companyAddressCity: organization.contactPoint ? organization.contactPoint.town : "",
                    companyAddressPostCode: organization.contactPoint ? organization.contactPoint.postalcode : "",
                    companyAddressEmail: organization.contactPoint ? organization.contactPoint.email : "",
                    companyAddressCountryCode: organization.contactPoint && organization.contactPoint.country ? organization.contactPoint.country.code : "",
                    bankDetails: {
                        bankCode: organization.bank ? organization.bank.code : "",
                        bankName: organization.bank ? organization.bank.name : "",
                        bankIban: organization.bank ? organization.bank.iban : "",
                        bankSwift: organization.bank ? organization.bank.swift : "",
                        bankAddressCode: organization.bank  && organization.bank.address ? organization.bank.address.code : "",
                        bankAddressLine1: organization.bank  && organization.bank.address ? organization.bank.address.line1 : "",
                        bankAddressLine2: organization.bank  && organization.bank.address ? organization.bank.address.line2 : "",
                        bankAddressCity: organization.bank  && organization.bank.address ? organization.bank.address.town : "",
                        bankAddressPostCode: organization.bank && organization.bank.address  ? organization.bank.address.postalcode : "",
                        bankAddressCountryCode: organization.bank  && organization.bank.address  && organization.bank.address.country ?
                        organization.bank.address.country.code : ""
                    }
                });
                 progressBar.dispatch({type: 'update', progress: 40});
            }).catch(error => {
               UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.', true);
            });
        }

        UserHelper.getCountries().then(response => {
            if (response.countries && response.countries.length) {
                setCountries(response.countries);
                  progressBar.dispatch({type: 'update', progress: 100});
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);
                setCountries([]);
                progressBar.dispatch({type: 'update', progress: 0});
            }
        });


    }, []);

    return (
        <>
            <Row className="mt-3">
                <Col xs={12} md={6}>
                    <AccountCompanyDetails countries={countries} companyDetails={companyDetails} canBeIndividualContractor={true}/>
                </Col>
                <Col xs={12} md={6} className="mt-3 mt-md-0">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-uppercase fw-bold">
                                Bank Details
                            </Card.Title>

                            <p>Please use this form to update your bank details</p>

                            <p className="text-warning">Fields marked with * are required</p>

                            <Form onSubmit={handleSubmitUpdateBank}>
                                <Form.Group as={Row} controlId="bankName" className="mb-3">
                                    <Form.Label column lg={3}>
                                        Name*
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="piggy-bank" /></InputGroup.Text>
                                            <Form.Control name="bankName" type="text" placeholder="Bank Name" defaultValue={companyDetails.bankDetails.bankName} required
                                            onChange={handleInputChange}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bankIban" className="mb-3">
                                    <Form.Label column lg={3}>
                                        IBAN*
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                            <Form.Control name="bankIban" type="text" placeholder="IBAN" defaultValue={companyDetails.bankDetails.bankIban} required
                                            onChange={handleInputChange}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="bankSwift" className="mb-3">
                                    <Form.Label column lg={3}>
                                        Swift*
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                            <Form.Control name="bankSwift" type="text" placeholder="Swift" defaultValue={companyDetails.bankDetails.bankSwift} required
                                            onChange={handleInputChange}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Card.Title className="text-uppercase fw-bold pt-4">
                                    Bank Address
                                </Card.Title>

                                <Form.Group as={Row} controlId="bankAddressLine1" className="mb-3">
                                    <Form.Label column lg={3}>
                                        Address
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="location-dot" /></InputGroup.Text>
                                            <Form.Control name="bankAddressLine1" type="text" placeholder="Address" defaultValue={companyDetails.bankDetails
                                            .bankAddressLine1}onChange={handleInputChange}/>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bankAddressLine2" className="mb-3">
                                    <Form.Label column lg={3}>
                                        Address
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="location-dot" /></InputGroup.Text>
                                            <Form.Control name="bankAddressLine2" type="text" placeholder="Address" defaultValue={companyDetails.bankDetails.bankAddressLine2}
                                            onChange={handleInputChange} />
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bankAddressCity" className="mb-3">
                                    <Form.Label column lg={3}>
                                        City
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="city" /></InputGroup.Text>
                                            <Form.Control name="bankAddressCity" type="text" placeholder="City" defaultValue={companyDetails.bankDetails.bankAddressCity}
                                            onChange={handleInputChange} />
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bankAddressPostCode" className="mb-3">
                                    <Form.Label column lg={3}>
                                        Post Code
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="address-card" /></InputGroup.Text>
                                            <Form.Control name="bankAddressPostCode" type="text" placeholder="PostCode" defaultValue={companyDetails.bankDetails
                                            .bankAddressPostCode}
                                            onChange={handleInputChange} />
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="bankAddressCountryCode" className="mb-3">
                                    <Form.Label column lg={3}>
                                        Country
                                    </Form.Label>
                                    <Col lg={9}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="globe" /></InputGroup.Text>
                                            <Form.Select name="bankAddressCountryCode" value={companyDetails.bankDetails.bankAddressCountryCode} onChange={(e)=>handleInputChange(e)}>
                                                { countryOptions }
                                            </Form.Select>
                                        </InputGroup>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formRegisterCreateAccount" className="mb-3">
                                    <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                        <Button variant="primary" type="submit" className="float-end text-uppercase">Save Updates</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {isManager || isIndividualContractor || isClient ?
                <Row className="mt-3">
                    <Col className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Contracts
                                </Card.Title>
                                <Row>
                                    <Col lg={12}>
                                        <p>You can download your contracts after you fill in all your company information. Please read it carefully, sign it
                                        and return it via email to info@supreme.contractors</p>
                                    </Col>
                                </Row>
                                {companyDetailsValid ?
                                    isClient ?
                                        <Row className="mt-2 pt-2 border-top border-secondary">
                                            <Col lg={8}>
                                                <p className="fw-bold">Your NDA</p>
                                            </Col>
                                            <Col lg={4}>
                                                <Button variant="secondary" type="submit" className="float-end text-uppercase" onClick={()=>downloadNda()}>
                                                    {downloadNdaStarted ?
                                                        <FontAwesomeIcon icon="spinner" spin/>:<FontAwesomeIcon icon="file-arrow-down" />
                                                    }
                                                    <span className="ms-1">Download</span>
                                                </Button>
                                                <Button variant="primary" type="submit" className="float-end text-uppercase me-2" onClick={()=>{setPreviewModal(true);setPreviewUrl(process.env.REACT_APP_FACADE_URL + 'supreme-organization/nda');}}>
                                                    <span className="ms-1">Preview</span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    :
                                        <>
                                            <Row className="mt-2 pt-2 border-top border-secondary">
                                                <Col lg={8}>
                                                    <p className="fw-bold">Your Master Service Agreement</p>
                                                </Col>
                                                <Col lg={4}>
                                                    <Button variant="secondary" type="submit" className="float-end text-uppercase" onClick={()=>downloadContract()}>
                                                        {downloadContractStarted ?
                                                            <FontAwesomeIcon icon="spinner" spin/>:<FontAwesomeIcon icon="file-arrow-down" />
                                                        }
                                                        <span className="ms-1">Download</span>
                                                    </Button>
                                                    <Button variant="primary" type="submit" className="float-end text-uppercase me-2" onClick={()=>{setPreviewModal(true);setPreviewUrl(process.env.REACT_APP_FACADE_URL + 'supreme-organization/contract');}}>
                                                        Preview
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2 pt-2 border-top border-secondary">
                                                <Col lg={8}>
                                                    <p className="fw-bold">Your Non Disclosure Agreement</p>
                                                </Col>
                                                <Col lg={4}>
                                                    <Button variant="secondary" type="submit" className="float-end text-uppercase" onClick={()=>downloadNda()}>
                                                        {downloadNdaStarted ?
                                                            <FontAwesomeIcon icon="spinner" spin/>:<FontAwesomeIcon icon="file-arrow-down" />
                                                        }
                                                        <span className="ms-1">Download</span>
                                                    </Button>
                                                    <Button variant="primary" type="submit" className="float-end text-uppercase me-2" onClick={()=>{setPreviewModal(true);setPreviewUrl(process.env.REACT_APP_FACADE_URL + 'supreme-organization/nda');}}>
                                                        Preview
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2 pt-2 border-top border-secondary">
                                                <Col lg={8}>
                                                    <p className="fw-bold">Your GDPR Data Processing Agreement</p>
                                                </Col>
                                                <Col lg={4}>
                                                    <Button variant="secondary" type="submit" className="float-end text-uppercase" onClick={()=>downloadGdpr()}>
                                                        {downloadGdprStarted ?
                                                            <FontAwesomeIcon icon="spinner" spin/>:<FontAwesomeIcon icon="file-arrow-down" />
                                                        }
                                                        <span className="ms-1">Download</span>
                                                    </Button>
                                                    <Button variant="primary" type="submit" className="float-end text-uppercase me-2" onClick={()=>{setPreviewModal(true);setPreviewUrl(process.env.REACT_APP_FACADE_URL + 'supreme-organization/gdpr');}}>
                                                        Preview
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                :null}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            : null}
            <BootstrapModal name="Preview Document" show={previewModal} handleClose={()=>{setPreviewModal(false);setPreviewUrl(null)}} handleAction={(e)=>{setSignatureModal(true);}} size="lg" actionBtn="Sign">
                <PreviewDocument url={previewUrl}/>
            </BootstrapModal>
            <BootstrapModal name="Signature" show={signatureModal} handleClose={()=>{setSignatureModal(false);}} handleAction={(e)=>submitSignature()} size="lg" actionBtn="Sign">
                <ReactSketchCanvas
                  style={styles}
                  width={256}
                  height={200}
                  strokeWidth={4}
                  strokeColor="#002e5b"
                  backgroundImage="/grid.jpg"
                  ref={signatureRef}
                />
            </BootstrapModal>
        </>
    )
}