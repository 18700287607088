import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Col, Breadcrumb, Nav, Navbar} from 'react-bootstrap';
import UserHelper from '../Helper/UserHelper.js';
import {PageContext} from "../cms/CmsPageProvider";
import './Header.scss';

class Header extends Component {
    constructor(props){
        super(props);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    render() {
        let isUserLoggedIn = UserHelper.isCustomer();

        return (
            <Col lg={12} className="p-0">
                <header>
                    <Navbar expand="md" className="pt-0 ps-md-0 pe-md-0">
                        <Navbar.Brand className="float-start float-end-md-0 ms-md-0 me-md-3" href="/">
                            <svg
                               width="104.07431"
                               height="19.61599"
                               viewBox="0 0 104.07431 19.61599"
                               version="1.1"
                               id="logo"
                               className="my-2"
                               style={{width:"210px",height:"40px"}}
                               x="0"
                               y="0">
                              <defs
                                 id="defs2334" />

                              <g
                                 id="layer1"
                                 transform="translate(-95.373572,-120.12653)">
                                <path
                                   d="m 110.5571,131.8662 v 3.79306 c 0,2.09374 -1.69827,3.79201 -3.792,3.79201 h -7.663395 c -1.375834,-0.0226 -2.57422,-0.78034 -3.219097,-1.89653 -0.304095,-0.52247 -0.484717,-1.12536 -0.506236,-1.76777 v -0.12771 h -0.0028 v -1.89688 h 3.793067 v 1.11477 c 0,0.43145 0.350661,0.78211 0.782108,0.78211 h 6.034263 c 0.4318,0 0.78211,-0.35066 0.78211,-0.78211 v -2.22885 c 0,-0.4318 -0.35031,-0.7821 -0.78211,-0.7821 h -6.816371 c -2.094795,0 -3.793067,-1.69757 -3.793067,-3.79201 v -2.61726 h 3.793067 v 1.83515 c 0,0.43145 0.350661,0.78211 0.782108,0.78211 h 6.816373 c 2.09373,0 3.792,1.69756 3.792,3.79201"
                                   id="path22"/>
                                <path
                                   d="M 121.92075,120.48911 H 99.166581 c -2.094442,0 -3.792714,1.69757 -3.792714,3.79201 h 25.771123 c 0.40005,0 0.7299,0.30163 0.77576,0.68898 v 1.20755 h 3.79271 v -1.89653 c 0,-2.09444 -1.69827,-3.79201 -3.79271,-3.79201"
                                   id="path26"/>
                                <path
                                   d="m 125.72398,135.65926 v -1.89653 h -3.79272 v 1.11442 c 0,0.43216 -0.35031,0.78211 -0.78211,0.78211 h -4.2164 c -0.43215,0 -0.78211,-0.34995 -0.78211,-0.78211 v -9.37718 h -3.792 v 10.25736 c 0.0522,2.04788 1.73002,3.69394 3.792,3.69394 h 5.90939 c 1.37583,-0.0243 2.5728,-0.77999 3.21803,-1.89653 l 0.007,-0.0116 c 0.30868,-0.53481 0.45191,-1.149 0.44133,-1.76601 z"
                                   id="path30"/>
                                <path
                                   d="m 129.13442,130.93811 v -1.76954 c 0.90911,0.38982 1.96427,0.68192 3.19828,0.68192 1.41217,0 2.02918,-0.5842 2.02918,-1.46085 0,-0.87665 -0.37359,-1.20121 -1.55857,-1.50989 l -1.00648,-0.25964 c -1.60725,-0.42228 -2.62996,-1.20157 -2.62996,-3.26285 0,-1.91593 1.2499,-3.23073 3.81494,-3.23073 1.55857,0 2.40277,0.27622 3.05188,0.60078 v 1.73672 c -0.74647,-0.25964 -1.65558,-0.56797 -2.90547,-0.56797 -1.13665,0 -1.75331,0.45438 -1.75331,1.29893 0,0.77928 0.35701,1.12007 1.41217,1.39594 l 0.97402,0.25964 c 1.81822,0.48684 2.87337,1.23367 2.87337,3.29565 0,2.17523 -1.34725,3.47416 -4.09081,3.47416 -1.4933,0 -2.62995,-0.27623 -3.40924,-0.68227"
                                   id="path34"/>
                                <path
                                   d="m 138.20984,127.51299 v -7.20796 h 2.32128 v 7.07813 c 0,1.88313 0.74683,2.51637 2.25672,2.51637 1.46085,0 2.12619,-0.61701 2.12619,-2.53259 v -7.06191 h 2.11067 v 7.15927 c 0,2.8247 -1.37972,4.17195 -4.31836,4.17195 -2.93828,0 -4.4965,-1.36383 -4.4965,-4.12326"
                                   id="path38"/>
                                <path
                                   d="m 153.04806,125.71086 c 1.41217,0 1.94804,-0.63289 1.94804,-1.91559 0,-1.39594 -0.56833,-1.75295 -1.91559,-1.75295 h -1.54234 v 3.66854 z m -3.81494,-5.40562 h 4.12327 c 2.71109,0 4.04212,1.15253 4.04212,3.39302 0,2.36996 -1.49331,3.70099 -3.94476,3.70099 h -1.91558 v 4.04213 h -2.30505 z"
                                   id="path42" />
                                <path
                                   d="m 162.65882,125.54893 c 1.36348,0 1.89935,-0.60078 1.89935,-1.81821 0,-1.20157 -0.55209,-1.72085 -1.91558,-1.72085 h -1.41217 v 3.53906 z m -3.71757,-5.24369 h 3.99344 c 2.62996,0 4.00967,1.08762 4.00967,3.31118 0,1.54269 -0.7627,2.40312 -1.98049,2.8575 v 0.0815 l 2.75978,4.88597 h -2.64619 l -2.20768,-4.38291 h -1.62313 v 4.38291 h -2.3054 z"
                                   id="path46"/>
                                <path
                                   d="m 168.99023,120.30517 h 7.14304 v 1.73708 h -4.83764 v 2.82469 h 3.78213 v 1.72085 h -3.78213 v 3.08434 h 4.95088 v 1.76918 h -7.25628 z"
                                   id="path50"
                                   />
                                <path
                                   d="m 178.17914,120.30517 h 3.19828 l 2.53224,7.92198 h 0.0487 l 2.50013,-7.92198 h 3.14925 v 11.13614 h -2.27259 v -8.32767 h -0.0487 l -2.64619,8.32767 h -1.80199 l -2.61337,-8.3439 h -0.0487 v 8.3439 h -1.99708 z"
                                   id="path54"
                                   />
                                <path
                                   d="m 191.88134,120.30517 h 7.14305 v 1.73708 h -4.83764 v 2.82469 h 3.78213 v 1.72085 h -3.78213 v 3.08434 h 4.95088 v 1.76918 h -7.25629 z"
                                   id="path58" />
                                <path
                                   d="m 134.48966,135.19522 -0.42722,0.32773 c -0.23566,-0.30657 -0.51858,-0.5394 -0.84843,-0.6978 -0.3302,-0.15804 -0.69321,-0.23742 -1.08832,-0.23742 -0.43215,0 -0.8322,0.10372 -1.20015,0.31115 -0.36795,0.20744 -0.6537,0.48613 -0.85619,0.83573 -0.20249,0.34996 -0.30374,0.74331 -0.30374,1.18005 0,0.6604 0.22648,1.21179 0.67945,1.65347 0.45296,0.44167 1.02411,0.66251 1.71379,0.66251 0.75847,0 1.39312,-0.29668 1.90359,-0.89111 l 0.42722,0.3242 c -0.27023,0.34396 -0.60714,0.6096 -1.01071,0.79728 -0.40393,0.18767 -0.85514,0.28151 -1.35326,0.28151 -0.94756,0 -1.6951,-0.31538 -2.24226,-0.94615 -0.45896,-0.53269 -0.68862,-1.17581 -0.68862,-1.92969 0,-0.79269 0.27799,-1.45944 0.83397,-2.00131 0.55633,-0.54081 1.25271,-0.81139 2.08985,-0.81139 0.50518,0 0.96203,0.10019 1.36949,0.29986 0.40746,0.20038 0.74119,0.48084 1.00154,0.84138"
                                   id="path62"/>
                                <path
                                   d="m 139.70967,134.58061 c -0.40111,0 -0.7807,0.10407 -1.13982,0.31327 -0.35878,0.20849 -0.63924,0.49 -0.84067,0.84349 -0.20179,0.35383 -0.30269,0.74789 -0.30269,1.18321 0,0.64312 0.22331,1.18675 0.66958,1.63019 0.44626,0.44379 0.98389,0.66534 1.6136,0.66534 0.42016,0 0.80927,-0.10195 1.16699,-0.30621 0.35772,-0.20356 0.63676,-0.48225 0.83714,-0.83644 0.20038,-0.35348 0.30057,-0.74648 0.30057,-1.17863 0,-0.43004 -0.10019,-0.81915 -0.30057,-1.16664 -0.20038,-0.34748 -0.4826,-0.62582 -0.84631,-0.83431 -0.36407,-0.2092 -0.74966,-0.31327 -1.15782,-0.31327 m -0.024,-0.5267 c 0.82021,0 1.50672,0.27376 2.05952,0.82127 0.55245,0.54716 0.82903,1.22096 0.82903,2.02106 0,0.79305 -0.27588,1.46579 -0.82727,2.01789 -0.55139,0.55245 -1.22378,0.82832 -2.01718,0.82832 -0.80292,0 -1.48096,-0.27481 -2.03376,-0.82479 -0.55245,-0.54998 -0.82903,-1.21462 -0.82903,-1.99531 0,-0.5207 0.12594,-1.00295 0.37782,-1.44745 0.25153,-0.44415 0.59479,-0.79199 1.02976,-1.04387 0.43462,-0.25118 0.90488,-0.37712 1.41111,-0.37712"
                                   id="path66"/>
                                <path
                                   d="m 145.18704,139.60631 v -5.41619 h 0.11783 l 3.60468,4.15184 v -4.15184 h 0.53375 v 5.41619 h -0.12135 l -3.5754,-4.10104 v 4.10104 z"
                                   id="path70"/>
                                <path
                                   d="m 151.75474,134.72013 v -0.52987 h 2.96757 v 0.52987 h -1.20756 v 4.88633 h -0.55245 v -4.88633 z"
                                   id="path74"/>
                                <path
                                   d="m 157.60454,134.72013 v 1.84115 l 0.93309,0.007 c 0.36125,0 0.62865,-0.0339 0.80187,-0.10301 0.17356,-0.0688 0.30868,-0.17886 0.40604,-0.3295 0.097,-0.15099 0.1457,-0.31997 0.1457,-0.50623 0,-0.18204 -0.0494,-0.34678 -0.14781,-0.4953 -0.0981,-0.14852 -0.22754,-0.25471 -0.387,-0.31856 -0.15981,-0.0639 -0.42545,-0.096 -0.79657,-0.096 z m -0.54117,-0.52987 h 1.0788 c 0.60148,0 1.00859,0.0243 1.22237,0.0737 0.32174,0.0734 0.58315,0.23142 0.78423,0.47343 0.20143,0.24236 0.30198,0.5401 0.30198,0.89429 0,0.29492 -0.0695,0.55386 -0.20814,0.77787 -0.13864,0.22367 -0.33691,0.39229 -0.59443,0.50695 -0.25788,0.1143 -0.61384,0.17286 -1.06786,0.17497 l 1.94768,2.51496 h -0.66957 l -1.94768,-2.51496 h -0.30621 v 2.51496 h -0.54117 z"
                                   id="path78"/>
                                <path
                                   d="m 165.28589,135.34092 -0.92816,1.96073 h 1.85137 z m 0.0681,-1.15077 2.52588,5.4162 h -0.58455 l -0.85196,-1.78223 h -2.33327 l -0.84384,1.78223 h -0.60361 l 2.5587,-5.4162 z"
                                   id="path82"/>
                                <path
                                   d="m 175.2944,135.19522 -0.42721,0.32773 c -0.23566,-0.30657 -0.51858,-0.5394 -0.84843,-0.6978 -0.3302,-0.15804 -0.69321,-0.23742 -1.08832,-0.23742 -0.43215,0 -0.8322,0.10372 -1.20015,0.31115 -0.36795,0.20744 -0.6537,0.48613 -0.85619,0.83573 -0.2025,0.34996 -0.30374,0.74331 -0.30374,1.18005 0,0.6604 0.22648,1.21179 0.67945,1.65347 0.45296,0.44167 1.02411,0.66251 1.71379,0.66251 0.75847,0 1.39312,-0.29668 1.90359,-0.89111 l 0.42721,0.3242 c -0.27023,0.34396 -0.60678,0.6096 -1.0107,0.79728 -0.40358,0.18767 -0.85514,0.28151 -1.35326,0.28151 -0.94756,0 -1.6951,-0.31538 -2.24226,-0.94615 -0.45896,-0.53269 -0.68862,-1.17581 -0.68862,-1.92969 0,-0.79269 0.27799,-1.45944 0.83432,-2.00131 0.55598,-0.54081 1.25236,-0.81139 2.0895,-0.81139 0.50518,0 0.96203,0.10019 1.36949,0.29986 0.40745,0.20038 0.74154,0.48084 1.00153,0.84138"
                                   id="path86"/>
                                <path
                                   d="m 177.37367,134.72013 v -0.52987 h 2.96757 v 0.52987 h -1.20756 v 4.88633 h -0.55245 v -4.88633 z"
                                   id="path90"/>
                                <path
                                   d="m 185.25572,134.58061 c -0.40076,0 -0.7807,0.10407 -1.13948,0.31327 -0.35912,0.20849 -0.63958,0.49 -0.84102,0.84349 -0.20179,0.35383 -0.30233,0.74789 -0.30233,1.18321 0,0.64312 0.22331,1.18675 0.66922,1.63019 0.44627,0.44379 0.9839,0.66534 1.61361,0.66534 0.42051,0 0.80927,-0.10195 1.16734,-0.30621 0.35772,-0.20356 0.63641,-0.48225 0.83679,-0.83644 0.20073,-0.35348 0.30092,-0.74648 0.30092,-1.17863 0,-0.43004 -0.10019,-0.81915 -0.30092,-1.16664 -0.20038,-0.34748 -0.4826,-0.62582 -0.84596,-0.83431 -0.36407,-0.2092 -0.75001,-0.31327 -1.15817,-0.31327 m -0.024,-0.5267 c 0.82021,0 1.50671,0.27376 2.05951,0.82127 0.55245,0.54716 0.82903,1.22096 0.82903,2.02106 0,0.79305 -0.27587,1.46579 -0.82726,2.01789 -0.55139,0.55245 -1.22379,0.82832 -2.01718,0.82832 -0.80293,0 -1.48097,-0.27481 -2.03377,-0.82479 -0.55245,-0.54998 -0.82903,-1.21462 -0.82903,-1.99531 0,-0.5207 0.12595,-1.00295 0.37783,-1.44745 0.25188,-0.44415 0.59513,-0.79199 1.02976,-1.04387 0.43462,-0.25118 0.90522,-0.37712 1.41111,-0.37712"
                                   id="path94" />
                                <path
                                   d="m 191.30032,134.72013 v 1.84115 l 0.93309,0.007 c 0.36125,0 0.62865,-0.0339 0.80187,-0.10301 0.17357,-0.0688 0.30868,-0.17886 0.40605,-0.3295 0.097,-0.15099 0.14534,-0.31997 0.14534,-0.50623 0,-0.18204 -0.049,-0.34678 -0.14746,-0.4953 -0.0981,-0.14852 -0.22754,-0.25471 -0.387,-0.31856 -0.16016,-0.0639 -0.42545,-0.096 -0.79657,-0.096 z m -0.54116,-0.52987 h 1.07879 c 0.60149,0 1.00859,0.0243 1.22238,0.0737 0.32138,0.0734 0.58314,0.23142 0.78387,0.47343 0.20143,0.24236 0.30233,0.5401 0.30233,0.89429 0,0.29492 -0.0695,0.55386 -0.20814,0.77787 -0.13864,0.22367 -0.3369,0.39229 -0.59443,0.50695 -0.25788,0.1143 -0.61383,0.17286 -1.06786,0.17497 l 1.94769,2.51496 h -0.66958 l -1.94803,-2.51496 h -0.30586 v 2.51496 h -0.54116 z"
                                   id="path98" />
                                <path
                                   d="m 196.27745,138.59723 0.46037,-0.27587 c 0.3242,0.59619 0.6985,0.89464 1.12289,0.89464 0.18168,0 0.35207,-0.0423 0.51188,-0.127 0.15946,-0.085 0.28082,-0.19826 0.36442,-0.34043 0.0833,-0.14287 0.12524,-0.29316 0.12524,-0.45297 0,-0.18168 -0.0614,-0.35983 -0.18415,-0.53375 -0.16933,-0.24059 -0.47837,-0.53058 -0.92781,-0.86889 -0.45155,-0.34149 -0.73236,-0.58808 -0.84313,-0.74048 -0.19156,-0.25506 -0.28717,-0.53128 -0.28717,-0.82797 0,-0.23601 0.0564,-0.45085 0.16934,-0.64452 0.11289,-0.19403 0.27164,-0.34679 0.47695,-0.45826 0.20497,-0.11183 0.42757,-0.16793 0.66816,-0.16793 0.25542,0 0.49389,0.0635 0.71614,0.18945 0.2219,0.127 0.4572,0.35912 0.7052,0.69814 l -0.44203,0.33479 c -0.20355,-0.26988 -0.37712,-0.44803 -0.52069,-0.53375 -0.14394,-0.0861 -0.30022,-0.12877 -0.46955,-0.12877 -0.21873,0 -0.39723,0.0663 -0.53587,0.19862 -0.13864,0.13264 -0.20779,0.29562 -0.20779,0.48965 0,0.11783 0.024,0.23178 0.0734,0.3429 0.049,0.11007 0.13864,0.23037 0.26882,0.36054 0.0709,0.0688 0.30444,0.24906 0.69956,0.54152 0.46884,0.34607 0.79022,0.65405 0.96484,0.92392 0.17427,0.26988 0.26141,0.54151 0.26141,0.81351 0,0.39299 -0.14958,0.73413 -0.44768,1.02376 -0.29809,0.28963 -0.66075,0.43427 -1.08796,0.43427 -0.32879,0 -0.62724,-0.0875 -0.89429,-0.26282 -0.26776,-0.17569 -0.51435,-0.46955 -0.74048,-0.8823"
                                   id="path102" />
                              </g>
                            </svg>
                        </Navbar.Brand>
                        <Nav className="pt-2 pt-3 mx-auto me-md-0">
                            {isUserLoggedIn ? <Nav.Link href="/j_spring_security_logout">Logout</Nav.Link>: null}
                        </Nav>
                    </Navbar>

                    {this.props.breadcrumbs
                        ? <Breadcrumbs breadcrumbs={this.props.breadcrumbs} categoryCode={this.props.match.params.categoryCode} />
                        : <PageContext.Consumer>
                            {value => {
                                  return (
                                       <Breadcrumbs breadcrumbs={value.state.pageData.breadcrumbs} categoryCode={this.props.match.params.categoryCode} />
                                  );
                            }}
                          </PageContext.Consumer>
                    }
                    <div className="border-top my-3"></div>
                </header>
            </Col>
        )
    }

    handleLogoutClick() {
        UserHelper.logout(this.props.history);
    }
}

function Breadcrumbs(props) {
    if (props.breadcrumbs != null && props.breadcrumbs.length === 0) {
      return null;
    }
  
    return (
         <Breadcrumb>
            {
                props.breadcrumbs.map((breadcrumb, i) => {
                    return <Breadcrumb.Item href={props.categoryCode ? props.categoryCode + '?q=' + breadcrumb.url : breadcrumb.url} key={i} active={i === props.breadcrumbs.length-1}>
                                {breadcrumb.name}
                           </Breadcrumb.Item>
                })
            }
         </Breadcrumb>
    );
}

export default withRouter(Header);
