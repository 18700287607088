import React, {useContext, useState, useEffect} from "react";
import {Card} from 'react-bootstrap';
import Slider from 'rc-slider';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import 'rc-slider/assets/index.css';

import {ListerContext} from '../Provider/ListerPageProvider.js';

export default function SearchFacetSliderProfile({widgetData}) {
    const [value, setValue] = useState(null);
    const [facet, setFacet] = useState(null);

    let listerContext = useContext(ListerContext);
    let sliderFacetContent = null;
    
    useEffect(() => {
        if (listerContext.state.searchPageData && listerContext.state.searchPageData.facets) {
            let searchPageDataFacet = listerContext.state.searchPageData.facets[widgetData.facetCode];

            if (searchPageDataFacet && (facet !== searchPageDataFacet)) {
                setFacet(searchPageDataFacet);
                setValue([Number(searchPageDataFacet.userSelectionMin), Number(searchPageDataFacet.userSelectionMax)]);
            }
        }
    }, [listerContext.state.searchPageData, widgetData.facetCode, facet]);

    if (!facet) {
        return null;
    }

    if (facet.values) {
        if (!value) {
            setValue([Number(facet.userSelectionMin), Number(facet.userSelectionMax)]);
        }

        if (facet.code === 'rating') {
            sliderFacetContent = <React.Fragment>
                <FontAwesomeIcon id="clear-rating-facet" icon="xmark" color="var(--bs-dark)" className="d-none"
                    onMouseEnter={() => document.getElementById("clear-rating-facet").classList.add("text-secondary")}
                    onMouseLeave={() => document.getElementById("clear-rating-facet").classList.remove("text-secondary")}
                    onClick={() => {
                        listerContext.getProducts(null, null, getNewFacetQueryUrl(facet, null, null));
                    }}/>
            </React.Fragment>;
        } else {
            sliderFacetContent = <React.Fragment>
                <Slider allowCross={false} min={Number(facet.initialMinValue)} max={Number(facet.initialMaxValue)} value={value} onChange={(val) => setValue
                (val)}
                    trackStyle={[{ backgroundColor: 'var(--bs-secondary)' }]}
                    handleStyle={[{ backgroundColor: 'var(--bs-secondary)', border: 'none', boxShadow: 'none' }, { backgroundColor: 'var(--bs-secondary)', border: 'none', boxShadow: 'none' }]}
                    railStyle={{ backgroundColor: 'var(--bs-dark)' }}
                    onAfterChange={(val) => {
                        setValue(val);
                        listerContext.getProducts(null, null, getNewFacetQueryUrl(facet, val[0], val[1]))
                    }} />
                <div>{value && value[0] + ' - ' + value[1]}</div>
            </React.Fragment>;
        }
    }

    return (
        <Card className={"mb-3 " + widgetData.styleClass}>
            <Card.Header className="fw-bold text-uppercase">{facet.name}</Card.Header>
            <Card.Body className={facet.code === 'rating' ? 'p-2' : ''}
                onMouseEnter={facet.code === 'rating' ? () => toggleClearRating(true) : null}
                onMouseLeave={facet.code === 'rating' ? () => toggleClearRating(false) : null}>
                {sliderFacetContent}
            </Card.Body>
        </Card>
    );
}

function getNewFacetQueryUrl(facet, minValue, maxValue) {
    if (!minValue && !maxValue) {
        return '';
    }
    
    let newFacetQueryUrl = facet.query.url;
    let userStart = newFacetQueryUrl.indexOf(":" + facet.code);
    userStart = (userStart + 1) + facet.code.length + 1;

    let userEnd = newFacetQueryUrl.substring(userStart + 1).indexOf("[");
    if (userEnd !== -1) {
        userEnd = userStart + userEnd;
    } else {
        userEnd = newFacetQueryUrl.indexOf(']');
    }

    return newFacetQueryUrl.substring(0, userStart) + "[" + minValue + "-" + maxValue + "]" + newFacetQueryUrl.substring(userEnd + 1);
}

function toggleClearRating(show) {
    let clearRatingIcon = document.getElementById("clear-rating-facet");

    if (!clearRatingIcon) {
        return;
    }

    if (show) {
        if (clearRatingIcon.classList.contains("d-none")) {
            clearRatingIcon.classList.remove("d-none");
            clearRatingIcon.classList.add("d-inline");
        }
    } else {
        if (clearRatingIcon.classList.contains("d-inline")) {
            clearRatingIcon.classList.remove("d-inline");
            clearRatingIcon.classList.add("d-none");
        }
    }
}
