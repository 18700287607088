import React, {Component} from 'react';
import {Row, Col, Table, Badge, Card, Form, InputGroup, Button, ButtonGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import {withRouter} from 'react-router-dom';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';

class AccountInvoices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            isLoading: true,
            currentUser: null,
            resourcesRates: {

            }
        };

        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.fireUser = this.fireUser.bind(this);
        this.toggleRates = this.toggleRates.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getResourcesInCurrentCompany = this.getResourcesInCurrentCompany.bind(this);
    }

    componentDidMount() {
        this.getCurrentUser();
        this.getResourcesInCurrentCompany(0);
    }

    render() {
        let resources = null;
        if (this.state.isLoading) {
            resources = <TableSectionLoader colSpan={7} numberOfRows={5}/>
        } else {
            if (this.state.resources && this.state.resources.content) {
                resources = this.state.resources.content.map(function(resource, key) {
                    return (
                        <tr key={resource.code}>
                            <td>{resource.available ? <Badge pill bg="success">Available</Badge>:<Badge pill bg="danger">Busy</Badge>}</td>
                            <td>{resource.displayName}</td>
                            <td>{resource.role}</td>
                            <td>
                                <Form.Control name={resource.code} type="number" onChange={this.handleInputChange}
                                    placeholder="Company Rate" defaultValue={resource.companyHourlyRate} />
                            </td>
                            <td className="text-nowrap w-10">
                                {resource ?
                                <ButtonGroup>
                                    <Button className="text-uppercase me-2" variant="primary" onClick={()=>this.saveUser(resource.code)
                                    }><FontAwesomeIcon icon="floppy-disk" /></Button>
                                    {this.state.currentUser && this.state.currentUser.code !== resource.code ?
                                        <Button className="text-uppercase" variant="danger" onClick={()=>this.fireUser(resource.code)}><FontAwesomeIcon
                                        icon="trash" /></Button>
                                    : null}
                                </ButtonGroup>
                                :null}
                            </td>
                        </tr>);
                }, this);
            } else {
              resources = <tr><td colSpan="7" className="text-center">You have no resources in your company</td></tr>;
            }
        }


        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Add more resources
                                </Card.Title>
                                <p>You can invite more resources to join your company by sharing this link with them</p>
                                <Form.Group as={Row} controlId="formProfileRole" className="mb-3">
                                    <Form.Label column sm={3} md={3} lg={3}>
                                        Registration link
                                    </Form.Label>
                                    <Col sm={7} md={7} lg={7}>
                                        <InputGroup>
                                            <InputGroup.Text><FontAwesomeIcon icon="plus" /></InputGroup.Text>
                                            {this.state.currentUser ?
                                            <Form.Control name="registrationLink" type="text" ref={(registrationLink) => this.registrationLink = registrationLink}
                                            placeholder="Registration Link" readOnly defaultValue={this.state.currentUser.registrationLink} />
                                            :null}
                                        </InputGroup>
                                    </Col>
                                    <Col sm={2} md={2} lg={2}>
                                        <Button onClick={() => this.copyToClipboard()}>Copy</Button>
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Company Resources
                                    <Button variant="primary" className="float-end text-uppercase ms-2"
                                    onClick={this.toggleRates}>Toggle Rates</Button>
                                </Card.Title>
                                <p>Here you can see the resources in your company</p>
                                <Table striped bordered hover responsive className="table-align-middle">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Name</th>
                                            <th>Role</th>
                                            <th>Company Rate</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { resources }
                                    </tbody>
                                    {this.state.resources && this.state.resources.content && this.state.resources.content.length > 0 ?
                                        <tfoot>
                                            <tr>
                                                <th colSpan="3">
                                                    <PaginationInfo page={this.state.resources} />
                                                </th>
                                                <th colSpan="4">
                                                    <Pagination page={this.state.resources} handleChangePage={this.getResourcesInCurrentCompany} isLoading={this.state.isLoading}
                                                    />
                                                </th>
                                            </tr>
                                        </tfoot>
                                    :null}
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let rr = this.state.resourcesRates;
        rr[name] = value;

        this.setState({
            resourcesRates : rr
        });
    }

    saveUser(code) {
        ApiCall.put('resource/' + code, {}, {
            'amount' : this.state.resourcesRates[code]
        }).then((response) => {
            UserHelper.showSuccessToast("Company rate was updated.");
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                profileInfoMsg: null
            });
        });
    }

    fireUser(code) {
        ApiCall.delete('resource/' + code).then((response) => {
            UserHelper.showSuccessToast("The resource was fired from your company.", true);
            this.getResourcesInCurrentCompany(0);
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.', true);
            this.setState({
                profileInfoMsg: null
            });
        });
    }

    getCurrentUser() {
        ApiCall.get('user/getCurrentUser', {
            projection: "supreme.contractors.backend.facade.dto.ContractorUserDetailsDtoDefinition"
        }).then((response) => {
            let currentUser = response.data;

            this.setState({
                currentUser: currentUser
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                profileInfoMsg: null
            });
        });
    }

    copyToClipboard = () => {
        const el = this.registrationLink;
        el.select();
        document.execCommand("copy");
        UserHelper.showSuccessToast("Link has been copied to clipboard");
    }

    getResourcesInCurrentCompany(pageNumber) {
        ApiCall.get('organization/resources', {
            projection: "supreme.contractors.backend.facade.dto.ContractorUserDetailsDtoDefinition",
            page: pageNumber,
            size: 5
        }).then((response) => {
            this.setState({
                resources: response.data,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    toggleRates(rates) {
        ApiCall.put('supreme-organization/switch', {
            employeeRates:rates
        }).then((response) => {
            this.setState({
                resources: response.data,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }
}

export default withRouter(AccountInvoices);
