import React, {useState} from "react";
import { Row, Col, Form, Card, InputGroup } from "react-bootstrap";
import {useHistory} from 'react-router-dom';
import { ListerContext } from "./ResourceListerPageProvider";
import SearchAvailableFacet from './SearchAvailableFacet.js';
import SearchAssignedFacet from './SearchAssignedFacet.js';
import SearchCategoriesFacet from './SearchCategoriesFacet.js';
import SearchSkillsFacet from './SearchSkillsFacet.js';
import SearchHourlyRateFacet from './SearchHourlyRateFacet.js';
import SearchLanguageFacet from './SearchLanguageFacet.js';
import SearchLocationFacet from './SearchLocationFacet.js';
import SearchPreviousAssignmentFacet from './SearchPreviousAssignmentFacet.js';
import SearchOrganizationFacet from './SearchOrganizationFacet.js';
import SearchRolesFacet from './SearchRolesFacet.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../../components/Helper/UserHelper.js';


export default function SearchForm({queryParams}) {

    let isSuperSales = UserHelper.isSuperSales();
    let isManager = UserHelper.isSuperSales();

    const [searchTerm, setSearchTerm] = useState('');

    let history = useHistory();

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleSearch = () => {
        history.push('/my-account/search?q=' + searchTerm);
    }

    const handleAutosuggestKeyDown = (event) => {
    //todo show autocomplete
//        if (event.key === 'Enter') {
//            this.props.history.push('/my-account/search?q=' + this.state.searchTerm);
//        }
    }



    return (
        <ListerContext.Consumer>
            {
                (context) => (
                    <Row className="mt-3">
                        <Col sm={12} md={12} className="mt-3 mt-md-0">
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-uppercase fw-bold">
                                        Search
                                    </Card.Title>
                                    <Form.Group as={Row} controlId="name" className="mb-3">
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <InputGroup>
                                                <InputGroup.Text><FontAwesomeIcon icon="magnifying-glass" /></InputGroup.Text>
                                                <Form.Control name="search" type="search" autoComplete="off" placeholder="Search" onKeyDown={handleAutosuggestKeyDown} defaultValue={context.state.searchTerm} onChange={handleInputChange} ref={element=>(element||{}).onsearch=handleSearch}/>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="platform" className="mb-3">
                                        <Col md={3} lg={3}>
                                            <SearchCategoriesFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                            <SearchSkillsFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                        </Col>
                                        <Col md={3} lg={3}>
                                            <SearchLanguageFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                            {isSuperSales ?
                                                <SearchOrganizationFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                            :null}
                                        </Col>
                                        <Col md={6} lg={6}>
                                            <Row>
                                                <Col md={6} lg={6}>
                                                    <SearchLocationFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                                </Col>
                                                <Col md={6} lg={6}>
                                                    <SearchAvailableFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6} lg={6}>
                                                    {isSuperSales ?
                                                        <SearchRolesFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                                    :""}
                                                </Col>
                                                <Col md={6} lg={6}>
                                                    <SearchAssignedFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="user" className="mb-3">
                                        <Col md={6} lg={6} className="mb-2">
                                            <SearchHourlyRateFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                        </Col>
                                        <Col md={6} lg={6}>
                                            {isSuperSales || isManager ?
                                                <SearchPreviousAssignmentFacet isLoading={context.state.isLoading} updateUrl={context.updateUrl} searchPageData={context.state.searchPageData}/>
                                            :null}
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="name" className="mb-3">
                                        <Col xs={6} sm={6} md={6} lg={6}>
                                            <span>RESULTS: {context.state.searchPageData ? context.state.searchPageData.totalElements :null}</span>
                                        </Col>
                                        <Col xs={6} sm={6} md={6} lg={6}>
                                            <a href="/my-account/search" className="float-end">Clear</a>
                                        </Col>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )
            }
        </ListerContext.Consumer>
    )
}