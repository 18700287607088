import ApiCall from './ApiCall.js';
import { properties } from '../../properties.js';

export default class CommerceHelper {

  static productImage(product, format) {
    if (product && product.images && format) {
      return product.images.find(image => (image.imageType === 'PRIMARY' && image.format === format));
    }
  }

  static getSearchImageForProductAndFormat(product, format) {
    if (product && product.properties && format) {
      let image = {
        url: product.properties["img" + format.charAt(0).toUpperCase() + format.slice(1)],
        altText: product.properties.name,
        name: product.properties.name,
      };

      return image;
    }
  }

  static getGalleryImages(product) {
    let galleryImages = [];

    if (product && product.images && product.images.length) {
      let images = product.images.filter(image => image.imageType === 'GALLERY');
      images.sort((img1, img2) => img1.galleryIndex < img2.galleryIndex);

      if (images.length) {
        let currentIndex = images[0].galleryIndex;
        let formats = new Map();

        for (let image of images) {
          if (currentIndex !== image.galleryIndex) {
            galleryImages.push(formats);
            formats = new Map();
            currentIndex = image.galleryIndex;
          }
          formats.set(image.format, image);
        }

        if (formats && formats.size) {
          galleryImages.push(formats);
        }
      }
    }

    return galleryImages;
  }

  static getIntersection(a, b) {
    var ai = 0, bi = 0;
    var result = [];

    while (ai < a.length && bi < b.length) {
      if (a[ai] < b[bi]) {
        ai++;
      }
      else if (a[ai] > b[bi]) {
        bi++;
      }
      else /* they're equal */
      {
        result.push(a[ai]);
        ai++;
        bi++;
      }
    }

    return result;
  }

  static getVariantOptions = async(productCode) =>  {
    try {
      let response = await ApiCall.get('product/getBaseProductForProductWithCode/' + productCode, {
        projection: 'io.nemesis.platform.module.commerce.facade.product.dto.VariantOptionsProductDtoDefinition'
      });

      return response.data.variantOptions;
    } catch (error) {
      return error;
    }
  };

  static getWishlists = async() =>  {
    try {
      let response = await ApiCall.get('wishlist/forCurrentUser', {
        saved: true
      });
      return {
        wishlists: response.data
      };
    } catch (error) {
      return {
        errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
      }
    }
  };

  static getStores = async(page, projection) =>  {
    try {
      let response = await ApiCall.get('search', {
        projection: projection,
        layout: page.layout,
        q: page.searchTerm,
        type: page.searchType,
        queryName: page.queryName,
        page: page.page,
        size: page.size
      });
      return {
        stores: response.data
      };
    } catch (error) {
      return {
        errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
      }
    }
  };

  static getCurrencies = async() =>  {
    try {
      let response = await ApiCall.get('commerce/i18n/supportedCurrenciesForSite', {
        siteCode: properties.site
      });
      return {
        currencies: response.data
      };
    } catch (error) {
      return {
        errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
      }
    }
  };

  static getCcPaymentInfos = async() =>  {
    try {
      let response = await ApiCall.get('customer/paymentInfo', {
        saved: true
      });
      return {
        paymentInfos: response.data
      };
    } catch (error) {
      return {
        errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
      }
    }
  };

  static getObfuscatedCCNumber(ccNumber) {
    if (ccNumber && ccNumber.length > 12) {
      return "************" + ccNumber.substring(12);
    }

    return ccNumber;
  }

  static hasShippingItems(cart) {
    if(cart && cart.entries && cart.entries.length) {
      for (let entry of cart.entries) {
        if (!entry.pickupStore && !entry.free) {
          return true;
        }
      }
    }

    return false;
  }

  static hasNoDeliveryAddress(cart) {
    return this.hasShippingItems(cart) && (!cart || !cart.checkoutInfo || !cart.checkoutInfo.deliveryAddress);
  }

  static hasNoDeliveryMode(cart) {
    return this.hasShippingItems(cart) && (!cart || !cart.checkoutInfo || !cart.checkoutInfo.deliveryMode);
  }

  static hasNoPaymentInfo(cart) {
    return (!cart || !cart.checkoutInfo || !cart.checkoutInfo.paymentInfo);
  }

  static getProduct = async(productCode, projection) =>  {
    try {
      let response = await ApiCall.get('product/' + productCode, {
          projection: projection
      });
      return {
        product: response.data
      };
    } catch (error) {
      return {
        errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
      }
    }
  }

  static getAutocompleteSuggestions = async(searchTerm, searchType) => {
    try {
      let response = await ApiCall.get('search/autocomplete', {
        q: searchTerm,
        type: searchType,
        queryName: "autocomplete"
      });

      return {
        suggestions: response.data.content
      };
    } catch (error) {
      return {
        errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
      }
    }
  }

  static getCurrentCurrency = async() =>  {
      try {
          let response = await ApiCall.get('commerce/i18n/currentCurrency', {
          });

          return {
              currency: response.data
          };
      } catch (error) {
          return {
              errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
          }
      }
  };

  static updateSessionCurrency = async(preferredCurrency) =>  {
      try {
          await ApiCall.post('commerce/user/updateSessionCurrency', null, null, {
              preferredCurrency: preferredCurrency,
              defaultCurrency: "USD"
          });

          localStorage.setItem("currentCurrency", preferredCurrency);

          return {
              success: true
          };
      } catch (error) {
          return {
              success: false,
              errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
          }
      }
  };

}
