import React, {useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Card, Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { GoogleLogin } from '@react-oauth/google';
import GlobalMessages from '../../Misc/GlobalMessages.js';
import ApiCall from '../../Helper/ApiCall.js';
import UserHelper from '../../Helper/UserHelper.js';
import TotpField from '../../Misc/TotpField.js';
import "./Login.scss";

export default function Login(props) {
    let history = useHistory();
    let location = useLocation();

    const [{ loginEmail,loginPassword,loginMFACode,mfaCheck}, setState] = useState(
        {
            loginEmail:null,
            loginPassword: null,
            loginMFACode: null,
            mfaCheck: 'unchecked',
        }
    );

    let secondInput = null;

    function updateValue(name,value) {
        setState(prevState => ({ ...prevState, [name]: value }));
    }

    const handleInputChange = (event) => {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                updateValue(name,value);
            }
        }
    }

    const handleSubmit = (event) => {
        if(event) {
            event.preventDefault();
            if (mfaCheck === 'unchecked') {
                ApiCall.get('mfa/user/enabled', {
                    username: loginEmail
                }).then(response => {
                    updateValue("mfaCheck", response.data);
                    if (response.data) {
                        secondInput.focus();
                    } else {
                        performLogin(loginEmail, loginPassword);
                    }
                }).catch(error => {
                    console.log(error);
                });
            } else {
                performLogin(loginEmail, loginPassword, loginMFACode);
            }
        } else {
            performLogin(loginEmail, loginPassword, loginMFACode);
        }
    }

    const loginWithGoogle = (credentialResponse) => {
        ApiCall.post('account/google/verify', {idRequestToken: credentialResponse.credential}).then((response) => {
            let data = response && response.data ? response.data : null;
            if (data && data.error) {
                UserHelper.showErrorToast(data.error && data.error.message ? data.error.message : 'An error occurred. Please try again later.');
            } else {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("authorities", response.data.authorities);
                localStorage.setItem("expiryTime", response.data.expiryTime);
                if (response.data.settings) {
                    localStorage.setItem("theme", response.data.settings.theme);
                    if (response.data.settings.theme) {
                        document.documentElement.setAttribute("data-bs-theme", response.data.settings.theme);
                    }
                    if (response.data.settings.user) {
                        localStorage.setItem("user", JSON.stringify(response.data.settings.user));
                    }
                }

                if (location.state && props.location.state.from) {
                    history.push(location.state.from);
                } else {
                    history.push('/my-account');
                }
            }
        }).catch(error => {
            UserHelper.showErrorToast(error && error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.');
        });
    }

    const performLogin = (username, password) => {
        UserHelper.login(username, password).then(result => {
            let data = result && result.response && result.response.data ? result.response.data : null;
            if (data && data.error) {
                UserHelper.showErrorToast(data.error ? data.error + "! " + data.message : props.i18n.t('error.occurred.please.try.again.later'), true);
            } else {
                if (location.state && location.state.from) {
                    history.push(location.state.from);
                } else {
                    history.push('/my-account');
                }
            }
        });
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title className="text-uppercase fw-bold h6">
                    Login
                </Card.Title>
                <GlobalMessages />
                <Card.Text>Please sign in using your email address and password</Card.Text>
                <Form onSubmit={(event)=>handleSubmit(event)} id="login-form">
                    <Form.Group as={Row} controlId="formLoginEmail" className="mb-3">
                        <Form.Label column sm={3} md={12} lg={3}>
                            Email address*
                        </Form.Label>
                        <Col sm={9} md={12} lg={9}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="envelope" /></InputGroup.Text>
                                <Form.Control name="loginEmail" type="email" placeholder="Email" required onChange={handleInputChange} />
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formLoginPassword" className="mb-3">
                        <Form.Label column sm={3} md={12} lg={3}>
                            Password*
                        </Form.Label>
                        <Col sm={9} md={12} lg={9}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                <Form.Control name="loginPassword" type="password" placeholder="Password" required onChange={handleInputChange} />
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    {mfaCheck === true ?
                        <Form.Group as={Row} controlId="formLoginEmail" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Two-factor code*
                            </Form.Label>
                            <Col sm={9} md={12} lg={9}>
                                <TotpField name="loginMFACode" placeholder="MFA Code" showLabel={true} handleChange={handleInputChange} ref = {(sI) => {secondInput = sI}}/>
                            </Col>
                        </Form.Group>
                    :""}

                    <Form.Group as={Row} controlId="formLoginForgotPassword" className="mb-3">
                        <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                            <Link to="/recover" className="fw-bold text-uppercase float-end forgotten-password-link">Forgotten your
                            password?</Link>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formLoginRememberMeSignIn" className="mb-3">
                        <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                            <Button variant="primary" type="submit" className="float-end text-uppercase mt-2 mt-lg-0">Sign in</Button>
                        </Col>
                    </Form.Group>
                </Form>

                <div className="text-center mb-4 d-flex">
                    <div className="w-100 position-relative border-bottom mb-25"></div>
                    <div className="px-4">OR</div>
                    <div className="w-100 position-relative border-bottom mb-25"></div>
                </div>

                <div className="my-3 text-center mx-auto" style={{'width':"250px"}} id="login-with-google-container">
                    <GoogleLogin
                        theme={UserHelper.isDark() ? "filled_black" : "outline"}
                        size="large"
                        width="250"
                        flow="implicit"
                        shape="pill"
                        type="standard"
                        cookiePolicy="single_host_origin"
                        accessType="offline"
                        responseType="code"
                        approvalPrompt="force"
                        scope='openid email profile https://www.googleapis.com/auth/calendar.events.readonly'
                        onSuccess={credentialResponse => loginWithGoogle(credentialResponse)}
                        onError={() => {
                            UserHelper.showErrorToast('An error occurred. Please try again later.');
                        }}
                    />
                </div>
            </Card.Body>
        </Card>
    )
}
