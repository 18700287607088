import React from 'react';
import ContentLoader from "react-content-loader";

const TableSectionLoader = (props) => {

    let result = [];

     for (let step = 0; step < props.numberOfRows; step++) {

          result.push(
          <tr key={step}>
              <td colSpan={props.colSpan}>
                  <ContentLoader
                    speed={1}
                    backgroundColor="var(--bs-body-bg)"
                    foregroundColor="var(--bs-muted)"
                    style={{ width: '100%', height: '30' }}
                  >
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="30" />
                  </ContentLoader>
              </td>
          </tr>);
      }

      return (
        <>
            {result}
        </>
      );

}
export default TableSectionLoader