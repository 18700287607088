import * as React from 'react';
import "./CmsTemplate.scss";
import {PageContext} from "./CmsPageProvider";
import * as changeCase from "change-case";

export default function CmsTemplate(props) {
    return <PageContext.Consumer>
        {value => {
            if(value.state.pageData &&
                value.state.pageData.masterTemplate &&
                value.state.pageData.masterTemplate["frontendTemplateName"]) {
                let pascalCasePath = value.state.pageData.masterTemplate.frontendTemplateName.split("/").map(el => changeCase.pascalCase(el)).join("/");
                try {
                    let Template = require('../' + pascalCasePath + '.js').default;
                    return (
                        <Template>
                            {props.children}
                        </Template>);
                } catch (e) {
                    return <div>Template not found, please create a default component into components/{pascalCasePath}.js</div>
                }

            }

            return <svg
                     width="51.379391mm"
                     height="32.098091mm"
                     viewBox="0 0 51.379391 32.098091"
                     version="1.1"
                     className="pageLoadingSpinner"
                     id="svg1013">
                    <g
                       id="layer1"
                       transform="translate(-51.039492,-108.59381)">
                      <path
                         d="m 76.741077,127.8522 v 6.42055 c 0,3.54436 -2.874786,6.41915 -6.418791,6.41915 H 57.350294 c -2.328686,-0.0381 -4.35751,-1.32116 -5.449358,-3.21028 -0.515055,-0.88441 -0.820561,-1.905 -0.856544,-2.99226 v -0.21661 h -0.0049 v -3.21027 h 6.420555 v 1.88665 c 0,0.7306 0.593373,1.32362 1.323975,1.32362 h 10.214681 c 0.73025,0 1.323622,-0.59302 1.323622,-1.32362 v -3.77331 c 0,-0.73025 -0.593372,-1.32362 -1.323622,-1.32362 H 57.460047 c -3.545769,0 -6.420555,-2.87302 -6.420555,-6.41879 v -4.43019 h 6.420555 v 3.10621 c 0,0.7306 0.593373,1.32398 1.323975,1.32398 h 11.538303 c 3.544005,0 6.418791,2.87302 6.418791,6.41879"
                         style={{fill:"transparent"}}
                         id="path_s"/>
                      <path
                         d="M 95.976567,108.59381 H 57.460289 c -3.545769,0 -6.420555,2.87373 -6.420555,6.41879 h 43.623793 c 0.676981,0 1.235427,0.51118 1.31304,1.16664 v 2.04364 h 6.420203 v -3.21028 c 0,-3.54506 -2.874435,-6.41879 -6.420203,-6.41879"
                         style={{fill:"transparent"}}
                         id="path_top" />
                      <path
                         d="m 102.41476,134.27282 v -3.21027 h -6.420202 v 1.88665 c 0,0.73096 -0.593018,1.32362 -1.324326,1.32362 h -7.137046 c -0.730956,0 -1.323623,-0.59266 -1.323623,-1.32362 v -15.87288 h -6.418792 v 17.36266 c 0.08855,3.4664 2.928057,6.25264 6.418792,6.25264 h 10.00266 c 2.32904,-0.0413 4.355397,-1.32045 5.447597,-3.20993 l 0.0113,-0.0198 c 0.52246,-0.90523 0.76482,-1.94486 0.74683,-2.98979 z"
                         style={{fill:"transparent"}}
                         id="path_c"/>
                    </g>
                  </svg>
            }
        }
    </PageContext.Consumer>
}
