import React, {Component} from 'react';
import ContentLoader from "react-content-loader";

export default class SectionLoader extends Component {
    render() {
        return (
            <ContentLoader
                speed={2}
                width={523}
                height={100}
                viewBox="0 0 523 120"
                >
                <rect x="0" y="0" rx="2" ry="2" width="523" height="389" />
            </ContentLoader>
        )
    }
}
