import React, {Component } from 'react';
import {Row, Col, Card, Form, Button, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import BootstrapTable from '../../../Misc/BootstrapTable.js';
import ApiCall from '../../../Helper/ApiCall.js';
import Select from 'react-select';
import UserHelper from '../../../../components/Helper/UserHelper.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import AccountClientsContactModal from './AccountClientsContactModal.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountClients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recipients: [],
            clients: {},
            statuses: [{'value' : 'prospect', 'label':'Prospect'}, {'value':'client', 'label':'Client'}],
            isLoading: true
        };

        this.getTrColor = this.getTrColor.bind(this);
        this.getAssignments = this.getAssignments.bind(this);
        this.getClients = this.getClients.bind(this);
        this.getEmployees = this.getEmployees.bind(this);
        this.deleteEmployee = this.deleteEmployee.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getActionsFormat = this.getActionsFormat.bind(this);
        this.downloadContracts = this.downloadContracts.bind(this);
    }

    componentDidMount() {
        let clientStatus = null;
        if (this.props.location && this.props.location.hash) {
            clientStatus = this.props.location.hash.substring(1);
            this.setState({clientStatus : clientStatus});
            this.getClients(0, clientStatus);
        } else {
            this.getClients(0);
        }
    }

    render() {

        let skin = UserHelper.isDark() ? "table-dark" : "";
        const clientColumns = [
            {
              dataField: 'legalName',
              text: 'Name',
              footer: columnData => <PaginationInfo page={this.state.clients} />
            },
            {
              dataField: 'lastContactDate',
              text: 'Last Contact',
              footer: ''
            },
            {
               dataField: 'dummy2',
               formatter: this.getActionsFormat,
               text: 'Actions',
               footer: columnData => <Pagination page={this.state.clients} handleChangePage={p=>this.getClients(p, this.state.clientStatus)}
               isLoading={this.state.isLoading} />
            }
        ];

        const expandRow = {
          renderer: row => {
            if (row.defaultRecipients) {
                let recipients = this.state.recipients;
                let setState = (a) => this.setState(a);
                let entries = row.defaultRecipients.map(function(entry, key) {
                return <Row className="ms-2" key={key}>
                            <Col xs={7} md={7} lg={7}><Form.Check name={entry.code} className="custom-checkbox" label={entry.displayName}
                            onChange={(event) =>{
                              var updatedList = recipients;
                              if (event.target.checked) {
                                updatedList.push(event.target.name);
                              } else {
                                updatedList.splice(updatedList.indexOf(event.target.name), 1);
                              }
                              setState({recipients : updatedList});
                            }}/></Col>
                            <Col xs={5} md={5} lg={5}>
                                <a href={"mailto:" + entry.code} className="ms-2"><FontAwesomeIcon size="lg" icon={"envelope"}/></a>
                                <a href={"mailto:" + entry.code} className="ms-2"><FontAwesomeIcon size="lg" icon={"phone"} /></a>
                                <a href={"mailto:" + entry.code} className="ms-2"><FontAwesomeIcon size="lg" icon={["fab", "linkedin"]} /></a>
                            </Col>
                        </Row>
            });

            return (
            <div>
              {entries}
            </div>
          )}},
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandHeaderColumnRenderer: ( isAnyExpands ) => {
            if (isAnyExpands) {
                return <b>[-]</b>;
            }
            return <b>[+]</b>;
          },
          expandColumnRenderer: ( expanded ) => {
            if (expanded) {
                return (
                  <b>[-]</b>
                );
            }
            return (
                <b>[+]</b>
            );
          }
        };

        return (
            <>
                <Row className="mt-3">
                    <Col xs={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Clients
                                    <Button variant="secondary" type="button" className="float-end text-uppercase ms-2" onClick={()=>this
                                    .setState({showClientsContactModal:true})}
                                    >Contact</Button>
                                </Card.Title>
                                <p>All clients</p>
                                <Row>
                                    <Col lg={3} md={3} className="mb-2">
                                    <Select name="status" options={this.state.statuses} value={this.state.clientStatus ? {value : this.state
                                    .clientStatus.code, label: this.state.clientStatus} : null} closeMenuOnSelect={true}
                                    className="w-100 react-select-container"
                                    isClearable="true" classNamePrefix="react-select" onChange={(event)=> {this.redirectToStatus(event, this.props.history)}}/>
                                    </Col>
                                </Row>

                                <Row>
                                   <Col lg={12}>
                                    <BootstrapTable
                                      keyField='code'
                                      classes={"table-align-middle table-hover " + skin}
                                      data={ this.state.clients.content }
                                      noDataIndication="No Clients"
                                      columns={ clientColumns }
                                      expandRow={ expandRow }
                                      bordered={true}
                                      hover
                                      condensed
                                      rowClasses={ this.getTrColor }
                                      isLoading={this.state.isLoading}
                                    />
                                    </Col>
                                </Row>

                                <Row>
                                    <AccountClientsContactModal show={this.state.showClientsContactModal} handleModalClose={()=>this.setState
                                    ({showClientsContactModal: false})} clients={this.state.recipients} />
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const code = target.dataset.id;

        let offerValues = this.state.offerValues;
        let specificOfferValues = offerValues.get(code);
        specificOfferValues[name] = value;

        offerValues.set(code, specificOfferValues);

        this.setState({
            offerValues : offerValues
        });
    }

    getActionsFormat(cell, organization) {
        const config = {
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        };

        return (
            <Dropdown className="float-end ms-2 text-bf">
                <Dropdown.Toggle variant="transparent" className="text-dark">
                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={config}>
                    <Dropdown.Item onClick={() => this.downloadContracts(organization.code)}>Download Contracts</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    downloadContracts = (organizationCode) => {
        fetch(process.env.REACT_APP_FACADE_URL + 'supreme-organization/legal/contracts/' + organizationCode, {
            method: "GET",
            headers: {
              'Accept': "application/zip",
              "Content-Type": "application/zip",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'legal.zip';
                    a.click();
                });
                this.setState({downloadNdaStarted:false});
                //window.location.href = response.url;
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    getTrColor(order, rowIndex) {
        if (order.clientStatus === 'CLIENT') {
            return "table-success";
        } else {
            return "table-warning";
        }
    }

    getClients(pageNumber, status) {

        let params = {'page':pageNumber,'size':20, 'sort':'legalName'};
        if (status) {
            params.status = status;
        }

        ApiCall.get('client-organization', params).then((response) => {
            this.setState({
                clients: response.data,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 0});
        });
    }

    getEmployees(pageNumber, organizationCode) {
        ApiCall.get('client-organization/' + organizationCode + '/employees',{
                size: 10,
                number: pageNumber
            }).then((response) => {
            this.setState({
                employees: response.data
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    redirectToStatus(event, history) {
        if (event) {
            history.push('/my-account/clients#' + event.value);
            this.setState({clientStatus:event.value});
            this.getClients(0, event.value);
        } else {
            history.push('/my-account/clients');
            this.setState({clientStatus:null});
            this.getClients(0, null);
        }
    }

    deleteEmployee(code) {
        ApiCall.delete('businessCustomer/' + code).then((response) => {
            this.setState({
                isLoading: false
            });
            UserHelper.showSuccessToast('Success.');
            this.getEmployees(0, this.state.selectedOrganization.code);
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getAssignments(page, code) {
        ApiCall.get("assignment/organization/" + code + "/all", {page: page, size: 10}).then((response) => {
            this.setState({
                assignments : response.data
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getContractorName(cell, contractor) {
        return contractor.user ? contractor.user.displayName : contractor.externalContractorDisplayName;
    }
}

export default withRouter(withProgressBarContext(AccountClients));
