import React from 'react';
import {Table, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';
import { useTable } from 'react-table';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';

const DND_ITEM_TYPE = 'row';

const DndRow = ({ row, index, data, setRecords, moveRow, reorderRows, editRow, deleteRow }) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop(item, monitor) {
        reorderRows(row.original.code, item.index);
    },
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { index },
    type: DND_ITEM_TYPE,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <tr ref={dropRef} style={{ opacity }} key={row.code}>
      <td ref={dragRef} className="text-center"><FontAwesomeIcon icon="bars" className="cursor-pointer"/></td>
      {row.cells.map(cell => {
        if (cell.column['Header'] !== 'Actions') {
            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
        } else {
            return <td {...cell.getCellProps()}>
                       <Button className="btn-primary text-uppercase me-3" onClick={()=>editRow(row.original)}>Edit</Button>
                       <Button variant="danger" className="btn-danger text-uppercase" onClick={()=>deleteRow(row.index, row.original.code)}>Delete</Button>
                   </td>
        }
      })}
    </tr>
  )
}

const DndTable = ({ entityName, columns, data, emptyMsg, reorderRows, editRow, isLoading }) => {
  const [records, setRecords] = React.useState(data);
  React.useEffect(() => { setRecords(data); } ,[data]);
  const getRowId = React.useCallback(row => {
    return row.code
  }, [])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    data: records,
    columns,
    getRowId,
  });

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    )
  }

  const deleteRow = (rowIndex, code) => {

    ApiCall.delete(entityName + '/' + code).then((response) => {
        setRecords(
          update(records, {
            $splice: [
              [rowIndex, 1],
            ],
          })
        )
    }).catch(error => {
        UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
    });
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Table striped bordered hover responsive className="table-align-middle" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th></th>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            isLoading ?
                <TableSectionLoader colSpan={columns.length + 1} numberOfRows={3}/>
          : rows && rows.length>0 ?  rows.map(
            (row, index) =>
              prepareRow(row) || (
                <DndRow
                  index={index}
                  row={row}
                  data={data}
                  setRecords={setRecords}
                  moveRow={moveRow}
                  reorderRows={reorderRows}
                  editRow={editRow}
                  deleteRow={deleteRow}
                  {...row.getRowProps()}
                />
              )
          )
          :<tr><td colSpan={columns.length + 1} className="text-center">{emptyMsg}</td></tr>
          }
        </tbody>
      </Table>
    </DndProvider>
  )
}

export default DndTable;
