import React, {Component} from 'react';
import {Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import Select from 'react-select';

import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';

class AccountNewClient extends Component {
    constructor(props){
        super(props);

        this.state = {
            technologies: [],
            levels: [],
            skillCompetenceForm: {
                code: null,
                name: null,
                level: null,
                years: 0
            }
        };

        this.getTechnologies = this.getTechnologies.bind(this);
        this.getLevels = this.getLevels.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeSkillCompetenceForm = this.handleChangeSkillCompetenceForm.bind(this);
        this.handleSubmitAddEditSkillCompetenceForm = this.handleSubmitAddEditSkillCompetenceForm.bind(this);
        this.clearSkillCompetenceForm = this.clearSkillCompetenceForm.bind(this);
    }

    componentDidMount() {
        this.getTechnologies();
        this.getLevels();
    }

    componentDidUpdate(prevProps) {
        if (this.props.editSkillCompetence !== prevProps.editSkillCompetence) {
            this.setState({
                    skillCompetenceForm: {
                        code: this.props.editSkillCompetence ? this.props.editSkillCompetence.code : null,
                        name: this.props.editSkillCompetence ? this.props.editSkillCompetence.name : "",
                        level: this.props.editSkillCompetence ? this.props.editSkillCompetence.level : "",
                        years: this.props.editSkillCompetence ? this.props.editSkillCompetence.years : 0,
                    }
                }
            );
        }
    }

    render() {
        let technologyOptions = [];
        technologyOptions.push(<option disabled value={-1} key={-1}>Please select...</option>);
        technologyOptions.push(this.state.technologies.map(function(technology, key){
            return <option key={key} value={technology.code}>{technology.name}</option>;
        }));

        let levelOptions = [];
        levelOptions.push(<option disabled value={-1} key={-1}>Please select...</option>);
        levelOptions.push(this.state.levels.map(function(level, key){
            return <option key={key} value={level}>{level}</option>;
        }));

        return (
            <React.Fragment>
                <Form id="skillCompetenceForm" onSubmit={this.handleSubmitAddEditSkillCompetenceForm}>
                    <Form.Group as={Row} controlId="name" className="mb-3">
                        <Col md={5} lg={5}>
                            <Form.Group as={Col} controlId="name">
                                <InputGroup>
                                    <Form.Control name="name" type="text" placeholder="Name" value={this.state.subject || ""}
                                    required onChange={this.handleInputChange} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col md={4} lg={4}>
                            <Form.Group as={Row} controlId="defaultRecipients" className="mb-3">
                                <InputGroup>
                                    <Select name="recipients" options={this.state.clientEmployees} value={this.state.recipients}
                                        closeMenuOnSelect={false} isMulti className="w-100" required
                                        onChange={this.handleInputChange}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col md={1} lg={1}>
                            <Button variant="primary" type="submit" className="float-end text-uppercase">Save</Button>
                        </Col>
                        <Col md={2} lg={2} className="mt-2">
                            <Button variant="link" onClick={() =>this.props.cancelNewClientForm()}>Cancel</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </React.Fragment>
        )
    }

    handleChangeSkillCompetenceForm(field, value) {
        let newSkillCompetenceForm = this.state.skillCompetenceForm;
        newSkillCompetenceForm[field] = value;

        this.setState({
            skillCompetenceForm: newSkillCompetenceForm
        });
    }


    handleInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                this.handleChangeSkillCompetenceForm(name, value);
            } else {
                this.handleChangeSkillCompetenceForm("name", event);
            }
        }
    }

    getTechnologies() {
        UserHelper.getTechnologies().then(response => {
            if (response.technologies && response.technologies.length) {
                let techs = [];
                response.technologies.forEach(r => {
                    techs.push({value: r.code, label: r.name});
                });

                this.setState({
                    technologies: techs
                });
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);

                this.setState({
                    technologies: []
                });
            }
        });
    }

    getLevels() {
        UserHelper.getLevels().then(response => {
            if (response.levels && response.levels.length) {
                this.setState({
                    levels: response.levels
                });
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);

                this.setState({
                    levels: []
                });
            }
        });
    }

    handleSubmitAddEditSkillCompetenceForm(event) {
        event.preventDefault();

        let skillCompetence = {
            code: this.state.skillCompetenceForm.code,
            level: this.state.skillCompetenceForm.level,
            years: this.state.skillCompetenceForm.years,
            technology: {
                code: this.state.skillCompetenceForm.name.value
            }
        };

        if (this.props.editSkillCompetence) {
            ApiCall.put('skill-competence', skillCompetence).then(() => {
                this.clearSkillCompetenceForm();

                UserHelper.showSuccessToast("Your skill competence was updated.");

                this.props.getSkillCompetences();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        } else {
            ApiCall.post('skill-competence', skillCompetence).then(() => {
                this.clearSkillCompetenceForm();

                UserHelper.showSuccessToast("Your skill competence was added.");

                this.props.getSkillCompetences();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }
    }

    clearSkillCompetenceForm() {
        this.setState({
            skillCompetenceForm: {
                code: null,
                name: null,
                level: null,
                years: 0
            }
        });
    }
}

export default AccountNewClient;
