import React, {Component} from 'react';
import {Row, Container, Button, Col, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import { Document, Page, pdfjs } from "react-pdf";
import UserHelper from '../../../Helper/UserHelper.js';
import './AccountOrderView.scss';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class AccountOrderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderCode: '',
            downloadStarted:false,
            totalPages: 0,
            currentPage : 1
        };

        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    componentDidMount() {
        if (this.props.location && this.props.location.hash) {
            this.setState({orderCode:this.props.location.hash.substring(1), pdf:{ url: process.env.REACT_APP_FACADE_URL + "invoice/"+this.props.location.hash.substring(1), httpHeaders: { 'X-Nemesis-Token': localStorage.getItem("token") }}});
        }
    }

    render() {

        return (<>
            <Row className="mt-3">
                <Col sm={12} md={12} className="mt-3 mt-md-0">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-uppercase fw-bold">
                                {'Order ' + this.state.orderCode}
                                <Button variant="secondary" className="float-end text-uppercase" onClick={this.downloadInvoice}>
                                    <span className="icon icon-white me-1">
                                        {this.state.downloadStarted ?
                                            <FontAwesomeIcon icon="spinner" spin/>:<FontAwesomeIcon icon="file-arrow-down" />
                                        }
                                    </span>
                                    <span className="text">Download PDF</span>
                                </Button>
                            </Card.Title>
                            <p>Here you can view the PDF invoice for your order</p>
                            <Container>
                                <Row>
                                    <Col sm={1} className="my-auto">
                                        {this.state.currentPage>1?
                                        <Button size="lg" variant="primary" onClick={()=>{this.setState({currentPage:this.state.currentPage-1})
                                        }}><FontAwesomeIcon
                                        icon={"arrow-left"}/></Button>
                                        :''}
                                    </Col>
                                    <Col className="Example__container__document">
                                        <Document file={this.state.pdf} onLoadSuccess={this.onDocumentLoadSuccess}>
                                            <Page pageNumber={this.state.currentPage} className="mx-auto"/>
                                        </Document>
                                    </Col>
                                    <Col sm={1} className="my-auto">
                                        {this.state.totalPages > this.state.currentPage?
                                        <Button size="lg" variant="primary" className="align-middle" onClick={()=>{this.setState({currentPage:this.state
                                        .currentPage+1})}}><FontAwesomeIcon icon={"arrow-right"}/></Button>
                                        :null}
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
        )
    }

    onDocumentLoadSuccess(nPages) {
        this.setState({totalPages: nPages._pdfInfo.numPages});
    }

    downloadInvoice = () => {
        this.setState({downloadStarted:true});
        fetch(process.env.REACT_APP_FACADE_URL + 'invoice/' + this.state.orderCode, {
            method: "GET",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-Invoice-' + this.state.orderCode + '.pdf';
                    a.click();
                });
                this.setState({downloadStarted:false});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

}

export default withRouter(AccountOrderView);
