import React, { useState } from "react";
import { InputGroup, Button } from 'react-bootstrap';
import AuthCode from 'react-auth-code-input';

import './TotpField.scss';

export default function TotpField(props) {

    const [result, setResult] = useState();

    const handleOnChange = async (res) => {
        setResult(res);

        if (res.length === 6 && props.submit) {
            props.submit(res);
        }
    };

    return (
        <InputGroup className="position-relative w-100">
             <InputGroup.Text className="otp-field d-flex w-100 mx-auto">
                <AuthCode onChange={handleOnChange} allowedCharacters='numeric' containerClassName="otp-field d-flex mx-auto" inputClassName="m-1 text-center fs-3 rounded-4 form-control form-control-lg" />
                {props.submitButton ?
                    <Button variant="primary" className="ms-2" onClick={()=>props.submit(result)}>Submit</Button>
                :""}
            </InputGroup.Text>
        </InputGroup>
    );
}