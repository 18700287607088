import React from "react";
import {Form, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import UserHelper from '../Helper/UserHelper.js';
import CommerceHelper from '../Helper/CommerceHelper.js';

export default function SelectInputfieldWidget(props) {
    if (!props.widgetData) {
        return null;
    }

    let currentLocale = localStorage.getItem("currentLocale");
    let currentCurrency = localStorage.getItem("currentCurrency");
    let className = props.widgetData.styleClass;
    let prependIconPrefix = null;
    let prependIcon = null;
    if (props.widgetData.prependIcon) {
        let res = props.widgetData.prependIcon.split(" ");
        prependIconPrefix = res[0];
        prependIcon = /fa-(.*)/.exec(res[1])[1];
    }

    let onLocaleChanged = (e) => {
        UserHelper.updateSessionLocale(e.target.value).then(response => {
            if (response.success) {
                window.location.reload();
            } else if (response.errorMsg) {
                console.log(response.errorMsg);
            }
        });
    }

    let onCurrencyChanged = (e) => {
        CommerceHelper.updateSessionCurrency(e.target.value).then(response => {
            if (response.success) {
                window.location.reload();
            } else if (response.errorMsg) {
                console.log(response.errorMsg);
            }
        });
    }

    let isLocaleOrCurrency = props.widgetData.code === "localeSelectInput" || props.widgetData.code === "currencySelectInput";

    return (
        <Form.Group className={isLocaleOrCurrency ? 'me-0 me-md-2' : 'row'}>
            {!isLocaleOrCurrency &&
                <Form.Label className="form-label col-form-label col-lg-3 col-md-12 col-sm-3">
                    {props.widgetData.name}{props.widgetData.mandatory ? "*" : ""}
                </Form.Label>
            }

            <div className={isLocaleOrCurrency ? "" : "col-lg-9 col-md-12 col-sm-9"}>
                <InputGroup>
                    {props.widgetData.prependIcon && 
                        <InputGroup.Text><FontAwesomeIcon icon={[prependIconPrefix, prependIcon]} /></InputGroup.Text>
                    }

                    <Form.Select onChange={props.widgetData.code === "localeSelectInput" ? onLocaleChanged : (props.widgetData.code === "currencySelectInput" ? onCurrencyChanged : (
                            props.handleInputChange ? props.handleInputChange : () => void 0
                        ))} defaultValue={props.widgetData.code === "localeSelectInput" ? currentLocale : (props.widgetData.code === "currencySelectInput" ? currentCurrency : props.widgetData.currentValue)}
                        name={props.widgetData.attributeName}
                        required={props.widgetData.mandatory}
                        className={className}>
                        {Object.keys(props.widgetData.options).map((entryKey) => {
                            return <option key={entryKey} value={entryKey}>{props.widgetData.options[entryKey]}</option>
                        })}
                    </Form.Select>
                </InputGroup>
            </div>
        </Form.Group>
    );
}
