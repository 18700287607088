import React from "react"
import { Button, Modal } from "react-bootstrap"

export default function BootstrapModal({ show, name, handleClose, handleAction, actionBtn, children, size }) {
    return (
        <Modal show={show} onHide={handleClose} size={size || "xl"}>
            <Modal.Header closeButton>
                <Modal.Title>{name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" className="text-white" onClick={handleAction}>
                    {actionBtn}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}