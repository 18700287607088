import React, {Component} from 'react';
import {format} from 'date-fns';
import {Row, Col, Table, Card} from 'react-bootstrap';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';

class ClientInterviewTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            interviews: [],
            interviewValues: new Map(),
            isLoading: true,
        };

        this.getInterviews = this.getInterviews.bind(this);
    }

    componentDidMount() {
        this.getInterviews(0);
    }

    render() {
        let interviews = null;

        if (this.state.isLoading) {
            interviews = <TableSectionLoader colSpan={4} numberOfRows={5}/>
        } else {
            if (this.state.interviews && this.state.interviews.content && this.state.interviews.content.length) {
                interviews = this.state.interviews.content.map(function(interview, key) {
                    return (
                        <React.Fragment key={interview.code}>
                            <tr key={interview.code + '1'}>
                                <td>{interview.offer && interview.offer.user ? interview.offer.user.initials : ''}</td>
                                <td>{format(interview.startDateTime, "EEE, dd MMM HH:mm")}</td>
                                <td>{format(interview.endDateTime, "EEE, dd MMM HH:mm")}</td>
                                <td><a href={interview.link} target="_blank" rel="noopener noreferrer">{interview.link}</a></td>
                            </tr>
                        </React.Fragment>
                    );
                }, this);
            } else {
              interviews = <tr><td colSpan="4" className="text-center">You have no interviews</td></tr>;
            }
        }

        return (
            <Row className="mt-3">
                <Col sm={12} md={12} className="mt-3 mt-md-0">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-uppercase fw-bold">
                                Your Interviews
                            </Card.Title>
                            <p>Here you can see your scheduled interviews</p>
                            <Table striped bordered hover responsive className="table-align-middle" id="interviews-table">
                                <thead>
                                    <tr>
                                        <th>Initials</th>
                                        <th>Start Date/Time</th>
                                        <th>End Date/Time</th>
                                        <th>Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { interviews }
                                </tbody>
                                {this.state.interviews && this.state.interviews.content && this.state.interviews.content.length > 0 ?
                                    <tfoot>
                                        <tr>
                                            <th colSpan="2">
                                                <PaginationInfo page={this.state.interviews} />
                                            </th>
                                            <th colSpan="3">
                                                <div className="float-end mb-3 w-50">
                                                    <Pagination page={this.state.interviews} handleChangePage={this.getInterviews} isLoading={this.state
                                                    .isLoading} />
                                                </div>
                                            </th>
                                        </tr>
                                    </tfoot>
                                : null}
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }

    getInterviews(pageNumber) {
        ApiCall.get('interview/all', {page:pageNumber}).then((response) => {

            let interviewValues = new Map();
            response.data.content.forEach(interview => {
                interviewValues.set(interview.code, {"dateTime":interview.dateTime,"link":interview.link});
            });

            this.setState({
                interviews: response.data,
                interviewValues: interviewValues,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

}

export default withProgressBarContext(ClientInterviewTable);
