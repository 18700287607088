import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import {withRouter} from 'react-router-dom';
import './Timeline.scss';

class AccountResourceHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
        this.getUserHistoryTimeline = this.getUserHistoryTimeline.bind(this);
    }

    componentDidMount() {
        this.getUserHistoryTimeline(this.props.username);
    }

    render() {
        var timelineEvents = null;

        if (this.state.timeline) {
            timelineEvents = this.state.timeline.events.map(function(event, key) {
                return (
                    <li className={key%2 ===0 ? 'timeline-inverted' : "timeline-badge"} key={event.code}>
                      <div className={this.getTimelineClass(event)}><FontAwesomeIcon className="mt-2" size="1x" icon={this.getTimelineIcon(event)} /></div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h5 className="timeline-title">{this.getEventCategoryName(event)}</h5>
                          <p><small className="text-muted">{event.dateTime}</small></p>
                          <p>{event.organization != null ? event.organization.legalName:null}</p>
                        </div>
                        <div className="timeline-body">
                          <p>{event.description}</p>
                        </div>
                      </div>
                    </li>);
            }, this);
        }

        return (<>
            <Row className="mt-3">
                <Col sm={12} md={12} className="mt-3 mt-md-0">
                    <Container>
                        <Row>
                            <Col sm={12} className="my-auto">
                                 <ul className="timeline">
                                    {timelineEvents}
                                 </ul>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
        )
    }

    getTimelineClass(event) {
        var result = "";

        switch(event.eventCategory) {
            case 'REGISTRATION' : result = 'bg-success';break;
            case 'OFFER' : result = 'bg-secondary';break;
            case 'OFFER_FAILED': result = 'bg-warning';break;
            case 'INTERVIEW' : result = 'bg-info';break;
            case 'INTERVIEW_FAILED' : result = 'bg-warning';break;
            case 'INTERVIEW_SUCCESS' : result = 'bg-muted';break;
            case 'ASSIGNMENT_START' : result = 'bg-success';break;
            case 'ASSIGNMENT_END' : result = 'bg-danger';break;
            default: result = '';break;
        }

        return "timeline-badge " + result;
    }

    getTimelineIcon(event) {
        var result = "";

        switch(event.eventCategory) {
            case 'REGISTRATION' : result = 'flag';break;
            case 'OFFER' : result = 'question';break;
            case 'OFFER_FAILED': result = 'xmark';break;
            case 'INTERVIEW' : result = 'comment';break;
            case 'INTERVIEW_FAILED' : result = 'thumbs-down';break;
            case 'INTERVIEW_SUCCESS' : result = 'thumbs-up';break;
            case 'ASSIGNMENT_START' : result = 'circle-play';break;
            case 'ASSIGNMENT_END' : result = 'circle-stop';break;
            case 'NOTES' : result = 'pencil';break;
            default: result="";break;
        }

        return result;
    }

    getEventCategoryName(event) {
        switch(event.eventCategory) {
            case 'REGISTRATION' : return 'Registration';
            case 'OFFER' : return 'Offer';
            case 'OFFER_FAILED' : return 'Offer Rejected';
            case 'INTERVIEW' : return 'Interview';
            case 'INTERVIEW_FAILED' : return 'Failed interview';
            case 'INTERVIEW_SUCCESS' : return 'Success interview';
            case 'ASSIGNMENT_START' : return 'Assignment start';
            case 'ASSIGNMENT_END' : return 'Assignment end';
            default: return '';
        }
    }

    getUserHistoryTimeline(username) {
        ApiCall.get('timeline/' + username).then((response) => {
            let timeline = response.data;
            this.setState({
                timeline: timeline
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                profileInfoMsg: null
            });
        });
    }
}

export default withRouter(AccountResourceHistory);
