import React, {Component} from 'react';
import {Form, Row, Col, Badge, Button, InputGroup} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import ApiCall from '../../../Helper/ApiCall.js';
import Select from 'react-select';

class AccountRequestResourceModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null,
            form:{
                status: 'INQUIRY',
                user: '',
                organization:'',
                jobRequest: ''
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitRequestForm = this.handleSubmitRequestForm.bind(this);
    }

    render() {
        return (
            <>
                {this.props.requestedResources && this.props.jobRequests ?
                    <Modal centered size="lg" show={this.props.show} onHide={()=>{this.props.handleModalClose(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-uppercase fw-bold">Request Resources</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="show-grid">
                                <Col xs={12} sm={12}>
                                    <div className="h5">
                                        {this.props.requestedResources.map(function(d, idx) {
                                            return (<Badge key={d.properties.id} pill className="me-1" bg="primary">{d.properties.initials}</Badge>)
                                        })}
                                    </div>
                                    <Form onSubmit={this.handleSubmitRequestForm}>
                                        <Form.Group controlId="orderPrefix">
                                            <Form.Label>
                                                Job Request*
                                            </Form.Label>
                                            <InputGroup>
                                                <Select name="request" options={this.props.jobRequests} value={this.state.request}
                                                closeMenuOnSelect={true} className="w-100 react-select-container" required classNamePrefix="react-select"
                                                onChange={(event)=> this.handleInputChange(event)}/>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formSubmitRequestResource" className="mb-3">
                                            <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                                <Button variant="primary" type="submit" className="float-end text-uppercase">Submit</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                :null}
            </>
        )
    }

    handleInputChange(event) {
        this.setState({
            request: event
        });
    }

    handleSubmitRequestForm(event) {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        let profiles = [];
        this.props.requestedResources.forEach(res=> {
            profiles.push(res.id);
        });

        let offerInquiry = {
            profiles: profiles,
            jobRequestCode: this.state.request.value
        };

        ApiCall.post('offer/inquiry', offerInquiry).then((response) => {
            this.setState({
                isLoading: true,
                showRejectionModal: false
            });
            this.props.handleModalClose(true);
        }).catch(error => {
            console.log(error);
            this.setState({
                isLoading: false
            });
            this.props.handleModalClose(false);
        });
    }
}

export default withRouter(AccountRequestResourceModal);
