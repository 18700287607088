import React, {useState} from "react";
import Autosuggest from 'react-autosuggest';
import {Container, Row, Col, Form, FormControl, InputGroup, Image} from 'react-bootstrap';
import {Link, useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import CommerceHelper from '../Helper/CommerceHelper.js';

import "./TextInputfieldWidget.scss";

export default function TextInputfieldWidget(props) {
    let history = useHistory();
    
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState('');
    
    if (!props.widgetData) {
        return null;
    }

    let input = null;

    let className = props.widgetData.styleClass;
    let prependIconPrefix = null;
    let prependIcon = null;

    if (props.widgetData.prependIcon) {
        let res = props.widgetData.prependIcon.split(" ");
        prependIconPrefix = res[0];
        prependIcon = /fa-(.*)/.exec(res[1])[1];
    }

    let onSuggestionsFetchRequested = ({ value }) => {
        CommerceHelper.getAutocompleteSuggestions(value, "product").then(response => {
            if (response.errorMsg) {
                console.log(response.errorMsg);
            } else {
                if (response.suggestions && response.suggestions.length > 0) {
                    setSuggestions(response.suggestions);
                } else {
                    setSuggestions([]);
                }
            }
        });
    };
 
    let onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    let onChange = (event) => {
        setValue(event.target.value);
    };

    let handleAutosuggestKeyDown = (event) => {
        if (event.key === 'Enter') {
            history.push('/search?q=' + value);
        }
    }

    const inputProps = {
        placeholder: props.widgetData.placeholder,
        value,
        onChange: onChange,
        onKeyDown: handleAutosuggestKeyDown
    };

    let renderSuggestion = suggestion => (
        <Container>
            <Link to={suggestion.properties.url}>
                <Row>
                    <Col sm={4} className="text-center">
                        <Image fluid src={suggestion.properties.imgMini} />
                    </Col>
                    <Col sm={8}>
                        <div className="name" dangerouslySetInnerHTML={{__html: suggestion.properties.name}}></div>
                        <div className="price">
                            <div className="d-inline-block me-2">{suggestion.properties.price.formatted}</div>
                            {suggestion.properties.discountedPrice != null &&
                                <div className="d-inline-block text-secondary fw-bold">{suggestion.properties.discountedPrice.formatted}</div>
                            }
                        </div>
                    </Col>
                </Row>
            </Link>
        </Container>
    );

    let renderInputComponent = (inputProps) => {
        return <FormControl type="search" {...inputProps} name={props.widgetData.attributeName} className={className} />;
    };

    let getSuggestionValue = suggestion => suggestion.properties.code;

    if (props.widgetData.code === "searchFormInput") {
        input = <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        inputProps={inputProps}
      />;
    } else {
        input = <FormControl type="text" placeholder={props.widgetData.placeholder} name={props.widgetData.attributeName} onChange={props.handleInputChange ? props.handleInputChange : () => void 0} 
                required={props.widgetData.mandatory} className={className} />;
    }

    let isSearchFormInput = props.widgetData.code === "searchFormInput";

    return (
        <Form.Group as={Row} className={isSearchFormInput ? 'mb-3' : 'mb-3 row'}>
            {!isSearchFormInput &&
                <Form.Label className="form-label col-form-label col-lg-3 col-md-12 col-sm-3">
                    {props.widgetData.name}{props.widgetData.mandatory ? "*" : ""}
                </Form.Label>
            }

            <div className={isSearchFormInput ? "" : "col-lg-9 col-md-12 col-sm-9"}>
                <InputGroup>
                    {props.widgetData.prependIcon && 
                        <InputGroup.Text><FontAwesomeIcon icon={[prependIconPrefix, prependIcon]} /></InputGroup.Text>
                    }

                    {input}
                </InputGroup>
            </div>
        </Form.Group>
    );
}
