import * as React from 'react';
// import SimpleBannerWidget from "./SimpleBannerWidget.js";
import {PageContext} from "./CmsPageProvider";
import * as changeCase from "change-case";

export default function CmsSlot(props) {
    //props.position
    //props.data.widget //or maybe from context data widgets

    // const toPascalCase = widgetCode => widgetCode.replace(/\w\S*/g, m=> m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());
    // console.log(changeCase.pascalCase("test_me"));

    // let Component = require('./simple_banner_widget.js').default;

    // return (
    //     <>
    //     {
    //         slotData[0].widgets.map((widget, i) => {
    //             try {
    //                 let Component = require('./' + changeCase.pascalCase(widget.entityName) + '.js').default;
    //                 return <Component key={i} widgetData={widget}/>;
    //             } catch(e) {
    //                 return <div> Component not found, please create a default component into components/cms/ called {changeCase.pascalCase(widget.entityName)} in a file  {changeCase.pascalCase(widget.entityName)}.js</div>
    //             }
    //         })
    //     }
    //     </>
    // );

    return <PageContext.Consumer>
        {value => {
            return (
                <>
                    {
                        value.state.pageData &&
                        value.state.pageData.slots &&
                        value.state.pageData.slots[props.position] &&
                        value.state.pageData.slots[props.position][0].widgets.map((widget, i) => {
                            try {
                                let Component = require('./' + changeCase.pascalCase(widget.entityName) + '.js').default;
                                return <Component key={i} widgetData={widget} {...props} />;
                            } catch (e) {
                                return <div> Component not found, please create a default component into components/cms/ called {changeCase.pascalCase(widget.entityName)} in a file {changeCase.pascalCase(widget.entityName)}.js</div>
                            }
                        }, props)
                    }
                </>
            );
        }
        }
    </PageContext.Consumer>
}
