import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import Pagination from './Pagination.js';
import ResultSize from './ResultSize.js';
import './PaginationToolbar.scss';
import { ListerContext } from "../Account/View/AccountSearch/ResourceListerPageProvider";

export default class PaginationToolbar extends Component {
    render() {
        return (
            <ListerContext.Consumer>
                {
                    (context) => (
                        <Row className="mt-3">
                            <Col lg={9} className="float-start">
                                <ResultSize page={context.state.searchPageData} isLoading={context.state.isLoading}/>
                            </Col>

                            <Col lg={3} className="float-end">
                                <Pagination page={context.state.searchPageData} handleChangePage={context.getResources} isLoading={context.state.isLoading} />
                            </Col>
                        </Row>
                    )
                }
            </ListerContext.Consumer>
        )
    }
}
