import React, {Component} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import DataObjectParser from "dataobject-parser";
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';

class OfferRejectionModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null,
            form:{}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitOfferFeedbackForm = this.handleSubmitOfferFeedbackForm.bind(this);
    }

    render() {
        return (
            <>
                {this.props.offers ?
                    <Modal centered size="lg" show={this.props.show} onHide={()=>{this.props.handleOfferModalClose(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-uppercase fw-bold">Offer Rejected</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="show-grid">
                                <Col xs={12} sm={12}>
                                    <Form onSubmit={this.handleSubmitOfferFeedbackForm}>
                                        <Form.Group as={Row} controlId="orderPrefix" className="mb-3">
                                            <Form.Label>
                                                Feedback*
                                            </Form.Label>
                                            <Form.Control name="feedback" as="textarea" rows={10} placeholder="Feedback" onChange={this.handleInputChange} />
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formRegisterCreateAccount" className="mb-3">
                                            <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                                <Button variant="primary" type="submit" className="float-end text-uppercase">Submit</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                :null}
            </>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let newForm = this.state.form;
        newForm[name] = value;

        this.setState({
            form: newForm
        });
    }


    handleSubmitOfferFeedbackForm(event) {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        let offer = DataObjectParser.transpose(this.state.form).data();
        ApiCall.delete('offer/reject', {"codes": this.props.offers.join(",")}, {reason:offer.feedback}).then((response) => {
            this.setState({
                isLoading: true,
                showRejectionModal: false
            });
            UserHelper.showSuccessToast("Offer rejected successfully!");
            this.props.handleOfferModalClose(true);
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.handleOfferModalClose(false);
        });
    }
}

export default withRouter(OfferRejectionModal);
