import React, {Component} from 'react';

export default class PaginationInfo extends Component {
    constructor(props){
        super(props);

        let maxResults = props.page ? (props.page.number + 1) * props.page.size : 0;
        let minResults = props.page ? maxResults - props.page.size + 1 : 0;

        this.state = {
            maxResultNo: maxResults,
            minResultNo: minResults
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.page !== prevProps.page) {
            let maxResults = this.props.page ? (this.props.page.number + 1) * this.props.page.size : 0;
            let minResults = this.props.page ? maxResults - this.props.page.size + 1 : 0;

            this.setState({
                maxResultNo: maxResults,
                minResultNo: minResults
            });
        }
    }

    render() {
        if(!this.props.page || this.props.page.totalElements < 1) {
            return null;
        }

        return (
            <span>Showing {this.state.minResultNo}-{this.state.maxResultNo > this.props.page.totalElements ? this.props.page.totalElements : this.state.maxResultNo} of {this.props.page.totalElements} results.</span>
        )
    }
}
