import React, {useState, useEffect} from "react";
import {Card} from 'react-bootstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../../components/Helper/UserHelper.js';
import FacetListerLoader from './FacetListerLoader.js';

export default function SearchHourlyRateFacet(props) {
    const [value, setValue] = useState(null);
    const [facet, setFacet] = useState(null);

    let sliderFacetContent = null;

    useEffect(() => {
        if (props.searchPageData && props.searchPageData.facets) {
            let facetName = UserHelper.isSuperSales() || UserHelper.isManager() ? 'hourlyRate' : 'supremeHourlyRate';
            let searchPageDataFacet = props.searchPageData.facets[facetName];
            if (searchPageDataFacet && (facet !== searchPageDataFacet)) {
                setFacet(searchPageDataFacet);
                setValue([Number(searchPageDataFacet.userSelectionMin), Number(searchPageDataFacet.userSelectionMax)]);
            }
        }
    }, [props.searchPageData, facet]);

    if (!props.isLoading) {
        if (!facet) {
            return null;
        }

        if (facet.values) {
            if (!value) {
                setValue([Number(facet.userSelectionMin), Number(facet.userSelectionMax)]);
            }

            sliderFacetContent = <React.Fragment>
                <div className="float-start mt-n1">{isChanged(facet) ?
                    <div className="float-start me-1"><FontAwesomeIcon icon={"xmark"} onClick={()=>{props.updateUrl(null, null, facet.clearQuery
                    ? facet.clearQuery.url : null)}}/></div>
                :" "}€{value && value[0]}</div>
                <Slider range allowCross={false} min={Number(facet.initialMinValue)} max={Number(facet.initialMaxValue)} value={value} onChange={(val) => setValue(val)}
                    className="mx-auto w-70 pt-2"
                    onAfterChange={(val) => {
                        setValue(val);
                        props.updateUrl(null, null, getNewFacetQueryUrl(facet, val[0], val[1]))
                    }} />
                <div className="float-end mt-n3">€{value && value[1]}</div>
            </React.Fragment>;
        }
    }

    return (
        <React.Fragment>
            {props.isLoading ?
                    <FacetListerLoader/>
                :
                    <Card className={"w-100 float-end"}>
                        <Card.Body className={'p-2'}>
                        {sliderFacetContent}
                        </Card.Body>
                    </Card>
            }
        </React.Fragment>
    );
}

function isChanged(facet) {
    return facet.userSelectionMin !== facet.initialMinValue && facet.userSelectionMax !== facet.initialMaxValue;
}

function getNewFacetQueryUrl(facet, minValue, maxValue) {
    if (!minValue && !maxValue) {
        return '';
    }

    let newFacetQueryUrl = facet.query.url;
    let userStart = newFacetQueryUrl.indexOf(":" + facet.code);
    userStart = (userStart + 1) + facet.code.length + 1;

    let userEnd = newFacetQueryUrl.substring(userStart + 1).indexOf("[");
    if (userEnd !== -1) {
        userEnd = userStart + userEnd;
    } else {
        userEnd = newFacetQueryUrl.indexOf(']');
    }

    return newFacetQueryUrl.substring(0, userStart) + "[" + minValue + "-" + maxValue + "]" + newFacetQueryUrl.substring(userEnd + 1);
}
