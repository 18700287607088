import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col, Table, Card, Form, InputGroup, Button, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import qs from 'qs';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class SalesAssignmentTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            assignments: [],
            assignmentValues: new Map(),
            organizations: [],
            clientManagers: [],
            expiring: false,
            assignmentForm: {
                externalContractorDisplayName: null,
                activeFrom: null,
                activeTo: null,
                client: null,
                supremeHourlyRate: null,
                companyHourlyRate:null
            },
            isLoading: true
        };

        this.getAssignmentClass = this.getAssignmentClass.bind(this);
        this.handleChangeAssignmentForm = this.handleChangeAssignmentForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAssignmentInputChange = this.handleAssignmentInputChange.bind(this);
        this.getClients = this.getClients.bind(this);
        this.handleSubmitAssignmentForm = this.handleSubmitAssignmentForm.bind(this);
        this.getAllAssignments = this.getAllAssignments.bind(this);
        this.updateAssignment = this.updateAssignment.bind(this);
        this.editAssignment = this.editAssignment.bind(this);
        this.getOrganizations = this.getOrganizations.bind(this);
        this.updateDoomedStatus = this.updateDoomedStatus.bind(this);
    }

    componentDidMount() {
        let organizationCode = null;
        let isSuperSales = UserHelper.isSuperSales();
        if (isSuperSales) {
            this.getClients();
            this.getOrganizations();

            if (this.props.location && this.props.location.hash) {
                organizationCode = this.props.location.hash.substring(1);
                this.setState({selectedOrganization : {value:organizationCode,label:organizationCode}});
            }
        }
        this.getAllAssignments(0, false, organizationCode);
    }

    render() {
        let tableCss = "table-align-middle";
        if (UserHelper.isDark()) {
            tableCss += " table-dark";
        }
        let isSuperSales = UserHelper.isSuperSales();
        let assignments = null;
        let clientManagers = [];
        clientManagers.push(<option disabled value={-1} key={-1}>Please select...</option>);
        clientManagers.push(this.state.clients.map(function(client, key) {
            return <option key={key} value={client.value}>{client.label}</option>;
        }));

        if (this.state.isLoading) {
            assignments = <TableSectionLoader colSpan={7} numberOfRows={5}/>
        } else {
            if (this.state.assignments && this.state.assignments.content && this.state.assignments.content.length) {
                assignments = this.state.assignments.content.map(function(assignment, key) {
                    let assignmentManagers = [];
                    assignmentManagers.push(<option disabled value={-1} key={-1}>Please select...</option>);
                    if(this.state.clients) {
                        assignmentManagers.push(this.state.clients.filter(function(client,key) {
                           return client.organizationCode === assignment.clientCode;
                        }).map(function(client, key){
                           return <option key={key} value={client.value} selected={assignment.manager ? assignment.manager.code === client.value : false}>{client.label}</option>;
                        }));
                    }
                    const config = {
                      modifiers: [
                        {
                          name: 'computeStyles',
                          options: {
                            gpuAcceleration: false, // true by default
                          },
                        },
                      ],
                    };

                    return (
                        <tr key={assignment.code} className={this.getAssignmentClass(assignment)}>
                            <td>{assignment.user ? assignment.user.displayName: assignment.externalContractorDisplayName}</td>
                            <td>
                                <Form.Select name="manager" data-id={assignment.code}
                                onChange={this.handleAssignmentInputChange}>{assignmentManagers}</Form.Select>
                            </td>
                            <td>
                                <Form.Control name="activeFrom" type="date" data-id={assignment.code} defaultValue={assignment
                                .activeFrom} onChange={this.handleAssignmentInputChange}></Form.Control>
                            </td>
                            <td>
                                <Form.Control name="activeTo" type="date" data-id={assignment.code} defaultValue={assignment
                                .activeTo}
                                onChange={this.handleAssignmentInputChange}></Form.Control>
                            </td>
                            <td>{assignment.companyHourlyRate}</td>
                            <td>{assignment.supremeHourlyRate}</td>
                            <td>
                                <Dropdown className="float-end ms-2 text-bf">
                                    <Dropdown.Toggle variant="transparent" className="text-dark">
                                        <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu popperConfig={config}>
                                        <Dropdown.Item onClick={() => this.updateAssignment(assignment.code)}>Update</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.editAssignment(assignment.code)}>Edit</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.remindForExpiration(assignment.code)}>Remind for Expiration</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.updateDoomedStatus(assignment.code, !assignment.doomed)}>{!assignment.doomed?"Doom":"Revive"}</Dropdown.Item>
                                        {new Date(assignment.activeTo) < new Date() ?
                                            <>
                                                <Dropdown.Item onClick={() => this.askForReview(assignment.code)}>Ask For Review</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.deleteAssignment(assignment.code)}>Delete</Dropdown.Item>
                                            </>
                                        :""}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>);
                }, this);
            } else {
              assignments = <tr><td colSpan="7" className="text-center">You have no assignments in your company</td></tr>;
            }
        }

        return (
            <>
                {isSuperSales ?
                    <Row className="mt-3">
                        <Col sm={12} md={12} className="mt-3 mt-md-0">
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-uppercase fw-bold">
                                        Create Assignment
                                    </Card.Title>
                                    <p>Here you can create a new assignment</p>
                                    <React.Fragment>
                                        <Form id="assignmentForm" onSubmit={this.handleSubmitAssignmentForm}>
                                            <Form.Group as={Row} controlId="locale">
                                                <Col md={6} lg={6}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                                        <Form.Control name="externalContractorDisplayName" type="text" placeholder="Name" value={this.state.assignmentForm.externalContractorDisplayName || ""}
                                                        required
                                                        onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={6} lg={6}>
                                                    <InputGroup className="mb-3">
                                                        <Select name="manager" options={this.state.clients} value={this.state.assignmentForm.manager}
                                                            closeMenuOnSelect={true} className="w-100 react-select-container" classNamePrefix="react-select"
                                                            onChange={this.handleInputChange}/>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Col md={3} lg={3}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><FontAwesomeIcon icon="calendar" /></InputGroup.Text>
                                                        <Form.Control name="activeFrom" type="date" placeholder="Start Date" value={this.state.assignmentForm
                                                        .activeFrom || ""}
                                                        required onChange={this.handleInputChange}>
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3} lg={3}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><FontAwesomeIcon icon="calendar" /></InputGroup.Text>
                                                        <Form.Control name="activeTo" type="date" placeholder="End Date" value={this.state.assignmentForm.activeTo
                                                        || ""}
                                                        required onChange={this.handleInputChange}>
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3} lg={3}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><FontAwesomeIcon icon="euro-sign" /></InputGroup.Text>
                                                        <Form.Control name="companyHourlyRate" type="number" placeholder="Company Rate" value={this.state
                                                        .assignmentForm.companyHourlyRate || ""} required onChange={this.handleInputChange} step={0.01} min={0}
                                                        max={1000}/>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={3} lg={3}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><FontAwesomeIcon icon="euro-sign" /></InputGroup.Text>
                                                        <Form.Control name="supremeHourlyRate" type="number" placeholder="Hourly Rate" value={this.state.assignmentForm
                                                        .supremeHourlyRate || ""}
                                                        required onChange={this.handleInputChange} step={0.01} min={0} max={1000}/>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Col md={8} lg={8}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text><FontAwesomeIcon icon="list-ol" /></InputGroup.Text>
                                                        <Form.Control name="purchaseOrderNumber" type="text" placeholder="Purchase Order Number"
                                                        value={this.state.assignmentForm.purchaseOrderNumber || ""}
                                                        onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </Col>
                                                <Col md={4} lg={4}>
                                                    <Button variant="primary" type="submit" className="float-end text-uppercase">Save</Button>
                                                </Col>
                                            </Form.Group>
                                        </Form>
                                    </React.Fragment>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                :""}
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    All Assignments
                                </Card.Title>
                                <p>Here you can see all the assignments</p>
                                <Row>
                                    <Col lg={6} md={6} className="mb-2">
                                        {isSuperSales?
                                            <Select name="organization" options={this.state.organizations} value={this.state.selectedOrganization}
                                                closeMenuOnSelect={true} className="w-100 react-select-container" isClearable="true" classNamePrefix="react-select"
                                                onChange={(event)=> {this.redirectToOrganization(event, this.props.history)}}/>
                                        :""}
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <Form.Check name="expiring" className="custom-checkbox d-block d-lg-inline float-end" label="Expiring"
                                        onClick={(event) => {this.setExpiring(event)}} />
                                    </Col>
                                </Row>
                                <Table bordered hover responsive className={tableCss}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Manager</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Out</th>
                                            <th>In</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { assignments }
                                    </tbody>
                                    {this.state.assignments && this.state.assignments.content && this.state.assignments.content.length > 0 ?
                                        <tfoot>
                                            <tr>
                                                <th colSpan="2">
                                                    <PaginationInfo page={this.state.assignments} />
                                                </th>
                                                <th colSpan="5">
                                                    <div className="float-end w-50">
                                                        <Pagination page={this.state.assignments} handleChangePage={p=>this.getAllAssignments(p, this.state
                                                        .expiring, this.state.selectedOrganization ? this.state.selectedOrganization.value:null)}
                                                        isLoading={this.state.isLoading} />
                                                    </div>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    :null}
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    setExpiring(event) {
        this.setState({expiring: event.target.checked});
        this.getAllAssignments(0, event.target.checked, this.state.selectedOrganization ? this.state.selectedOrganization.value:null)
    }

    getAssignmentClass(assignment) {
        let result = assignment.doomed ? "disabled " : "";

        if (new Date(assignment.activeTo) < new Date()) {
            return result + "table-danger";
        } else {
            if (new Date(assignment.activeTo) < new Date(new Date().setDate(new Date().getDate()+14))) {
                return result + "table-warning";
            } else {
                return result + "table-success";
            }
        }
    }

    redirectToOrganization(event, history) {
        if (event) {
            history.push('/my-account/assignments#' + event.value);
            this.setState({selectedOrganization:event});
            this.getAllAssignments(0, this.state.expiring, event.value);
        } else {
            history.push('/my-account/assignments');
            this.setState({selectedOrganization:null});
            this.getAllAssignments(0, this.state.expiring, null);
        }
    }

    handleInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                this.handleChangeAssignmentForm(name, value);
            } else {
                this.handleChangeAssignmentForm("manager", event);
            }
        }
    }

    handleChangeAssignmentForm(field, value) {
        let newAssignmentForm = this.state.assignmentForm;
        newAssignmentForm[field] = value;

        this.setState({
            assignmentForm: newAssignmentForm
        });
    }

    handleAssignmentInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const code = target.dataset.id;

        let assignmentValues = this.state.assignmentValues;
        let specificAssignmentValues = assignmentValues.get(code);
        specificAssignmentValues[name] = value;

        assignmentValues.set(code, specificAssignmentValues);

        this.setState({
            assignmentValues : assignmentValues
        });
    }

    getClients() {
        ApiCall.get('supreme-customer/clientgroup',{
            projection: "supreme.contractors.backend.facade.dto.BasicNameableDtoDefinition",
            size: 1000
        }).then((response) => {
            let clients = [];
            response.data.content.forEach(r => {
                clients.push({value: r.code, label: r.displayName, organizationCode: r.organizationCode});
            });
            this.setState({
                clients: clients,
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    getAllAssignments(pageNumber, expiring, organizationCode) {
        let url = "assignment";
        if (organizationCode) {
            url = "assignment/organization/" + organizationCode;
        }
        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let defaultSize = queryParams.size ? queryParams.size : 20;

        ApiCall.get(url, {size:defaultSize,page:pageNumber,expiring:expiring}).then((response) => {
            let assignmentValues = new Map();
            response.data.content.forEach(assignment => {
                  assignmentValues.set(assignment.code, {"activeFrom":assignment.activeFrom,"activeTo":assignment.activeTo, 'manager': assignment.manager ?
                  assignment.manager.code : null, "contractorHourlyRate":assignment.contractorHourlyRate, "companyHourlyRate":assignment.companyHourlyRate,
                  "supremeHourlyRate":assignment.supremeHourlyRate});
            });

            this.setState({
                assignments: response.data,
                assignmentValues: assignmentValues,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    editAssignment(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.get('assignment/' + code).then((response) => {
            this.setState({
                assignmentForm : {
                    code: code,
                    externalContractorDisplayName: response.data.user ? response.data.user.code : response.data.externalContractorDisplayName,
                    activeFrom:response.data.activeFrom,
                    activeTo:response.data.activeTo,
                    purchaseOrderNumber: response.data.purchaseOrderNumber,
                    manager : {value: response.data.manager.code, label: response.data.manager.displayName},
                    supremeHourlyRate: response.data.supremeHourlyRate,
                    companyHourlyRate: response.data.companyHourlyRate
                },
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    updateAssignment(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let assignmentValues = this.state.assignmentValues;
        let specificAssignmentValues = assignmentValues.get(code);
        ApiCall.put('assignment',{
            code: code,
            activeFrom: specificAssignmentValues.activeFrom,
            activeTo: specificAssignmentValues.activeTo,
            purchaseOrderNumber: specificAssignmentValues.purchaseOrderNumber,
            contractorHourlyRate: specificAssignmentValues.contractorHourlyRate,
            companyHourlyRate: specificAssignmentValues.companyHourlyRate,
            supremeHourlyRate: specificAssignmentValues.supremeHourlyRate,
            manager: {
                code: specificAssignmentValues.manager
            }
        }).then((response) => {
            UserHelper.showSuccessToast("Your assignment was updated");
            this.getAllAssignments(this.state.assignments.pageable.pageNumber, this.state.expiring, this.state.selectedOrganization ? this.state.selectedOrganization.value:null);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    handleSubmitAssignmentForm(event) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        event.preventDefault();
        ApiCall.put('assignment', {
            code: this.state.assignmentForm.code,
            externalContractorDisplayName: this.state.assignmentForm.externalContractorDisplayName,
            activeFrom:this.state.assignmentForm.activeFrom,
            activeTo:this.state.assignmentForm.activeTo,
            purchaseOrderNumber: this.state.assignmentForm.purchaseOrderNumber,
            manager : {code: this.state.assignmentForm.manager.value},
            supremeHourlyRate: this.state.assignmentForm.supremeHourlyRate,
            companyHourlyRate: this.state.assignmentForm.companyHourlyRate
        }).then((response) => {
            this.setState({
                assignments: response.data
            });
            this.getAllAssignments(0);
            UserHelper.showSuccessToast("Assignment was updated");
            window.scrollTo(0,0);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    remindForExpiration(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.post('assignment/' + code + "/expiration").then((response) => {
            this.setState({
                assignments: response.data
            });
            this.getAllAssignments(0, this.state.expiring, this.state.selectedOrganization ? this.state.selectedOrganization.value:null);
            UserHelper.showSuccessToast("Reminder for expiration sent");
            window.scrollTo(0,0);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    updateDoomedStatus = (assignmentCode, status) => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let url = "assignment/doom/" + assignmentCode;

        ApiCall.post(url, {'status' : status}).then((response) => {
            this.getAllAssignments(0, this.state.expiring, this.state.selectedOrganization ? this.state.selectedOrganization.value:null);
            let msg = 'Assignment was revived!';
            if (status) {
                msg = "Assignment was doomed!";
            }
            UserHelper.showSuccessToast(msg);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    askForReview(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.post('assignment/' + code + "/review").then((response) => {
            this.setState({
                assignments: response.data
            });
            this.getAllAssignments(0, this.state.expiring, this.state.selectedOrganization ? this.state.selectedOrganization.value:null);
            UserHelper.showSuccessToast("Invitation for review sent");
            window.scrollTo(0,0);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    deleteAssignment(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.delete('assignment/' + code).then((response) => {
            this.getAllAssignments(0, this.state.expiring, this.state.selectedOrganization ? this.state.selectedOrganization.value:null);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    getOrganizations() {
        ApiCall.get('client-organization/names').then((response) => {
            let organizations = [];
            for (const [key, value] of Object.entries(response.data)) {
                organizations.push({value: `${key}`, label: `${value}`});
            }
            this.setState({
                organizations: organizations
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }
}

export default withRouter(withProgressBarContext(SalesAssignmentTable));
