import React, {Component} from 'react';
import { Row, Col, Card, Form, InputGroup, Button } from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DndTable from './DndTable.js';
import Select from 'react-select';
import DndProjects from './DndProjects.js';
import SkillCompetenceForm from './SkillCompetenceForm.js';
import CertificationForm from './CertificationForm.js';
import ProjectForm from './ProjectForm.js';
import EducationForm from './EducationForm.js';
import LanguageForm from './LanguageForm.js';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import SectionLoader from '../../../Loader/SectionLoader.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountExperience extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloadStarted:false,
            showSkillCompetenceForm:false,
            showCertificationForm:false,
            showEmployerForm:false,
            showProjectForm:false,
            showEducationForm:false,
            showLanguageForm:false,
            editSkillCompetence: null,
            editCertification: null,
            editEmployer: null,
            editProject: null,
            editEducation: null,
            editLanguage: null,
            profileImage: null,
            profileCategories:[],
            profileRole: "",
            profileSummary: "",
            skillCompetences: [],
            certifications: [],
            projects: [],
            educations: [],
            languages: [],
            isLoading: true,
            projectsIsLoading: true,
            skillCompetencesIsLoading:true,
            certificationsIsLoading:true,
            educationsIsLoading:true,
            languagesIsLoading:true
        };

        this.getCategories = this.getCategories.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.cancelSkillCompetence = this.cancelSkillCompetence.bind(this);
        this.cancelCertification = this.cancelCertification.bind(this);
        this.cancelEmployer = this.cancelEmployer.bind(this);
        this.cancelProject = this.cancelProject.bind(this);
        this.cancelEducation = this.cancelEducation.bind(this);
        this.cancelLanguage = this.cancelLanguage.bind(this);
        this.getSkillCompetences = this.getSkillCompetences.bind(this);
        this.getCertifications = this.getCertifications.bind(this);
        this.getEmployers = this.getEmployers.bind(this);
        this.getProjects = this.getProjects.bind(this);
        this.getEducations = this.getEducations.bind(this);
        this.getLanguages = this.getLanguages.bind(this);
        this.setEditSkillCompetence = this.setEditSkillCompetence.bind(this);
        this.setEditCertification = this.setEditCertification.bind(this);
        this.setEditEmployer = this.setEditEmployer.bind(this);
        this.setEditProject = this.setEditProject.bind(this);
        this.setEditEducation = this.setEditEducation.bind(this);
        this.setEditLanguage = this.setEditLanguage.bind(this);
        this.afterEditSkillCompetence = this.afterEditSkillCompetence.bind(this);
        this.afterEditCertification = this.afterEditCertification.bind(this);
        this.afterEditProject = this.afterEditProject.bind(this);
        this.afterEditEducation = this.afterEditEducation.bind(this);
        this.afterEditLanguage = this.afterEditLanguage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.reorderSkillRows = this.reorderSkillRows.bind(this);
        this.reorderCertificationRows = this.reorderCertificationRows.bind(this);
        this.reorderProjectRows = this.reorderProjectRows.bind(this);
        this.reorderEducationRows = this.reorderEducationRows.bind(this);
        this.reorderLanguageRows = this.reorderLanguageRows.bind(this);
        this.handleSubmitUpdateExperienceForm = this.handleSubmitUpdateExperienceForm.bind(this);
    }

    componentDidMount() {
        this.getCategories();
        this.getCurrentUser();
        this.getSkillCompetences();
        this.getCertifications();
        this.getEmployers();
        this.getProjects();
        this.getEducations();
        this.getLanguages();
    }

    render() {
        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    General Information
                                    <Button variant="secondary" className="float-end text-uppercase" onClick={(e) => UserHelper.downloadCV(this.state.username, this.state.initials, false, this.props.progressBarContext)}>
                                        <span className="icon icon-white me-1">
                                            {this.state.downloadStarted ?
                                                <FontAwesomeIcon icon="spinner" spin/>:<FontAwesomeIcon icon="file-arrow-down" />
                                            }
                                        </span>
                                        <span className="text">Download PDF</span>
                                    </Button>
                                </Card.Title>

                                <p className="mt-5">Please use this section to specify your desired role and summary.</p>
                                <p className="text-warning">Fields marked with * are required</p>

                                <Form onSubmit={this.handleSubmitUpdateExperienceForm}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={2} md={2} lg={2} className="text-center">
                                            <Form.Group as={Row} controlId="formProfileRole" className="mb-3">
                                                <Form.Label column>
                                                    <a href="http://en.gravatar.com/" target="_blank" rel="noopener noreferrer">Gravatar</a>
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formProfileRole" className="mb-3">
                                                <Form.Label column>
                                                    <img src={this.state.profileImage} alt=""/>
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={10} md={10} lg={10}>
                                            <Form.Group as={Row} controlId="formProfileRole" className="mb-3">
                                                <Form.Label column sm={2} md={12} lg={2}>
                                                    Categories*
                                                </Form.Label>
                                                <Col sm={10} md={12} lg={10}>
                                                    <InputGroup>
                                                        <Select name="technologies" options={this.state.categories} classNamePrefix="react-select"
                                                        closeMenuOnSelect={false} isMulti className="w-100 react-select-container" value={this.state
                                                        .profileCategories}
                                                        onChange={this.handleInputChange}/>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formProfileRole" className="mb-3">
                                                <Form.Label column sm={2} md={12} lg={2}>
                                                    Role*
                                                </Form.Label>
                                                <Col sm={10} md={12} lg={10}>
                                                    <InputGroup>
                                                        <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                                        <Form.Control name="profileRole" type="text" placeholder="Role" required defaultValue={this.state.profileRole} onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} controlId="formProfileSummary" className="mb-3">
                                                <Form.Label column sm={2} md={12} lg={2}>
                                                    Summary*
                                                </Form.Label>
                                                <Col sm={10} md={12} lg={10}>
                                                    <InputGroup>
                                                        <InputGroup.Text><FontAwesomeIcon icon="file" /></InputGroup.Text>
                                                        <Form.Control name="profileSummary" as="textarea" rows={5} placeholder="Summary" required defaultValue={this
                                                        .state
                                                        .profileSummary} onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Button variant="primary" type="submit" className="float-start text-uppercase float-end">Save</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Skill Competences
                                </Card.Title>
                                <p>Please use this section to list the level of your skill competences</p>
                                {
                                    <DndTable entityName="skill-competence" columns={[{Header:'Name', accessor:'technology.name'},{Header:'Level', accessor:'level'}, {Header:'Years of Experience', accessor:'years'}, {Header:'Actions', accessor:'-'}]}
                                    data={this.state.skillCompetences} emptyMsg="You have no skills listed" reorderRows={this.reorderSkillRows} editRow={this.setEditSkillCompetence} isLoading={this.state.skillCompetencesIsLoading}/>
                                }
                                {this.state.showSkillCompetenceForm ? <SkillCompetenceForm editSkillCompetence={this.state.editSkillCompetence} getSkillCompetences={this
                                .getSkillCompetences} cancelSkillCompetence={this.cancelSkillCompetence} afterEditSkillCompetence={this.afterEditSkillCompetence}/>
                                : null}
                                {!this.state.showSkillCompetenceForm ? <Button variant="success" onClick={() => this.setState
                                ({editSkillCompetence:null, showSkillCompetenceForm:true}) }>Add a skill competence</Button> : null}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Certifications
                                </Card.Title>
                                <p>Please use this section to list the certifications you have acquired</p>
                                {
                                    <DndTable entityName="certification" columns={[{Header:'Name', accessor:'name'},{Header:'Start Date', accessor:'startDate'},
                                    {Header:'End Date', accessor:'endDate'}, {Header:'Actions', accessor:'-'}]}
                                    data={this.state.certifications} emptyMsg="You have no certifications listed" reorderRows={this.reorderCertificationRows}
                                    editRow={this.setEditCertification} isLoading={this.state.certificationsIsLoading}/>
                                }
                                {this.state.showCertificationForm ? <CertificationForm getCertifications={this.getCertifications} editCertification={this.state
                                .editCertification} cancelCertification={this.cancelCertification} afterEditCertification={this.afterEditCertification}/> : null}
                                {!this.state.showCertificationForm ? <Button variant="success" onClick={() => this.setState
                                ({editCertification:null, showCertificationForm:true})}>Add a certification</Button> : null}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/*
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Employer History
                                </Card.Title>
                                <p>Please use this section to list your previous employers</p>
                                { employers }
                                {this.state.showEmployerForm ? <EmployerForm getEmployers={this.getEmployers} editEmployer={this.state.editEmployer}
                                cancelEmployer={this.cancelEmployer}/> : null}
                                {!this.state.showEmployerForm ? <Button variant="success" onClick={() => this.setState
                                ({editEmployer:{}, showEmployerForm:true})}>Add an employer</Button> : null}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                */}
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Projects
                                </Card.Title>
                                <p>Please use this section to update your completed projects</p>
                                <p className="text-warning">Sort your project chronologically with most recent ones on top</p>
                                {this.state.projectsIsLoading ?
                                    <SectionLoader />
                                :   <DndProjects data={this.state.projects} emptyMsg="You have no projects listed" reorderRows={this.reorderProjectRows}
                                editRow={this.setEditProject}/>
                                }
                                {this.state.showProjectForm ? <ProjectForm getProjects={this.getProjects} editProject={this.state.editProject}
                                cancelProject={this.cancelProject} afterEditProject={this.afterEditProject}/> : null}
                                {!this.state.showProjectForm ? <Button variant="success" onClick={() => this.setState({editProject:null,
                                showProjectForm:true}) }>Add a project</Button> : null}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
               <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Educations
                                </Card.Title>
                                <p>Please use this section to update the educations you have acquired</p>
                                {
                                    <DndTable entityName="education" columns={[{Header:'Start Date', accessor:'startDate'},{Header:'End Date', accessor:'endDate'},
                                    {Header:'Degree', accessor:'degree'},  {Header:'University', accessor:'university'}, {Header:'Actions', accessor:'-'}]}
                                    emptyMsg="You have no educations listed" data={this.state.educations} reorderRows={this.reorderEducationRows} editRow={this
                                    .setEditEducation} isLoading={this.state.educationsIsLoading}/>
                                }
                                {this.state.showEducationForm ? <EducationForm getEducations={this.getEducations} editEducation={this.state.editEducation}
                                cancelEducation={this.cancelEducation} afterEditEducation={this.afterEditEducation}/> :
                                null}
                                {!this.state.showEducationForm ? <Button variant="success" onClick={() => this.setState({editEducation:null,
                                showEducationForm:true}) }>Add an education</Button> : null}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Languages
                                </Card.Title>
                                <p>Please use this section to update the languages you speak with the level of proficiency</p>
                                {
                                    <DndTable entityName="language" columns={[{Header:'Language', accessor:'locale'},{Header:'Level', accessor:'level'},
                                    {Header:'Actions', accessor:'-'}]} data={this.state.languages} emptyMsg="You have no languages listed"
                                    reorderRows={this.reorderLanguageRows} editRow={this.setEditLanguage} isLoading={this.state.languagesIsLoading}/>
                                }
                                {this.state.showLanguageForm ? <LanguageForm getLanguages={this.getLanguages} editLanguage={this.state.editLanguage}
                                cancelLanguage={this.cancelLanguage} afterEditLanguageCompetence={this.afterEditLanguage}/> : null}
                                {!this.state.showLanguageForm ? <Button variant="success" onClick={() => this.setState({editLanguage:null,
                                showLanguageForm:true}) }>Add a language</Button> : null}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    reorderSkillRows(code, atIndex) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let data = {'element':code, 'position':atIndex};

        ApiCall.post('skill-competence/reorder', data).then(() => {
            this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        });
    }

    reorderCertificationRows(code, atIndex) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let data = {'element':code, 'position':atIndex};

        ApiCall.post('certification/reorder', data).then(() => {
            this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        });
    }

    reorderProjectRows(code, atIndex) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let data = {'element':code, 'position':atIndex};

        ApiCall.post('project/reorder', data).then(() => {
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    reorderEducationRows(code, atIndex) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let data = {'element':code, 'position':atIndex};

        ApiCall.post('education/reorder', data).then(() => {
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    reorderLanguageRows(code, atIndex) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let data = {'element':code, 'position':atIndex};

        ApiCall.post('language/reorder', data).then(() => {
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    handleInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;

                this.setState({
                    [name]: value
                });
            } else {
                let newCategories = [];
                event.forEach(sel => {
                    newCategories.push({value:sel.value, label:sel.label});
                });
                this.setState({'profileCategories' : newCategories});
            }
        }
    }

    getCategories() {
        ApiCall.get('category/childCategoriesForCategory',{'categoryCode':'contractor-category'}).then((response) => {

            let cats = [];
            response.data.forEach(cat => {
                cats.push({value:cat.code, label:cat.name});
            })

            this.setState({
                categories: cats,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 10});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getCurrentUser() {
        ApiCall.get('user/getCurrentUser', {
            projection: "supreme.contractors.backend.facade.dto.ExperiencePageContractorUserDetailsDtoDefinition"
        }).then((response) => {
            let currentUser = response.data;

            let cats = [];
            currentUser.categories.forEach(cat => {
                cats.push({value:cat.code, label:cat.name});
            })

            this.setState({
                username: response.data.code,
                initials: response.data.initials,
                profileCategories: cats,
                profileRole: currentUser.role,
                profileSummary: currentUser.summary,
                profileImage: currentUser.avatar.url
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 10});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                profileInfoMsg: null
            });
        });
    }

    cancelSkillCompetence() {
        this.setState({showSkillCompetenceForm:false});
    }

    cancelCertification() {
        this.setState({showCertificationForm:false});
    }

    cancelEmployer() {
        this.setState({showEmployerForm:false});
    }

    cancelProject() {
        this.setState({showProjectForm:false});
    }

    cancelEducation() {
        this.setState({showEducationForm:false});
    }

    cancelLanguage() {
        this.setState({showLanguageForm:false});
    }

    deleteProject(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        this.setState({projectsIsLoading:true});
        ApiCall.delete('project/' + code).then((response) => {
            this.getProjects();
            this.setState({
                projectsIsLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                projectsIsLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    getSkillCompetences() {
        this.setState({skillCompetencesIsLoading:true});
        ApiCall.get('skill-competence').then((response) => {
            this.setState({
                skillCompetences: response.data,
                skillCompetencesIsLoading:false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 10});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                skillCompetencesIsLoading: false
            });
        });
    }

    getCertifications() {
        this.setState({certificationsIsLoading:true});
        ApiCall.get('certification').then((response) => {
            this.setState({
                certifications: response.data,
                certificationsIsLoading:false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 10});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                certificationsIsLoading: false
            });
        });
    }

    getEmployers() {
        this.setState({isLoading:true});
        ApiCall.get('employer').then((response) => {
            this.setState({
                employers: response.data,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 10});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getProjects() {
        this.setState({projectsIsLoading:true});
        ApiCall.get('project').then((response) => {
            this.setState({
                projects: response.data,
                projectsIsLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 10});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                projectsIsLoading: false
            });
        });
    }

    getEducations() {
        this.setState({educationsIsLoading:true});
        ApiCall.get('education').then((response) => {
            this.setState({
                educations: response.data,
                educationsIsLoading:false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 10});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                educationsIsLoading:false
            });
        });
    }

    getLanguages() {
        this.setState({languagesIsLoading:true});
        ApiCall.get('language').then((response) => {
            this.setState({
                languages: response.data,
                languagesIsLoading:false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                languagesIsLoading:false
            });
        });
    }

    setEditSkillCompetence(skillCompetence) {
        this.setState({
            showSkillCompetenceForm: true,
            editSkillCompetence: skillCompetence
        });
    }

    afterEditSkillCompetence(skillCompetence) {
        UserHelper.showSuccessToast("Your skill competence was updated.");
        this.getSkillCompetences();
        this.setState({
            showSkillCompetenceForm: false,
            editSkillCompetence: null
        });
    }

    setEditCertification(certification) {
        this.setState({
            showCertificationForm: true,
            editCertification: certification
        });
    }

    afterEditCertification(certification) {
        UserHelper.showSuccessToast("Your certification was updated.");
        this.getCertifications();
        this.setState({
            showCertificationForm: false,
            editCertification: null
        });
    }

    setEditEmployer(employer) {
        this.setState({
            showEmployerForm: true,
            editEmployer: employer
        });
    }

    setEditProject(project) {
        this.setState({
            showProjectForm: true,
            editProject: project
        });
    }

    afterEditProject(project) {
        UserHelper.showSuccessToast("Your project was updated.");
        this.getProjects();
        this.setState({
            showProjectForm: false,
            editProject: null
        });
    }

    setEditEducation(education) {
        this.setState({
            showEducationForm: true,
            editEducation: education
        });
    }

    afterEditEducation(education) {
        UserHelper.showSuccessToast("Your education was updated.");
        this.getEducations();
        this.setState({
            showEducationForm: false,
            editEducation: null
        });
    }

    setEditLanguage(language) {
        this.setState({
            showLanguageForm: true,
            editLanguage: language
        });
    }

    afterEditLanguage(languageCompetence) {
        UserHelper.showSuccessToast("Your language was updated.");
        this.getLanguages();
        this.setState({
            showLanguageForm: false,
            editLanguage: null
        });
    }

    handleSubmitUpdateExperienceForm(event) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        event.preventDefault();

        let cats = [];
        this.state.profileCategories.forEach(t=> {
            cats.push({code:t.value});
        });

        let customer = {
            categories: cats,
            role: this.state.profileRole,
            summary: this.state.profileSummary,
        };

        ApiCall.put('experience', customer).then(() => {
            UserHelper.showSuccessToast("Your profile has been updated");
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
        window.scrollTo(0,0);
    }
}

export default withProgressBarContext(AccountExperience);
