import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import qs from 'qs';
import FacetListerLoader from '../Account/View/AccountSearch/FacetListerLoader.js';

class SortingOptions extends Component {
    constructor(props){
        super(props);

        this.state = {
            sortOptions: props.page ? props.page.sortOptions : null,
            sort: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).sort
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.page !== prevProps.page) {
            this.setState({
                sortOptions: this.props.page ? this.props.page.sortOptions : null,
                sort: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).sort
            });
        }
    }

    render() {
        if(!this.props.page || this.props.page.totalElements < 1) {
            return null;
        }

        let sortDropdownOptions = [];

        if(this.state.sortOptions) {
            for (let i = 0; i < this.state.sortOptions.length; i++) {
                sortDropdownOptions.push(
                    <option key={i} value={this.state.sortOptions[i].propertyCode + ',' + this.state.sortOptions[i].direction}>
                        {this.state.sortOptions[i].propertyName} ({this.state.sortOptions[i].direction})
                    </option>);
            }
        }

        return (
            <>
                {this.props.isLoading ?
                    <FacetListerLoader width="100%" className="float-end"/>
                    :
                    <Form className="float-end">
                        <Form.Group className="mt-md-0 float-end">
                        <Form.Select name="sort" value={this.state.sort} onChange={this.handleChange} className="float-end">
                            { sortDropdownOptions }
                        </Form.Select>
                        </Form.Group>
                    </Form>
                }
            </>
        )
    }

    handleChange(event) {
        this.setState({
            sort: event.target.value
        });

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        queryParams.sort = event.target.value;

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: '?' + qs.stringify(queryParams)
        });
    }
}

export default withRouter(SortingOptions);
