import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import qs from 'qs';
import Select from 'react-select';

class ResultSize extends Component {
    constructor(props){
        super(props);

        let size = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).size;
        this.state = {
            size: size ? size : 0
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.page !== prevProps.page) {
            this.setState({
                size: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).size
            });
        }
    }

    render() {
        if(!this.props.page || this.props.page.totalElements < 1) {
            return null;
        }

        let sizeDropdownOptions = [];
        let defaultValue = 12;
        for (let i = 1; i < 5; i++) {
            sizeDropdownOptions.push({value:defaultValue * i, label: 'Show ' + defaultValue * i});
        }

        return (
            <Select name="size"
                className="w-50 react-select-container"
                classNamePrefix="react-select"
                options={sizeDropdownOptions} placeholder="Show:"
                onChange={this.handleChange}
                value={{value:this.state.size, label: 'Show ' +  this.state.size}}
                theme={(theme) => ({...theme, colors: { ...theme.colors, primary25:"#F2ECFF", primary: "#916FFE"}})}/>
        )
    }

    handleChange(event) {
        this.setState({
            sort: event.value
        });

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        queryParams.size = event.value;

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: '?' + qs.stringify(queryParams)
        });
    }
}

export default withRouter(ResultSize);
