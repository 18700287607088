import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import CmsPageProvider from '../../cms/CmsPageProvider.js';
import CmsTemplate from '../../cms/CmsTemplate.js';
import GlobalMessagesProvider from '../../Provider/GlobalMessagesProvider.js';
import UserHelper from '../../Helper/UserHelper.js';
import Login from '../../User/Login/Login.js';
import Register from '../../User/Register/Register.js';

class LoginPage extends Component {
    constructor(props){
        super(props);

        if (UserHelper.isUserLoggedIn()) {
            this.props.history.push('/my-account');
        }

        console.log("             _             _    _                __                           _            _ _        _   _            ___   ");
        console.log("            | |           | |  (_)              / _|                         (_)          (_) |      | | (_)          |__ \\  ");
        console.log("            | | ___   ___ | | ___ _ __   __ _  | |_ ___  _ __    __ _ _ __    _ _ ____   ___| |_ __ _| |_ _  ___  _ __   ) | ");
        console.log("            | |/ _ \\ / _ \\| |/ / | '_ \\ / _` | |  _/ _ \\| '__|  / _` | '_ \\  | | '_ \\ \\ / / | __/ _` | __| |/ _ \\| '_ \\ / /  ");
        console.log("            | | (_) | (_) |   <| | | | | (_| | | || (_) | |    | (_| | | | | | | | | \\ V /| | || (_| | |_| | (_) | | | |_|   ");
        console.log("            |_|\\___/ \\___/|_|\\_\\_|_| |_|\\__, | |_| \\___/|_|     \\__,_|_| |_| |_|_| |_|\\_/ |_|\\__\\__,_|\\__|_|\\___/|_| |_(_)   ")
        ;
        console.log("                                         __/ |                                                                               ");
        console.log("                                        |___/                                                                                ");
        console.log("You might want to book a call with us: https://app.harmonizely.com/ptahchiev");
    }

    render() {
        return (
            <CmsPageProvider props={{page:'login'}}>
                <GlobalMessagesProvider>
                    <CmsTemplate>
                        <main>
                            <Container fluid className="mb-3 ps-0 pe-0">
                                <Row>
                                    <Col sm={12} md={6} className="mb-3">
                                        <Login />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Register />
                                    </Col>
                                </Row>
                            </Container>
                        </main>
                    </CmsTemplate>
                </GlobalMessagesProvider>
            </CmsPageProvider>
        )
    }
}

export default LoginPage;
