import React from "react";
import {Form, InputGroup, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './CalendarInputfieldWidget.scss';

export default function CalendarInputfieldWidget(props) {
    if (!props.widgetData) {
        return null;
    }

    let className = props.widgetData.styleClass;
    let prependIconPrefix = null;
    let prependIcon = null;
    if (props.widgetData.prependIcon) {
        let res = props.widgetData.prependIcon.split(" ");
        prependIconPrefix = res[0];
        prependIcon = /fa-(.*)/.exec(res[1])[1];
    }

    return (
        <Form.Group className="row">
            <Form.Label className="form-label col-form-label col-lg-3 col-md-12 col-sm-3">
                {props.widgetData.name}{props.widgetData.mandatory ? "*" : ""}
            </Form.Label>

            <Col sm={9} md={12} lg={9}>
                <InputGroup>
                    {props.widgetData.prependIcon &&
                        <InputGroup.Text><FontAwesomeIcon icon={[prependIconPrefix, prependIcon]} /></InputGroup.Text>
                    }
<DatePicker className={className} required={props.widgetData.mandatory}  name={props.widgetData.attributeName}
   dateFormat={props.widgetData.dateFormat} placeholderText={props.widgetData.placeholder}/>

                </InputGroup>
            </Col>
        </Form.Group>
    );
}
