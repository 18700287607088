import React, {Component} from 'react';
import {Form, Row, Col, InputGroup, Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import CreatableSelect from 'react-select/creatable';

class RerouteModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null,
            form:{}
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRerouteForm = this.handleRerouteForm.bind(this);
    }

    render() {
        let recipientVals = [];
        if (this.props.defaultRecipients) {
            this.props.defaultRecipients.forEach(el => {
                recipientVals.push({'value':el.code, 'label':el.displayName});
            });

        }
        return (
            <>
                {this.props.defaultRecipients ?
                    <Modal centered size="lg" show={this.props.show} onHide={()=>{this.props.handleModalClose(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-uppercase fw-bold">Reroute Offer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="show-grid">
                                <Col xs={12} sm={12}>
                                    <Form onSubmit={this.handleRerouteForm}>
                                        <Form.Group controlId="orderPrefix" as={Row} className="mb-2">
                                            <Form.Label>
                                                Recipient*
                                            </Form.Label>
                                            <InputGroup>
                                                <CreatableSelect name="recipient" isMulti options={recipientVals} value={this.state.selectedRecipient}
                                                    closeMenuOnSelect={true} className="w-100 react-select-container" isClearable="true" classNamePrefix="react-select"
                                                    onChange={(event)=> {this.setState({recipients:event})}}/>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formRegisterCreateAccount" className="mb-3">
                                            <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                                <Button variant="primary" type="submit" className="float-end text-uppercase">Submit</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                :null}
            </>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let newForm = this.state.form;
        newForm[name] = value;

        this.setState({
            form: newForm
        });
    }


    handleRerouteForm(event) {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        let recipients = [];

        this.state.recipients.forEach(rec => {
            recipients.push(rec.value);
        });

        ApiCall.delete('offer/reroute/', {companyCode : this.props.organization}, {recipients:recipients, offers: this.props.offers}).then((response) => {
            this.setState({
                isLoading: true,
                showRejectionModal: false
            });
            UserHelper.showSuccessToast('Success');
            this.props.handleModalClose();
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.handleModalClose();
        });
    }
}

export default withRouter(RerouteModal);
