import React, {Component} from 'react';
import {Row, Col, Card, Form} from 'react-bootstrap';
import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../../components/Helper/UserHelper.js';
import SectionLoader from '../../../Loader/SectionLoader.js';
import { Rating } from 'react-simple-star-rating';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountReviews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rating: 0,
            isLoading: true
        };

        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.getReviews = this.getReviews.bind(this);
    }

    componentDidMount() {
        this.getReviews(0);
        this.getCurrentUser();
    }

    render() {
        let reviews = null;
        if (this.state.isLoading) {
            reviews = <Row className="mb-3"><Col md={12} lg={12}><SectionLoader /></Col></Row>;
        } else {
            if (this.state.reviews && this.state.reviews.content.length > 0) {
                reviews = this.state.reviews.content.map(function(review, key) {
                    return (
                        <Card className="w-100 mt-3" key={key}>
                            <Card.Body>
                                <Row>
                                    <Col md={12} lg={12}>
                                        <Form.Group as={Row} controlId="rating" className="mb-3">
                                            <Col md={2} lg={2}>
                                               {review.user.displayName}
                                            </Col>
                                            <Col md={10} lg={10}>
                                                <Rating initialValue={review.rating} emptyColor={UserHelper.isDark() ? "var(--bs-light)" : "var(--bs-primary)"} fillColor={"var(--bs-secondary)"} allowFraction={true} size={28} readonly/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="content" className="mb-3">
                                            <Col md={12} lg={12}>
                                                {review.content}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    );
                }, this);
            } else {
                reviews = <Row className="mb-3"><Col md={12} lg={12}>You have no reviews</Col></Row>;
            }
        }
        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Overall
                                </Card.Title>
                                <Form.Group as={Row} controlId="formProfileRole" className="mb-3">
                                    <Form.Label column sm={3} md={12} lg={3}>
                                        {this.state.rating} based on {this.state.reviews ? this.state.reviews.totalElements : '0'} reviews
                                    </Form.Label>
                                    <Col sm={9} md={12} lg={9}>
                                        <Rating initialValue={this.state.rating} emptyColor={UserHelper.isDark() ? "var(--bs-light)" : "var(--bs-primary)"} fillColor={"var(--bs-secondary)"} allowFraction={true} readonly/>
                                     </Col>
                                </Form.Group>
                                    <Card.Title className="text-uppercase fw-bold">
                                        Reviews
                                    </Card.Title>
                                <Form.Group as={Row} controlId="reviews" className="mb-3">
                                    <Col>
                                        <PaginationInfo page={this.state.reviews} />
                                    </Col>
                                    <Col>
                                        <Pagination page={this.state.reviews} handleChangePage={this.getReviews} isLoading={this.state.isLoading} />
                                    </Col>
                                </Form.Group>
                                {reviews}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    getReviews(pageNumber) {
        ApiCall.get('customerReview/all/user/' + UserHelper.getCustomerCode(), {size: 5, page:pageNumber}).then((response) => {
            this.setState({
                reviews: response.data,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                isLoading: false
            });
        });
    }

    getCurrentUser() {
        ApiCall.get('user/getCurrentUser', {
            projection: "supreme.contractors.backend.facade.dto.SupremeReviewableUserDetailsDtoDefinition"
        }).then((response) => {
            let currentUser = response.data;

            this.setState({
                currentUser: currentUser,
                rating: currentUser.averageRating,
                isLoading: false
            });

            this.getReviews(0);
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                currentUser: null,
                isLoading: false
            });
        });
    }
}

export default withProgressBarContext(AccountReviews);
