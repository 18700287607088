import React, {Component} from 'react';
import qs from 'qs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import UserHelper from '../Helper/UserHelper.js';
import ApiCall from '../Helper/ApiCall.js';
import { Document, Page } from "react-pdf";
import {Card, Form, Container, Row, Col, Button} from 'react-bootstrap';
import {withProgressBarContext} from '../Provider/ProgressBarProvider.js';

class Invoice extends Component {
    constructor(props){
        super(props);

        this.state = {
            order: null,
            token: null,
            rejectReason: '',
            accepted: false,
            rejected: false,
            errorMsg: null,
            totalPages: 0,
            currentPage: 1
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.getOrderDetails = this.getOrderDetails.bind(this);
        this.changeOrderStatus = this.changeOrderStatus.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.submitRejection = this.submitRejection.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    componentDidMount() {
        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        if (!queryParams || !queryParams.token) {
            this.props.history.push("/login");
        } else {
            this.getOrderDetails(queryParams.token);
        }
    }

    render() {
        return (
            <Card className="border-0">
                <Card.Body>
                    <Card.Title className="text-uppercase fw-bold">
                        {'Invoice ' + (this.state.order ? this.state.order.code : '')}
                    </Card.Title>
                    <p>Here you can view the PDF invoice for your order</p>
                    <Container>
                        <Row>
                            <Col sm={1} className="my-auto">
                                {this.state.currentPage>1?
                                <Button size="lg" variant="primary" onClick={()=>{this.setState({currentPage:this.state.currentPage-1})
                                }}><FontAwesomeIcon
                                icon={"arrow-left"}/></Button>
                                :''}
                            </Col>
                            <Col className="Example__container__document">
                                <Document file={this.state.pdf} onLoadSuccess={this.onDocumentLoadSuccess}>
                                    <Page pageNumber={this.state.currentPage} className="mx-auto"/>
                                </Document>
                            </Col>
                            <Col sm={1} className="my-auto">
                                {this.state.totalPages > this.state.currentPage?
                                <Button size="lg" variant="primary" className="align-middle" onClick={()=>{this.setState({currentPage:this.state
                                .currentPage+1})}}><FontAwesomeIcon icon={"arrow-right"}/></Button>
                                :null}
                            </Col>
                        </Row>
                        {!this.state.rejected && !this.state.accepted ?
                            <Row>
                                <Col sm={6} md={6} lg={6} className="text-center">
                                    <Button variant="success" size={'lg'} disabled={this.state.accepted} className="text-uppercase me-2"
                                    onClick={() =>this.changeOrderStatus('ACCEPTED')}>
                                        {this.state.downloadStarted ? <FontAwesomeIcon icon="spinner" spin className="me-1"/>:<FontAwesomeIcon icon="check" className="me-1" /> }
                                        Accept
                                    </Button>
                                </Col>
                                <Col sm={6} md={6} lg={6} className="text-center">
                                    <Button variant="danger" size={'lg'} disabled={this.state.rejected} className="text-uppercase me-2" onClick={() =>this.changeOrderStatus('REJECTED')}>
                                        {this.state.downloadStarted ? <FontAwesomeIcon icon="spinner" spin className="me-1"/>:<FontAwesomeIcon icon="ban" className="me-1"/>}
                                        Reject
                                    </Button>
                                </Col>
                            </Row>
                        :""}
                        <Row>
                            <Col sm={12} md={12} lg={12} className="text-center mt-5">
                                {this.state.rejected ?
                                    <>
                                        <Form.Group controlId="rejectReason">
                                            <Form.Label>You can provide the reason for rejection here</Form.Label>
                                            <Form.Control name="rejectReason" as="textarea" rows="3" maxLength={255} placeholder="Reject Reason*" required
                                            onChange={this.handleInputChange} />
                                        </Form.Group>
                                        <Form.Group controlId="rejectReason">
                                            <Button variant="primary" type="submit" className="float-end text-uppercase mt-1" onClick={this
                                            .submitRejection}>Submit</Button>
                                        </Form.Group>
                                    </>
                                :""}
                                {this.state.accepted ?
                                    <Button variant="secondary" size={'lg'} disabled={!this.state.accepted} className="text-uppercase me-2" onClick={()=>this.downloadInvoice(this.state.order.code)}>
                                        {this.state.downloadStarted ? <FontAwesomeIcon icon="spinner" spin className="me-1"/>:<FontAwesomeIcon icon="file-arrow-down" className="me-1"/>}
                                        Download PDF
                                    </Button>
                                :""}
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        )
    }

    onDocumentLoadSuccess(nPages) {
        this.setState({totalPages: nPages._pdfInfo.numPages});
    }

    changeOrderStatus(status) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.post('supremeOrder/status', {
            token: this.state.token,
            status: status
        }).then((response) => {
            if (status === 'ACCEPTED') {
                this.setState({accepted: true});
            } else if (status === 'REJECTED'){
                this.setState({rejected: true});
            }
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            if (error.response.status === 404) {
                window.location='http://supreme.contractors/';
            }
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                assignment: null,
                isLoading: false
            });
        });
    }

    downloadInvoice = (orderCode) => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        this.setState({downloadStarted:true});
        fetch(process.env.REACT_APP_FACADE_URL + 'invoice/' + orderCode + '?token=' + encodeURIComponent(this.state.token), {
            method: "GET",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            console.log(response.headers['Content-Disposition']);
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'SupremeContractors-Invoice-' + orderCode + '.pdf';
                a.click();
            });
            this.setState({downloadStarted:false});
                //window.location.href = response.url;
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    submitRejection() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.post('supremeOrder/reject', {
            token: this.state.token,
            reason: this.state.rejectReason
        }).then((response) => {
            UserHelper.showSuccessToast('Invoice was rejected');
            window.scrollTo(0, 0);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            if (error.response.status === 404) {
                window.location='http://supreme.contractors/';
            }
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                assignment: null,
                isLoading: false
            });
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    getOrderDetails(token) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.get('supremeOrder/invoice', {
            token: token
        }).then((response) => {
            let order = response.data;
            this.setState({
                order: order,
                token: token,
                pdf:{ url: process.env.REACT_APP_FACADE_URL + "invoice/" + order.code + '?token=' + encodeURIComponent(token), httpHeaders: {
                'X-Nemesis-Token':
                localStorage.getItem("token")}},
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            if (error.response.status === 404) {
                window.location='http://supreme.contractors/';
            }
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                assignment: null,
                isLoading: false
            });
        });
    }
}

export default withRouter(withProgressBarContext(Invoice));
