import React from "react";
import {Link, useHistory} from 'react-router-dom';
import {Nav} from 'react-bootstrap';

export default function NavbarCmsWidget({widgetData}) {
    let history = useHistory();
    let className = "nav-item text-uppercase fw-bold ";
    className += widgetData.styleClass;

    return (
        <React.Fragment>
            {widgetData.links && widgetData.links.map(function(link, key) {
                    className += history.location.pathname.endsWith(link.url) ? ' active' : '';
                    className += ' ' + link.styleClass;

                    return <Nav.Link className={className} key={key} as={Link} to={link.url}>{link.linkName}</Nav.Link>
                })
            }
        </React.Fragment>
    );
}