import React from 'react';
import ContentLoader from "react-content-loader";

const CalendarTimeSlotsLoader = (props) => {

    let result = [];

     for (let step = 0; step < props.numberOfRows; step++) {

          result.push(
              <ContentLoader
                speed={1}
                key={step}
                backgroundColor="var(--bs-body-bg)"
                foregroundColor="var(--bs-muted)"
                style={{ width: '60%', height: '48' }}
              >
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="40" />
              </ContentLoader>);
      }

      return (
        <>
            {result}
        </>
      );

}
export default CalendarTimeSlotsLoader