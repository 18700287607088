import React from "react";
import {Form, FormControl, InputGroup, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function PasswordInputfieldWidget(props) {
    if (!props.widgetData) {
        return null;
    }
    
    let className = props.widgetData.styleClass;
    let prependIconPrefix = null;
    let prependIcon = null;
    if (props.widgetData.prependIcon) {
        let res = props.widgetData.prependIcon.split(" ");
        prependIconPrefix = res[0];
        prependIcon = /fa-(.*)/.exec(res[1])[1];
    }

    return (
        <Form.Group className="row">
            <Form.Label className="form-label col-form-label col-lg-3 col-md-12 col-sm-3">
                {props.widgetData.name}{props.widgetData.mandatory ? "*" : ""}
            </Form.Label>

            <Col sm={9} md={12} lg={9}>
                <InputGroup>
                    {props.widgetData.prependIcon && 
                        <InputGroup.Text><FontAwesomeIcon icon={[prependIconPrefix, prependIcon]} /></InputGroup.Text>
                    }

                    <FormControl type="password" placeholder={props.widgetData.placeholder} name={props.widgetData.attributeName} onChange={props.handleInputChange ? props.handleInputChange : () => void 0}
                        required={props.widgetData.mandatory} className={className} />
                </InputGroup>
            </Col>
        </Form.Group>
    );
}
