import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react";
import { Rating } from 'react-simple-star-rating';
import { Card, Row, Col, Dropdown, Badge } from "react-bootstrap"
import UserHelper from "../../../Helper/UserHelper";
import {SelectedResourceContext} from './SelectedResourceProvider.js';
import './ResourceListerGridItem.scss';

export default function ResourceListerGridItem({ resource, handleResourceModalShow, handleResourceNotesModalShow, handleResourceHistoryModalShow, handleDownloadContracts, getResourcePrice, progressBarContext }) {
    let isSales = UserHelper.isSales();
    let isSuperSales = UserHelper.isSuperSales();
    let isManager = UserHelper.isManager();

    let resourcePrice = getResourcePrice(resource, isSales && !isSuperSales);
    let addedToCompare = false;

    return (
        <Col md={3} className="mb-4">
            <Card className="shadow-hover h-100">
                <Row className="p-3">
                    <Col lg={4} xs={4}>
                        <SelectedResourceContext.Consumer>
                            {(context) => (
                                <React.Fragment>
                                    { addedToCompare = context.isResourceSelected(resource.properties.code) }
                                    <div className={`holder ${addedToCompare ? 'addedToCompare' : ''}`}>
                                        <img src={resource.properties.imageUrl} className="rounded-circle img-fluid" alt="user"/>
                                            <div className="overlay">
                                                <div className="icon" onClick={() => {
                                                     if (addedToCompare) {
                                                         context.unselectResource(resource.properties.code);
                                                     } else {
                                                         context.selectResource(resource);
                                                     }
                                                 }}>
                                                    <FontAwesomeIcon size="xl" icon={addedToCompare ? "check" : "plus"} className={addedToCompare ? "text-secondary" : "text-primary"} />
                                                </div>
                                            </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </SelectedResourceContext.Consumer>
                    </Col>
                    <Col lg={6} xs={6}>
                        <div className="fs-6">{resource.properties.organization}</div>
                    </Col>
                    <Col lg={2} xs={2}>
                        <Dropdown className="position-absolute text-bf" style={{top:"20px", right:"20px"}}>
                          <Dropdown.Toggle variant="white">
                            <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleResourceModalShow(resource.properties)}>View</Dropdown.Item>
                            {isSuperSales ?
                                <Dropdown.Item onClick={() => handleResourceNotesModalShow(resource.properties)}>Notes</Dropdown.Item>
                                :""
                            }
                            {isSuperSales || isManager ?
                                <>
                                    <Dropdown.Item onClick={() => handleResourceHistoryModalShow(resource.properties)}>History</Dropdown.Item>
                                    <Dropdown.Item onClick={() => UserHelper.downloadCV(resource.properties.code, resource.properties.initials, false, progressBarContext)}>Download</Dropdown.Item>
                                </>
                            :null}
                            <Dropdown.Item onClick={e => UserHelper.downloadCV(resource.properties.code, resource.properties.initials, true, progressBarContext)}>Download Anonymous</Dropdown.Item>
                            {isSuperSales ?
                                <>
                                    <Dropdown.Item onClick={() => handleDownloadContracts(resource.properties.code)}>Download Contracts</Dropdown.Item>
                                </>
                            :""}
                          </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Card.Body className="position-relative">
                    <Card.Title className="mx-auto text-center fw-bold my-2" onClick={()=> handleResourceModalShow(resource.properties)}>{resource.properties.name}</Card.Title>
                    <Row className="my-2 py-1">
                        <Col className="text-center">
                            <Rating initialValue={resource.properties.rating} emptyColor={UserHelper.isDark() ? "var(--bs-light)" : "var(--bs-primary)"} fillColor={"var(--bs-secondary)"} allowFraction={true} size={30} readonly/>
                        </Col>
                    </Row>
                    <Row className="mx-auto fs-5 my-2 py-1 text-center">
                        <Col className="my-auto"><FontAwesomeIcon size="lg" icon={"star"} className="me-1 text-muted"/><span className="fw-bold">{resource.properties.rating}</span></Col>
                        {resourcePrice ? <Col className="fs-5 me-4 my-auto fw-bold">{resourcePrice}</Col> : ""}
                        <Col className="my-auto"><FontAwesomeIcon size="lg" icon={"file-contract"} className="me-1 text-muted"/><span className="fw-bold">{resource.properties.previousAssignments.length}</span></Col>
                    </Row>
                    <Row className="my-2 mx-auto text-center py-1 border border-muted rounded-pill">
                        {isSuperSales || isManager ?
                            <Col><a href={"mailto:" + resource.properties.code} className="text-muted"><FontAwesomeIcon size="lg" icon={"envelope"}/></a></Col>
                        :null}
                        {resource.properties.phone && isSuperSales ?
                            <Col><a href={"tel:" + resource.properties.phone} className="text-muted"><FontAwesomeIcon size="lg" icon="phone"/></a></Col>
                        :null}
                        {resource.properties.skype && isSuperSales?
                            <Col><a href={"skype:" + resource.properties.skype + "?chat"} className="text-muted"><FontAwesomeIcon size="lg" icon={["fab", "skype"]}/></a></Col>
                        :''}
                        {resource.properties.whatsap && isSuperSales?
                            <Col><a href={"whatsapp:" + resource.properties.phone} className="text-muted"><FontAwesomeIcon size="lg" icon={["fab", "whatsapp"]} /></a></Col>
                        :''}
                        {resource.properties.telegram && isSuperSales ?
                            <Col><a href={"tg:" + resource.properties.phone} className="text-muted"><FontAwesomeIcon size="lg" icon={["fab",  "telegram"]}/></a></Col>
                        :''}
                        {resource.properties.viber && isSuperSales ?
                            <Col><a href={"viber:" + resource.properties.phone} className="text-muted"><FontAwesomeIcon size="lg" icon={["fab", "viber"]} /></a></Col>
                        :''}
                    </Row>
                    <Row className="my-2 py-1">
                        <Col>
                            {resource.properties.skills.slice(0, 5).map((skill) => {
                                return <Badge pill bg="success" className="me-2 mb-2" key={skill.value}>{skill.name.en}</Badge>
                            })}
                        </Col>
                    </Row>
                </Card.Body>
                <div className="position-absolute bottom-0 end-0 my-2 me-2">
                    <FontAwesomeIcon size="lg" icon={["fas", "circle"]} className={`me-2 ${resource.properties.available ? "text-success" : "text-danger"}`}/>
                    <FontAwesomeIcon size="lg" icon={["fas", "circle"]} className={resource.properties.assigned ? "text-secondary" : "text-primary"} />
                </div>
            </Card>
        </Col>
    )
}
