import {Component} from 'react';

import UserHelper from '../../Helper/UserHelper.js';
import ApiCall from '../../Helper/ApiCall.js';

export default class ActivatePage extends Component {
    constructor(props){
        super(props);

        if (UserHelper.isUserLoggedIn()) {
            this.props.history.push('/my-account');
        }

        ApiCall.post('account' + props.location.pathname).then((response) => {
            this.props.history.push({
                pathname: "/login",
                state: { globalInfoMsgs: ["Your account was activated"] }
            });
        }).catch(error => {
            this.props.history.push({
                pathname: "/login",
                state: { globalErrorMsgs: ["There was a problem with your account activation: " + error.message] }
            });
        });
    }

    render() {
        return null;
    }
}
