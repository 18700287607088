import React, {Component} from 'react';
import {Form, InputGroup, Row, Col, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';

import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';

class SkillCompetenceForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            technologies: [],
            levels: [],
            skillCompetenceForm: {
                code: this.props.editSkillCompetence && this.props.editSkillCompetence.code ? this.props.editSkillCompetence.code : null,
                name: this.props.editSkillCompetence && this.props.editSkillCompetence.technology && this.props.editSkillCompetence.technology.name ?
                {value:this.props.editSkillCompetence.technology.code, label:this.props.editSkillCompetence.technology.name} : "",
                level: this.props.editSkillCompetence && this.props.editSkillCompetence.level ? this.props.editSkillCompetence.level : 'BEGINNER',
                years: this.props.editSkillCompetence && this.props.editSkillCompetence.years ? this.props.editSkillCompetence.years : 0,
            }
        };

        this.getTechnologies = this.getTechnologies.bind(this);
        this.getLevels = this.getLevels.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeSkillCompetenceForm = this.handleChangeSkillCompetenceForm.bind(this);
        this.handleSubmitAddEditSkillCompetenceForm = this.handleSubmitAddEditSkillCompetenceForm.bind(this);
        this.clearSkillCompetenceForm = this.clearSkillCompetenceForm.bind(this);
    }

    componentDidMount() {
        this.getTechnologies();
        this.getLevels();

        if (this.props.editSkillCompetence && !UserHelper.isEquivalent(this.props.editSkillCompetence, this.state.skillCompetenceForm)) {
            this.setState({
                    skillCompetenceForm: {
                        code: this.props.editSkillCompetence && this.props.editSkillCompetence.code ? this.props.editSkillCompetence.code : null,
                        name: this.props.editSkillCompetence && this.props.editSkillCompetence.technology && this.props.editSkillCompetence.technology.name ?
                        {value:this.props.editSkillCompetence.technology.code, label:this.props.editSkillCompetence.technology.name} : "",
                        level: this.props.editSkillCompetence && this.props.editSkillCompetence.level ? this.props.editSkillCompetence.level : 'BEGINNER',
                        years: this.props.editSkillCompetence && this.props.editSkillCompetence.years ? this.props.editSkillCompetence.years : 0,
                    }
                }
            );
        }
    }

    render() {
        let technologyOptions = [];
        technologyOptions.push(<option disabled value={-1} key={-1}>Please select...</option>);
        technologyOptions.push(this.state.technologies.map(function(technology, key){
            return <option key={key} value={technology.code}>{technology.name}</option>;
        }));

        let levelOptions = [];
        levelOptions.push(<option disabled value={-1} key={-1}>Please select...</option>);
        levelOptions.push(this.state.levels.map(function(level, key){
            return <option key={key} value={level}>{level}</option>;
        }));

        return (
            <React.Fragment>
                <Form id="skillCompetenceForm" onSubmit={this.handleSubmitAddEditSkillCompetenceForm}>
                    <Form.Group as={Row} controlId="name" className="mb-3">
                        <Col md={3} lg={3}>
                            <InputGroup>
                                <Select name="name" options={this.state.technologies} value={this.state.skillCompetenceForm.name || -1}
                                    closeMenuOnSelect={true} className="w-100 react-select-container" required classNamePrefix="react-select"
                                    onChange={this.handleInputChange}/>
                            </InputGroup>
                        </Col>
                        <Col md={3} lg={3}>
                            <InputGroup>
                                <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={<Tooltip id="button-tooltip-2">
                                      Level. What is your competence level for this skill.
                                      </Tooltip>}>
                                      <InputGroup.Text><FontAwesomeIcon icon="crown" /></InputGroup.Text>
                                </OverlayTrigger>
                                <Form.Select name="level" value={this.state.skillCompetenceForm.level} required onChange={this.handleInputChange}>
                                    {levelOptions}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                        <Col md={3} lg={3}>
                            <InputGroup>
                                <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={<Tooltip id="button-tooltip-2">
                                      Years of experience. How many years of experience do you have with this skill.
                                      </Tooltip>}>
                                      <InputGroup.Text><FontAwesomeIcon icon="clock" /></InputGroup.Text>
                                </OverlayTrigger>
                                <Form.Control name="years" type="number" placeholder="Years Of Experience" value={this.state.skillCompetenceForm.years || 0} required
                                onChange={this.handleInputChange}>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md={1} lg={1}>
                            <Button variant="primary" size="md" type="submit" className="float-end text-uppercase">Save</Button>
                        </Col>
                        <Col md={2} lg={2}>
                            <Button variant="link" onClick={() =>this.props.cancelSkillCompetence()}>Cancel</Button>
                        </Col>
                    </Form.Group>
                    <p className="text-info">Missing skill? Reach out to ptahchiev@supreme.contractors</p>
                </Form>
            </React.Fragment>
        )
    }

    handleChangeSkillCompetenceForm(field, value) {
        let newSkillCompetenceForm = this.state.skillCompetenceForm;
        newSkillCompetenceForm[field] = value;

        this.setState({
            skillCompetenceForm: newSkillCompetenceForm
        });
    }


    handleInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                this.handleChangeSkillCompetenceForm(name, value);
            } else {
                this.handleChangeSkillCompetenceForm("name", event);
            }
        }
    }

    getTechnologies() {
        UserHelper.getTechnologies().then(response => {
            if (response.technologies && response.technologies.length) {
                let techs = [];
                response.technologies.forEach(r => {
                    techs.push({value: r.code, label: r.name});
                });

                this.setState({
                    technologies: techs
                });
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);

                this.setState({
                    technologies: []
                });
            }
        });
    }

    getLevels() {
        UserHelper.getLevels().then(response => {
            if (response.levels && response.levels.length) {
                this.setState({
                    levels: response.levels
                });
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);

                this.setState({
                    levels: []
                });
            }
        });
    }

    handleSubmitAddEditSkillCompetenceForm(event) {
        event.preventDefault();

        if (this.state.skillCompetenceForm.level && this.state.skillCompetenceForm.years && this.state.skillCompetenceForm.name) {
            let skillCompetence = {
                code: this.state.skillCompetenceForm.code,
                level: this.state.skillCompetenceForm.level,
                years: this.state.skillCompetenceForm.years,
                technology: {
                    code: this.state.skillCompetenceForm.name.value
                }
            };

            if (this.props.editSkillCompetence) {
                ApiCall.put('skill-competence', skillCompetence).then(() => {
                    this.clearSkillCompetenceForm();
                    this.props.afterEditSkillCompetence(skillCompetence);
                }).catch(error => {
                    UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                });
            } else {
                ApiCall.post('skill-competence', skillCompetence).then(() => {
                    this.clearSkillCompetenceForm();

                    UserHelper.showSuccessToast("Your skill competence was added.");

                    this.props.getSkillCompetences();
                }).catch(error => {
                    UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                });
            }
        }
    }

    clearSkillCompetenceForm() {
        this.setState({
            skillCompetenceForm: {
                code: null,
                name: null,
                level: 'BEGINNER',
                years: 0
            }
        });
    }
}

export default SkillCompetenceForm;
