import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ApiCall from '../../../Helper/ApiCall.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal, Badge, Form, InputGroup, Button} from 'react-bootstrap';

class AccountClientsContactModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null,
            subject: "",
            description: "",
            communicationType: "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSendMessage = this.handleSendMessage.bind(this);
    }

    render() {

        let recipients = this.props.clients.map(function(title, key){
            return <Badge pill bg="success" className="me-2 mb-2">{title}</Badge>;
        });

        return (
            <>
                {this.props.clients ?
                    <Modal centered size="lg" show={this.props.show} onHide={this.props.handleModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-uppercase fw-bold">Write Message</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputGroup className="mb-2">
                                {recipients}
                            </InputGroup>
                            <InputGroup className="mb-2">
                                <InputGroup.Text><FontAwesomeIcon icon="file" /></InputGroup.Text>
                                <Form.Control name="subject" as="input" placeholder="Subject" value={this.state.subject} rows={5}
                                required onChange={this.handleInputChange} />
                            </InputGroup>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="file" /></InputGroup.Text>
                                <Form.Control name="description" as="textarea" placeholder="Notes" value={this.state.description} rows={5}
                                required onChange={this.handleInputChange} />
                            </InputGroup>
                            <InputGroup>
                                <Form.Check
                                    inline
                                    label="Email"
                                    name="communicationType"
                                    type="radio"
                                    onChange={this.handleInputChange}
                                    id="email"
                                />
                                <Form.Check
                                    inline
                                    label="Whatsap"
                                    name="communicationType"
                                    type="radio"
                                    onChange={this.handleInputChange}
                                    id="whatsap"
                                />
                                <Form.Check
                                    inline
                                    label="Linkedin"
                                    name="communicationType"
                                    type="radio"
                                    onChange={this.handleInputChange}
                                    id="linkedin"
                                />
                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="text-uppercase" onClick={this.handleSendMessage}>
                                Send
                            </Button>
                        </Modal.Footer>
                    </Modal>
                :null}
            </>
        )
    }


    handleInputChange(event) {
        const target = event.target;
        let key = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [key]: value
        });
    }

    handleSendMessage() {
        this.setState({isLoading:true});
        ApiCall.post('client-organization/message', {
            subject: this.state.subject,
            description: this.state.description,
            communicationType: this.state.communicationType
        }).then(() => {
            this.props.handleResourceNotesModalClose();
            this.setState({isLoading:false});
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.',
                isLoading:false
            });
        });
    }
}

export default withRouter(AccountClientsContactModal);
