import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import qs from 'qs';

import ApiCall from '../Helper/ApiCall.js';

export const ListerContext = React.createContext();

class ListerPageProvider extends Component {
    constructor(props) {
        super(props);

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        this.defaultPageSize = 9;

        this.state = {
            searchPageData: null,
            layout: queryParams.layout ? queryParams.layout : "grid",
            isLoading: true
        }

        this.getProducts = this.getProducts.bind(this);
    }

    componentDidMount() {
        this.getProducts();
    }

    componentDidUpdate(prevProps) {
        if (this.props.categoryCode !== prevProps.categoryCode ||
            this.props.location.search !== prevProps.location.search) {
            this.getProducts();
        }
    }

    render() {
        return (
            <ListerContext.Provider value={{
                state: this.state,
                defaultPageSize: this.defaultPageSize,
                getProducts: this.getProducts
            }}>
                {this.props.children}
            </ListerContext.Provider>
        );
    }

    getProducts(pageNumber, layout, q) {
        this.setState({
            layout: layout ? layout : this.state.layout,
            isLoading: true
        });

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        let searchParams = {
            q: q == null ? (queryParams.q ? queryParams.q : ':allCategories:' + this.props.categoryCode) : q,
            page: pageNumber == null ? (queryParams.page ? queryParams.page : 0) : pageNumber,
            size: queryParams.size ? queryParams.size : this.defaultPageSize,
            layout: layout == null ? (queryParams.layout ? queryParams.layout : 'grid') : layout,
            sort: queryParams.sort ? queryParams.sort : ''
        };

        this.props.history.replace({
            search: '?' + qs.stringify(searchParams)
        });

        ApiCall.get('search', {
            projection: 'io.nemesis.platform.module.commerce.facade.search.dto.ProductFacetSearchPageDtoDefinition',
            categoryCode: this.props.categoryCode,
            type: 'product',
            queryName: this.props.categoryCode ? 'categorySearch' : null,
            q: searchParams.q,
            page: searchParams.page,
            size: searchParams.size,
            layout: searchParams.layout,
            sort: searchParams.sort
        }).then(response => {
            if (response.data.keywordRedirectUrl) {
                this.props.history.push(response.data.keywordRedirectUrl);
            }

            this.setState({
                searchPageData: response.data,
                layout: searchParams.layout,
                isLoading: false
            });
        }).catch(error => {
            console.log(error);

            this.setState({
                searchPageData: null,
                isLoading: false
            });
        });
    }
}

export default withRouter(ListerPageProvider);
