import React, {Component} from 'react';
import {format} from 'date-fns';
import {Row, NavDropdown, Col, Table, Card, Form, Button, Accordion} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';
import UserHelper from '../../../Helper/UserHelper.js';
import CommonFunctions from '../../../Helper/CommonFunctions.js';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ApiCall from '../../../Helper/ApiCall.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import InterviewRejectionModal from './InterviewRejectionModal.js';
import InterviewPreparationModal from './InterviewPreparationModal.js';
import './AccountInterviews.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class SalesInterviewTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            interviews: [],
            clientEmployees: [],
            interviewValues: new Map(),
            isLoading: true
        };

        this.getInterviews = this.getInterviews.bind(this);
        this.deleteInterview = this.deleteInterview.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getOrganizations = this.getOrganizations.bind(this);
        this.getInterviewRequests = this.getInterviewRequests.bind(this);
        this.handleInterviewModalClose = this.handleInterviewModalClose.bind(this);
        this.handleInterviewPreparationModalClose = this.handleInterviewPreparationModalClose.bind(this);
    }

    componentDidMount() {
        let organizationCode = null;
        if (this.props.location && this.props.location.hash) {
            organizationCode = this.props.location.hash.substring(1);
            this.setState({selectedOrganization : {value:organizationCode,label:organizationCode}});
        }

        this.getInterviewRequests();
        this.getInterviews(0, organizationCode);
        let isSuperSales = UserHelper.isSuperSales();
        if (isSuperSales) {
            this.getOrganizations();
        }
    }

    render() {
        let interviews, interviewRequests = null;
        let tableCss = "table-align-middle";
        let isSuperSales = UserHelper.isSuperSales();
        if (UserHelper.isDark()) {
            tableCss += " table-dark";
        }

        if (this.state.isLoading) {
            interviews = <TableSectionLoader colSpan={7} numberOfRows={5}/>
        } else {
            if (this.state.interviewRequests && this.state.interviewRequests.length>0) {
                let _this = this;
                interviewRequests = this.state.interviewRequests.map(function(interviewRequest, interviewRequestKey) {
                    let slots = interviewRequest.slots.map(function(slot, slotKey) {
                        return (
                            <tr key={slotKey}>
                                <td className="py-2">
                                    <div><FontAwesomeIcon icon="calendar" className="me-1 text-muted"/>{format(slot.startDateTime, "EEE, dd MMM")}</div>
                                    <div><FontAwesomeIcon icon="clock" className="me-1 text-muted"/>{format(slot.startDateTime, "HH:mm")} - {format(slot.endDateTime, "HH:mm")}</div>
                                </td>
                                <td><Button size="sm" variant="secondary" className="float-end" onClick={(e) => CommonFunctions.createInterviewFromInterviewRequest(slot.code, _this.props.progressBarContext, ()=>{_this.getInterviewRequests();_this.getInterviews(0);})}>Accept</Button></td>
                            </tr>
                        );
                    });
                    return (
                        <Col lg={12} md={12} sm={12} className="mb-2" key={interviewRequestKey}>
                            <Accordion defaultActiveKey={0}>
                                <Accordion.Item eventKey={interviewRequestKey}>
                                    <Accordion.Header>{interviewRequest.jobRequestName} - {interviewRequest.user ? interviewRequest.user.displayName:""}</Accordion.Header>
                                    <Accordion.Body>
                                        <Table bordered striped hover responsive className="table-align-middle" id="interview-requests-table">
                                            <tbody>
                                                {slots}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    );
                });
            }
            if (this.state.interviews && this.state.interviews.content && this.state.interviews.content.length) {
                interviews = this.state.interviews.content.map(function(interview, key) {
                    return (
                        <React.Fragment key={interview.code}>
                            <tr key={interview.code + '-1'}>
                                <td>{interview.offer && interview.offer.user ? interview.offer.user.initials : ''}</td>
                                <td>{interview.offer && interview.offer.user ? interview.offer.user.displayName : ''}</td>
                                <td>{interview.offer && interview.offer.organization ? interview.offer.organization.legalName : null}</td>
                                <td>
                                    <Form.Control name="startDateTime" type="datetime-local" data-id={interview.code} defaultValue={interview
                                    .startDateTime}
                                    onChange={this.handleInputChange}></Form.Control>
                                </td>
                                <td>
                                    <Form.Control name="endDateTime" type="datetime-local" data-id={interview.code} defaultValue={interview
                                    .endDateTime}
                                    onChange={this.handleInputChange}></Form.Control>
                                </td>
                                <td>
                                    <NavDropdown title="Actions" id="basic-nav-dropdown">
                                      <NavDropdown.Item onClick={() => this.setState({showPreparationModal:true, selectedInterview:interview.code, prepLinks:interview.preparationUrls})}>Preparation</NavDropdown.Item>
                                      <NavDropdown.Item onClick={() => this.setState({showRejectionModal:true, selectedInterview:interview.code})} className="text-warning">Reject</NavDropdown.Item>
                                      <NavDropdown.Item onClick={() => this.makeAssignment(interview.code)} className="text-success">Success</NavDropdown.Item>
                                      <NavDropdown.Divider />
                                      <NavDropdown.Item onClick={() => this.deleteInterview(interview.code)} className="text-danger">Delete</NavDropdown.Item>
                                    </NavDropdown>
                                </td>
                            </tr>
                            <tr key={interview.code + '-2'}>
                                <td colSpan="3">
                                    <Form.Control name="link" type="text" data-id={interview.code} defaultValue={interview.link}
                                    onChange={this.handleInputChange}>
                                    </Form.Control>
                                </td>
                                <td colSpan="2">
                                    <CreatableSelect isCreatable isMulti name="recipients" options={this.state.interviewValues.get(interview.code).allEmployees} value={this.state.interviewValues.get(interview.code).recipients}
                                        closeMenuOnSelect={true} className="w-100 react-select-container" isClearable="true" classNamePrefix="react-select"
                                        onChange={(values)=> this.onChange(interview.code, values)}/>
                                </td>
                                <td>
                                    <Button variant="primary" className="text-uppercase" onClick={() => this.updateInterview(interview.code)}>Save</Button>
                                </td>
                            </tr>
                        </React.Fragment>
                     );
                }, this);
            } else {
              interviews = <tr><td colSpan="6" className="text-center">You have no interviews</td></tr>;
            }
        }

        return (
            <>
                {this.state.interviewRequests && this.state.interviewRequests.length > 0 ?
                    <Row className="mt-3">
                        <Col sm={12} md={12} className="mt-3 mt-md-0">
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-uppercase fw-bold">
                                        Interview Requests
                                    </Card.Title>
                                    <p>Here are the recent interview requests</p>
                                    <Row>
                                        {interviewRequests}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                :""}
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Your Interviews
                                </Card.Title>
                                <p>Here you can see your scheduled interviews</p>
                                {isSuperSales?
                                    <Row>
                                        <Col lg={6} md={6} className="mb-2">
                                        <Select name="organization" options={this.state.organizations} value={this.state.selectedOrganization}
                                            closeMenuOnSelect={true} className="w-100 react-select-container" isClearable="true" classNamePrefix="react-select"
                                            onChange={(event)=> {this.redirectToOrganization(event, this.props.history)}}/>
                                        </Col>
                                    </Row>
                                :""}
                                <Table id="interviews-table" striped bordered hover responsive className={tableCss}>
                                    <thead>
                                        <tr>
                                            <th>Initials</th>
                                            <th>Name</th>
                                            <th>Client</th>
                                            <th>Start (EET)</th>
                                            <th>Finish (EET)</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { interviews }
                                    </tbody>
                                    {this.state.interviews && this.state.interviews.content && this.state.interviews.content.length > 0 ?
                                        <tfoot>
                                            <tr>
                                                <th colSpan="3">
                                                    <PaginationInfo page={this.state.interviews} />
                                                </th>
                                                <th colSpan="3">
                                                    <div className="float-end w-50">
                                                        <Pagination page={this.state.interviews} handleChangePage={this.getInterviews} isLoading={this.state
                                                        .isLoading} />
                                                    </div>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    :null}
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <InterviewRejectionModal show={this.state.showRejectionModal} handleInterviewModalClose={this.handleInterviewModalClose} interview={this.state.selectedInterview} />
                    {isSuperSales ?
                        <InterviewPreparationModal show={this.state.showPreparationModal} handleInterviewPreparationModalClose={this.handleInterviewPreparationModalClose} interview={this.state.selectedInterview} prepLinks={this.state.prepLinks}/>
                    :""}
                </Row>
            </>
        )
    }

    handleInputChange(event) {
        if (event) {
            let name = '';
            let value = '';
            let code = '';
            const target = event.target;
            if (target) {
                value = target.value;
                name = target.name;
                code = target.dataset.id;
            } else {
                code = event.interview.code;
                let newRecipients = [];
                if (event.e) {
                    event.e.forEach(sel => {
                        newRecipients.push({value:sel.value, label:sel.label});
                    });
                }
                name = 'recipients';
                value = newRecipients;
            }
            let interviewValues = this.state.interviewValues;
            let specificInterviewValues = interviewValues.get(code);
            specificInterviewValues[name] = value;
            interviewValues.set(code, specificInterviewValues);

            this.setState({
                interviewValues : interviewValues
            });
        }
    }

    redirectToOrganization(event, history) {
        if (event) {
            history.push('/my-account/interviews#' + event.value);
            this.setState({selectedOrganization:event});
            this.getInterviews(0, event.value);
        } else {
            history.push('/my-account/interviews');
            this.setState({selectedOrganization:null});
            this.getInterviews(0, null);
        }
    }

    handleInterviewModalClose() {
        this.setState({
            showRejectionModal:false,
            selectedInterview:null
        });
    }

    handleInterviewPreparationModalClose() {
        this.setState({
            showPreparationModal:false,
            selectedInterview:null
        });
    }

    getInterviews(pageNumber, organizationCode) {

        let url = "interview/all";
        if (organizationCode) {
            url = "interview/organization/" + organizationCode;
        }

        ApiCall.get(url, {page:pageNumber, size: 10}).then((response) => {
            let interviewValues = new Map();
            response.data.content.forEach(interview => {
                let recipients = [];
                interview.recipients.forEach(r => {
                    recipients.push({value: r.address, label: r.address});
                });

                let allEmployees = [];
                if (interview.allEmployees) {
                    interview.allEmployees.forEach(r => {
                        allEmployees.push({value: r.address, label: r.address});
                    });
                }

                interviewValues.set(interview.code, {"startDateTime":interview.startDateTime,"endDateTime":interview.endDateTime,"link":interview.link,
                "recipients" : recipients, "allEmployees" : allEmployees});
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            });

            this.setState({
                currentPage:pageNumber,
                interviews: response.data,
                interviewValues: interviewValues,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getInterviewRequests() {
        ApiCall.get('interview/request').then((response) => {
            this.setState({interviewRequests:response.data});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });

    }

    onChange = (code, values) => {
        let interviewValues = this.state.interviewValues;
        let specificInterviewValues = interviewValues.get(code);
        specificInterviewValues["recipients"] = values;
        interviewValues.set(code, specificInterviewValues);

        this.setState({
            interviewValues : interviewValues
        });
    }

    updateInterview(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 35});

        this.setState({isLoading:true});
        let interviewValues = this.state.interviewValues;
        let specificInterviewValues = interviewValues.get(code);
        let recipients = [];
        specificInterviewValues.recipients.forEach(recipient => {
            recipients.push({address:recipient.value});
        });
        ApiCall.put('interview',{
            code: code,
            link: specificInterviewValues.link,
            startDateTime: specificInterviewValues.startDateTime,
            endDateTime: specificInterviewValues.endDateTime,
            recipients: recipients
        }).then((response) => {
            let selectedOrganizationCode = this.state.selectedOrganization ? this.state.selectedOrganization.value : null;
            UserHelper.showSuccessToast("Your interview was updated");
            this.getInterviews(0, selectedOrganizationCode);
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});

        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.', true);
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    makeAssignment(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 35});
        ApiCall.post('interview/' + code + '/assignment/').then((response) => {
            this.props.history.push("/my-account/assignments");
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    deleteInterview(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 35});
        ApiCall.delete('interview/' + code).then((response) => {
            this.getInterviews(0, this.state.organizationCode);
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    getOrganizations() {
        ApiCall.get('client-organization/names').then((response) => {
            let organizations = [];
            for (const [key, value] of Object.entries(response.data)) {
                organizations.push({value: `${key}`, label: `${value}`});
            }
            this.setState({
                organizations: organizations,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 40});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }
}

export default withRouter(withProgressBarContext(SalesInterviewTable));
