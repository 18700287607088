import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col, Card, Dropdown, Alert, Form} from 'react-bootstrap';
import BootstrapTable from '../../../Misc/BootstrapTable.js';
import BootstrapModal from '../../../Misc/BootstrapModal.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

import './AccountOrderHistory.scss';

class ClientOrderHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ordersPage: {},
            showOrderSettingsModal: false,
            isLoading: true
        };

        this.getOrders = this.getOrders.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.getTrColor = this.getTrColor.bind(this);
        this.submitRejection = this.submitRejection.bind(this);
        this.getActionsFormatForOrder = this.getActionsFormatForOrder.bind(this);
    }

    componentDidMount() {
        this.getOrders(0);
    }

    render() {
        const expandRow = {
          renderer: row => {
            let entries = row.cart.entries.map(function(entry, key){
                return <div key={entry.code} id={entry.entity.code}>
                        <span className="ms-5">{entry.entity.name}</span>
                        <span className="float-end me-5">
                            {entry.quantity} {entry.unit.code} x {entry.baseAmount.formatted} = <span className="fw-bold">{entry.totalAmount.formatted}</span>
                        </span>
                 </div>;
            });

            return (
            <div>
                {entries}
                {row.rejectReason ?
                    <Alert variant="danger" className="mt-3">
                        <div className="text-center">
                            <FontAwesomeIcon className="me-1" size="lg" icon="circle-exclamation" />{row.rejectReason}
                        </div>
                    </Alert>
                :""}
            </div>
          )},
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandHeaderColumnRenderer: (isAnyExpands) => {
              if (isAnyExpands) {
                return <b>[-]</b>;
              }
              return <b>[+]</b>;
          },
          expandColumnRenderer: (expanded) => {
              if (expanded) {
                return (
                  <b>[-]</b>
                );
              }
              return (
                <b>[+]</b>
              );
          }
        };

        const incomingColumns = [
            {
              dataField: 'code',
              text: 'Order number',
              formatter: (cell, row) => <a href={"/my-account/order-view#" + cell}>{ cell }</a>,
              footer: columnData => <PaginationInfo page={this.state.ordersPage} />,
              footerAttrs: {
                  colSpan: 2
              }
            },
            {
              dataField: 'issuerLegalName',
              text: 'Issuer'
            },
            {
              dataField: 'created',
              text: 'Date Placed',
              footer: ''
            },
            {
              dataField: 'status',
              text: 'Order Status'
            },
            {
              dataField: 'totalAmount.formatted',
              text: 'Total'
            },
            {
               dataField: 'dummy2',
               formatter: this.getActionsFormatForOrder,
               text: 'Actions',
               footer: columnData => <Pagination page={this.state.ordersPage} handleChangePage={this.getOrders} isLoading={this.state.isLoading} />,
               footerAttrs: {
                  colSpan: 3
               }
            }
        ];

        let skin = UserHelper.isDark() ? "table-dark" : "";

        return (
            <Row className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body className="order-history-wrapper">
                            <Card.Title className="text-uppercase fw-bold">
                                Order history
                            </Card.Title>
                            <p>This is a list of your previous orders</p>
                            <BootstrapTable
                              keyField='code'
                              classes={"table-align-middle " + skin}
                              data={ this.state.ordersPage.content }
                              noDataIndication="No Incoming Orders"
                              columns={ incomingColumns }
                              expandRow={ expandRow }
                              bordered={true}
                              hover
                              condensed
                              rowClasses={ this.getTrColor }
                              isLoading={this.state.isLoading}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <BootstrapModal name="Reject Invoice" show={this.state.showRejectModal} handleClose={()=>{this.setState({showRejectModal:false})}} handleAction={this.submitRejection} size="l" actionBtn="Reject">
                    <Form>
                        <Form.Group className="mb-3" controlId="reason">
                            <Form.Label>Reason</Form.Label>
                            <Form.Control as="textarea" rows={10} value={this.state.rejectReason} onChange={(e)=>this.setState({rejectReason:e.target.value})} maxLength={5000}/>
                        </Form.Group>
                    </Form>
                </BootstrapModal>
            </Row>
        )
    }

    getOrders(pageNumber) {
        ApiCall.get('supremeOrder/incoming', {
            page: pageNumber,
            size: 25
        }).then((response) => {
            this.setState({
                ordersPage: response.data,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 100});
        }).catch(error => {
            this.setState({
                ordersPage: null,
                isLoading: false
            });
        });
    }

    submitRejection() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.post('supremeOrder/reject', {
            code: this.state.selectedOrder,
            reason: this.state.rejectReason
        }).then((response) => {
            UserHelper.showSuccessToast('Invoice was rejected');
            this.getOrders(0);
            this.setState({showRejectModal:false});
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    getActionsFormatForOrder(cell, order) {
        const config = {
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        };
        return (
            <Dropdown className="float-end ms-2 text-bf">
                <Dropdown.Toggle variant="transparent">
                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={config}>
                    <Dropdown.Item onClick={() => this.props.history.push('/my-account/order-view#' + order.code)}>View</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.downloadInvoice(order.code)}>Download</Dropdown.Item>
                    <Dropdown.Item onClick={() => {this.setState({showRejectModal: true, selectedOrder: order.code})}}>Reject</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    downloadInvoice = (orderCode) => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 0});
        fetch(process.env.REACT_APP_FACADE_URL + 'invoice/' + orderCode, {
            method: "GET",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-Invoice-' + orderCode + '.pdf';
                    a.click();
                });

                if (UserHelper.isClient()) {
                    ApiCall.post('supremeOrder/status', {
                        code: orderCode,
                        status: 'ACCEPTED'
                    }).then((response) => {
                        this.getOrders(0);
                        this.setState({downloadStarted:false});
                        this.props.progressBarContext.dispatch({type: 'update', progress: 100});
                    }).catch(error => {
                        UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                        this.setState({downloadStarted:false});
                        this.props.progressBarContext.dispatch({type: 'update', progress: 100});
                    });
                }
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    getTrColor(order, rowIndex) {
        let result = order.frozen ? "disabled " : "";
        if (order.status === 'CREATED') {
            return result += "table-primary";
        } else if (order.status === 'REJECTED') {
            return result + 'table-danger';
        } else if (order.status === 'ACCEPTED') {
            return result + "table-info";
        } else if (order.status === 'EMAIL_SENT') {
            return result + "table-warning";
        } else if (order.status === 'PAID') {
            return result + "table-success";
        } else if (order.status === 'CANCELLED') {
            return result + "table-muted";
        }
    }
}

export default withRouter(withProgressBarContext(ClientOrderHistory));
