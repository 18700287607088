import React, {Component} from 'react';
import {Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CertificationsForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            technologies: [],
            levels: [],
            certificationForm: {
                code: null,
                name: null,
                startDate: null,
                endDate: null
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeCertificationForm = this.handleChangeCertificationForm.bind(this);
        this.handleSubmitAddEditCertificationForm = this.handleSubmitAddEditCertificationForm.bind(this);
        this.clearCertificationForm = this.clearCertificationForm.bind(this);
    }

    componentDidMount() {
        if (this.props.editCertification && !UserHelper.isEquivalent(this.props.editCertification, this.state.certificationForm)) {
            this.setState({
                    certificationForm: {
                        code: this.props.editCertification ? this.props.editCertification.code : null,
                        name: this.props.editCertification ? this.props.editCertification.name : "",
                        startDate: this.props.editCertification ? this.props.editCertification.startDate : "",
                        endDate: this.props.editCertification ? this.props.editCertification.endDate : "",
                    }
                }
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <Form id="certificationForm" onSubmit={this.handleSubmitAddEditCertificationForm}>
                    <Form.Group as={Row} controlId="name" className="mb-3">
                        <Col md={3} lg={3}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="certificate" /></InputGroup.Text>
                                <Form.Control name="name" type="text" placeholder="Name" value={this.state.certificationForm.name || ""}
                                required
                                onChange={this.handleInputChange}>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md={3} lg={3}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="calendar" /></InputGroup.Text>
                                <DatePicker
                                  value={this.state.certificationForm.startDate || ""}
                                  onChange={(date)=>this.handleInputChange({target:{name:'startDate', value:new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,7)}})}
                                  className="form-control calendar"
                                  dateFormat="yyyy-MMM"
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                  placeholderText="Start Date"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3} lg={3}>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="calendar" /></InputGroup.Text>
                                <DatePicker
                                  value={this.state.certificationForm.endDate || ""}
                                  onChange={(date)=>this.handleInputChange({target:{name:'endDate', value:new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,7)}})}
                                  className="form-control calendar"
                                  dateFormat="yyyy-MMM"
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                  placeholderText="End Date"
                                />
                            </InputGroup>
                        </Col>
                        <Col md={1} lg={1}>
                            <Button variant="primary" type="submit" className="float-end text-uppercase">Save</Button>
                        </Col>
                        <Col md={2} lg={2}>
                            <Button variant="link" onClick={() =>this.props.cancelCertification()}>Cancel</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </React.Fragment>
        )
    }

    handleChangeCertificationForm(field, value) {
        let newCertificationForm = this.state.certificationForm;
        newCertificationForm[field] = value;

        this.setState({
            certificationForm: newCertificationForm
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.handleChangeCertificationForm(name, value);
    }

    handleSubmitAddEditCertificationForm(event) {
        event.preventDefault();

        let certification = {
            code: this.state.certificationForm.code,
            name: this.state.certificationForm.name,
            startDate: this.state.certificationForm.startDate,
            endDate: this.state.certificationForm.endDate
        };

        if (this.props.editCertification) {
            ApiCall.put('certification', certification).then(() => {
                this.clearCertificationForm();
                this.props.afterEditCertification();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        } else {
            ApiCall.post('certification', certification).then(() => {
                this.clearCertificationForm();

                UserHelper.showSuccessToast("Your certification was added.");

                this.props.getCertifications();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }
    }

    clearCertificationForm() {
        this.setState({
            certificationForm: {
                code: null,
                name: null,
                startDate: null,
                endDate: null
            }
        });
    }
}

export default CertificationsForm;
