import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col, Card, Form, InputGroup, Button} from 'react-bootstrap';
import Select from 'react-select';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import {withSelectedResourceContext} from '../AccountSearch/SelectedResourceProvider.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';
import { Editor } from '@tinymce/tinymce-react';
import './TinyMce.scss';

class AccountNewRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organizations: [],
            code: null,
            selectedOrganization: null,
            technologies: [],
            name: null,
            selectedTechnologies: [],
            selectedTimeZone: null,
            numberOfResources: 0,
            seniority:'SENIOR',
            message: null,
            organization: null,
            isLoading: true,
            private: false
        };

        this.getOrganizations = this.getOrganizations.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.selectOrganization = this.selectOrganization.bind(this);
        this.getTechnologies = this.getTechnologies.bind(this);
        this.getTimeZones = this.getTimeZones.bind(this);
        this.createRequest = this.createRequest.bind(this);
        this.getRequest = this.getRequest.bind(this);
    }

    componentDidMount() {
        this.getTechnologies();
        let isSuperSales = UserHelper.isSuperSales();
        if (isSuperSales) {
            this.getOrganizations();
        }
        this.getTimeZones();
        if (this.props.location && this.props.location.hash) {
            let requestCode = this.props.location.hash.substring(1);
            this.getRequest(requestCode);
        }
    }

    render() {
        let isDark = UserHelper.isDark();

        let skin = isDark ? "oxide-dark" : "oxide";
        let contentCss = isDark ? "dark" : "light";
        let isSuperSales = UserHelper.isSuperSales();
        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Create New Request
                                </Card.Title>
                                <p>Please create your new offer</p>
                                <p className="text-warning">Fields marked with * are required</p>
                                <Form id="requestForm" onSubmit={this.createRequest}>
                                    <Row>
                                        <Col md={12} lg={12}>
                                            {isSuperSales ?
                                             <Form.Group as={Row} controlId="organization" className="mb-3">
                                                <Form.Label column md={3} lg={3}>
                                                    Organization
                                                </Form.Label>
                                                <Col md={9} lg={9}>
                                                    <InputGroup>
                                                        <Select name="organization" options={this.state.organizations} value={this.state.organization}
                                                        closeMenuOnSelect={true} className="w-100 react-select-container" required classNamePrefix="react-select"
                                                        onChange={(event)=> {this.selectOrganization(event, this.props.history)}}/>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            :""}
                                            <Form.Group as={Row} controlId="numberOfResources" className="mb-3">
                                                <Form.Label column md={3} lg={3}>
                                                    Number of Resources*
                                                </Form.Label>
                                                <Col md={9} lg={9}>
                                                    <InputGroup>
                                                        <Form.Control name="numberOfResources" type="number" placeholder="Number of resources" value={this.state
                                                        .numberOfResources || ""}
                                                        required onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="seniority" className="mb-3">
                                                <Form.Label column md={3} lg={3}>
                                                    Seniority*
                                                </Form.Label>
                                                <Col md={9} lg={9}>
                                                    <InputGroup>
                                                        <Form.Select name="seniority" type="number" placeholder="Seniority" value={this.state
                                                        .seniority || ""}
                                                        required onChange={this.handleInputChange}>
                                                            <option value="SENIOR">SENIOR</option>
                                                            <option value="MIDDLE">MIDDLE</option>
                                                            <option value="JUNIOR">JUNIOR</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="timeZone" className="mb-3">
                                                <Form.Label column md={3} lg={3}>
                                                    Time Zone*
                                                </Form.Label>
                                                <Col md={9} lg={9}>
                                                    <InputGroup>
                                                        <Select name="timeZone" options={this.state.timezones} classNamePrefix="react-select"
                                                            closeMenuOnSelect={true} className="w-100 react-select-container" value={this.state
                                                            .selectedTimeZone || ""} onChange={(el)=>{this.setState({selectedTimeZone:el})}}/>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="maxHourlyBudget" className="mb-3">
                                                <Form.Label column md={3} lg={3}>
                                                    Max Hourly Budget
                                                </Form.Label>
                                                <Col md={9} lg={9}>
                                                    <InputGroup>
                                                        <Form.Control name="maxHourlyBudget" type="number" placeholder="Max Hourly Budget" value={this.state.maxHourlyBudget || ""} onChange={this.handleInputChange} step={0.01} min={0} max={100} />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="name" className="mb-3">
                                                <Form.Label column md={3} lg={3}>
                                                    Name*
                                                </Form.Label>
                                                <Col md={9} lg={9}>
                                                    <InputGroup>
                                                        <Form.Control name="name" type="text" placeholder="Name" value={this.state.name || ""}
                                                        required onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="technologies" className="mb-3">
                                                <Form.Label column sm={3} md={12} lg={3}>
                                                    Technologies
                                                </Form.Label>
                                                <Col sm={9} md={12} lg={9}>
                                                    <InputGroup>
                                                        <Select name="technologies" options={this.state.technologies} classNamePrefix="react-select"
                                                            closeMenuOnSelect={false} isMulti className="w-100 react-select-container" value={this.state.selectedTechnologies || ""}
                                                            onChange={this.handleInputChange}/>
                                                    </InputGroup>
                                                    <p className="text-info mt-2">Missing technology? Reach out to ptahchiev@supreme.contractors</p>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="description" className="mb-3">
                                                <Form.Label column md={3} lg={3}>
                                                    Description*
                                                </Form.Label>
                                                <Col md={9} lg={9}>
                                                    <Editor
                                                        apiKey="pmqdr31g3vkb9glmtnlkegq4jkxxw7b7rd98pv0sa10eyjml"
                                                        className="test"
                                                        initialValue={this.state.description}
                                                        init={{height: 500,  menubar: false,
                                                            skin: skin,
                                                            content_css: contentCss,
                                                            plugins: [
                                                                'advlist autolink lists link image charmap print preview anchor',
                                                                'searchreplace visualblocks code fullscreen textcolor',
                                                                'insertdatetime media table paste code help wordcount'
                                                            ],
                                                            toolbar: 'undo redo fullscreen| formatselect link image| bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code'
                                                        }}
                                                        onEditorChange={this.handleEditorChange}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            {isSuperSales ?
                                                <Form.Group as={Row} controlId="private" className="mb-3">
                                                    <Form.Label column sm={3} md={12} lg={3}>
                                                        Is Private
                                                    </Form.Label>
                                                    <Col sm={9} md={12} lg={9}>
                                                        <InputGroup>
                                                            <input
                                                              type="checkbox"
                                                              onChange={(e) => {
                                                                this.setState({private:e.target.checked});
                                                              }}
                                                              defaultChecked={this.state.private}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                            :""}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                            <Button variant="primary" type="submit" className="float-start text-uppercase"
                                            disabled={this.state.isLoading}>Save</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    handleInputChange(event) {
        if (event && event.target) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;
            this.handleChangeForm(name, value);
        } else {
            let newTechnologies = [];
            if (event) {
                event.forEach(sel => {
                    newTechnologies.push({value:sel.value, label:sel.label});
                });
            }
            this.setState({
                "selectedTechnologies": newTechnologies
            });
        }
    }

    handleEditorChange(content, editor) {
        this.setState({ newDescription:content });
    }

    getRequest(code) {
        ApiCall.get('request/' + code).then((response) => {
            let request = response.data;
            let selectedOrganization = null;
            if (request.organization) {
                selectedOrganization = {value:request.organization.code, label:request.organization.legalName};
            }
            let selectedTechnologies = [];
            request.technologies.forEach(technology=> {
                selectedTechnologies.push({value: technology.code, label:technology.name});
            });

            this.setState({
                code: request.code,
                organization: selectedOrganization,
                name: request.name,
                maxHourlyBudget: request.maxHourlyBudget,
                selectedTechnologies: selectedTechnologies,
                selectedTimeZone: {value: request.timeZone, label: request.timeZone},
                description: request.description,
                newDescription: request.description,
                numberOfResources: request.numberOfResources,
                seniority: request.seniority,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getTechnologies() {
        UserHelper.getTechnologies().then(response => {
            if (response.technologies && response.technologies.length) {
                let techs = [];
                response.technologies.forEach(r => {
                    techs.push({value: r.code, label: r.name});
                });

                this.setState({
                    technologies: techs
                });
                this.props.progressBarContext.dispatch({type: 'increment', progress: 30});
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg, true);

                this.setState({
                    technologies: []
                });
            }
        });
    }

    getTimeZones() {
        ApiCall.get('calendar/timezones').then((response) => {
            let timezones = [];
            for (const el of response.data) {
                timezones.push({value: `${el.second}`, label: `${el.second} - ${el.first}`});
            }
            this.setState({
                timezones: timezones,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 30});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getOrganizations() {
        ApiCall.get('client-organization/names').then((response) => {
            let organizations = [];
            for (const [key, value] of Object.entries(response.data)) {
                organizations.push({value: `${key}`, label: `${value}`});
            }
            this.setState({
                organizations: organizations,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    selectOrganization(event, history) {
        this.setState({organization:event});
    }

    handleChangeForm(field, value) {
        this.setState({
            [field]: value
        });
    }

    createRequest(event) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 35});
        event.preventDefault();
        this.setState({isLoading:true});

        let technologies = [];
        this.state.selectedTechnologies.forEach(t=> {
            technologies.push({code:t.value});
        });

        ApiCall.post('request', {
            code: this.state.code,
            name: this.state.name,
            numberOfResources: this.state.numberOfResources,
            description: this.state.newDescription,
            seniority: this.state.seniority,
            technologies:technologies,
            timeZone: this.state.selectedTimeZone.value,
            maxHourlyBudget: this.state.maxHourlyBudget,
            organization: {
                "@class": "io.nemesis.platform.facade.location.dto.OrganizationDto",
                code: this.state.organization ? this.state.organization.value : null
            }
        }, null, {private: this.state.private}).then(() => {
            this.props.selectedResourcesContext.clearSelectedResources();
            this.props.history.push({pathname:"/my-account/requests",state: { globalInfoMsgs: ["Offer has been sent"] }});
            this.setState({isLoading:false});
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.',
                isLoading:false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });

    }

}

export default withRouter(withSelectedResourceContext(withProgressBarContext(AccountNewRequest)));
