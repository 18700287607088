import React, {Component} from 'react';
import {format} from 'date-fns';
import {Row, Dropdown, Badge, Col, Card, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountRequests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requests: [],
            offerValues: new Map(),
            isLoading: true
        };

        this.getRequests = this.getRequests.bind(this);
        this.deleteRequest = this.deleteRequest.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.redirectToSearch = this.redirectToSearch.bind(this);
    }

    componentDidMount() {
        this.getRequests();
    }

    render() {

        let requests = null;
        let isSales = UserHelper.isSales();
        let isClient = UserHelper.isClient();

        if (this.state.isLoading) {
            requests = <div>No requests available</div>;
        } else {
            if (this.state.requests && this.state.requests && this.state.requests.length) {
                requests = this.state.requests.map(function(request, key) {
                    return (
                        <Row key={request.code}>
                            <Col sm={12} md={12} className="mt-3">
                                <Card className="border-left-secondary shadow-hover h-100 py-2">
                                    <Card.Body>
                                        <Row>
                                            <Col xs={1}>
                                                <div className="h1 mb-0 fw-bold text-muted">{key+1}</div>
                                            </Col>
                                            <Col xs={9}>
                                                <div className="fw-bold text-uppercase mb-1 h5 mb-2">{request.name}</div>
                                                <div>{request.createdDate ? format(request.createdDate, "dd MMM yyyy") : ''}</div>
                                                <div>{request.organization ? request.organization.legalName : null}</div>
                                                <div>{request.numberOfResources} {request.seniority} Resource(s)</div>
                                                {request.timeZone?
                                                    <div className="text-success">{request.timeZone} Timezone</div>
                                                :null}
                                                {request.maxHourlyBudget?
                                                    <div className="text-danger mb-2">Budget limit: €{request.maxHourlyBudget}</div>
                                                :null}
                                                <div className="h5 mt-2">
                                                    {request.technologies && request.technologies.map(function(d, idx) {
                                                        return (<Badge key={request.code + '-' + d.code} pill className="me-1 mt-1 p-3" bg="primary">{d
                                                        .name}</Badge>)
                                                    })}
                                                </div>
                                                <div className="mb-0 mt-2"
                                                  dangerouslySetInnerHTML={{
                                                    __html: request.description
                                                  }}>
                                                </div>
                                                {(isSales || isClient) && request.offeredProfiles ?
                                                    <div className="border-top pt-2 h5">
                                                        {request.offeredProfiles && request.offeredProfiles.map(function(p, idx) {
                                                            return <Badge key={idx} pill className="me-1 mt-1 p-3 text-primary bg-secondary">{p.displayName}</Badge>
                                                        })}
                                                    </div>
                                                : ""}
                                            </Col>
                                            <Col xs={2}>
                                                {isSales || isClient ?
                                                    <Dropdown className="float-end ms-2 text-bf">
                                                        <Dropdown.Toggle variant="primary">
                                                            <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => this.props.history.push("/my-account/request/#" + request.code)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => this.deleteRequest(request.code)}>Delete</Dropdown.Item>
                                                            {isSales ? <Dropdown.Item onClick={() => this.redirectToSearch(request.technologies)}>Search</Dropdown.Item> : ""}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                :null}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        );
                }, this);
            } else {
              requests = <div>No requests available</div>;
            }
        }

        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    All Requests
                                    {isSales || isClient ?
                                        <Button variant="secondary" className="float-end text-uppercase ms-2" onClick={
                                        () => {this.props.history.push('/my-account/request/new')}}>New</Button>
                                    :null}
                                </Card.Title>
                                <p>This is a list of all requests at the moment</p>
                            </Card.Body>
                        </Card>
                        {requests}
                    </Col>
                </Row>
            </>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const code = target.dataset.id;

        let offerValues = this.state.offerValues;
        let specificOfferValues = offerValues.get(code);
        specificOfferValues[name] = value;

        offerValues.set(code, specificOfferValues);

        this.setState({
            offerValues : offerValues
        });
    }

    getRequests() {
        ApiCall.get('request/', {}).then((response) => {

            let offerValues = new Map();
            response.data.forEach(offer => {
                offerValues.set(offer.code, {"companyHourlyRate":offer.companyHourlyRate,"supremeHourlyRate":offer.supremeHourlyRate,"status":offer.status,
                "userCode" :
                offer.user ? offer.user.code : null});
            });

            this.setState({
                requests: response.data,
                offerValues: offerValues,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 80});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 0});
        });
    }

    redirectToSearch(technologies) {
        let queryParam = "?q=:available:true";
        technologies.forEach(t=> {
            queryParam += ":skills:" + t.code;
        });

        this.props.history.push("/my-account/search" + queryParam + "&page=0&size=9&sort=")
    }

    deleteRequest(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.delete('request/' + code).then((response) => {
            this.getRequests();
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }
}

export default withRouter(withProgressBarContext(AccountRequests));
