import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {set, format} from 'date-fns';
import {Row, Col, Modal, Button} from 'react-bootstrap';
import Select from 'react-select';
import CalendarSlotSelector from '../../../Misc/CalendarSlotSelector.js';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class InterviewRequestModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null,
            selectedSlots: [],
            granularity: {value: 15, label: "15 mins"},
            granularities: [{value: 15, label: "15 mins"}, {value: 30, label: "30 mins"}, {value: 45, label: "45 mins"}, {value: 60, label: "1 hour"}, {value: 90, label: "90 mins"}, {value: 120, label: "2 hours"}]
        };
        this.askForInterview = this.askForInterview.bind(this);
    }

    render() {
        return (
            <Modal centered size="xl" show={this.props.show} onHide={this.props.handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-uppercase fw-bold">Interview Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="show-grid text-center">
                        <Col xs={12} sm={12}>
                            <h4 className="">Duration</h4>
                            <p>Select the duration of the interview</p>
                            <div className="mx-auto my-4">
                                <Select name="granularity" options={this.state.granularities} classNamePrefix="react-select"
                                closeMenuOnSelect={true} className="w-50 mx-auto react-select-container" value={this.state.granularity}
                                onChange={(val)=>{this.setState({granularity:val})}}/>
                            </div>
                        </Col>
                    </Row>
                    <CalendarSlotSelector granularity={this.state.granularity.value} selectTimeSlot={(timeSlotsArray)=>this.selectTimeSlot(timeSlotsArray)} startDate={new Date()}/>
                </Modal.Body>
                {this.state.selectedSlots ?
                    <Modal.Footer>
                        <Row>
                            <Col className="text-center">
                                <Button variant="secondary"size="lg" onClick={(e)=>this.askForInterview(e)}>Ask for interview</Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                :""}
            </Modal>
        )
    }

    selectTimeSlot(timeSlotsArray) {
        this.setState({selectedSlots : timeSlotsArray});
    }

    askForInterview(event) {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        let offerCodes = [];
        this.props.offers.forEach(el => {
            offerCodes.push(el.code);
        });

        let slots = [];
        this.state.selectedSlots.forEach(el => {
            let start = el.date;
            let end = el.date;
            let starts = el.timeSlot.start.split(":");
            let ends = el.timeSlot.end.split(":");
            start = set(start, {hours: starts[0], minutes: starts[1]});
            end = set(end, {hours: ends[0], minutes: ends[1], seconds: 0});

            slots.push({start:format(start, "yyyy-MM-dd'T'HH:mm"), end:format(end, "yyyy-MM-dd'T'HH:mm")});
        });

        let body = {
            offerCodes: offerCodes,
            slots: slots
        }
        ApiCall.post('interview/request', body).then((response) => {
            this.setState({
                isLoading: false
            });
            this.props.handleModalClose();
            UserHelper.showSuccessToast('Created interview requests!');
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }
}

export default withRouter(withProgressBarContext(InterviewRequestModal));
