import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import CmsPageProvider from '../../cms/CmsPageProvider.js';
import CmsTemplate from '../../cms/CmsTemplate.js';
import GlobalMessagesProvider from '../../Provider/GlobalMessagesProvider.js';
import GlobalMessages from '../../Misc/GlobalMessages.js';
import AccountResource from '../../Account/View/AccountResource/AccountResource.js';

export default class ResourceContractorPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: this.props.match.params.code
        };
    }

    render() {
        return (
            <CmsPageProvider props={{page:'contractor'}}>
                <GlobalMessagesProvider>
                    <CmsTemplate>
                        <main>
                            <Container fluid className="mt-3 mb-3 ps-0 pe-0">
                                <Row>
                                    <Col sm={12}>
                                        <GlobalMessages />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={12} md={12}>
                                        <AccountResource username="test" token={this.state.code}/>
                                    </Col>
                                </Row>
                            </Container>
                        </main>
                    </CmsTemplate>
                </GlobalMessagesProvider>
            </CmsPageProvider>
        )
    }
}
