import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Card, Form, InputGroup, Row, Col, Button, Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApiCall from '../../Helper/ApiCall.js';
import {withProgressBarContext} from '../../Provider/ProgressBarProvider.js';

class RecoverPassword extends Component {
    constructor(props){
        super(props);

        this.state = {
            loginEmail: null,
            errorMsg: null,
            isLoading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 100});
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title className="text-uppercase fw-bold h6">
                        Recover Your Password
                    </Card.Title>
                    {this.state.errorMsg &&
                    <Alert variant="danger"><FontAwesomeIcon className="me-1" size="lg" icon="circle-exclamation" />{this.state.errorMsg}</Alert>
                    }
                    {this.state.infoMsg &&
                    <Alert variant="success"><FontAwesomeIcon className="me-1" size="lg" icon="circle-check" />{this.state.infoMsg}</Alert>
                    }
                    {this.state.warnMsg &&
                    <Alert variant="warning"><FontAwesomeIcon className="me-1" size="lg" icon="triangle-exclamation" />{this.state.warnMsg}</Alert>
                    }
                    <Card.Text>Please type your email below</Card.Text>
                    <Form onSubmit={this.handleSubmit} id="login-form">
                        <Form.Group as={Row} controlId="formLoginEmail" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Email address*
                            </Form.Label>
                            <Col sm={9} md={12} lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="envelope" /></InputGroup.Text>
                                    <Form.Control name="loginEmail" type="email" placeholder="Email" required onChange={this.handleInputChange} />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formLoginRememberMeSignIn" className="mb-3">
                            <Col sm={12} md={12} lg={12} className="float-end">
                                <Button variant="primary" type="submit" className="float-end text-uppercase mt-2 mt-lg-0" disabled={this
                                .state.isLoading}>Submit</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            errorMsg: null,
            infoMsg: null,
            warnMsg: null,
            isLoading: true
        });


        ApiCall.post('account/forgottenPassword/' + this.state.loginEmail, null, null, {'relativeUrl':'/password'}).then(() => {

            this.setState({
                loginEmail:null,
                errorMsg: null,
                infoMsg: "Success! We have sent you an email with instructions.",
                warnMsg: null,
                isLoading:false
            });
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.',
                infoMsg: null,
                warnMsg: null,
                isLoading: false
            });
        });

    }
}

export default withRouter(withProgressBarContext(RecoverPassword));
