import React, {useState, useEffect, useContext} from 'react';
import {addDays, isSameYear, isSameMonth, isSameDay} from 'date-fns';
import {Row, Col, Card, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import UserHelper from '../Helper/UserHelper.js';
import ApiCall from '../Helper/ApiCall.js';
import {ProgressBarContext} from '../Provider/ProgressBarProvider.js';
import CalendarTimeSlotsLoader from '../Loader/CalendarTimeSlotsLoader.js';
import './CalendarSlotSelector.scss';


export default function CalendarSlotSelector(props) {
    const progressBar = useContext(ProgressBarContext);

    const getStartDate = () => {
        return props.startDate ? props.startDate : addDays(new Date(), 1);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState(null);
    const [selection, setSelection] = useState(getStartDate());
    const [timeSlots, setTimeSlots] = useState(null);
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
    const [dates, setDates] = useState(null);
    const [currentTimeZone, setCurrentTimeZone] = useState(null);
    const [allTimeZones, setAllTimeZones] = useState(null);

    const getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){
            a.push(new Date(d));
        }
        return a;
    }

    const isDatesEqualLight = (d1, d2) => {
        return isSameYear(d1, d2) && isSameMonth(d1, d2) && isSameDay(d1, d2);
    }

    const changeSessionTimeZone = (event) => {
        const value = event.value;
        ApiCall.put('calendar/timezone', {
            value
        }).then(() => {
            setCurrentTimeZone(event);
            selectDate(date);
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const getCurrentTimeZone = () => {
        ApiCall.get('calendar/timezone', null, null, null, {'X-Nemesis-Token': localStorage.getItem('token')}).then((response) => {
            if (response.headers['x-nemesis-token'] && localStorage.getItem('token') !== response.headers['x-nemesis-token']) {
                localStorage.setItem("token", response.headers['x-nemesis-token']);
            }
            setCurrentTimeZone({key:response.data.first, label:response.data.second});
            progressBar.dispatch({type: 'increment', progress: 40});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const getAllTimeZones = () => {
        ApiCall.get('calendar/timezones').then((response) => {
            let timeZones = [];
            response.data.forEach(function(timeZone, key){
                timeZones.push({value:timeZone.first,label:timeZone.second});
            });
            setAllTimeZones(timeZones);
            progressBar.dispatch({type: 'increment', progress: 40});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const changeSelection = (days) => {
        let sel = addDays(selection, days);
        let daylist = getDaysArray(sel, addDays(sel, 4));
        daylist.map((v)=>v.toISOString().slice(0,10)).join("");
        setSelection(sel);
        setDates(daylist);
    }

    const selectTimeSlot = (timeSlot) => {
        if (timeSlot.available && !timeSlot.past) {
            let oldSelects = props.singleSelect ? [] : [...selectedTimeSlots];
            let tsObject = {date: date, timeSlot: timeSlot};
            if (timeSlotsArrayIncludesObject(oldSelects, tsObject)) {
                oldSelects.splice(timeSlotsArrayIndexOfObject(oldSelects, tsObject), 1);
            } else {
                oldSelects.push(tsObject);
            }

            setSelectedTimeSlots(oldSelects);
            props.selectTimeSlot(oldSelects);
        }
    }

    const timeSlotsArrayIncludesObject = (tsArray, timeSlotObject) => {
        return tsArray.filter(e => isDatesEqualLight(e.date, timeSlotObject.date) && e.timeSlot.start===timeSlotObject.timeSlot.start && e.timeSlot.end===timeSlotObject.timeSlot.end).length > 0;
    }

    const timeSlotsArrayIndexOfObject = (tsArray, timeSlotObject) => {
        return tsArray.findIndex(e => isDatesEqualLight(e.date, timeSlotObject.date) && e.timeSlot.start===timeSlotObject.timeSlot.start && e.timeSlot.end===timeSlotObject.timeSlot.end);
    }

    const selectDate = (d) => {
        setIsLoading(true);
        progressBar.dispatch({type: 'update', progress: 20});
        let formattedDate = UserHelper.formatDate(d.getDate(),2)  + "-" + d.toLocaleString('en-us', { month: 'short' }) + "-" + d.getFullYear();
        setDate(d);
        ApiCall.get('calendar/', {date: formattedDate, granularity: props.granularity}).then((response) => {
            setTimeSlots(response.data);
            document.getElementById('timeslots-section').scrollIntoView({ behavior: "smooth" });
            progressBar.dispatch({type: 'update', progress: 100});
            setIsLoading(false);
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getCurrentTimeZone();
        getAllTimeZones();
    }, []);

    useEffect(() => {
        let d = date;
        if (!d) {
            let daylist = getDaysArray(selection, addDays(selection, 4));
            daylist.map((v)=>v.toISOString().slice(0,10)).join("");
            setDates(daylist);
            d = daylist[0];
        }

        selectDate(d);
    },[props.granularity]);

    const weekDayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ];

    return(
        <>
            <Row className="mt-3 text-center border-top">
                <Col lg={12} className="mt-3">
                    <h4>Select Date</h4>
                    <p>Select the desired date</p>
                </Col>
            </Row>
            <Row className="py-3 text-center">
                <Col lg={1} className="my-auto">
                    {selection && (selection.getTime() > getStartDate().getTime()) ?
                        <Button size="lg" variant="secondary" onClick={()=>changeSelection(-1)}><FontAwesomeIcon icon={"arrow-left"}/></Button>
                     : null}
                </Col>
                {dates ? dates.map(d=> {
                    return <Col sm={2} md={2} className="mt-3 mt-md-0" key={d}>
                        <Card className={"border-left-primary border-right-secondary cursor-pointer shadow-hover h-100 py-2 " + (isDatesEqualLight(date, d) ? "bg-primary text-white" : "")} onClick={()=>{selectDate(d)}}>
                           <Card.Body>
                               <Row className="no-gutters align-items-center">
                                   <Col lg={12} className="text-center">
                                       <div className="h6 mb-0 fw-bold text-gray-800">{weekDayNames[d.getDay()]}</div>
                                       <div className="h3 text-xs fw-bold text-uppercase mb-1">{d.getDate()}</div>
                                       <div className="h6 mb-0 fw-bold text-gray-800">{monthNames[d.getMonth()]}</div>
                                   </Col>
                               </Row>
                           </Card.Body>
                        </Card>
                     </Col>
                }) : null}
                <Col lg={1} className="my-auto">
                    <Button size="lg" variant="primary" onClick={()=>changeSelection(1)}><FontAwesomeIcon icon={"arrow-right"}/></Button>
                </Col>
            </Row>
            <Row className="mt-3 text-center border-top" id="timeslots-section">
                {date && currentTimeZone ?
                    <Col lg={12}>
                        <h4 className="mb-3 mt-3">Select time slot on {weekDayNames[date.getDay()]} {date.getDate()} {monthNames[date.getMonth()]}</h4>
                        <p>Time zone: {currentTimeZone.label}</p>
                        <div className="mx-auto my-4">
                            <Select name="sessionLocale" options={allTimeZones} classNamePrefix="react-select" closeMenuOnSelect={true} className="w-50 mx-auto react-select-container" value={currentTimeZone} onChange={changeSessionTimeZone}/>
                        </div>
                        <Row>
                            <Col lg={4} md={4}>
                                <h5>Morning(Europe)</h5>
                                {isLoading ?
                                    <CalendarTimeSlotsLoader numberOfRows={240/props.granularity}/>
                                : timeSlots && timeSlots['morning'].map(timeSlot=> {
                                    return <Card key={timeSlot.start} className={"cursor-pointer time-slot border-left-primary border-right-secondary shadow-hover py-2 mx-5 my-2 " + (timeSlot.past ? "disabled" : "") + (timeSlot.available ? "" : "bg-secondary text-black")+ (timeSlotsArrayIncludesObject(selectedTimeSlots, {date: date, timeSlot : timeSlot}) ? ' bg-primary text-white' : '')} onClick={()=>{selectTimeSlot(timeSlot)}}>
                                            {timeSlot.start + "-" + timeSlot.end}
                                        </Card>
                                })}
                            </Col>
                            <Col lg={4} md={4}>
                                <h5>Afternoon(Europe)</h5>
                                {isLoading ?
                                    <CalendarTimeSlotsLoader numberOfRows={240/props.granularity}/>
                                : timeSlots && timeSlots['afternoon'].map(timeSlot=> {
                                    return <Card key={timeSlot.start} className={"cursor-pointer time-slot border-left-primary border-right-secondary shadow-hover py-2 mx-5 my-2 " + (timeSlot.past ? "disabled" : "") + (timeSlot.available ? "": "bg-secondary text-black") + (timeSlotsArrayIncludesObject(selectedTimeSlots, {date: date, timeSlot : timeSlot}) ? ' bg-primary text-white' : '')} onClick={()=>{selectTimeSlot(timeSlot)}}>
                                            {timeSlot.start + "-" + timeSlot.end}
                                        </Card>
                                })}
                            </Col>
                           <Col lg={4} md={4}>
                                <h5>Evening(Europe)</h5>
                                {isLoading ?
                                    <CalendarTimeSlotsLoader numberOfRows={240/props.granularity}/>
                                : timeSlots && timeSlots['evening'].map(timeSlot=> {
                                    return <Card key={timeSlot.start} className={"cursor-pointer time-slot border-left-primary border-right-secondary shadow-hover py-2 mx-5 my-2 " + (timeSlot.past ? "disabled" : "") + (timeSlot.available ? "": "bg-secondary text-black") + (timeSlotsArrayIncludesObject(selectedTimeSlots, {date: date, timeSlot : timeSlot}) ? ' bg-primary text-white' : '')} onClick={()=>{selectTimeSlot(timeSlot)}}>
                                           {timeSlot.start + "-" + timeSlot.end}
                                        </Card>
                                })}
                            </Col>
                        </Row>
                    </Col>
                : null}
            </Row>
        </>
    );

}