import React from "react";
import {Toast} from 'react-bootstrap';
import Faq from './Faq.js';

export default function ContractorNotifications(props) {

    let toasts = [];

    if (props.isFriday) {
        toasts.push({id: 1, name: "Today is the last day of the week. Please don't forget to update your hours in the system"});
    }

    if (props.isLastDayOfMonth) {
        toasts.push({id: 2, name : "Today is the last day of the month. Please don't forget to update your hours in the system"});
    }

    const renderToasts = () => {
        if (toasts.length > 0) {
            return toasts.map(toast => {
                return (
                    <Toast className="seen w-100" animation={true} key={toast.id}>
                        <Toast.Header closeButton={false}>
                            <strong className="me-auto">Customer Service</strong>
                            <small>just now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <div>{toast.name}</div>
                            <p className="signature">
                                <span>Kind regards</span>
                                Customer Service
                            </p>
                        </Toast.Body>
                    </Toast>
                )
            })
        } else {
            return <Faq/>
        }
    }

    return (
        <>{ renderToasts() }</>
    );
}
