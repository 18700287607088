import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import {withProgressBarContext} from '../Provider/ProgressBarProvider.js';
import CmsPageProvider from '../cms/CmsPageProvider.js';
import CmsTemplate from '../cms/CmsTemplate.js';
import CmsSlot from '../cms/CmsSlot.js';
import GlobalMessagesProvider from '../Provider/GlobalMessagesProvider.js';

class PrivacyPage extends Component {
    constructor(props){
        super(props);

        this.handleReturnButtonClick = this.handleReturnButtonClick.bind(this);
    }

    componentDidMount() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 100});
    }

    render() {
        return (
            <CmsPageProvider props={{page:'privacy'}}>
                <GlobalMessagesProvider>
                    <CmsTemplate>
                        <main>
                            <Container fluid className="mt-3 mb-3 ps-0 pe-0">
                                <CmsSlot position="Section1"/>
                                <CmsSlot position="BottomContent"/>
                            </Container>
                        </main>
                    </CmsTemplate>
                </GlobalMessagesProvider>
            </CmsPageProvider>
        )
    }

    handleReturnButtonClick(event) {
        event.preventDefault();
        this.props.history.push('/');
    }
}
export default withProgressBarContext(PrivacyPage);