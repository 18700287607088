import React, {Component} from 'react';
import {format} from 'date-fns';
import {Row, Col, Table, Card, Button, Accordion} from 'react-bootstrap';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import CommonFunctions from '../../../Helper/CommonFunctions.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import AudioPlayer from 'react-h5-audio-player';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class ContractorInterviewTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            interviews: [],
            interviewValues: new Map(),
            isLoading: true,
            play: new Map(),
        };

        this.audio = new Audio()
        this.getInterviews = this.getInterviews.bind(this);
        this.getInterviewRequests = this.getInterviewRequests.bind(this);
        this.togglePlay = this.togglePlay.bind(this);
    }

    componentDidMount() {
        this.getInterviews(0);
        this.getInterviewRequests();
        this.audio.addEventListener('ended', () => this.setState({ play: false }));
    }

    componentWillUnmount() {
        this.audio.removeEventListener('ended', () => this.setState({ play: false }));
    }

    render() {
        let interviews, interviewRequests = null;
        let isManager = UserHelper.isManager();

        if (this.state.isLoading) {
            interviews = <TableSectionLoader colSpan={4} numberOfRows={5}/>
        } else {
            if (this.state.interviewRequests && this.state.interviewRequests.length>0) {
                let _this = this;
                interviewRequests = this.state.interviewRequests.map(function(interviewRequest, interviewRequestKey) {
                    let slots = interviewRequest.slots.map(function(slot, slotKey) {
                        return (
                            <tr key={slotKey}>
                                <td className="py-2">
                                    <div><FontAwesomeIcon icon="calendar" className="me-1 text-muted"/>{format(slot.startDateTime, "EEE, dd MMM")}</div>
                                    <div><FontAwesomeIcon icon="clock" className="me-1 text-muted"/>{format(slot.startDateTime, "HH:mm")} - {format(slot.endDateTime, "HH:mm")}</div>
                                </td>
                                <td><Button size="sm" variant="secondary" className="float-end" onClick={(e) => CommonFunctions.createInterviewFromInterviewRequest(slot.code, _this.props.progressBarContext, ()=>{_this.getInterviewRequests();_this.getInterviews(0);})}>Accept</Button></td>
                            </tr>
                        );
                    });
                    return (
                        <Col lg={12} md={12} sm={12} className="mb-2" key={interviewRequestKey}>
                            <Accordion defaultActiveKey={0}>
                                <Accordion.Item eventKey={interviewRequestKey}>
                                    <Accordion.Header>{interviewRequest.jobRequestName}</Accordion.Header>
                                    <Accordion.Body>
                                        <Table bordered striped hover responsive className="table-align-middle" id="interview-requests-table">
                                            <tbody>
                                                {slots}
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    );
                });
            }

            if (this.state.interviews && this.state.interviews.content && this.state.interviews.content.length) {
                interviews = this.state.interviews.content.map(function(interview, key) {
                    let audioPlayers = [];
                    if(interview.preparationUrls) {
                        interview.preparationUrls.forEach(preparationLink=> {
                            if (!isManager || interview.offer.user.code === UserHelper.getCustomerCode()) {
                                audioPlayers.push(
                                  <AudioPlayer
                                    key={preparationLink}
                                    volume={0.5}
                                    showFilledVolume={true}
                                    src={preparationLink}
                                    className="mb-3 rounded-2"
                                    onPlayError={(error)=>UserHelper.showErrorToast('Error playing the audio')}
                                  />);
                              }
                        });
                    }
                    return (
                        <React.Fragment key={interview.code}>
                            <tr key={interview.code + '1'}>
                                <td>{interview.offer && interview.offer.user ? interview.offer.user.displayName : ''}</td>
                                <td><a href={interview.link} target="_blank" rel="noopener noreferrer">{interview.link}</a></td>
                                <td>{format(interview.startDateTime, "EEE, dd MMM HH:mm")}</td>
                                <td>{format(interview.endDateTime, "EEE, dd MMM HH:mm")}</td>
                            </tr>
                            {audioPlayers.length > 0 ?
                                <tr key={interview.code + '-2'}>
                                    <td colSpan="4">
                                        {audioPlayers}
                                    </td>
                                </tr>
                            :""}
                        </React.Fragment>
                    );
                }, this);
            } else {
              interviews = <tr><td colSpan="4" className="text-center">You have no interviews</td></tr>;
            }
        }

        return (
            <>
                {this.state.interviewRequests && this.state.interviewRequests.length > 0 ?
                    <Row className="mt-3">
                        <Col sm={12} md={12} className="mt-3 mt-md-0">
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-uppercase fw-bold">
                                        Interview Requests
                                    </Card.Title>
                                    <p>Please select a suitable slot for interview</p>
                                    <Row>
                                        {interviewRequests}
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                :""}
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Your Interviews
                                </Card.Title>
                                <p>Here you can see your scheduled interviews</p>
                                <Table striped bordered hover responsive className="table-align-middle" id="interviews-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Link</th>
                                            <th>Start Date/Time</th>
                                            <th>End Date/Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { interviews }
                                    </tbody>
                                    {this.state.interviews && this.state.interviews.content && this.state.interviews.content.length > 0 ?
                                        <tfoot>
                                            <tr>
                                                <th colSpan="2">
                                                    <PaginationInfo page={this.state.interviews} />
                                                </th>
                                                <th colSpan="2">
                                                    <div className="float-end mb-3 w-50">
                                                        <Pagination page={this.state.interviews} handleChangePage={this.getInterviews} isLoading={this.state
                                                        .isLoading} />
                                                    </div>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    : null}
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    togglePlay = (src) => {
        if (this.audio.src !== src) {
            this.audio.src=src;
        }

        let play = this.state.play;
        play.set(src, !this.state.play.get(src));
        this.setState({ play: play }, () => {
          this.state.play.get(src) ? this.audio.play() : this.audio.pause();
        });
    }

    getInterviewRequests() {
        ApiCall.get('interview/request').then((response) => {
            this.setState({interviewRequests:response.data});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getInterviews(pageNumber) {
        ApiCall.get('interview/all', {page:pageNumber}).then((response) => {

            let interviewValues = new Map();
            response.data.content.forEach(interview => {
                interviewValues.set(interview.code, {"dateTime":interview.dateTime,"link":interview.link});
            });

            this.setState({
                interviews: response.data,
                interviewValues: interviewValues,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }
}

export default withProgressBarContext(ContractorInterviewTable);
