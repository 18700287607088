import React from "react";
import {InputGroup} from 'react-bootstrap';
import Select from 'react-select';
import FacetListerLoader from './FacetListerLoader.js';

export default function SearchSkillsFacet(props) {

    let facet = null;
    let skills = [];
    let selectedSkills = [];

    if (!props.isLoading) {
        if (props.searchPageData && props.searchPageData.facets) {
            facet = props.searchPageData.facets['skills'];
        }

        if (!facet) {
            return null;
        }

        if (facet.values) {
            facet.values.forEach(facetVal => {
                skills.push({value:facetVal.code, label:facetVal.name + "(" + facetVal.count + ")", url: facetVal.currentQuery.url});
                if (facetVal.selected) {
                    selectedSkills.push({value:facetVal.code, label:facetVal.name, url: facetVal.currentQuery.url});
                }
            })
        }
    }

    return (
        <React.Fragment>
            {props.isLoading ?
                <FacetListerLoader/>
            :
                <InputGroup>
                    <Select name="technologies" options={skills} placeholder="Technologies" value={selectedSkills}
                        closeMenuOnSelect={false} isMulti hasValue className="w-100 mb-2 react-select-container" classNamePrefix="react-select"
                        onChange={(value: ValueType, action: ActionType) => handleInputChange(value, action, facet.clearQuery ? facet.clearQuery.url : null,
                        props.updateUrl)}/>
                </InputGroup>
            }
        </React.Fragment>
    );
}

function handleInputChange(selectedOptions, currentOption, clearUrl, updateUrl) {

    if (currentOption.action === 'select-option') {
        updateUrl(null, null, currentOption.option.url)
    }

    if (currentOption.action === 'remove-value' || currentOption.action === 'pop-value' ) {
        updateUrl(null, null, currentOption.removedValue ? currentOption.removedValue.url : null)
    }

    if (currentOption.action === 'clear') {
        updateUrl(null, null, clearUrl);
    }
}
