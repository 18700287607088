import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import UserHelper from '../../../../components/Helper/UserHelper.js';
import ContractorInterviewTable from './ContractorInterviewTable.js';
import ClientInterviewTable from './ClientInterviewTable.js';
import SalesInterviewTable from './SalesInterviewTable.js';

class AccountInterviews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    render() {
        let isSales = UserHelper.isSales();
        let isContractor = UserHelper.isContractor();
        let isClient = UserHelper.isClient();

        return (
            <>
                {isClient ? <ClientInterviewTable/> : null}
                {isContractor ? <ContractorInterviewTable/> :null}
                {isSales ? <SalesInterviewTable/> :null}
            </>
        )
    }
}

export default withRouter(AccountInterviews);
