import React, {Component} from "react";
import ApiCall from '../Helper/ApiCall.js';
import {withProgressBarContext} from '../Provider/ProgressBarProvider.js';

export const PageContext = React.createContext('page');

class CmsPageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: null,
            isLoading: true
        }
    }

    componentDidMount() {
        this.props.progressBarContext.dispatch({type: 'increment', progress: 5});
        let pageName = 'starting';
        if (this.props && this.props.props && this.props.props.page) {
            pageName = this.props.props.page;
        }
        ApiCall.get('cmsPage/' + pageName + "/headless", {
        }).then(response => {
            this.setState({
                isLoading: false,
                pageData: response.data
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 15});
        }).catch(error => {
            this.setState({
                isLoading: false,
                pageData: {globalMessage: 'could not load page data from the server'}
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 0});
        });
    }

    render() {
        return (
            <>
            {
                /*
                <CookieConsent
                  location="top"
                  buttonText="I accept"
                  style={{ background: "#002e5b" }}
                  buttonStyle={{ color: "#002e5b", background:"#fde428", padding:'3px 20px', borderRadius:"0.25rem" }}
                >
                  This website uses cookies to enhance the user experience.
                </CookieConsent>
                */
                }
                <PageContext.Provider value={{
                    state: this.state
                }}>
                    {this.props.children}
                </PageContext.Provider>
            </>
        );
    }
}

export default withProgressBarContext(CmsPageProvider);
