import React, {Component} from 'react';
import {Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import Select from 'react-select';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';

class AccountClientNewEmployee extends Component {
    constructor(props){
        super(props);

        this.state = {
            email:'',
            firstName:'',
            lastName:'',
            department:null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitAddEditSkillCompetenceForm = this.handleSubmitAddEditSkillCompetenceForm.bind(this);
        this.clearEmployeeForm = this.clearEmployeeForm.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.editSkillCompetence !== prevProps.editSkillCompetence) {
            this.setState({
                    skillCompetenceForm: {
                        code: this.props.editSkillCompetence ? this.props.editSkillCompetence.code : null,
                        name: this.props.editSkillCompetence ? this.props.editSkillCompetence.name : "",
                        level: this.props.editSkillCompetence ? this.props.editSkillCompetence.level : "",
                        years: this.props.editSkillCompetence ? this.props.editSkillCompetence.years : 0,
                    }
                }
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <Form id="skillCompetenceForm" onSubmit={this.handleSubmitAddEditSkillCompetenceForm}>
                    <Form.Group as={Row} controlId="name" className="mb-3">
                        <Col md={3} lg={3} className="ms-0 ps-0 pe-0">
                            <Form.Group as={Col} controlId="email" className="ms-0">
                                <InputGroup className="ms-0">
                                    <Form.Control name="email" type="text" placeholder="Email" defaultValue={this.state.email || ""}
                                    required onChange={this.handleInputChange} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col md={3} lg={3} className="ms-0">
                            <Form.Group as={Row} controlId="companyDirectorFirstName" className="mb-3">
                                <Form.Group as={Col} controlId="firstName" className="ms-0 ps-0">
                                    <InputGroup>
                                        <Form.Control name="firstName" type="text" placeholder="First Name" value={this.state.firstName || ""}
                                        required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} controlId="lastName" className="ms-0 ps-0">
                                    <InputGroup>
                                        <Form.Control name="lastName" type="text" placeholder="Last Name" value={this.state.lastName || ""}
                                        required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Form.Group>
                            </Form.Group>
                        </Col>
                        <Col md={3} lg={3} className="ms-0">
                            <Form.Group as={Row} controlId="defaultRecipients" className="mb-3">
                                <InputGroup>
                                    <Select name="department" options={this.props.departmentValues} value={this.state.department}
                                        className="w-100" required onChange={this.handleInputChange}/>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col md={1} lg={1}>
                            <Button variant="primary" type="submit" className="float-end text-uppercase">Save</Button>
                        </Col>
                        <Col md={2} lg={2} className="mt-2">
                            <Button variant="link" onClick={() =>this.props.cancelNewClientForm()}>Cancel</Button>
                        </Col>
                    </Form.Group>
                </Form>
            </React.Fragment>
        )
    }

    handleInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                this.setState({
                    [name]: value
                });
            } else {
                this.setState({
                    department: event.value
                });
            }
        }
    }

    handleSubmitAddEditSkillCompetenceForm(event) {
        event.preventDefault();
        if (this.props.editSkillCompetence) {
            ApiCall.put('employee', {}).then(() => {
                this.clearEmployeeForm();

                UserHelper.showSuccessToast("Your skill competence was updated.");

                this.props.getEmployees();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        } else {
            ApiCall.put('businessCustomer', {
                username:this.state.email,
                firstName:this.state.firstName,
                lastName:this.state.lastName,
                department:{
                    code:this.state.department
                },
                businessUserGroups:['clientgroup']
            }).then(() => {
                this.clearEmployeeForm();

                UserHelper.showSuccessToast("Your skill competence was added.");

                this.props.getEmployees();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }
    }

    clearEmployeeForm() {
        this.setState({
            email:null,
            firstName:null,
            lastName:null,
            department:null
        });
    }
}

export default AccountClientNewEmployee;
