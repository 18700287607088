import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import UserHelper from '../../../Helper/UserHelper.js';
import {withRouter} from 'react-router-dom';
import ContractorNotifications from './ContractorNotifications.js';
import SalesNotifications from './SalesNotifications.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';
import './AccountHome.scss';

class AccountHome extends Component {

    componentDidMount() {
        this.props.progressBarContext.dispatch({type: 'increment', progress: 20});
        this.props.progressBarContext.dispatch({type: 'update', progress: 100});
    }

    render() {
        let isContractor = UserHelper.isContractor();
        let isSuperSales = UserHelper.isSuperSales();

        return (
            <Row className="mt-3">
                <Col className="inbox">
                    {isContractor ? <ContractorNotifications isFriday={this.isFriday()} isLastDayOfMonth={this.isLastDayOfMonth()}/> : null}
                    {isSuperSales ? <SalesNotifications isLastDayOfMonth={this.isLastDayOfMonth()} isFirstDayOfMonth={this.isFirstDayOfMonth()}/> : null}
                </Col>
            </Row>
        )
    }

    isLastDayOfMonth() {
        var test = new Date();
        test.setDate(test.getDate() + 1);
        return test.getDate() === 1;
    }

    isFirstDayOfMonth() {
        var test = new Date();
        return test.getDate() === 1;
    }

    isFriday() {
        return new Date().getDay() === 5;
    }
}

export default withRouter(withProgressBarContext(AccountHome));
