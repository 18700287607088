import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Modal, Button} from 'react-bootstrap';

class AccountConfirmModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null
        };
    }

    render() {
        return (
            <>
                <Modal centered size="lg" show={this.props.show} onHide={this.props.handleModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-uppercase fw-bold">{this.props.header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.body}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.handleModalClose}>No</Button>
                        <Button variant="primary" onClick={this.props.performAction}>Yes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default withRouter(AccountConfirmModal);
