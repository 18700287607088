import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Table, Card, Form, ButtonGroup, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import {ProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

export default function ContractorAssignmentTable(props) {

    const progressBar = useContext(ProgressBarContext);

    const [assignments, setAssignments] = useState([]);
    const [assignmentValues, setAssignmentValues] = useState(new Map());
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAssignmentsForCurrentUser(0);
    }, []);

    const getAssignmentClass = (assignment) => {
        if (new Date(assignment.activeTo) < new Date()) {
            return "table-danger";
        } else {
            if (new Date(assignment.activeTo) < new Date(new Date().setDate(new Date().getDate()+14))) {
                return "table-warning";
            } else {
                return "table-success";
            }
        }
    }

    const handleAssignmentInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const code = target.dataset.id;

        let newAssignmentValues = assignmentValues;
        let specificAssignmentValues = newAssignmentValues.get(code);
        specificAssignmentValues[name] = value;

        newAssignmentValues.set(code, specificAssignmentValues);

        setAssignmentValues(newAssignmentValues);
    }

    const getAssignmentsForCurrentUser = (pageNumber) => {
        ApiCall.get('assignment/user', {page:pageNumber}).then((response) => {
            let assignmentValues = new Map();
            response.data.content.forEach(assignment => {
                assignmentValues.set(assignment.code, {'lastMonthHours':0});
            });
            setAssignments(response.data);
            setAssignmentValues(assignmentValues);
            setIsLoading(false);
            progressBar.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            setIsLoading(false);
        });
    }

    const saveLastMonthHours = (code) => {
        progressBar.dispatch({type: 'update', progress: 20});
        let newAssignmentValues = assignmentValues;
        let specificAssignmentValues = newAssignmentValues.get(code);

        ApiCall.put('assignment/hours/' + code, null, {lastMonthHours:specificAssignmentValues.lastMonthHours}).then((response) => {
            UserHelper.showSuccessToast('Success.');
            progressBar.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    let isIndividualContractor = UserHelper.isIndividualContractor();

    let assignmentsJSX = null;

    if (isLoading) {
        assignmentsJSX = <TableSectionLoader colSpan={8} numberOfRows={5}/>
    } else {
        if (assignments && assignments.content && assignments.content.length) {
            assignmentsJSX = assignments.content.map(function(assignment, key) {
                return (
                    <tr key={key} className={getAssignmentClass(assignment)}>
                        <td>{assignment.user.displayName}</td>
                        <td>{assignment.manager && assignment.manager.department && assignment.manager.department.organization ? assignment.manager
                        .department.organization.legalName : ''}</td>
                        <td>{assignment.activeFrom}</td>
                        <td>{assignment.activeTo}</td>
                        {isIndividualContractor ? <td>&euro;{assignment.contractorHourlyRate}</td>: null}
                        <td>
                            <Form.Control name="lastMonthHours" type="number" data-id={assignment.code} defaultValue={assignment
                                .lastMonthHours} style={{ width: "80px" }} onChange={handleAssignmentInputChange}></Form.Control>
                        </td>
                        <td>
                            <ButtonGroup>
                                <Button className="text-uppercase me-2" variant="primary" onClick={()=>saveLastMonthHours(assignment.code)}><FontAwesomeIcon icon="floppy-disk" /></Button>
                            </ButtonGroup>
                        </td>
                    </tr>);
            }, this);
        } else {
          assignmentsJSX = <tr><td colSpan="8" className="text-center">You have no assignments</td></tr>;
        }
    }

    return (
        <>
            <Row className="mt-3">
                <Col sm={12} md={12} className="mt-3 mt-md-0">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-uppercase fw-bold">
                                Your Assignments
                            </Card.Title>
                            <p>Here you can see your assignments</p>
                            <Table bordered hover responsive className="table-align-middle">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Organization</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        {isIndividualContractor ? <th>Rate</th>:null}
                                        <th>Hours</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { assignmentsJSX }
                                </tbody>
                                {assignments && assignments.content && assignments.content.length > 0 ?
                                    <tfoot>
                                        <tr>
                                            <th colSpan="4">
                                                <PaginationInfo page={assignments} />
                                            </th>
                                            <th colSpan="4">
                                                <div className="float-end w-50">
                                                    <Pagination page={assignments} handleChangePage={getAssignmentsForCurrentUser} isLoading={isLoading} />
                                                </div>
                                            </th>
                                        </tr>
                                    </tfoot>
                                :null}
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
