import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import qs from 'qs';
import ApiCall from '../../../Helper/ApiCall';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

export const ListerContext = React.createContext();

class ResourceListerPageProvider extends Component {
    constructor(props) {

        super(props);

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        let searchPreferences = JSON.parse(localStorage.getItem("searchPreferences"));

        this.defaultPageSize = searchPreferences && searchPreferences.size ? searchPreferences.size : 12;
        this.defaultSort = searchPreferences && searchPreferences.sort ? searchPreferences.sort : '_score,DESC';
        this.defaultLayout = searchPreferences && searchPreferences.layout ? searchPreferences.layout : 'grid';

        this.state = {
            searchPageData: null,
            layout: queryParams.layout ? queryParams.layout : "grid",
            isLoading: true,
            searchTerm:queryParams.q ? queryParams.q.split(":")[0] : "",
            defaultPageSize: 12
        }

        this.getResources = this.getResources.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
    }

    componentDidMount() {
        this.getResources();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.getResources();
        }
    }

    render() {
        return (
            <ListerContext.Provider value={{
                state: this.state,
                defaultPageSize: this.defaultPageSize,
                getResources: this.getResources,
                updateUrl: this.updateUrl
            }}>
                {this.props.children}
            </ListerContext.Provider>
        );
    }

    updateUrl(pageNumber, layout, q) {
        this.setState({
            layout: layout ? layout : this.state.layout,
            isLoading: true
        });

        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

        let searchParams = {
            q: q == null ? (queryParams.q ? queryParams.q : null) : q,
            page: pageNumber == null ? (queryParams.page ? queryParams.page : 0) : pageNumber,
            size: queryParams.size ? queryParams.size : this.defaultPageSize,
            sort: queryParams.sort ? queryParams.sort : this.defaultSort,
            layout: layout == null ? (queryParams.layout ? queryParams.layout : this.defaultLayout) : layout,
        };

        localStorage.setItem("searchPreferences", JSON.stringify(searchParams));

        this.props.history.replace({
            search: '?' + qs.stringify(searchParams)
        });

        return searchParams;
    }

    getResources(pageNumber, layout, q) {
        let searchParams = this.updateUrl(pageNumber, layout, q);
        ApiCall.get('search', {
            projection: 'io.nemesis.platform.module.commerce.facade.search.dto.ProductFacetSearchPageDtoDefinition',
            type: 'resource',
            queryName: null,
            q: searchParams.q,
            page: searchParams.page,
            size: searchParams.size,
            sort: searchParams.sort,
            layout: searchParams.layout
        }).then(response => {
            if (response.data.keywordRedirectUrl) {
                this.props.history.push(response.data.keywordRedirectUrl);
            }
            this.setState({
                searchPageData: response.data,
                layout: searchParams.layout,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            console.log(error);

            this.setState({
                searchPageData: null,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 0});
        });
    }
}

export default withRouter(withProgressBarContext(ResourceListerPageProvider));
