import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Card, Form, Row, Col, OverlayTrigger, Tooltip, InputGroup, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import Select from 'react-select';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountCompanyDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            companyDetails: {},
            recipients: [],
            clientEmployees: [],
            accountants:[]
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.downloadNda = this.downloadNda.bind(this);
        this.handleSubmitUpdateCompanyForm = this.handleSubmitUpdateCompanyForm.bind(this);
        this.getClientEmployees = this.getClientEmployees.bind(this);
    }

    componentDidMount() {
        if (this.props.location && this.props.location.hash) {
            let organizationCode = this.props.location.hash.substring(1);
            this.getClientEmployees(organizationCode);
        }
    }

    render() {
        let countryOptions = this.props.countries.map(function(country, key){
            return <option key={key} value={country.code}>{country.name}</option>;
        });

        let isSuperSales = UserHelper.isSuperSales();

        return (
        <>
            <Card>
                <Card.Body>
                    <Card.Title className="text-uppercase fw-bold">
                        Legal Information
                    </Card.Title>

                    <p>Please use this form to update your company details</p>

                    <p className="text-warning">Fields marked with * are required</p>

                    <Form onSubmit={this.handleSubmitUpdateCompanyForm}>
                        <Form.Group as={Row} controlId="companyName" className="mb-3">
                            <Form.Label column lg={3}>
                                Name*
                            </Form.Label>
                            <Col lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="building" /></InputGroup.Text>
                                    <Form.Control name="companyName" type="text" placeholder="Legal Entity Name" required defaultValue={this.props.companyDetails
                                    .companyName} onChange={this.handleInputChange} />
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="companyRegisterNumber" className="mb-3">
                            <Form.Label column lg={3}>
                                Number*
                            </Form.Label>
                            <Col lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="paragraph" /></InputGroup.Text>
                                    <Form.Control name="companyRegisterNumber" type="text" placeholder="Number" required defaultValue={this
                                    .props.companyDetails.companyRegisterNumber} onChange={this.handleInputChange} />
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="companyDirectorFirstName" className="mb-3">
                            {this.props.canBeIndividualContractor?
                                <OverlayTrigger
                                   placement="top"
                                   delay={{ show: 250, hide: 400 }}
                                   overlay={<Tooltip id="button-tooltip-2">Leave empty if you an individual.</Tooltip>}>
                                    <Form.Label column lg={3}>
                                        <b>Director</b>
                                    </Form.Label>
                                </OverlayTrigger>
                            :
                                <Form.Label column lg={3}>
                                    Director*
                                </Form.Label>
                            }
                            <Form.Group as={Col} controlId="companyDirectorFirstName" className="mb-0">
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                        <Form.Control name="companyDirectorFirstName" type="text" placeholder="First Name"
                                        value={this.props.companyDetails.companyDirectorFirstName || ''} onChange={this.handleInputChange} />
                                    </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} controlId="companyDirectorLastName" className="mb-0">
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                        <Form.Control name="companyDirectorLastName" type="text" placeholder="Last Name"
                                        value={this.props.companyDetails.companyDirectorLastName || ''} onChange={this.handleInputChange} />
                                    </InputGroup>
                            </Form.Group>
                        </Form.Group>
                        {isSuperSales ?
                            <Form.Group as={Row} controlId="defaultRecipients" className="mb-3">
                                <Form.Label column lg={3}>
                                    Default recipients
                                </Form.Label>
                                <Form.Group as={Col} controlId="defaultRecipientsInputField" className="mb-0">
                                    <InputGroup>
                                        <Select name="recipients" options={this.state.clientEmployees} value={this.props.companyDetails.recipients}
                                        closeMenuOnSelect={false} isMulti onChange={this.handleInputChange}
                                        classNamePrefix="react-select" className="w-100 react-select-container"/>
                                    </InputGroup>
                                </Form.Group>
                            </Form.Group>
                        :null}
                        {isSuperSales ?
                            <Form.Group as={Row} controlId="accountants" className="mb-3">
                                <Form.Label column lg={3}>
                                    Accountants
                                </Form.Label>
                                <Form.Group as={Col} controlId="accountantsInputField" className="mb-0">
                                    <InputGroup>
                                        <Select name="accountants" options={this.state.accountants} value={this.props.companyDetails.accountants}
                                        closeMenuOnSelect={false} isMulti onChange={e => this.handleSelectInputChange('accountants', e)}
                                        classNamePrefix="react-select" className="w-100 react-select-container"/>
                                    </InputGroup>
                                </Form.Group>
                            </Form.Group>
                        :null}

                        <Card.Title className="text-uppercase fw-bold pt-4">
                            Address
                        </Card.Title>

                        <Form.Group as={Row} controlId="companyAddressLine1" className="mb-3">
                            <Form.Label column lg={3}>
                                Address
                            </Form.Label>
                            <Col lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="location-dot" /></InputGroup.Text>
                                    <Form.Control name="companyAddressLine1" type="text" placeholder="Address" defaultValue={this.props.companyDetails
                                    .companyAddressLine1}
                                    onChange={this.handleInputChange} />
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="companyAddressLine2" className="mb-3">
                            <Form.Label column lg={3}>
                                Address
                            </Form.Label>
                            <Col lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="location-dot" /></InputGroup.Text>
                                    <Form.Control name="companyAddressLine2" type="text" placeholder="Address" defaultValue={this.props.companyDetails
                                    .companyAddressLine2}
                                    onChange={this.handleInputChange} />
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="companyAddressCity" className="mb-3">
                            <Form.Label column lg={3}>
                                City
                            </Form.Label>
                            <Col lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="city" /></InputGroup.Text>
                                    <Form.Control name="companyAddressCity" type="text" placeholder="City" defaultValue={this.props.companyDetails.companyAddressCity}
                                    onChange={this.handleInputChange} />
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="companyAddressPostCode" className="mb-3">
                            <Form.Label column lg={3}>
                                Post Code
                            </Form.Label>
                            <Col lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="address-card" /></InputGroup.Text>
                                    <Form.Control name="companyAddressPostCode" type="text" placeholder="PostCode" defaultValue={this.props.companyDetails.companyAddressPostCode}
                                    onChange={this.handleInputChange} />
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="companyAddressCountryCode" className="mb-3">
                            <Form.Label column lg={3}>
                                Country
                            </Form.Label>
                            <Col lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="globe" /></InputGroup.Text>
                                    <Form.Select name="companyAddressCountryCode" value={this.props.companyDetails.companyAddressCountryCode ? this.props.companyDetails
                                    .companyAddressCountryCode : ''} onChange={this.handleInputChange}>
                                        { countryOptions }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formUpdateProfile" className="mb-3">
                            <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                <Button variant="primary" type="submit" className="float-end text-uppercase">Save updates</Button>
                                {isSuperSales ? <Button variant="secondary" type="button" onClick={(e) => this.downloadNda()} className="float-end text-uppercase me-1">Get NDA</Button> : ""}
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </>
        )
    }

    handleSelectInputChange(id, event) {
        let newVals = [];
        event.forEach(sel => {
            newVals.push({value:sel.value, label:sel.label});
        });
        let companyDetails = this.props.companyDetails;
        companyDetails[id] = newVals;
        this.setState({'companyDetails' : companyDetails});
    }

    handleInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                let companyDetails = this.props.companyDetails;
                companyDetails[name] = value;
                this.setState({'companyDetails' : companyDetails});
            } else {
                let newClientEmployees = [];
                event.forEach(sel => {
                    newClientEmployees.push({value:sel.value, label:sel.label});
                });
                let companyDetails = this.props.companyDetails;
                companyDetails['recipients'] = newClientEmployees;
                this.setState({'companyDetails' : companyDetails});
            }
        }
    }

    downloadNda() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        this.setState({downloadStarted:true});

        let country = this.props.countries.find(country => {
            return country.code === this.props.companyDetails.companyAddressCountryCode
        });

        let company = {
            "@class": "io.nemesis.platform.facade.location.dto.OrganizationDto",
            legalName: this.props.companyDetails.companyName,
            vatCode: this.props.companyDetails.companyRegisterNumber,
            contactPoint: {
                "@class":"io.nemesis.platform.facade.user.dto.AddressDto",
                firstName: this.props.companyDetails.companyDirectorFirstName,
                lastName: this.props.companyDetails.companyDirectorLastName,
                line1: this.props.companyDetails.companyAddressLine1,
                line2: this.props.companyDetails.companyAddressLine2,
                town: this.props.companyDetails.companyAddressCity,
                postalcode: this.props.companyDetails.companyAddressPostCode,
                country: country
            }
        };

        fetch(process.env.REACT_APP_FACADE_URL + 'client-organization/nda/', {
            method: "POST",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/json",
              "X-Nemesis-Token":localStorage.getItem("token")
            },
            body: JSON.stringify(company),
        }).then(response => {
            this.setState({downloadStarted:false});
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-NDA.pdf';
                    a.click();
                });
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    getClientEmployees(code) {
        let url = 'supreme-customer/clientgroup';
        if (code) {
            url = 'businessCustomer/forOrganization/' + code;
        }

        ApiCall.get(url, {
            projection: "supreme.contractors.backend.facade.dto.BasicNameableDtoDefinition"
        }).then((response) => {
            let clientEmployees = [];
            response.data.content.forEach(r => {
                clientEmployees.push({value: r.code, label: r.displayName});
            });

            this.setState({
                clientEmployees: clientEmployees,
                accountants: clientEmployees,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    handleSubmitUpdateCompanyForm(event) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        event.preventDefault();

        let country = this.props.countries.find(country => {
            return country.code === this.props.companyDetails.companyAddressCountryCode
        });

        let recipients = [];
        if (this.props.companyDetails.recipients) {
            this.props.companyDetails.recipients.forEach(customer=> {
                recipients.push({code:customer.value});
            });
        }

        let accountants = [];
        if (this.props.companyDetails.accountants) {
            this.props.companyDetails.accountants.forEach(customer=> {
                accountants.push({code:customer.value});
            });
        }

        let company = {
            "@class": "io.nemesis.platform.facade.location.dto.OrganizationDto",
            code: this.props.companyDetails.companyCode,
            legalName: this.props.companyDetails.companyName,
            vatCode: this.props.companyDetails.companyRegisterNumber,
            defaultRecipients: recipients,
            accountants: accountants,
            contactPoint: {
                "@class":"io.nemesis.platform.facade.user.dto.AddressDto",
                code: this.props.companyDetails.companyAddressCode,
                firstName: this.props.companyDetails.companyDirectorFirstName,
                lastName: this.props.companyDetails.companyDirectorLastName,
                line1: this.props.companyDetails.companyAddressLine1,
                line2: this.props.companyDetails.companyAddressLine2,
                town: this.props.companyDetails.companyAddressCity,
                postalcode: this.props.companyDetails.companyAddressPostCode,
                email: this.props.companyDetails.companyAddressEmail,
                country: country
            }
        };

        let url = this.props.url;
        if (!url) {
            url = "organization";
            let isSales = UserHelper.isSales();

            if (isSales) {
                url = "client-organization";
            }
        }

        ApiCall.put(url, company, {customerCode:this.props.customerCode}).then((response) => {
            UserHelper.showSuccessToast("Your organization has been updated");
            this.setState({
                companyDetailsValid : company.legalName && company.vatCode && company.contactPoint && company.contactPoint.firstName &&
                company.contactPoint.lastName && company.contactPoint.line1 && company.contactPoint.town && company.contactPoint.postalCode
                && company.contactPoint.country && company.contactPoint.country.code
            });
            window.scrollTo(0,0);
            if (this.props.submitCallback) {
                this.props.submitCallback({responseData: response.data, company:company});
            }
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }
}

export default withRouter(withProgressBarContext(AccountCompanyDetails));
