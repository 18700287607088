import React, {useReducer} from "react";
import LoadingBar from 'react-top-loading-bar';
import UserHelper from '../Helper/UserHelper.js';

export const ProgressBarContext = React.createContext();

export default function ProgressBarProvider(props) {
    const progressReducer = (state, action) =>  {
        switch (action.type) {
            case 'increment': {
              return {progress: state.progress + action.progress};
            }
            case 'update': {
              return {progress: action.progress};
            }
            default: {
              throw new Error(`Unhandled action type: ${action.type}`);
            }
        }
    }

    const [state, dispatch] = useReducer(progressReducer, {progress: 0})

    return (
        <>
            <LoadingBar color={UserHelper.getTheme()==='dark' ? 'var(--bs-secondary)' : 'var(--bs-primary)'} progress={state.progress}/>
            <ProgressBarContext.Provider value={{
                state, dispatch
            }}>
                {props.children}
            </ProgressBarContext.Provider>
        </>
    );
}

const withProgressBarContext = (Component) => {
    return (props) => (
        <ProgressBarContext.Consumer>
                {context => <Component {...props} progressBarContext={context} />}
        </ProgressBarContext.Consumer>
    )
}

export {withProgressBarContext}
