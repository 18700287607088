import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ApiCall from '../../../Helper/ApiCall.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Modal, Form, InputGroup, Button} from 'react-bootstrap';

class AccountResourceNotesModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null,
            description: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmitNotes = this.handleSubmitNotes.bind(this);
    }

    render() {
        return (
            <>
                {this.props.resource ?
                    <Modal centered size="lg" show={this.props.show} onHide={this.props.handleResourceNotesModalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-uppercase fw-bold">{this.props.resource.displayName} Add Notes</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon="file" /></InputGroup.Text>
                                <Form.Control name="description" as="textarea" placeholder="Notes" value={this.state.description} rows={5}
                                required onChange={this.handleInputChange} />
                            </InputGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className="text-uppercase" onClick={this.handleSubmitNotes}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                :null}
            </>
        )
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            description: value
        });
    }

    handleSubmitNotes() {
        this.setState({isLoading:true});
        ApiCall.post('timeline/' + this.props.resource.code, {
            note: this.state.description
        }).then(() => {
            this.props.handleResourceNotesModalClose();
            this.setState({isLoading:false});
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.',
                isLoading:false
            });
        });
    }
}

export default withRouter(AccountResourceNotesModal);
