import React, {Component} from 'react';
import {Row, Col, Modal, Button, Form, Alert} from 'react-bootstrap';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';

class OrderSettingsModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null,
            orderPrefix: null,
            defaultReasonForVatAbsence: null
        };

        this.isFormValid = this.isFormValid.bind(this);
        this.handleSubmitOrderSettings = this.handleSubmitOrderSettings.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    render() {
        let modalBody = null;

        if (this.state.isLoading) {
            modalBody = "Loading...";
        } else {
            modalBody = <React.Fragment>
                {this.state.errorMsg &&
                <Alert variant="danger">{this.state.errorMsg}</Alert>
                }

                <Row className="show-grid">
                    <Col xs={12} sm={8}>
                        <Form>
                            <Form.Group controlId="orderPrefix">
                                <Form.Label>
                                    Order Prefix*
                                </Form.Label>
                                <Form.Control name="orderPrefix" type="text" placeholder="Order Prefix" onChange={this.handleInputChange}
                                defaultValue={this.props.orderSettings ? this.props.orderSettings.orderPrefix : ''} />
                            </Form.Group>
                            <Form.Group controlId="defaultReasonForVatAbsence">
                                <Form.Label>
                                    Default reason for VAT absence
                                </Form.Label>
                                <Form.Control name="defaultReasonForVatAbsence" as="textarea" rows="3" placeholder="Default reason for VAT absence"
                                onChange={this.handleInputChange}
                                defaultValue={this.props.orderSettings ? this.props.orderSettings.defaultReasonForVatAbsence : ''}/>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>;
        }

        return (
            <Modal centered size="lg" show={this.props.show} onHide={this.props.handleOnOrderSettingsModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-uppercase fw-bold">Orders Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalBody}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="text-uppercase" onClick={this.handleSubmitOrderSettings}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    handleRatingChange(newValue) {
        this.setState({
            customerReviewRating: newValue
        });
    }

    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'orderPrefix') {
            value = value.toUpperCase();
            target.value = value;
        }

        this.setState({
            [name]: value
        });
    }

    isFormValid() {
        let isSales = UserHelper.isSales();
        if (!this.state.orderPrefix && !isSales) {
            this.setState({
                errorMsg: "Fields marked * are required"
            });

            return false;
        } else {
            this.setState({
                errorMsg: null
            });

            return true;
        }
    }

    handleSubmitOrderSettings() {
        if (this.isFormValid()) {
            ApiCall.post('supremeOrder/settings', {
                orderPrefix: this.state.orderPrefix,
                defaultReasonForVatAbsence: this.state.defaultReasonForVatAbsence
            }, null).then(() => {
                UserHelper.showSuccessToast("Success");
                this.props.handleOnOrderSettingsModalClose();
            }).catch(error => {
                this.setState({
                    errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.'
                });
            });
        }
    }
}

export default OrderSettingsModal;
