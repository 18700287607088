import React, {Component} from 'react';
import {ButtonGroup, Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import qs from 'qs';
import FacetListerLoader from '../Account/View/AccountSearch/FacetListerLoader.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class LayoutOptions extends Component {
    constructor(props){
        super(props);
        this.state = {
            layout: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).layout
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.page !== prevProps.page) {
            this.setState({
                layout: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).layout
            });
        }
    }

    render() {
        if(!this.props.page || this.props.page.totalElements < 1) {
            return null;
        }

        return (
            <React.Fragment>
                {this.props.isLoading ?
                    <FacetListerLoader contentWidth={85}/>
                :
                    <ButtonGroup aria-label="Layout">
                      <Button variant={this.state.layout==='grid' ? 'primary' : "highlight-hover btn-outline-primary card-bg"} onClick={()=>this.handleChange('grid')}><FontAwesomeIcon icon={"table-cells"}/></Button>
                      <Button variant={this.state.layout==='list' ? 'primary' : "highlight-hover btn-outline-primary card-bg"} onClick={()=>this.handleChange('list')}><FontAwesomeIcon icon={"list"}/></Button>
                    </ButtonGroup>
                }
            </React.Fragment>
        )
    }

    handleChange(type) {

        this.setState({
            layout: type
        });

        if (!this.props.isLoading) {
            this.props.handleGetResources(null, type, null);
        }
    }
}

export default withRouter(LayoutOptions);
