import React, {Component} from 'react';
import {Container, Col, Row, Card, Button} from 'react-bootstrap';

import CmsPageProvider from '../../cms/CmsPageProvider.js';
import CmsTemplate from '../../cms/CmsTemplate.js';
import CmsSlot from '../../cms/CmsSlot.js';
import {withProgressBarContext} from '../../Provider/ProgressBarProvider.js';

class ErrorNotFoundPage extends Component {
    constructor(props){
        super(props);

        this.handleReturnButtonClick = this.handleReturnButtonClick.bind(this);
    }

    componentDidMount() {
        this.props.progressBarContext.dispatch({type: 'update', progress: 100});
    }

    render() {
        return (
            <CmsPageProvider props={{page:'notFoundPage'}}>
                <CmsTemplate>
                    <main>
                        <Container fluid className="mt-3 mb-3 ps-0 pe-0">
                            <Row className="mt-4 mb-4">
                                <Col sm={12} className="text-center">
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0 fw-bold">Not found!</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <p>Could not find page for current URL.</p>
                                            <Button variant="secondary" type="button" className="text-uppercase" onClick={this.handleReturnButtonClick}>Return to Home</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <CmsSlot position="BottomContent"/>
                        </Container>
                    </main>
                </CmsTemplate>
            </CmsPageProvider>
        )
    }

    handleReturnButtonClick(event) {
        event.preventDefault();
        this.props.history.push('/');
    }
}

export default withProgressBarContext(ErrorNotFoundPage);