import React, { useState } from "react";
import useIdleTimeout from './useIdleTimeout.js';
import SessionTimeoutModal from "./SessionTimeoutModal";
import UserHelper from './UserHelper.js';
import ApiCall from './ApiCall.js';
const createHistory = require("history").createBrowserHistory;

let countdownInterval;
let timeout;

const SessionTimeout = ({isAuthenticated, logOut, waitBeforeLogout, waitBeforePrompt}) => {

    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);

    const onPrompt = () => {
        const delay = 1000 * 1;
        if (isAuthenticated && !timeoutModalOpen) {
            timeout = setTimeout(() => {
                let countDown = waitBeforePrompt;
                setTimeoutModalOpen(true);
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout(true);
                    }
                }, 1000);
            }, delay);
        }
    };

    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };
    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };

    const handleLogout = async (isTimedOut = false) => {
        try {
            setTimeoutModalOpen(false);
            clearSessionInterval();
            clearSessionTimeout();
            UserHelper.logout(createHistory(), { globalWarnMsgs: ["You have been logged out due to inactivity"] });
        } catch (err) {
            console.error(err);
        }
    };

    const handleContinue = () => {
        ApiCall.get('account/ping').then((response) => {
            setTimeoutModalOpen(false);
            clearSessionInterval();
            clearSessionTimeout();
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    };

    const {idleTimer} = useIdleTimeout({ onPrompt: onPrompt, idleTime: waitBeforeLogout, promptTime: waitBeforePrompt, onLogout: handleLogout })

    return (
        <>
            {isAuthenticated ?
                <SessionTimeoutModal
                    show={timeoutModalOpen}
                    countdown={timeoutCountdown}
                    onContinue={() => handleContinue()}
                    onLogout={() => handleLogout(false)}
                    onHide={() => setTimeoutModalOpen(false)}
                />
            :""}
        </>
    );
}
export default SessionTimeout;