import React, {Component} from 'react';
import {Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';

class EmployerForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            locales: [],
            levels: [],
            employerForm: {
                code: null,
                name: null,
                description:null,
                role: null,
                startDate:null,
                endDate:null
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeEmployerForm = this.handleChangeEmployerForm.bind(this);
        this.handleSubmitAddEditEmployerForm = this.handleSubmitAddEditEmployerForm.bind(this);
        this.clearEmployerForm = this.clearEmployerForm.bind(this);
    }

    componentDidMount() {
        if (!UserHelper.isEquivalent(this.props.editEmployer, this.state.employerForm)) {
            this.setState({
                    employerForm: {
                        code: this.props.editEmployer ? this.props.editEmployer.code : null,
                        name: this.props.editEmployer ? this.props.editEmployer.name : "",
                        description: this.props.editEmployer ? this.props.editEmployer.description : "",
                        role: this.props.editEmployer ? this.props.editEmployer.role : "",
                        startDate: this.props.editEmployer ? this.props.editEmployer.startDate : "",
                        endDate: this.props.editEmployer ? this.props.editEmployer.endDate : "",
                    }
                }
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <Form id="employerForm" onSubmit={this.handleSubmitAddEditEmployerForm}>
                    <Row>
                        <Col md={5} lg={5} className="border-end">
                             <Form.Group as={Row} controlId="role" className="mb-3">
                                <Col md={12} lg={12}>
                                    <InputGroup>
                                        <Form.Control name="role" type="text" placeholder="Role" value={this.state.employerForm.role || ""} required
                                        onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="startDate" className="mb-3">
                                <Col md={12} lg={12}>
                                    <Form.Control name="startDate" type="date" placeholder="Start Date" value={this.state.employerForm.startDate || ""}
                                    required
                                    onChange={this.handleInputChange}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="endDate" className="mb-3">
                                <Col md={12} lg={12}>
                                    <Form.Control name="endDate" type="date" placeholder="End Date" value={this.state.employerForm.endDate || ""}
                                    onChange={this.handleInputChange}/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={7} lg={7}>
                            <Form.Group as={Row} controlId="name" className="mb-3">
                                <Form.Label column md={5} lg={4}>
                                    Name*
                                </Form.Label>
                                <Col md={7} lg={8}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="building" /></InputGroup.Text>
                                        <Form.Control name="name" type="text" placeholder="Name" value={this.state.employerForm.name || ""} required
                                        onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="description" className="mb-3">
                                <Form.Label column md={5} lg={4}>
                                    Description*
                                </Form.Label>
                                <Col md={7} lg={8}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="file" /></InputGroup.Text>
                                        <Form.Control name="description" as="textarea" placeholder="Description" value={this.state.employerForm.description ||
                                        ""} rows={5}
                                        required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={11} md={11} lg={11}>
                            <Button variant="primary" type="submit" className="float-end text-uppercase">Save</Button>
                        </Col>
                        <Col sm={1} md={1} lg={1}>
                            <Button variant="link" onClick={() =>this.props.cancelEmployer()} className="float-end">Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }

    handleChangeEmployerForm(field, value) {
        let newEmployerForm = this.state.employerForm;
        newEmployerForm[field] = value;

        this.setState({
            employerForm: newEmployerForm
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.handleChangeEmployerForm(name, value);
    }

    getLevels() {
        UserHelper.getLevels().then(response => {
            if (response.levels && response.levels.length) {
                this.setState({
                    levels: response.levels
                });
            } else if (response.errorMsg) {
                UserHelper.showErrorToast(response.errorMsg);

                this.setState({
                    levels: []
                });
            }
        });
    }

    handleSubmitAddEditEmployerForm(event) {
        event.preventDefault();
        let employer = {
            code: this.state.employerForm.code,
            name: this.state.employerForm.name,
            role: this.state.employerForm.role,
            description: this.state.employerForm.description,
            startDate: this.state.employerForm.startDate,
            endDate: this.state.employerForm.endDate
        };

        if (this.props.editEmployer) {
            ApiCall.put('employer', employer).then(() => {
                this.clearEmployerForm();

                UserHelper.showSuccessToast("Your employer was updated.");

                this.props.getEmployers();
            }).catch(error => {
                UserHelper.showErrorMsg(error && error.message ? error.message : 'An error occurred. Please try again later.', true);
            });
        } else {
            ApiCall.post('employer', employer).then(() => {
                this.clearEmployerForm();

                UserHelper.showSuccessToast("Your employer was added.");

                this.props.getEmployers();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }
    }

    clearEmployerForm() {
        this.setState({
            employerForm: {
                code: null,
                locale: null,
                level: null
            }
        });
    }
}

export default EmployerForm;
