import React, {Component} from 'react';
import {Alert, Row, Col, Table, Card, Form, InputGroup, Button, Dropdown, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApiCall from '../../../Helper/ApiCall.js';
import {withRouter} from 'react-router-dom';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import UserHelper from '../../../../components/Helper/UserHelper.js';
import AccountResourceModal from '../AccountResource/AccountResourceModal.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountRates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [],
            ratesAllowed:true,
            isLoading: true,
            searchContractorName: null,
            currentUser: null,
            selectedResource: null,
            resourcesRates: {},
            resourcesAvailability: {}
        };

        this.handleResourceModalClose = this.handleResourceModalClose.bind(this);
        this.handleResourceModalShow = this.handleResourceModalShow.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.fireUser = this.fireUser.bind(this);
        this.toggleRates = this.toggleRates.bind(this);
        this.toggleAvailability = this.toggleAvailability.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.getResourcesInCurrentCompany = this.getResourcesInCurrentCompany.bind(this);
        this.getAvailabilityStatus = this.getAvailabilityStatus.bind(this);
    }

    componentDidMount() {
        this.getCurrentUser();
        this.getResourcesInCurrentCompany(0);
    }

    render() {
        let isManager = UserHelper.isManager();
        let resources = null;
        if (this.state.isLoading) {
            resources = <TableSectionLoader colSpan={7} numberOfRows={5}/>
        } else {
            if (this.state.resources && this.state.resources.content) {
                resources = this.state.resources.content.map(function(resource, key) {
                    return (
                        <tr key={resource.code}>
                            <td>
                                <InputGroup>
                                      <div className="toggle-availability-wrapper">
                                          <label className="toggle-theme mb-0">
                                            <input
                                              type="checkbox"
                                              onChange={(e) => {
                                                this.toggleAvailability(resource.code, e.target.checked);
                                              }}
                                              defaultChecked={resource.available}
                                            />
                                            <div className={`slider round w-100 ${this.getAvailabilityStatus(resource.available, resource.code)}`}></div>
                                           </label>
                                      </div>
                                </InputGroup>
                            </td>
                            <td>{resource.displayName}</td>
                            <td>{resource.role}</td>
                            {this.state.ratesAllowed ?
                                <td>
                                    &euro;{resource.contractorHourlyRate}
                                </td>
                            :null}
                            <td>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="euro-sign" /></InputGroup.Text>
                                    <Form.Control name={resource.code} type="number" onChange={this.handleInputChange}
                                        placeholder="Hourly Rate" defaultValue={resource.companyHourlyRate}  step={0.01} min={0} max={100} />
                                </InputGroup>
                            </td>
                            <td className="text-nowrap w-10">
                                {resource ?
                                    <Dropdown className="float-end ms-2 text-bf">
                                        <Dropdown.Toggle variant="primary">
                                            <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>this.saveUser(resource.code)}>Save</Dropdown.Item>
                                            {this.state.currentUser && this.state.currentUser.code !== resource.code ?
                                                <>
                                                    <Dropdown.Item onClick={() => this.handleResourceModalShow(resource)}>View</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => UserHelper.downloadCV(resource.code, resource.initials, false, this.props.progressBarContext)}>Download CV</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => UserHelper.downloadCV(resource.code, resource.initials, true, this.props.progressBarContext)}>Download Anonymous CV</Dropdown.Item>
                                                    <Dropdown.Item onClick={()=>this.fireUser(resource.code)}>Fire user</Dropdown.Item>
                                                </>
                                            : null}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                :null}
                            </td>
                        </tr>);
                }, this);
            } else {
              resources = <tr><td colSpan="7" className="text-center">You have no resources in your company</td></tr>;
            }
        }

        return (
            <>
                {isManager?
                    <Row className="mt-3">
                        <Col sm={12} md={12} className="mt-3 mt-md-0">
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-uppercase fw-bold">
                                        Add more resources
                                    </Card.Title>
                                    <p>You can invite more resources to join your company by sharing this link with them</p>
                                    <Form.Group as={Row} controlId="formProfileRole" className="mb-3">
                                        <Form.Label column sm={3} md={3} lg={3}>
                                            Registration link
                                        </Form.Label>
                                        <Col sm={7} md={7} lg={7}>
                                            <InputGroup>
                                                <InputGroup.Text><FontAwesomeIcon icon="plus" /></InputGroup.Text>
                                                {this.state.currentUser ?
                                                    <Form.Control name="registrationLink" type="text" ref={(registrationLink) => this.registrationLink = registrationLink}
                                                    placeholder="Registration Link" readOnly defaultValue={this.state.currentUser.registrationLink} />
                                                :null}
                                            </InputGroup>
                                        </Col>
                                        <Col sm={2} md={2} lg={2}>
                                            <Button onClick={() => this.copyToClipboard()}>Copy</Button>
                                        </Col>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                :null}
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    <Alert variant="warning">
                                        <Alert.Heading>WARNING!</Alert.Heading>
                                        <p>
                                            This information is confidential and you should treat it as such.
                                            Sharing your rate with other people is strictly forbidden!
                                        </p>
                                    </Alert>
                                    Company Resources
                                    {isManager?
                                        <ToggleButtonGroup name="ratesAllowed" className="float-end">
                                            <ToggleButton variant={!this.state.ratesAllowed ? "outline-primary": "primary"} onClick={(e)=>this.toggleRates(true)}
                                            value={true}>Individual Rates</ToggleButton>
                                            <ToggleButton variant={this.state.ratesAllowed ? "outline-primary": "primary"} onClick={(e)=>this.toggleRates(false)} value={false}>Company
                                            Rates</ToggleButton>
                                        </ToggleButtonGroup>
                                    :null}
                                </Card.Title>
                                <p>Here you can see the resources in your company</p>
                                {isManager?
                                    <Row>
                                        <Col lg={6} md={6} className="mb-2">
                                            <InputGroup>
                                                <InputGroup.Text><FontAwesomeIcon icon="magnifying-glass" /></InputGroup.Text>
                                                <Form.Control name="searchContractorName" type="text" placeholder="Search" required
                                                defaultValue={this.state.searchContractorName} onChange={this.handleSearchInputChange} />
                                                <Button variant="secondary" className="ms-2 float-end text-uppercase" onClick={()=>this
                                                .getResourcesInCurrentCompany
                                                (0)}>Search</Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                :null}
                                <Table striped bordered hover responsive className="table-align-middle">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Name</th>
                                            <th>Role</th>
                                            {this.state.ratesAllowed ?
                                            <th>Contractor Rate</th>
                                            :null}
                                            <th>Company Rate</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { resources }
                                    </tbody>
                                    {this.state.resources && this.state.resources.content && this.state.resources.content.length > 0 ?
                                        <tfoot>
                                            <tr>
                                                <th colSpan="3">
                                                    <PaginationInfo page={this.state.resources} />
                                                </th>
                                                <th colSpan="4">
                                                    <Pagination page={this.state.resources} handleChangePage={this.getResourcesInCurrentCompany} isLoading={this.state.isLoading}
                                                    />
                                                </th>
                                            </tr>
                                        </tfoot>
                                    :null}
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                    <AccountResourceModal show={this.state.showResourceModal} handleResourceModalClose={this.handleResourceModalClose} resource={this.state
                    .selectedResource} />
                </Row>
            </>
        )
    }

    handleSearchInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                this.setState({
                    [name]: value
                });
            } else {
                let newCountries = [];
                event.forEach(sel => {
                    newCountries.push({value:sel.value, label:sel.label});
                });
                this.setState({
                    "profileCountries": newCountries
                });
            }
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let rr = this.state.resourcesRates;
        rr[name] = value;

        this.setState({
            resourcesRates : rr
        });
    }

    handleResourceModalClose() {
        this.setState({
            showResourceModal: false
        });
    }

    handleResourceModalShow(resource) {
        this.setState({
            showResourceModal: true,
            selectedResource: resource
        });
    }

    saveUser(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.put('resource/' + code, {}, {
            'amount' : this.state.resourcesRates[code]
        }).then((response) => {
            UserHelper.showSuccessToast("Company rate was updated.");
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                profileInfoMsg: null
            });
        });
    }

    fireUser(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.delete('resource/' + code).then((response) => {
            UserHelper.showSuccessToast("The resource was fired from your company.");
            this.getResourcesInCurrentCompany(0);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.', true);
            this.setState({
                profileInfoMsg: null
            });
        });
    }

    getCurrentUser() {
        ApiCall.get('user/getCurrentUser', {
            projection: "supreme.contractors.backend.facade.dto.ContractorUserDetailsDtoDefinition"
        }).then((response) => {
            let currentUser = response.data;

            this.setState({
                currentUser: currentUser,
                ratesAllowed: currentUser.department.organization.allowEmployeeRates
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 40});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                profileInfoMsg: null
            });
        });
    }

    copyToClipboard = () => {
        const el = this.registrationLink;
        el.select();
        document.execCommand("copy");
        UserHelper.showSuccessToast("Link has been copied to clipboard");
    }

    getResourcesInCurrentCompany(pageNumber) {
        ApiCall.get('supreme-organization/resources-with-name', {
            projection: "supreme.contractors.backend.facade.dto.ContractorUserDetailsDtoDefinition",
            page: pageNumber,
            name: this.state.searchContractorName,
            size: 10
        }).then((response) => {
            this.setState({
                resources: response.data,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 40});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    toggleRates(val) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.put('supreme-organization/switch',{}, {
            employeeRates:val
        }).then((response) => {
            this.setState({
                ratesAllowed: val,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    toggleAvailability(code, availability) {
        ApiCall.performPut('profile/availability', {}, this.props.progressBarContext, (result) => {
            let ra = this.state.resourcesAvailability;
            ra[code] = availability;

            this.setState({resourcesAvailability : ra});
            UserHelper.showSuccessToast("Your availability has been updated");
        }, { code: code, available: availability });
    }

    getAvailabilityStatus(serverAvailability, userCode) {
        if (this.state.resourcesAvailability[userCode] !== undefined) {
            return this.state.resourcesAvailability[userCode] ? 'bg-success' : 'bg-danger';
        } else {
            return serverAvailability ? 'bg-success' : 'bg-danger';
        }
    }
}

export default withRouter(withProgressBarContext(AccountRates));
