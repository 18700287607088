import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col, Table, Card, Dropdown, Form, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import qs from 'qs';
import BootstrapModal from '../../../Misc/BootstrapModal.js';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import { Rating } from 'react-simple-star-rating';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class ClientAssignmentTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assignments: [],
            selectedAssignment: null,
            isLoading: true,
            writeReviewModal: false
        };

        this.getAllAssignments = this.getAllAssignments.bind(this);
        this.getAssignmentClass = this.getAssignmentClass.bind(this);
        this.submitReview = this.submitReview.bind(this);
    }

    componentDidMount() {
        this.getAllAssignments(0, false);
    }

    render() {
        let tableCss = "table-align-middle";
        if (UserHelper.isDark()) {
            tableCss += " table-dark";
        }
        let assignments = null;

        if (this.state.isLoading) {
            assignments = <TableSectionLoader colSpan={6} numberOfRows={5}/>
        } else {
            if (this.state.assignments && this.state.assignments.content && this.state.assignments.content.length) {
                assignments = this.state.assignments.content.map(function(assignment, key) {
                    let assignmentManagers = [];
                    assignmentManagers.push(<option disabled value={-1} key={-1}>Please select...</option>);
                    if(this.state.clients) {
                        assignmentManagers.push(this.state.clients.filter(function(client,key) {
                           return client.organizationCode === assignment.clientCode;
                        }).map(function(client, key){
                           return <option key={key} value={client.value}>{client.label}</option>;
                        }));
                    }
                    const config = {
                      modifiers: [
                        {
                          name: 'computeStyles',
                          options: {
                            gpuAcceleration: false, // true by default
                          },
                        },
                      ],
                    };
                    return (
                        <tr key={assignment.code} className={this.getAssignmentClass(assignment)}>
                            <td>{assignment.user ? assignment.user.displayName: assignment.externalContractorDisplayName}</td>
                            <td>{assignment.user ? assignment.user.role: ""}</td>
                            <td>{assignment.activeFrom}</td>
                            <td>{assignment.activeTo}</td>
                            <td>€ {assignment.supremeHourlyRate}</td>
                            <td>
                                <Dropdown className="float-end ms-2 text-bf">
                                    <Dropdown.Toggle variant="transparent" className="text-dark">
                                        <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu popperConfig={config}>
                                        <Dropdown.Item onClick={() => {this.setState({selectedAssignment:assignment,writeReviewModal:true})}}>Write Review</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>);
                }, this);
            } else {
              assignments = <tr><td colSpan="7" className="text-center">You have no assignments in your company</td></tr>;
            }
        }

        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    All Assignments
                                </Card.Title>
                                <p>Here you can see all the assignments</p>
                                <Table bordered hover responsive className={tableCss}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Role</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Rate</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { assignments }
                                    </tbody>
                                    {this.state.assignments && this.state.assignments.content && this.state.assignments.content.length > 0 ?
                                        <tfoot>
                                            <tr>
                                                <th colSpan="2">
                                                    <PaginationInfo page={this.state.assignments} />
                                                </th>
                                                <th colSpan="4">
                                                    <div className="float-end w-50">
                                                        <Pagination page={this.state.assignments} handleChangePage={p=>this.getAllAssignments(p, this.state
                                                        .expiring, this.state.selectedOrganization ? this.state.selectedOrganization.value:null)}
                                                        isLoading={this.state.isLoading} />
                                                    </div>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    :null}
                                </Table>
                                <BootstrapModal name="Write review" show={this.state.writeReviewModal} handleClose={()=>{this.setState({rating:0, review: null, writeReviewModal:false})}} handleAction={(e)=>this.submitReview()} size="xl" actionBtn="Write review">
                                    <Form>
                                        <Form.Group as={Row} controlId="rating" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                Rating*
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <InputGroup>
                                                    <Rating name="rating" initialValue={this.state.rating} onClick={ (newValue, index, event)=> {this.setState({rating: newValue})}} emptyColor={UserHelper.isDark() ? "var(--bs-light)" : "var(--bs-primary)"} fillColor={"var(--bs-secondary)"} allowFraction={true}/>
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="review" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                Review*
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <InputGroup>
                                                    <Form.Control name="review" as="textarea" rows="6" placeholder="Write your review here" value={this.state.review || ""} onChange={(e)=>this.setState({review:e.target.value})} />
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </BootstrapModal>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    getAllAssignments(pageNumber, expiring, organizationCode) {
        let url = "assignment";
        if (organizationCode) {
            url = "assignment/organization/" + organizationCode;
        }
        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        let defaultSize = queryParams.size ? queryParams.size : 20;

        ApiCall.get(url, {size:defaultSize,page:pageNumber,expiring:expiring}).then((response) => {
            let assignmentValues = new Map();
            response.data.content.forEach(assignment => {
                  assignmentValues.set(assignment.code, {"activeFrom":assignment.activeFrom,"activeTo":assignment.activeTo, 'manager': assignment.manager ?
                  assignment.manager.code : null, "contractorHourlyRate":assignment.contractorHourlyRate, "companyHourlyRate":assignment.companyHourlyRate,
                  "supremeHourlyRate":assignment.supremeHourlyRate});
            });

            this.setState({
                assignments: response.data,
                assignmentValues: assignmentValues,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getAssignmentClass(assignment) {
        let result = assignment.doomed ? "disabled " : "";

        if (new Date(assignment.activeTo) < new Date()) {
            return result + "table-danger";
        } else {
            if (new Date(assignment.activeTo) < new Date(new Date().setDate(new Date().getDate()+14))) {
                return result + "table-warning";
            } else {
                return result + "table-success";
            }
        }
    }

    submitReview(rating, review) {
        ApiCall.post('customerReview/customer/' + this.state.selectedAssignment.user.code, {
            content: this.state.review,
            rating: this.state.rating,
            user: {
                "@class":"io.nemesis.platform.facade.user.dto.UserDetailsDto",
                username: this.state.selectedAssignment.manager.code
            }
        }, null).then((response) => {
            this.setState({rating:0, review:null, writeReviewModal:false});
            if (response.data) {
                UserHelper.showErrorToast("You have already submitted review for this user!");
            } else {
                UserHelper.showSuccessToast("Thank you for your review!");
            }
        }).catch(error => {
            this.setState({rating:0, review:null, writeReviewModal:false});
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });

    }
}

export default withRouter(withProgressBarContext(ClientAssignmentTable));
