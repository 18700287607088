import React from "react";

/*
{
        Example data:
        {
        "code": "sa-normal-logo",
        "entityName": "simpleBannerWidget",
        "id": "101331110969919008",
        "initializeScript": null,
        "name": "Home Logo Banner",
        "styleClass": null,
        "syncStates": [],
        "title": "",
        "external": false,
        "lazyLoad": false,
        "media": {
          "external": false,
          "imageType": null,
          "format": null,
          "name": null,
          "url": "https://dk4bbvhtxx00t.cloudfront.net/solarapparel/content/banner/logo-sa.png",
          "altText": "Home",
          "mimeType": null,
          "galleryIndex": null,
          "width": 0,
          "height": 0
        },
        "urlEncoded": false,
        "urlLink": "/"
      },
 */
export default function SimpleBannerWidget({widgetData}) {
    let className = widgetData.styleClass;

    return (
        <div className={className}>
            <a href={widgetData.urlLink} name={widgetData.code}>
                <img title={widgetData.name} width="100%" alt={widgetData.media.altText} src={widgetData.media.url}/>
            </a>
        </div>
    );
}
