import React from 'react';
import {Container} from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

/**
 * This is an example template for the homepage as configured on the b2c homepage.
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Homepagetemplate(props) {
    return (
        <>
            <div className="wrap">
                <Container>
                    <Header breadcrumbs={[]}/>
                    {props.children}
                </Container>
            </div>
            <Footer/>
        </>
    );
}
