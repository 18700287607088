import React, {useState} from "react";
import {Navbar, Container, Row, Col, Button, Image, Spinner} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import {SelectedResourceContext} from './SelectedResourceProvider.js';
import AccountRequestResourceModal from './AccountRequestResourceModal.js';
import ApiCall from '../../../Helper/ApiCall.js';

export default function ResourceSelectedBar(props) {
    let className = "";

    const [isLoading] = useState(false);
    const [showRequestResourceModal, setShowRequestResourceModal] = useState(false);
    const [jobRequests, setJobRequests] = useState([]);
    let isSuperSales = UserHelper.isSuperSales();

    function showRequestModal() {
        ApiCall.get('request').then((response) => {
            let requests = [];

            for (const req of response.data) {
                requests.push({value: `${req.code}`, label: `${req.name} (${req.organization ? req.organization.legalName: ''})`});
            }
            setJobRequests(requests);
            setShowRequestResourceModal(true);
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <Navbar className={className + ' compare-bar'} expand="lg" bg="primary" fixed="top">
            <Container className="h-100">
                <Container className="wrapper">
                    <SelectedResourceContext.Consumer>
                        {(context) => (
                            <Row className="align-items-center h-100 w-100">
                                {context.state.selectedResources  && [...Array(context.maxItemsCount - context.state.selectedResources.length)].map((x, key) =>
                                    <Col xs={1} md={1} className="item text-light d-flex flex-row me-1 justify-content-center" key={key}>
                                        <div className="product ms-1 text-center"></div>
                                    </Col>
                                )}

                                {context.state.selectedResources && context.state.selectedResources.map(function(resource, key) {
                                    return <Col xs={1} md={1} className="item text-light d-flex flex-row justify-content-center position-relative" key={key}>
                                            <div className="remove d-inline-block position-absolute">
                                                <FontAwesomeIcon icon="xmark" size="xs" color="var(--bs-light)" onClick={() => {
                                                        if (!isLoading) {
                                                            context.unselectResource(resource.properties.code);
                                                        }
                                                    }
                                                } />
                                            </div>
                                            <div className="product ms-1 text-center">
                                                <Image src={resource.properties.imageUrl} />
                                                <div className="position-absolute fixed-bottom ms-1 bg-secondary text-primary w-50">
                                                    {resource.properties.initials}
                                                </div>
                                            </div>

                                        </Col>
                                    })
                                }
                                
                                <Col xs={1} md={2} className="text-center text-light">
                                    {!isSuperSales ?
                                        <Button variant="secondary" className="text-uppercase ms-1"
                                            disabled={isLoading || !context.state.selectedResources || context.state.selectedResources.length === 0}
                                            onClick={()=>showRequestModal()}>
                                                {isLoading ? <React.Fragment>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        role="status"
                                                        aria-hidden="true"/>
                                                    <div className="d-inline ms-1">
                                                        <small>Loading</small>
                                                    </div>
                                                </React.Fragment> : "Request"}
                                        </Button>
                                    :
                                        <Button variant="secondary" className="text-uppercase ms-1"
                                            disabled={isLoading || !context.state.selectedResources || context.state.selectedResources.length === 0}
                                            onClick={() => props.history.push("/my-account/offer")}>
                                                {isLoading ? <React.Fragment>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        role="status"
                                                        aria-hidden="true"/>
                                                    <div className="d-inline ms-1">
                                                        <small>Loading</small>
                                                    </div>
                                                </React.Fragment> : "Offer"}
                                        </Button>
                                    }

                                </Col>
                                <AccountRequestResourceModal show={showRequestResourceModal} jobRequests={jobRequests} requestedResources={context.state.selectedResources}
                                 handleModalClose={()=>setShowRequestResourceModal(false)}/>
                            </Row>
                        )}
                    </SelectedResourceContext.Consumer>
                </Container>
            </Container>
        </Navbar>
    );
}
