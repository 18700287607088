import React from 'react';
import {Button, Row, Col, Form, Badge} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
const DND_ITEM_TYPE = 'project';

const DndProjectRow = ({ row, index, setRecords, moveRow, reorderRows, editRow, deleteRow }) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop(item, monitor) {
        reorderRows(row.code, item.index);
    },
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    item: { index },
    type: DND_ITEM_TYPE,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)
  return (
    <Row className="border-bottom mt-3" style={{ opacity }} ref={dropRef} key={row.code}>
        <Col md={1} lg={1}>
            <div ref={dragRef}><FontAwesomeIcon size="lg" icon="bars" className="cursor-pointer"/></div>
        </Col>
        <Col md={4} lg={4} className="border-end">
             <Form.Group as={Row} controlId="name" className="mb-3">
                <Col md={12} lg={12}>
                    {row.name}
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="url" className="mb-3">
                <Col md={12} lg={12}>
                    {row.url}
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="url" className="mb-3">
                <Col md={6} lg={6}>
                    {row.startDate}
                </Col>
                <Col md={6} lg={6}>
                    {row.endDate}
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formAddEditProject"  className="mb-3">
                <Col sm={2} md={2} lg={2}>
                    <Button variant="primary" type="submit" className="float-start text-uppercase" onClick={()=>editRow(row)}>Edit</Button>
                </Col>
                <Col sm={2} md={2} lg={2}>
                    <Button variant="danger" type="submit" className="float-start text-uppercase" onClick={()=>deleteRow(index, row.code)}>Delete</Button>
                </Col>
            </Form.Group>
        </Col>
        <Col md={7} lg={7}>
            <Form.Group as={Row} controlId="platform" className="mb-3">
                <Form.Label column md={5} lg={4}>
                    <b>Platform</b>
                </Form.Label>
                <Col md={7} lg={8}>
                    {row.platform}
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="database" className="mb-3">
                <Form.Label column md={5} lg={4}>
                    <b>Database</b>
                </Form.Label>
                <Col md={7} lg={8}>
                    {row.database}
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="role" className="mb-3">
                <Form.Label column md={5} lg={4}>
                    <b>Role</b>
                </Form.Label>
                <Col md={7} lg={8}>
                    {row.role}
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="description" className="mb-3">
                <Form.Label column md={5} lg={4}>
                    <b>Description</b>
                </Form.Label>
                <Col md={7} lg={8}>
                    {row.description}
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="teamSize" className="mb-3">
                <Form.Label column md={5} lg={4}>
                    <b>Team Size</b>
                </Form.Label>
                <Col md={7} lg={8}>
                    {row.teamSize}
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="technologies" className="mb-3">
                <Form.Label column md={5} lg={4}>
                    <b>Technologies</b>
                </Form.Label>
                <Col md={7} lg={8}>
                    <h5>
                        {row.technologies.map(function(d, idx){
                            return (<Badge key={row.code + '-' + d.code} pill className="me-1" variant="primary">{d.name}</Badge>)
                        })}
                    </h5>
                </Col>
            </Form.Group>
        </Col>
    </Row>
  )
}

const DndProjects = ({ data, emptyMsg, reorderRows, editRow }) => {
  const [records, setRecords] = React.useState(data);
  React.useEffect(() => { setRecords(data); } ,[data]);

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    )
  }

  const deleteRow = (rowIndex, code) => {
    ApiCall.delete('project/' + code).then((response) => {
        setRecords(
          update(records, {
            $splice: [
              [rowIndex, 1],
            ],
          })
        )
    }).catch(error => {
        UserHelper.showdErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
    });
  }

  return (
    <DndProvider backend={HTML5Backend}>
        <div className="mb-3">
            {
                records && records.length>0 ? records.map((row, index) => {
                    return <DndProjectRow key={row.code} index={index} row={row}
                                          setRecords={setRecords}
                                          moveRow={moveRow}
                                          reorderRows={reorderRows}
                                          editRow={editRow}
                                          deleteRow={deleteRow}/>
                }) : <p>{emptyMsg}</p>
            }
        </div>
    </DndProvider>
  )
}

export default DndProjects;
