import React, {Component} from 'react';
import ContentLoader from "react-content-loader";

const FacetLoader = (props) => (
    <ContentLoader
        width={props.contentWidth}
        height={46}
        speed={1}
        backgroundColor="var(--bs-body-bg)"
        foregroundColor="var(--bs-muted)"
        className={props.className}
    >
        <rect x={props.x} y={0} rx="4" ry="4" width={props.width} height="38"/>
    </ContentLoader>
);

export default class FacetListerLoader extends Component {
    render() {
        return (
            <FacetLoader width={this.props.width || '100%'} contentWidth={this.props.contentWidth || "100%"} x={this.props.x || 0} y={this.props.y || 0}/>
        )
    }
}
