import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Container, Navbar, Nav} from 'react-bootstrap';
import UserHelper from '../../../components/Helper/UserHelper.js';

import './AccountNav.scss';

export default class AccountNav extends Component {
    render() {

        let isAccountant = UserHelper.isAccountant();
        let isContractor = UserHelper.isContractor();
        let isManager = UserHelper.isManager();
        let isIndividualContractor = UserHelper.isIndividualContractor();
        let isSales = UserHelper.isSales();
        let isSuperSales = UserHelper.isSuperSales();
        let isClient = UserHelper.isClient();

        return (
            <Navbar expand="lg" className="user-nav rounded border">
                <Navbar.Toggle aria-controls="top-navbar-nav" className="ms-2"/>
                <Navbar.Collapse id="top-navbar-nav">
                    <Container>
                        <Nav className="nav-fill w-100">
                            <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "profile" ? "active" : ""}`} as={Link} to="/my-account/profile">Profile</Nav.Link>
                            {isIndividualContractor || isSuperSales || isClient ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "legal" ? "active" : ""}`} as={Link} to="/my-account/legal">Legal</Nav.Link>
                                </>
                            : null}
                            {isSuperSales ?
                            <>
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "clients" || this.props.selected === "client" ? "active" : ""}`} as={Link} to="/my-account/clients">Clients</Nav.Link>
                            </>
                            :null}
                            {isContractor ?
                            <>
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "experience" ? "active": ""}`} as={Link} to="/my-account/experience">Experience</Nav.Link>
                            </>
                            : null}
                            {isIndividualContractor ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "rates" ? "active" : ""}`} as={Link} to="/my-account/rates">Rates</Nav.Link>
                                </>
                            : null}
                            {isManager || isSales || isSuperSales || isClient ?
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "requests" || this.props
                                .selected === "request" ? "active" : ""}`} as={Link} to="/my-account/requests">Requests</Nav.Link>
                            :null}
                            {isSales || isManager ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "search" ? "active" : ""}`} as={Link} to="/my-account/search">Search</Nav.Link>
                                </>
                            : null}
                            {isContractor || isSales || isSuperSales || isClient ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "offers" || this.props.selected ==="offer" ? "active" : ""}`} as={Link} to="/my-account/offers">Offers</Nav.Link>
                                </>
                            : null}
                            {isContractor || isSales || isSuperSales || isClient ?
                                <>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "interviews" ? "active"
                                    : ""}`} as={Link} to="/my-account/interviews">Interviews</Nav.Link>
                                    <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "assignments" ? "active"
                                    : ""}`} as={Link} to="/my-account/assignments">Assignments</Nav.Link>
                                </>
                            :''}
                            {isIndividualContractor || isSuperSales || isAccountant || isClient ?
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "orders"  || this.props.selected ==="order" || this.props.selected ==="order-view" ? "active"
                                : ""}`} as={Link} to="/my-account/orders">Orders</Nav.Link>
                            : null}
                            {isContractor || isSales || isSuperSales || isClient ?
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "vacation" ? "active"
                                : ""}`} as={Link} to="/my-account/vacation">Vacation</Nav.Link>
                            : null}
                            {isSuperSales ?
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "reports" ? "active": ""}`} as={Link} to="/my-account/reports">Reports</Nav.Link>
                            : null}
                            {isContractor ?
                            <>
                                <Nav.Link className={`rounded nav-item text-uppercase fw-bold ${this.props.selected === "reviews" ? "active" : ""}`} as={Link} to="/my-account/reviews">Reviews</Nav.Link>
                            </>
                            : null}
                        </Nav>
                    </Container>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}
