import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import CmsPageProvider from '../../cms/CmsPageProvider.js';
import CmsTemplate from '../../cms/CmsTemplate.js';
import GlobalMessagesProvider from '../../Provider/GlobalMessagesProvider.js';
import GlobalMessages from '../../Misc/GlobalMessages.js';
import Invoice from '../../Invoice/Invoice.js';

export default class ResourceInvoicePage extends Component {
    render() {
        return (
            <CmsPageProvider props={{page:'invoice'}}>
                <GlobalMessagesProvider>
                    <CmsTemplate>
                        <main>
                            <Container fluid className="mt-3 mb-3 ps-0 pe-0">
                                <Row>
                                    <Col sm={12}>
                                        <GlobalMessages />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={12} md={12}>
                                        <Invoice/>
                                    </Col>
                                </Row>
                            </Container>
                        </main>
                    </CmsTemplate>
                </GlobalMessagesProvider>
            </CmsPageProvider>
        )
    }
}
