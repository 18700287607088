import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import UserHelper from "../../../Helper/UserHelper";
import {ListerContext} from './ResourceListerPageProvider';
import ResourceListerListItemLoader from './ResourceListerListItemLoader.js';
import ResourceListerGridItemLoader from './ResourceListerGridItemLoader.js';
import ResourceListerListItem from './ResourceListerListItem.js';
import ResourceListerGridItem from './ResourceListerGridItem.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';
import AccountResourceModal from '../AccountResource/AccountResourceModal.js';
import AccountResourceNotesModal from '../AccountResource/AccountResourceNotesModal.js';
import AccountResourceHistoryModal from '../AccountResource/AccountResourceHistoryModal.js';

class ResourceLister extends Component {

    constructor(props){
        super(props);

        this.state = {
            selectedResource: null,
            showResourceModal: false,
            showResourceNotesModal: false,
            showResourceHistoryModal: false
        };

        this.downloadContracts = this.downloadContracts.bind(this);
    }

    render() {
        return (
            <ListerContext.Consumer>
                {(context) => (
                    <Row className="mt-3">
                        {context.state.isLoading && [...Array(context.state.defaultPageSize)].map((x, key) => {
                            return context.state.layout === 'grid' ?
                                <Col key={key} md={3} className="mb-4"><ResourceListerGridItemLoader /></Col>
                            :   <Col key={key} sm={12} className="mb-4"><ResourceListerListItemLoader /></Col>
                        })}

                        {!context.state.isLoading && context.state.searchPageData && context.state.searchPageData.content && context.state.searchPageData.content.length === 0 &&
                           <div className="py-3 fw-bold text-center text-secondary text-uppercase">Nothing was found</div>
                        }

                        {!context.state.isLoading && context.state.searchPageData && context.state.searchPageData.content && context.state.searchPageData.content.map((resource, key) => {
                           return context.state.layout === 'grid' ?
                                <ResourceListerGridItem key={key}
                                    resource={resource}
                                    handleDownloadContracts={(code)=>this.downloadContracts(code)}
                                    handleResourceModalShow={(res)=> this.setState({showResourceModal: true, selectedResource: res})}
                                    handleResourceNotesModalShow={(res) => this.setState({showResourceNotesModal: true, selectedResource: res})}
                                    handleResourceHistoryModalShow={(res) => this.setState({showResourceHistoryModal: true, selectedResource: res})}
                                    getResourcePrice={(res, isSalesAndNotSuperSales) => this.getResourcePrice(res, isSalesAndNotSuperSales)}
                                    progressBarContext={this.props.progressBarContext}/>
                                :
                                <ResourceListerListItem key={key}
                                    resource={resource}
                                    handleDownloadContracts={(code)=>this.downloadContracts(code)}
                                    handleResourceModalShow={(res)=> this.setState({showResourceModal: true, selectedResource: res})}
                                    handleResourceNotesModalShow={(res) => this.setState({showResourceNotesModal: true, selectedResource: res})}
                                    handleResourceHistoryModalShow={(res) => this.setState({showResourceHistoryModal: true, selectedResource: res})}
                                    getResourcePrice={(res, isSalesAndNotSuperSales) => this.getResourcePrice(res, isSalesAndNotSuperSales)}
                                    progressBarContext={this.props.progressBarContext}/>
                           }
                        )}

                        <AccountResourceModal show={this.state.showResourceModal} handleResourceModalClose={()=>this.setState({showResourceModal:false})} resource={this.state.selectedResource} />
                        <AccountResourceNotesModal show={this.state.showResourceNotesModal} handleResourceNotesModalClose={()=>this.setState({showResourceNotesModal: false})} resource={this.state.selectedResource} />
                        <AccountResourceHistoryModal show={this.state.showResourceHistoryModal} handleResourceHistoryModalClose={()=>this.setState({showResourceHistoryModal: false})} resource={this.state.selectedResource} />
                    </Row>
                ) }
            </ListerContext.Consumer>
        )
    }

    getResourcePrice = (resource, isSalesAndNotSuperSales) => {
        if (isSalesAndNotSuperSales) {
            if (resource.properties.supremeHourlyRate) {
                return resource.properties.supremeHourlyRate.formatted;
            }
            return null;
        } else {
            if (resource.properties.companyHourlyRate) {
                return resource.properties.companyHourlyRate.formatted;
            } else if (resource.properties.contractorHourlyRate){
                return resource.properties.contractorHourlyRate.formatted;
            } else {
                return null;
            }
        }
    }

    downloadContracts = (username) => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        fetch(process.env.REACT_APP_FACADE_URL + 'supreme-organization/legal/contracts?username=' + encodeURIComponent(username), {
            method: "GET",
            headers: {
              'Accept': "application/zip",
              "Content-Type": "application/zip",
              "X-Nemesis-Token": localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'legal.zip';
                    a.click();
                });
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }
 }

 export default withProgressBarContext(ResourceLister);


