import React, {Component} from 'react';
import {Row, Col, Card, Button, Dropdown, InputGroup, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import UserHelper from '../../../Helper/UserHelper.js';
import BootstrapTable from '../../../Misc/BootstrapTable.js';
import ApiCall from '../../../Helper/ApiCall.js';
import OfferRejectionModal from './OfferRejectionModal.js';
import AccountResourceModal from '../AccountResource/AccountResourceModal.js';
import RerouteModal from './RerouteModal.js';
import InterviewRequestModal from './InterviewRequestModal';
import OfferDetailsModal from './OfferDetailsModal.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountOffers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offerStatuses: [],
            offers: {},
            offerValues: new Map(),
            isLoading: true,
            selected: []
        };

        this.renderStatus = this.renderStatus.bind(this);
        this.getOffers = this.getOffers.bind(this);
        this.deleteOffers = this.deleteOffers.bind(this);
        this.confirmOffer = this.confirmOffer.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getOrganizations = this.getOrganizations.bind(this);
        this.handleOfferModalClose = this.handleOfferModalClose.bind(this);
        this.handleShowOfferModalClose = this.handleShowOfferModalClose.bind(this);
        this.getActionsFormatForOffers = this.getActionsFormatForOffers.bind(this);
        this.getClientActionsFormatForOffers = this.getClientActionsFormatForOffers.bind(this);
    }

    componentDidMount() {
        let isSales = UserHelper.isSales();
        if (isSales) {
            this.getOrganizations();
        }
        let organizationCode = null;
        if (this.props.location && this.props.location.hash) {
            organizationCode = this.props.location.hash.substring(1);
            this.setState({selectedOrganization : {value:organizationCode,label:organizationCode}});
        }
        this.getOffers(0, organizationCode);
    }

    render() {
        let isSales = UserHelper.isSales();
        let isClient = UserHelper.isClient();
        let isSuperSales = UserHelper.isSuperSales();
        let isManager = UserHelper.isManager();
        let isIndividualContractor = UserHelper.isIndividualContractor();

        let skin = UserHelper.isDark() ? "table-dark" : "";

        const employeeColumns = [
            {
              dataField: 'user.initials',
              text: 'Initials',
              footer: columnData => <PaginationInfo page={this.state.offers} />,
              footerAttrs: {
                  colSpan: 2
              }
            },
            {
              dataField: 'user.displayName',
              text: 'Name'
            },
            {
              dataField: 'jobRequest.name',
              text: 'Request',
              formatter: (cell, row) =>  {
                return <Button variant="link" className="text-start p-0 text-dark bg-transparent" onClick={() => this.setState({showOfferDetailsModal:true, selectedOffer:row})
                }>{cell}</Button>
              }
            },
            {
              dataField: 'createdDate',
              text: 'Date',
              footer: columnData => <Pagination page={this.state.offers} handleChangePage={p=>this.getOffers(p, this.state.selectedOrganization)} isLoading={this.state
              .isLoading} />,
              footerAttrs: {
                  colSpan: 2
              }
            }
        ];

        const managerColumns = [
            {
              dataField: 'user.initials',
              text: 'Initials',
              footer: columnData => <PaginationInfo page={this.state.offers} />,
              footerAttrs: {
                  colSpan: 2
              }
            },
            {
              dataField: 'user.displayName',
              text: 'Name'
            },
            {
              dataField: 'jobRequest.name',
              text: 'Request',
              formatter: (cell, row) =>  {
                return <Button variant="link" className="text-start p-0 text-dark bg-transparent" onClick={() => this.setState({showOfferDetailsModal:true, selectedOffer:row})
                }>{cell}</Button>
              }
            },
            {
              dataField: 'createdDate',
              text: 'Date'
            },
            {
              dataField: 'companyHourlyRate',
              text: 'Rate(€)',
              footer: columnData => <Pagination page={this.state.offers} handleChangePage={p=>this.getOffers(p, this.state.selectedOrganization)}
                isLoading={this.state.isLoading} />,
              footerAttrs: {
                  colSpan: 3
              }
            }
        ];

        const clientColumns = [
            {
              dataField: 'user.initials',
              formatter: (cell, row) =>  {
                return <Button variant="link" className="text-start p-0 text-dark bg-transparent" onClick={() => this.setState({showResourceModal:true, selectedResource:row.user})}>{cell}</Button>
              },
              text: 'Initials',
              footer: columnData => <PaginationInfo page={this.state.offers} />,
              footerAttrs: {
                  colSpan: 3
              }
            },
            {
              dataField: 'jobRequest.name',
              text: 'Request',
              formatter: (cell, row) =>  {
                return <Button variant="link" className="text-start p-0 text-dark bg-transparent" onClick={() => this.setState({showOfferDetailsModal:true, selectedOffer:row})}>{cell}</Button>
              }
            },
            {
              dataField: 'createdDate',
              text: 'Date'
            },
            {
              dataField: 'supremeHourlyRate',
              text: 'Rate',
              formatter: (cell, row) => {
                return '€ ' + cell;
              }
            },
            {
              dataField: 'dummy2',
              formatter: this.getClientActionsFormatForOffers,
              text: 'Actions',
              footer: columnData => <Pagination page={this.state.offers} handleChangePage={p=>this.getOffers(p, this.state.selectedOrganization)}
              isLoading={this.state.isLoading} />,
              footerAttrs: {
                  colSpan: 5
              }
            }
        ];

        const superSalesColumns = [
            {
              dataField: 'user.initials',
              text: 'Initials',
              footer: columnData => <PaginationInfo page={this.state.offers} />,
              footerAttrs: {
                  colSpan: 3
              }
            },
            {
              dataField: 'user.displayName',
              text: 'Name'
            },
            {
              dataField: 'jobRequest.name',
              text: 'Request',
              formatter: (cell, row) =>  {
                return <Button variant="link" className="text-start p-0 text-dark bg-transparent" onClick={() => this.setState({showOfferDetailsModal:true, selectedOffer:row})
                }>{cell}</Button>
              }
            },
            {
              dataField: 'organization.legalName',
              text: 'Client'
            },
            {
              dataField: 'createdDate',
              text: 'Date'
            },
            {
              dataField: 'companyHourlyRate',
              text: 'Out(€)',
              formatter: (cell, row) => {
                return <InputGroup>
                    <Form.Control name="companyHourlyRate" type="number" data-id={row.code} style={{ 'minWidth': "80px" }}
                    defaultValue={cell} step={0.01} min={0} max={100}
                    onChange={this.handleInputChange}></Form.Control>
                </InputGroup>
              }
            },
            {
              dataField: 'supremeHourlyRate',
              text: 'In(€)',
              formatter: (cell, row) => {
                return <InputGroup>
                    <Form.Control name="supremeHourlyRate" type="number" data-id={row.code} style={{ 'minWidth': "80px" }}
                    defaultValue={cell} step={0.01} min={0} max={100}
                    onChange={this.handleInputChange}></Form.Control>
                </InputGroup>
              }
            },
            {
              dataField: 'dummy2',
              formatter: this.getActionsFormatForOffers,
              text: 'Actions',
              footer: columnData => <Pagination page={this.state.offers} handleChangePage={p=>this.getOffers(p, this.state.selectedOrganization)}
              isLoading={this.state.isLoading} />,
              footerAttrs: {
                  colSpan: 5
              }
            }
        ];

        let selectRow = undefined;
        let columns = null;

        if(isSuperSales) {
            columns = superSalesColumns;
            selectRow = {
              mode: 'checkbox',
              clickToSelect: false,
              selected: this.state.selected,
              onSelect: this.handleOnSelect,
              onSelectAll: this.handleOnSelectAll
            };
        } else if (isSales || isClient)  {
            columns = clientColumns;
        } else if (isManager || isIndividualContractor) {
           columns = managerColumns;
        } else {
            columns = employeeColumns;
        }

        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Your Offers
                                    {isSuperSales?
                                        <Dropdown className="float-end ms-2 text-bf">
                                            <Dropdown.Toggle variant="primary">
                                                <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => this.props.history.push('/my-account/offer')}>New</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {this.setState({showRerouteModal:true})}} disabled={!this.state.selectedOrganization || this.state.selected.length===0}>Reroute</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {this.deleteOffers(this.state.selected)}} disabled={this.state.selected.length===0}>Delete</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.setState({showRejectionModal : true})} disabled={this.state.selected.length===0}>Reject</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    :""}
                                </Card.Title>
                                <p>{isIndividualContractor ? "Here you will see where your profile was offered" : "Here you can see all offers of your employees"}</p>
                                {isSuperSales?
                                    <Row>
                                        <Col lg={6} md={6} className="mb-2">
                                        <Select name="organization" options={this.state.organizations} value={this.state.selectedOrganization}
                                            closeMenuOnSelect={true} className="w-100 react-select-container" isClearable="true" classNamePrefix="react-select"
                                            onChange={(event)=> {this.redirectToOrganization(event, this.props.history)}}/>
                                        </Col>
                                    </Row>
                                : ""}
                                <Row className="mt-3">
                                   <Col lg={12} className="mt-3 mt-md-0">
                                    <BootstrapTable
                                      keyField='code'
                                      classes={"table-hover table-align-middle " + skin}
                                      data={ this.state.offers.content }
                                      noDataIndication="No Offers"
                                      columns={ columns }
                                      bordered={true}
                                      selectRow={ selectRow }
                                      rowClasses={ this.getTrColor }
                                      hover
                                      condensed
                                      isLoading={this.state.isLoading}
                                    />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    {isSuperSales || isClient ?
                        <>
                            <OfferRejectionModal show={this.state.showRejectionModal} handleOfferModalClose={this.handleOfferModalClose} offers={this.state
                            .selectedOffer ? this.state.selectedOffer.code.split("   ") : this.state.selected}/>
                            {this.state.defaultRecipients && this.state.selectedOrganization ?
                                <RerouteModal show={this.state.showRerouteModal} handleModalClose={()=>this.setState({showRerouteModal:false})} offers={this.state
                                .selected} defaultRecipients={this.state.defaultRecipients.get(this.state.selectedOrganization.value)} organization={this.state
                                .selectedOrganization.value}/>
                            :null}
                        </>
                    :null}
                    <InterviewRequestModal show={this.state.showInterviewRequestModal} handleModalClose={()=>{this.setState({showInterviewRequestModal:false})}} offers={[this.state.selectedOffer]}/>
                    <OfferDetailsModal show={this.state.showOfferDetailsModal} handleOfferModalClose={this.handleShowOfferModalClose} offer={this.state.selectedOffer} />
                    <AccountResourceModal show={this.state.showResourceModal} handleResourceModalClose={()=>{this.setState({showResourceModal : false})}} resource={this.state.selectedResource} />
                </Row>
            </>
        )
    }

    renderStatus(status) {
        switch(status) {
            case 'INQUIRY':
                return 'Inquired';
            case 'OFFERED':
                return 'Offered';
            case 'INTERVIEW_SCHEDULED':
                return 'Interview Set';
            case 'INTERVIEWED':
                return "Interviewed";
            case 'DECLINED':
                return "Declined";
            case 'ASSIGNED':
                return "Assigned";
            default:
                return 'foo';
        }
    }

    getTrColor(offer) {
        if (offer.status === 'INQUIRY') {
            return "table-warning";
        } else if (offer.status === 'OFFERED') {
            return 'table-success';
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const code = target.dataset.id;

        let offerValues = this.state.offerValues;
        let specificOfferValues = offerValues.get(code);
        specificOfferValues[name] = value;

        offerValues.set(code, specificOfferValues);

        this.setState({
            offerValues : offerValues
        });
    }

    getActionsFormatForOffers(cell, offer) {
        const config = {
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        };
        return (
            <Dropdown className="float-end ms-2 text-bf">
                <Dropdown.Toggle variant="transparent" className="text-dark">
                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={config}>
                    <Dropdown.Item onClick={() => this.updateOffer(offer.code)}>Update</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.setState({showRejectionModal:true, selectedOffer:offer})}>Reject</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.deleteOffers([offer.code].flat())}>Delete</Dropdown.Item>
                    {offer.status === 'INQUIRY' ?
                        <Dropdown.Item onClick={() => this.confirmOffer(offer.code)}>Confirm</Dropdown.Item>
                    :""}
                    <Dropdown.Item onClick={() => this.setInterview(offer.code)}>Success</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    getClientActionsFormatForOffers(cell, offer) {
        const config = {
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        };
        return (
            <Dropdown className="float-end ms-2 text-bf">
                <Dropdown.Toggle variant="transparent" className="text-dark">
                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={config}>
                    <Dropdown.Item onClick={() => UserHelper.downloadCV(offer.user.code, offer.user.initials, false, this.props.progressBarContext)}>Download</Dropdown.Item>
                    <Dropdown.Item onClick={() => UserHelper.downloadCV(offer.user.code, offer.user.initials, true, this.props.progressBarContext)}>Download Anonymous</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.setState({showRejectionModal:true, selectedOffer:offer})}>Reject</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.setState({showInterviewRequestModal: true, selectedOffer:offer})}>Ask For Interview</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    getOffers(pageNumber, organizationCode) {
        let isSales = UserHelper.isSales();
        let isSuperSales = UserHelper.isSuperSales();
        let isManager = UserHelper.isManager();
        let isClient = UserHelper.isClient();

        let url = "offer";
        if (isSales || isSuperSales || isClient) {
            if (organizationCode) {
                url = "offer/organization/" + organizationCode;
            }
        } else if (isManager) {
            url = "offer/user/organization";
        } else {
            url = "offer/user";
        }

        ApiCall.get(url, {size:10,page:pageNumber}).then((response) => {
            let offerValues = new Map();
            response.data.content.forEach(offer => {
                offerValues.set(offer.code, {"companyHourlyRate":offer.companyHourlyRate,"supremeHourlyRate":offer.supremeHourlyRate,"status":offer.status,
                "userCode" :
                offer.user ? offer.user.code : null});
            });

            this.setState({
                offers: response.data,
                offerValues: offerValues,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getOfferStatuses() {
        ApiCall.get('offer/statuses').then((response) => {
            this.setState({
                offerStatuses: response.data,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    setInterview(code) {
        ApiCall.post('offer/' + code + '/interview/').then((response) => {
            this.props.history.push("/my-account/interviews");
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.', true);
            this.setState({
                isLoading: false
            });
        });
    }

    handleRerouteClose(getOffers) {

        this.setState({
            showRerouteModal:false
        });
    }

    handleOfferModalClose(getOffers) {

        this.setState({
            showRejectionModal:false,
            selectedOffer:null
        });

        if (getOffers) {
            this.getOffers(this.state.offers.number, this.state.selectedOrganization ? this.state.selectedOrganization.value : null);
        }
    }

    handleShowOfferModalClose() {
        this.setState({
            showOfferDetailsModal:false,
        });
    }

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
          this.setState(() => ({
            selected: [...this.state.selected, row.code]
          }));
        } else {
          this.setState(() => ({
            selected: this.state.selected.filter(x => x !== row.code)
          }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map(r => r.code);
        if (isSelect) {
          this.setState(() => ({
            selected: ids
          }));
        } else {
          this.setState(() => ({
            selected: []
          }));
        }
    }

    updateOffer(code) {
        let offerValues = this.state.offerValues;
        let specificOfferValues = offerValues.get(code);

        ApiCall.put('offer',{
            code: code,
            supremeHourlyRate: specificOfferValues.supremeHourlyRate,
            companyHourlyRate: specificOfferValues.companyHourlyRate,
            status: specificOfferValues.status,
            user:{
                code: specificOfferValues.userCode
            }
        }).then((response) => {
            UserHelper.showSuccessToast("Your offer was updated");
            this.getOffers();
            this.setState({
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    deleteOffers(codes) {
        ApiCall.delete('offer', {'codes': codes.join(",")}).then((response) => {
            this.getOffers();
            this.setState({
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    confirmOffer(code) {
        ApiCall.put('offer/confirm/' + code).then((response) => {
            this.getOffers();
            this.setState({
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    redirectToOrganization(event, history) {
        if (event) {
            history.push('/my-account/offers#' + event.value);
            this.setState({selectedOrganization:event});
            this.getOffers(0, event.value);
        } else {
            history.push('/my-account/offers');
            this.setState({selectedOrganization:null});
            this.getOffers(0, null);
        }
    }

    getOrganizations() {
        ApiCall.get('client-organization', {projection : 'supreme.contractors.backend.facade.dto.OfferPageClientOrganizationDtoDefinition', 'sort':'legalName'}).then((response) => {
            let organizations = [];
            let defaultRecipients = new Map();
            for (const elem of response.data.content) {
                organizations.push({value: elem.code, label: elem.legalName});
                defaultRecipients.set(elem.code, elem.defaultRecipients);
            }
            this.setState({
                organizations: organizations,
                defaultRecipients: defaultRecipients,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }
}

export default withRouter(withProgressBarContext(AccountOffers));
