import React, {useState, useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Modal} from 'react-bootstrap';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './InterviewPreparationModal.scss';

export default function InterviewPreparationModal(props) {
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [cachedExpands, setCachedExpands] = useState([]);
    const [nodes, setNodes] = useState([]);

    const onCheck = (value) => {
        setChecked(value);
    };

    const onExpand = async (value) => {
        setExpanded(value);
        const expandedValue = value[value.length - 1];
        setCachedExpands([...cachedExpands, expandedValue])
        if (!expanded.includes(expandedValue) && !cachedExpands.includes(expandedValue)) {
            const update = (array, value, childrenArray) => array?.map(o => o.value === value
                ? { ...o, children: [...o.children, ...childrenArray] }
                : { ...o, children: update(o.children, expandedValue, childrenArray) });
            let result = await getTree(expandedValue);
            const updatedNodes = update(nodes, expandedValue, result);
            setNodes(updatedNodes);
        }
    };

    const saveInterviewPreparation = (event) => {
        let code = props.interview;
        ApiCall.put('interview/prepare/' + code, {preps:checked}).then((response) => {
            props.handleInterviewPreparationModalClose();
            UserHelper.showSuccessToast('Changes saved successfully');
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }

    const getTree = async (href) => {
        let url = process.env.REACT_APP_FACADE_URL + 'interview/preparations';
        if (href) {
            url += '?href='+href;
        }

        const response = await fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        });
        return await response.json();
    }

    useEffect(() => {
      async function fetchData() {
        let result = await getTree('preparations/');
        setNodes(result);
      }
      fetchData();
    }, []);

    return (
        <>
            {props.interview ?
                <Modal centered size="lg" show={props.show} onHide={props.handleInterviewPreparationModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-uppercase fw-bold">Interview Preparation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="show-grid">
                            <Col xs={12} sm={12}>
                                 <CheckboxTree
                                     nodes={nodes}
                                     checked={checked}
                                     expanded={expanded}
                                     onCheck={checked => { onCheck(checked) }}
                                     onExpand={expanded => { onExpand(expanded) }}
                                     showNodeIcon={true}
                                     expandOnClick={true}
                                     checkModel="leaf"
//                                     onClick={(targetNode)=> getTree(targetNode)}
                                 />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col>
                                <Button variant="primary" type="submit" onClick={()=>saveInterviewPreparation()} className="float-end text-uppercase">Save</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            :null}
        </>
    )
}
