import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class PaginationOptions extends Component {
    render() {
        return (
            <div>
                <FontAwesomeIcon icon="th" size="lg" className={this.props.layout === "grid" ? "text-secondary" : "text-dark"} style={{cursor: "pointer"}} onClick={() => this.props.handleChangePage(null, "grid")} />
                <FontAwesomeIcon icon="th-list" size="lg" className={"ms-2 " + (this.props.layout === "list" ? "text-secondary" : "text-dark")} style={{cursor: "pointer"}} onClick={() => this.props.handleChangePage(null, "list")} />
            </div>
        )
    }
}