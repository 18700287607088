import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {faGlobe, faMoneyBillWaveAlt, faEuroSign, faSearch, faUsers, faCrown, faDatabase, faCertificate, faBox, faShoppingCart, faUser, faLock, faUserTie,
faEnvelope, faPhone, faPlus, faBuilding, faCity, faAddressCard, faPiggyBank, faUniversity, faParagraph,faTrash,faSave,faAngleRight, faPencilAlt, faHardHat,
    faGift, faCircle, faCheck, faTruck, faChevronCircleLeft, faChevronCircleRight, faMoneyCheckAlt, faCreditCard, faCalendar, faUserGraduate, faFileContract,
    faCoins, faMapMarkerAlt, faEdit, faTimesCircle, faStar, faBookOpen, faAlignJustify, faLink, faStore, faFlag, faComment, faFilePdf,faSpinner,faArrowLeft,
    faArrowRight, faFileDownload, faBirthdayCake,faEye,faQuestion,faCog, faBan, faBars, faList, faListOl,faRecycle,faHistory,faPlayCircle,faStopCircle,faThumbsUp,
    faEllipsisVertical, faClock, faPalette,
    faThumbsDown, faTh, faThList, faTimes, faSquare, faExclamationCircle, faExclamationTriangle, faCheckCircle, faFile, faDroplet, faFileCode, faSnowflake} from
    '@fortawesome/free-solid-svg-icons';
import {faPaypal, faTwitter, faFacebook, faYoutube, faGoogle, faInstagram, faSkype, faWhatsapp, faViber, faTelegram, faLinkedin} from
'@fortawesome/free-brands-svg-icons';
import {faStar as faRegularStar} from '@fortawesome/free-regular-svg-icons';

import SessionTimeout from './components/Helper/SessionTimeout.js';
import UserHelper from './components/Helper/UserHelper.js';
import { PrivateRoute } from './components/Helper/PrivateRoute.js';
import LoginPage from './components/Page/LoginPage/LoginPage.js';
import RegisterPage from './components/Page/RegisterPage/RegisterPage.js';
import ActivatePage from './components/Page/RegisterPage/ActivatePage.js';
import RecoverPasswordPage from './components/Page/ForgotPassword/RecoverPasswordPage.js';
import ChangePasswordPage from './components/Page/ForgotPassword/ChangePasswordPage.js';
import ApplyPage from './components/Page/Application/ApplyPage.js';
import ResourceReviewPage from './components/Page/ResourceReviewPage/ResourceReviewPage.js';
import ResourceInvoicePage from './components/Page/ResourceInvoicePage/ResourceInvoicePage.js';
import ResourceContractorPage from './components/Page/ResourceContractorPage/ResourceContractorPage.js';
import ResourceCalendarPage from './components/Page/ResourceCalendarPage/ResourceCalendarPage.js';
import AccountPage from './components/Page/AccountPage/AccountPage.js';
import ClientOrganizationNda from './components/Page/ClientOrganizationNda.js';
import PrivacyPage from './components/Page/PrivacyPage.js';
import TermsAndConditionsPage from './components/Page/TermsAndConditionsPage.js';
import ErrorNotFoundPage from './components/Page/Error/ErrorNotFoundPage.js';
import { ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "./_theme.scss";
import ProgressBarProvider from './components/Provider/ProgressBarProvider.js';
import { GoogleOAuthProvider } from '@react-oauth/google';

library.add(faGlobe, faFile, faFileCode, faBox, faBuilding, faUsers, faCrown, faCertificate, faDatabase, faMoneyBillWaveAlt, faEuroSign, faSearch, faSnowflake,
faShoppingCart,faAngleRight, faUser, faPencilAlt, faHardHat, faUserGraduate, faFileContract, faFilePdf, faFileDownload, faBirthdayCake,faBan,faRecycle,
faLock, faCity, faUserTie, faEnvelope, faAddressCard, faPiggyBank, faUniversity,faParagraph,faTrash,faSave,faSpinner, faEye,faQuestion,faArrowLeft,faArrowRight,
faPhone, faPlus, faGift, faCircle, faCheck, faTruck, faChevronCircleLeft, faChevronCircleRight, faMoneyCheckAlt, faCreditCard, faCalendar,  faPaypal, faSkype,
faTwitter, faFacebook, faYoutube, faGoogle, faInstagram, faLinkedin, faCoins, faMapMarkerAlt, faEdit, faTimesCircle, faStar, faRegularStar, faBookOpen, faLink, faDroplet,
faAlignJustify,faCog,faWhatsapp, faViber, faTelegram,faBars, faList, faListOl,faHistory,faPlayCircle,faStopCircle, faThumbsUp, faThumbsDown, faEllipsisVertical,
faClock, faStore, faFlag, faComment, faTh, faThList, faTimes, faSquare, faExclamationCircle, faExclamationTriangle, faCheckCircle, faPalette);

config.searchPseudoElements = true;

function App() {

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <ProgressBarProvider>
                <BrowserRouter>
                    <SessionTimeout isAuthenticated={UserHelper.isUserLoggedIn()} logOut={() => UserHelper.logout()} waitBeforeLogout={60*60*24} waitBeforePrompt={30} />
                    <ToastContainer
                        position="top-right"
                        autoClose={6000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme={UserHelper.getTheme()}
                        toastClassName="rounded-2"
                    />
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="my-account" />}/>
                        <Route exact path="/j_spring_security_logout" render={routeProps => {
                                UserHelper.logout(routeProps.history);
                        }}/>
                        <Route path="/review" component={ResourceReviewPage}/>
                        <Route path="/apply" component={ApplyPage}/>
                        <Route path="/invoice" component={ResourceInvoicePage}/>
                        <Route path="/contractor/:code?" component={ResourceContractorPage}/>
                        <Route path="/calendar" component={ResourceCalendarPage}/>
                        <Route path="/login" component={LoginPage}/>
                        <Route path="/register" component={RegisterPage}/>
                        <Route path="/activate" component={ActivatePage}/>
                        <Route path="/recover" component={RecoverPasswordPage}/>
                        <Route path="/password" component={ChangePasswordPage}/>
                        <Route path="/nda" component={ClientOrganizationNda}/>
                        <Route path="/privacy" component={PrivacyPage}/>
                        <Route path="/terms-and-conditions" component={TermsAndConditionsPage}/>
                        <PrivateRoute path="/my-account/:view?/:code?" component={AccountPage}/>
                        <Route component={ErrorNotFoundPage}/>
                    </Switch>
                </BrowserRouter>
            </ProgressBarProvider>
        </GoogleOAuthProvider>
    );
}
export default App;
