import React, {Component}  from 'react';
import {Form, InputGroup, Row, Col, Button} from 'react-bootstrap';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ProjectForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            technologies: [],
            projectForm: {
                code: null,
                name: null,
                url: null,
                startDate: null,
                endDate: null,
                platform: "",
                database: "",
                role: "",
                teamSize: 0,
                description: "",
                technologies: []
            }
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeProjectForm = this.handleChangeProjectForm.bind(this);
        this.getTechnologies = this.getTechnologies.bind(this);
        this.handleSubmitAddEditProjectForm = this.handleSubmitAddEditProjectForm.bind(this);
        this.clearProjectForm = this.clearProjectForm.bind(this);
    }


    componentDidMount() {
        this.getTechnologies();
        if (this.props.editProject && !UserHelper.isEquivalent(this.props.editProject, this.state.projectForm)) {
            let techno = [];
            if (this.props.editProject) {
                if (this.props.editProject.technologies) {
                    this.props.editProject.technologies.forEach(t => {
                        techno.push({value:t.code, label:t.name});
                    });
                }
            }

            this.setState({
                    projectForm: {
                        code: this.props.editProject ? this.props.editProject.code : null,
                        name: this.props.editProject ? this.props.editProject.name : "",
                        url: this.props.editProject ? this.props.editProject.url : "",
                        startDate: this.props.editProject ? this.props.editProject.startDate : null,
                        endDate: this.props.editProject ? this.props.editProject.endDate : null,
                        platform: this.props.editProject ? this.props.editProject.platform : "",
                        database: this.props.editProject ? this.props.editProject.database : "",
                        role: this.props.editProject ? this.props.editProject.role : "",
                        description: this.props.editProject ? this.props.editProject.description : "",
                        teamSize: this.props.editProject ? this.props.editProject.teamSize : 0,
                        technologies: this.props.editProject ? techno : []
                    }
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        let technologies = [];
        technologies.push(<option disabled value={-1} key={-1}>Please select...</option>);
        technologies.push(this.state.technologies.map(function(title, key){
            return <option key={key} value={title.code}>{title.name}</option>;
        }));

        return (
            <React.Fragment>
                <p>Please use this form to add/edit a project</p>

                <p className="text-danger">Fields marked * are required</p>

                <Form id="projectForm" onSubmit={this.handleSubmitAddEditProjectForm}>
                    <Row>
                        <Col md={5} lg={5} className="border-end">
                             <Form.Group as={Row} controlId="name" className="mb-3">
                                <Col md={12} lg={12}>
                                    <InputGroup>
                                        <Form.Control name="name" type="text" placeholder="Project Name" value={this.state.projectForm.name || ""} required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="url" className="mb-3">
                                <Col md={12} lg={12}>
                                    <InputGroup>
                                        <Form.Control name="url" type="text" placeholder="Project URL" value={this.state.projectForm.url || ""}
                                        onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="url" className="mb-3">
                                <Col md={6} lg={6}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="calendar" /></InputGroup.Text>
                                        <DatePicker
                                          value={this.state.projectForm.startDate || ""}
                                          onChange={(date)=>this.handleInputChange({target:{name:'startDate', value:new Date(date.getTime() - (date.getTimezoneOffset()* 60000)).toISOString().slice(0,7)}})}
                                          className="form-control calendar"
                                          dateFormat="yyyy-MM"
                                          showMonthYearPicker
                                          showFullMonthYearPicker
                                          placeholderText="Start Date"
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={6} lg={6}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="calendar" /></InputGroup.Text>
                                        <DatePicker
                                          value={this.state.projectForm.endDate || ""}
                                          onChange={(date)=>this.handleInputChange({target:{name:'endDate', value:new Date(date.getTime() - (date.getTimezoneOffset()* 60000)).toISOString().slice(0,7)}})}
                                          className="form-control calendar"
                                          dateFormat="yyyy-MM"
                                          showMonthYearPicker
                                          showFullMonthYearPicker
                                          placeholderText="End Date"
                                        />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col md={7} lg={7}>
                            <Form.Group as={Row} controlId="platform" className="mb-3">
                                <Form.Label column md={5} lg={4}>
                                    Platform*
                                </Form.Label>
                                <Col md={7} lg={8}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="box" /></InputGroup.Text>
                                        <Form.Control name="platform" type="text" placeholder="Platform" value={this.state.projectForm.platform || ""} required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="database" className="mb-3">
                                <Form.Label column md={5} lg={4}>
                                    Database*
                                </Form.Label>
                                <Col md={7} lg={8}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="database" /></InputGroup.Text>
                                        <Form.Control name="database" type="text" placeholder="Database" value={this.state.projectForm.database || ""} required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="role" className="mb-3">
                                <Form.Label column md={5} lg={4}>
                                    Role*
                                </Form.Label>
                                <Col md={7} lg={8}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="user-tie" /></InputGroup.Text>
                                        <Form.Control name="role" type="text" placeholder="Role" value={this.state.projectForm.role || ""} required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="description" className="mb-3">
                                <Form.Label column md={5} lg={4}>
                                    Description*
                                </Form.Label>
                                <Col md={7} lg={8}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="file" /></InputGroup.Text>
                                        <Form.Control name="description" as="textarea" placeholder="Describe your responsibilities and the technical features you implemented"
                                        rows={5}
                                        value={this.state.projectForm.description || ""} required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="teamSize" className="mb-3">
                                <Form.Label column md={5} lg={4}>
                                    Team Size*
                                </Form.Label>
                                <Col md={7} lg={8}>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="users" /></InputGroup.Text>
                                        <Form.Control name="teamSize" type="number" placeholder="Team Size" value={this.state.projectForm.teamSize || ""} required onChange={this.handleInputChange} />
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="technologies" className="mb-3">
                                <Form.Label column md={5} lg={4}>
                                    Technologies
                                </Form.Label>
                                <Col md={7} lg={8}>
                                    <InputGroup>
                                        <Select name="technologies" options={this.state.technologies} classNamePrefix="react-select"
                                        closeMenuOnSelect={false} isMulti className="w-100 react-select-container" value={this.state.projectForm.technologies}
                                        onChange={this.handleInputChange}/>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={11} md={11} lg={11}>
                            <Button variant="primary" type="submit" className="float-end text-uppercase">Save</Button>
                        </Col>
                        <Col sm={1} md={1} lg={1}>
                            <Button variant="link" onClick={() =>this.props.cancelProject()} className="float-end">Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        )
    }

    handleChangeProjectForm(field, value) {
        let newProjectForm = this.state.projectForm;
        newProjectForm[field] = value;

        this.setState({
            projectForm: newProjectForm
        });
    }

    handleInputChange(event) {
        if (event) {
            const target = event.target;
            if (target) {
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const name = target.name;
                this.handleChangeProjectForm(name, value);
            } else {
                let newTech = [];
                event.forEach(sel => {
                    newTech.push({value:sel.value, label:sel.label});
                });
                this.handleChangeProjectForm("technologies", newTech);
            }
        }
    }

    getTechnologies() {
        ApiCall.get('technology').then((response) => {

            let tech = [];
            response.data.forEach(technology => {
                tech.push({value:technology.code, label:technology.name});
            })

            this.setState({
                technologies: tech,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    handleSubmitAddEditProjectForm(event) {
        event.preventDefault();

        let technologies = [];
        this.state.projectForm.technologies.forEach(t=> {
            technologies.push({code:t.value});
        });

        let project = {
            code: this.state.projectForm.code,
            name: this.state.projectForm.name,
            url: this.state.projectForm.url,
            startDate: this.state.projectForm.startDate,
            endDate: this.state.projectForm.endDate,
            platform: this.state.projectForm.platform,
            database: this.state.projectForm.database,
            role: this.state.projectForm.role,
            teamSize: this.state.projectForm.teamSize,
            description: this.state.projectForm.description,
            technologies: technologies
        };

        if (this.props.editProject) {
            ApiCall.put('project', project).then(() => {
                this.clearProjectForm();
                this.clearProjectForm();
                this.props.afterEditProject();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        } else {
            ApiCall.post('project', project).then(() => {
                this.clearProjectForm();

                UserHelper.showSuccessToast("Your project was added.");

                this.props.getProjects();
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }
    }

    clearProjectForm() {
        this.setState({
            projectForm: {
                code: null,
                name: "",
                url: "",
                platform: "",
                database: "",
                role: "",
                description: "",
                teamSize: 0,
                technologies: []
            }
        });
    }
}

export default ProjectForm;
