import React, {useContext} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ListerContext} from '../Provider/ListerPageProvider.js';

export default function AppliedFacetsWidget({widgetData}) {
    let listerContext = useContext(ListerContext);
    let appliedFacets = null;

    if (listerContext.state.searchPageData && listerContext.state.searchPageData.appliedFacets) {
        appliedFacets = listerContext.state.searchPageData.appliedFacets;
    }
    
    if (!appliedFacets || appliedFacets.length < 1) {
        return null;
    }

    return (
        <div className="mb-3">
            <h6 className="text-uppercase fw-bold">{widgetData.name}</h6>
            {appliedFacets.map(function(appliedFacet, key){
                return <div key={key} className="text-secondary" style={{cursor: "pointer"}} onClick={ () => listerContext.getProducts(null, null, appliedFacet.removeQuery.url)}>
                    <FontAwesomeIcon icon="xmark" /> <small>{appliedFacet.facetName} : {appliedFacet.facetValueName}</small>
                </div>
                })
            }
        </div>
    );
}
