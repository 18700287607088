import React, {Component} from 'react';
import BootstrapPagination from 'react-bootstrap/Pagination';
import { withRouter } from 'react-router-dom';
import FacetListerLoader from '../Account/View/AccountSearch/FacetListerLoader.js';

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasPreviousPage: props.page ? props.page.number > 0 : false,
            hasNextPage: props.page ? (props.page.number + 1) < props.page.totalPages : false
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);
        this.goToPreviousPage = this.goToPreviousPage.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.page !== prevProps.page) {
            this.setState({
                hasPreviousPage: this.props.page ? this.props.page.number > 0 : false,
                hasNextPage: this.props.page ? (this.props.page.number + 1) < this.props.page.totalPages : false
            });
        }
    }

    render() {
        if (!this.props.page) {
            return null;
        }

        let pagination = [];
        let totalPages, pageNumber = '';

        if (this.props.page) {
            totalPages = this.props.page.totalPages;
            pageNumber = this.props.page.number;
        }

        // Conditions when pagination display looks different
        // 1). There is only one page, no pagination info
        // 2). Number of pages are less than or equal 5,then show 1 2 3 4 5
        // 3). Current Page is the first page, then show [1] 2 3 ... <last page number>
        // 4) Current page is the second page then we show 1,[2],3 ... <last page number>
        // 5) Current page is the third page then we show 1,2,[3] ... <last page number>
        // 6). Current Page is the last page, then show 1 ... <last page number-2> <last page number-1> [<last page number>]
        // 7). Current Page is the page before the last page, then show 1 ... <last page number-2> [<last page number-1>] <last page number>
        // 8). Current Page is the page before the page before the last page, then show 1 ... [<last page number-2>] <last page number-1> <last page number>
        // 9). All other cases display <first page> ...<previous page> <current page> <next page> ... <last page number>

        if (totalPages == null || totalPages <= 1) {
            // 1).	There is only one page: Show nothing
            pagination = '';
        } else if (totalPages <= 5) {
            // 2). Number of pages are less than or equal 5,then show 1 2 3 4 5
            for (let i = 0; i < totalPages; i++) {
                pagination.push(
                    <BootstrapPagination.Item active={i === pageNumber} key={i} onClick={() => this.onPageChange(i)}>{i + 1}</BootstrapPagination.Item>
                );
            }
        } else if (pageNumber === 0) {
            // 3). Current Page is the first page, then show [1] 2 3 ... <last page number>
            pagination.push(
                <BootstrapPagination.Item active key='0' onClick={() => this.onPageChange(0)}>{1}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='1' onClick={() => this.onPageChange(1)}>{2}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='2' onClick={() => this.onPageChange(2)}>{3}</BootstrapPagination.Item>,
                <BootstrapPagination.Ellipsis key='3'/>,
                <BootstrapPagination.Item key='4' onClick={() => this.onPageChange(totalPages - 1)}>{totalPages}</BootstrapPagination.Item>
            );
        } else if (pageNumber === 1) {
            // 4) Current page is the second page then we show 1,[2],3 ... <last page number>
            pagination.push(
                <BootstrapPagination.Item key='0' onClick={() => this.onPageChange(0)}>{1}</BootstrapPagination.Item>,
                <BootstrapPagination.Item active key='1' onClick={() => this.onPageChange(1)}>{2}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='2' onClick={() => this.onPageChange(2)}>{3}</BootstrapPagination.Item>,
                <BootstrapPagination.Ellipsis key='3'/>,
                <BootstrapPagination.Item key='4' onClick={() => this.onPageChange(totalPages - 1)}>{totalPages}</BootstrapPagination.Item>
            );
        } else if (pageNumber === 2) {
            // 5) Current page is the third page then we show 1,2,[3] ... <last page number>
            pagination.push(
                <BootstrapPagination.Item key='0' onClick={() => this.onPageChange(0)}>{1}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='1' onClick={() => this.onPageChange(1)}>{2}</BootstrapPagination.Item>,
                <BootstrapPagination.Item active key='2' onClick={() => this.onPageChange(2)}>{3}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='3' onClick={() => this.onPageChange(3)}>{4}</BootstrapPagination.Item>,
                <BootstrapPagination.Ellipsis key='4'/>,
                <BootstrapPagination.Item key='5' onClick={() => this.onPageChange(totalPages - 1)}>{totalPages}</BootstrapPagination.Item>
            );
        } else if (pageNumber === totalPages - 1) {
            // 6). Current Page is the last page, then show 1 ... <last page number-2> <last page number-1> [<last page number>]
            pagination.push(
                <BootstrapPagination.Item key='0' onClick={() => this.onPageChange(0)}>{1}</BootstrapPagination.Item>,
                <BootstrapPagination.Ellipsis key='2'/>,
                <BootstrapPagination.Item key='3' onClick={() => this.onPageChange(pageNumber - 2)}>{pageNumber - 1}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='4' onClick={() => this.onPageChange(pageNumber - 1)}>{pageNumber}</BootstrapPagination.Item>,
                <BootstrapPagination.Item active key='5' onClick={() => this.onPageChange(pageNumber)}>{pageNumber + 1}</BootstrapPagination.Item>
            );
        } else if (pageNumber === totalPages - 2) {
            // 7). Current Page is the page before the last page, then show 1 ... <last page number-2> [<last page number-1>] <last page number>
            pagination.push(
                <BootstrapPagination.Item key='0' onClick={() => this.onPageChange(0)}>{1}</BootstrapPagination.Item>,
                <BootstrapPagination.Ellipsis key='1'/>,
                <BootstrapPagination.Item key='2' onClick={() => this.onPageChange(pageNumber - 1)}>{pageNumber}</BootstrapPagination.Item>,
                <BootstrapPagination.Item active key='3' onClick={() => this.onPageChange(pageNumber)}>{pageNumber + 1}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='4' onClick={() => this.onPageChange(pageNumber + 1)}>{pageNumber + 2}</BootstrapPagination.Item>
            );
        } else if (pageNumber === totalPages - 3) {
            // 8). Current Page is the page before the page before the last page, then show 1 ... [<last page number-2>] <last page number-1> <last page number>
            pagination.push(
                <BootstrapPagination.Item key='0' onClick={() => this.onPageChange(0)}>{1}</BootstrapPagination.Item>,
                <BootstrapPagination.Ellipsis key='1'/>,
                <BootstrapPagination.Item key='2' onClick={() => this.onPageChange(pageNumber - 1)}>{pageNumber}</BootstrapPagination.Item>,
                <BootstrapPagination.Item active key='3' onClick={() => this.onPageChange(pageNumber)}>{pageNumber + 1}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='4' onClick={() => this.onPageChange(pageNumber + 1)}>{pageNumber + 2}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='5' onClick={() => this.onPageChange(pageNumber + 2)}>{pageNumber + 3}</BootstrapPagination.Item>
            );
        } else {
            // 9). All other cases display <first page> ...<previous page> <current page> <next page> ... <last page number>
            pagination.push(
                <BootstrapPagination.Item key='0' onClick={() => this.onPageChange(0)}>{1}</BootstrapPagination.Item>,
                <BootstrapPagination.Ellipsis key='1'/>,
                <BootstrapPagination.Item key='2' onClick={() => this.onPageChange(pageNumber - 1)}>{pageNumber}</BootstrapPagination.Item>,
                <BootstrapPagination.Item active key='3' onClick={() => this.onPageChange(pageNumber)}>{pageNumber + 1}</BootstrapPagination.Item>,
                <BootstrapPagination.Item key='4' onClick={() => this.onPageChange(pageNumber + 1)}>{pageNumber + 2}</BootstrapPagination.Item>,
                <BootstrapPagination.Ellipsis key='5'/>,
                <BootstrapPagination.Item key='6' onClick={() => this.onPageChange(totalPages - 1)}>{totalPages}</BootstrapPagination.Item>
            );
        }

        return (
            <>
                {this.props.isLoading ?
                    <FacetListerLoader />
                :
                    <BootstrapPagination className="justify-content-center justify-content-md-end mb-0 float-end">
                        {this.state.hasPreviousPage &&
                        <BootstrapPagination.Prev onClick={() => this.goToPreviousPage()} />
                        }

                        { pagination }

                        {this.state.hasNextPage &&
                        <BootstrapPagination.Next onClick={() => this.goToNextPage()} />
                        }
                    </BootstrapPagination>
                }
            </>
        )
    }

    onPageChange(pageNumber) {
        if (!this.props.isLoading) {
            this.props.handleChangePage(pageNumber);
        }
    }

    goToNextPage() {
        this.onPageChange(this.props.page.number + 1);
    }

    goToPreviousPage() {
        this.onPageChange(this.props.page.number - 1);
    }
}

export default withRouter(Pagination);
