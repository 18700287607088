import React from 'react';
import UserHelper from '../../../../components/Helper/UserHelper.js';
import ContractorAssignmentTable from './ContractorAssignmentTable.js';
import ManagerAssignmentTable from './ManagerAssignmentTable.js';
import SalesAssignmentTable from './SalesAssignmentTable.js';
import ClientAssignmentTable from './ClientAssignmentTable.js';

export default function AccountAssignments(props) {

    let isSales = UserHelper.isSales();
    let isClient = UserHelper.isClient();
    let isManager = UserHelper.isManager();
    let isIndividualContractor = UserHelper.isIndividualContractor();
    let isContractor = UserHelper.isContractor();

    return (
        <>
            {isContractor && !(isManager || isIndividualContractor) ? <ContractorAssignmentTable/> :null}
            {isManager || isIndividualContractor ? <ManagerAssignmentTable/> :null}
            {isSales ? <SalesAssignmentTable/> :null}
            {isClient ? <ClientAssignmentTable/> :null}
        </>
    )
}

