import React, {Component} from 'react';
import ContentLoader from "react-content-loader";
import { Rating } from 'react-simple-star-rating';
import {Card, Row, Col} from 'react-bootstrap';
import UserHelper from '../../../../components/Helper/UserHelper.js';

const ProductLoader = () => (

    <Card className="w-100">
        <Card.Body>
            <Row>
                <Col lg={2} xs={9}>
                    <div className="position-relative">
                        <ContentLoader
                            width={190}
                            height={190}
                            viewBox="0 0 190 190"
                            speed={1}
                            backgroundColor="var(--bs-body-bg)"
                            foregroundColor="var(--bs-muted)"
                        >
                            <rect x="0" y="0" rx="6" ry="6" width="190" height="190" />
                        </ContentLoader>
                    </div>
                    <Row className="text-center mt-2 mb-0">
                        <Col>
                            <ContentLoader
                                width={120}
                                height={25}
                                viewBox="0 0 120 25"
                                speed={1}
                                backgroundColor="var(--bs-body-bg)"
                                foregroundColor="var(--bs-muted)"
                            >
                                <rect x="0" y="0" rx="3" ry="3" width="120" height="25" />
                            </ContentLoader>
                        </Col>
                    </Row>
                    <Row className="my-2 mx-auto" style={{marginTop:'-15px'}}>
                        <Col className="text-center"><Rating initialValue={0} emptyColor={UserHelper.isDark() ? "var(--bs-light)" : "var(--bs-primary)"} fillColor={"var(--bs-secondary)"} allowFraction={true} size={30} readonly/></Col>
                    </Row>
                    <Row className="my-2 text-center mx-auto">
                        <Col>
                            <ContentLoader
                                width={100}
                                height={20}
                                viewBox="0 0 100 20"
                                speed={1}
                                backgroundColor="var(--bs-body-bg)"
                                foregroundColor="var(--bs-muted)"
                            >
                                <rect x="0" y="0" rx="3" ry="3" width="20" height="20" />
                                <rect x="25" y="0" rx="3" ry="3" width="20" height="20" />
                                <rect x="50" y="0" rx="3" ry="3" width="20" height="20" />
                                <rect x="75" y="0" rx="3" ry="3" width="20" height="20" />
                            </ContentLoader>
                        </Col>
                    </Row>
                </Col>
                <Col lg={8} xs={12}>
                    <Card.Title className="text-uppercase">
                        <ContentLoader
                            width={300}
                            height={25}
                            viewBox="0 0 300 25"
                            speed={1}
                            backgroundColor="var(--bs-body-bg)"
                            foregroundColor="var(--bs-muted)"
                        >
                            <rect x="0" y="0" rx="3" ry="3" width="300" height="25" />
                        </ContentLoader>
                    </Card.Title>
                    <h6>
                        <ContentLoader
                            width={250}
                            height={25}
                            viewBox="0 0 250 25"
                            speed={1}
                            backgroundColor="var(--bs-body-bg)"
                            foregroundColor="var(--bs-muted)"
                        >
                            <rect x="0" y="0" rx="3" ry="3" width="250" height="25" />
                        </ContentLoader>
                    </h6>
                    <div>
                        <ContentLoader
                            width={762}
                            height={130}
                            viewBox="0 0 762 130"
                            speed={1}
                            backgroundColor="var(--bs-body-bg)"
                            foregroundColor="var(--bs-muted)"
                        >
                            <rect x="0" y="0" rx="3" ry="3" width="763" height="130" />
                        </ContentLoader>
                    </div>
                </Col>
                <Col lg={2} xs={3}>
                    <ContentLoader
                        width={43}
                        height={38}
                        viewBox="0 0 43 38"
                        speed={1}
                        backgroundColor="var(--bs-body-bg)"
                        foregroundColor="var(--bs-muted)"
                        style={{"position":"absolute", "top":"20px", "right":"20px"}}
                    >
                        <rect x="0" y="0" rx="3" ry="3" width="43" height="38" />
                    </ContentLoader>
                </Col>
            </Row>
        </Card.Body>
    </Card>
);

export default class ResourceListerListItemLoader extends Component {
    render() {
        return (
            <Card className="product-list-item w-100 border-0">
                <ProductLoader />
            </Card>
        )
    }
}
