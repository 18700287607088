import React from "react";
import {Form, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function SelectorWidget({widgetData}) {
    return (
        <div className={widgetData.styleClass}>
            <Form.Group className="pe-lg-2 mt-2 mt-lg-0 mb-2 mb-lg-0">
                <InputGroup>
                    <InputGroup.Text><FontAwesomeIcon icon="globe" /></InputGroup.Text>
                    <Form.Select>
                        {Object.keys(widgetData.options).map((entryKey) => {
                            return <option key={entryKey} value={entryKey}>{widgetData.options[entryKey]}</option>
                        })}
                    </Form.Select>
                </InputGroup>
            </Form.Group>
        </div>
    );
}
