import React from "react";
import parse from 'html-react-parser';

export default function ParagraphWidget({widgetData}) {
    let className = widgetData.styleClass;
    return (
        <div className={className}>
            {parse(widgetData.content)}
        </div>
    );
}
