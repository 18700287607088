import React, {useState} from 'react';
import {Table, Form} from 'react-bootstrap';
import TableSectionLoader from '../Loader/TableSectionLoader.js';

export default function BootstrapTable(props) {

    const [anySelected, setAnySelected] = useState(false);
    const [anyExpanded, setAnyExpanded] = useState(false);
    const [expandedRows, setExpandedRows] = useState(new Array(0).fill(false));
    const [selectedRows, setSelectedRows] = useState(new Array(0).fill(false));

    const access = (path, object) => {
      return path.toString().split('.').reduce((o, i) => {
        if (!o[i]) return "";
        return  o[i];
      }, object)
    }

    const handleUpdateAllSelectedRows = (allRowsSelected) => {
        setAnySelected(allRowsSelected);
        setSelectedRows(new Array(props.data.length).fill(allRowsSelected));
    }

    const handleUpdateAllExpandedRows = (allRowsExpanded) => {
        setAnyExpanded(allRowsExpanded);
        setExpandedRows(new Array(props.data.length).fill(allRowsExpanded));
    }

    const handleUpdateExpandedRow = (rowIndex, isExpanded) => {
        let newArray = [ ...expandedRows ];
        newArray[rowIndex] = isExpanded;
        let val = newArray;
        setExpandedRows(val);
        setAnyExpanded(!val.every(item=>item===false));
    }

    let numberOfColumns = props.columns.length + (props.expandRow && props.expandRow.showExpandColumn ? 1 : 0) + (props.selectRow ? 1 : 0);

    return(
        <Table responsive className={props.classes + (props.bordered ? ' table-bordered' : '')}>
          <thead>
            <tr>
              {
                props.expandRow && props.expandRow.showExpandColumn ?
                    <th key="expand" className="expand-cell-header" data-row-selection="true" onClick={()=>handleUpdateAllExpandedRows(!anyExpanded)}>{props.expandRow.expandHeaderColumnRenderer(anyExpanded)}</th>
                :""
              }
              {
                props.selectRow ?
                    <th key="select" className="select-cell-header" data-row-selection="true">
                        <Form.Check onChange={(e)=> {handleUpdateAllSelectedRows(e.target.checked);props.selectRow.onSelectAll(e.target.checked, props.data)}} checked={anySelected}/>
                    </th>
                :""
              }
              {Array.from({ length: props.columns.length }).map((_, index) => (
                <th key={index}>{props.columns[index].text}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.isLoading || !props.data ?
                <TableSectionLoader colSpan={numberOfColumns} numberOfRows={5}/>
            : props.data && props.data.length > 0 ?
                    Array.from({ length: props.data.length }).map((_, rowIndex) => {
                        let row = props.data[rowIndex];
                        return (
                            <React.Fragment key={rowIndex}>
                                <tr className={props.rowClasses ? props.rowClasses(row, rowIndex) : ""}>
                                  {
                                    props.expandRow && props.expandRow.showExpandColumn  ?
                                        <td key={`${rowIndex}-expandIndicator`} className="expand-cell" data-row-selection="true" onClick={()=>handleUpdateExpandedRow(rowIndex, !expandedRows[rowIndex])}>{props.expandRow.expandColumnRenderer(expandedRows[rowIndex])}</td>
                                    :""
                                  }
                                  {
                                    props.selectRow ?
                                        <td key={`${rowIndex}-selectIndicator`} className="select-cell" data-row-selection="true">
                                            <Form.Check onChange={(e)=> {selectedRows[rowIndex]=e.target.checked;props.selectRow.onSelect(row, e.target.checked)}} checked={selectedRows[rowIndex]} />
                                        </td>
                                    :""
                                  }
                                  {Array.from({ length: props.columns.length }).map((_, colIndex) => {
                                    let cell = props.columns[colIndex];
                                    if (cell.formatter) {
                                      return (<td key={`${rowIndex}-${colIndex}`}>{cell.formatter(access(cell.dataField, row),row)}</td>);
                                    } else {
                                      return(<td key={`${rowIndex}-${colIndex}`}>{access(cell.dataField, row)}</td>)
                                    }
                                  })}
                                </tr>
                                {
                                    expandedRows[rowIndex] ?
                                        <tr key={`expanded-${rowIndex}`}><td className="reset-expansion-style" colSpan={props.columns.length+1}>{props.expandRow.renderer(row)}</td></tr>
                                    : ""
                                }
                           </React.Fragment>
                        )})
                :
                    <tr>
                        <td colSpan={numberOfColumns} className="text-center">{props.noDataIndication}</td>
                    </tr>
            }
          </tbody>
          <tfoot>
            <tr>
              {
                props.expandRow && props.expandRow.showExpandColumn ?
                    <th></th>
                :""
              }
              {
                props.selectRow && props.selectRow ?
                    <th></th>
                :""
              }
              {Array.from({ length: props.columns.length }).map((_, index) => {
                let cell = props.columns[index];

                if (cell.footer === '') {
                    return (<th key={index}></th>);
                }
                if (cell.footer !== undefined) {
                    return (<th key={index} {...cell.footerAttrs}>{props.columns[index].footer(cell)}</th>);
                }
                return "";
              })}
            </tr>
          </tfoot>
        </Table>
    );

}