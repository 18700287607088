import React, {Component} from 'react';
import {Container, Card, Row, Col, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AccountCompanyDetails from '../Account/View/AccountLegal/AccountCompanyDetails.js';
import CmsPageProvider from '../cms/CmsPageProvider.js';
import CmsTemplate from '../cms/CmsTemplate.js';
import UserHelper from '../Helper/UserHelper.js';
import GlobalMessagesProvider from '../Provider/GlobalMessagesProvider.js';
import {withProgressBarContext} from '../Provider/ProgressBarProvider.js';

class ClientOrganizationNda extends Component {
    constructor(props){
        super(props);
        this.state = {
            organizationCreated:false,
            countries: [],
            companyDetails: {},
            downloadStarted:false
        };
        this.handleReturnButtonClick = this.handleReturnButtonClick.bind(this);
        this.getCountries = this.getCountries.bind(this);
        this.downloadNda = this.downloadNda.bind(this);
    }

    componentDidMount() {
        this.getCountries();
    }

    render() {
        let customerCode = "ptahchiev@supreme.contractors";
        if (this.props.location && this.props.location.hash !== '') {
            customerCode = this.props.location.hash.substring(1);
        }

        return (
            <CmsPageProvider props={{page:'nda'}}>
                <GlobalMessagesProvider>
                    <CmsTemplate>
                        <main>
                            <Container fluid className="mt-3 mb-3 ps-0 pe-0">
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <AccountCompanyDetails countries={this.state.countries} companyDetails={this.state.companyDetails}
                                        url="client-organization/nda" customerCode={customerCode}
                                        submitCallback={(data, company)=> {
                                            UserHelper.showSuccessToast('Success! Your company was added.');
                                            this.setState({organizationCreated:true, clientOrganizationCode:data.responseData});
                                        }}/>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col sm={12} md={12} lg={12} className="text-center">
                                        <Card>
                                            <Card.Body>
                                                <p>You will be able to download the NDA agreement when you fill in your company details.</p>
                                                <Button variant="secondary" size={'lg'} disabled={!this.state.organizationCreated}
                                                className="text-uppercase me-2" onClick={()=>this.downloadNda()}>
                                                    {this.state.downloadStarted ?
                                                        <FontAwesomeIcon icon="spinner" spin/>:<FontAwesomeIcon icon="file-arrow-down" />
                                                    }
                                                    <span className="ms-1">Download NDA</span>
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </main>
                    </CmsTemplate>
                </GlobalMessagesProvider>
            </CmsPageProvider>
        )
    }

    handleReturnButtonClick(event) {
        event.preventDefault();
        this.props.history.push('/');
    }

    getCountries() {
        UserHelper.getCountries().then(response => {
            if (response.countries && response.countries.length) {
                this.setState({
                    countries: response.countries
                });
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            } else if (response.errorMsg) {
                this.setState({
                    countries: []
                });
            }
        });
    }

    downloadNda = () => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        this.setState({downloadStarted:true});
        fetch(process.env.REACT_APP_FACADE_URL + 'client-organization/nda/' + this.state.clientOrganizationCode , {
            method: "GET",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/json"
            }
        }).then(response => {
            this.setState({downloadStarted:false});
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-NDA.pdf';
                    a.click();
                });
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }
}

export default withProgressBarContext(ClientOrganizationNda);
