import React, {Component} from 'react';
import {Row, Col, Table, Card, Form, InputGroup, ToggleButton, ToggleButtonGroup, Button, ButtonGroup} from 'react-bootstrap';
import Select from 'react-select';
import {withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountCreditNoteDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orderCode: null,
            createdDate: null,
            suppliedDate: null,
            dueDate: null,
            createOrderCode: null,
            hasVat:false,
            vatAbsenceReason:null,
            vatValue:20,
            discountValue:0,
            cart:null,
            cartEntries: new Map(),
            isLoading: false,
            formIsValidated:false,
            notes:'',
        };

        this.updateDiscount = this.updateDiscount.bind(this);
        this.updateTax = this.updateTax.bind(this);
        this.updateCartEntryQuantity = this.updateCartEntryQuantity.bind(this);
        this.switchVat=this.switchVat.bind(this);
        this.getOrder = this.getOrder.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.createAdditionalExpense = this.createAdditionalExpense.bind(this);
        this.getCurrentCart = this.getCurrentCart.bind(this);
        this.createOrUpdateOrder = this.createOrUpdateOrder.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCartEntryInputChange = this.handleCartEntryInputChange.bind(this);
        this.getAllPurchasedOrders = this.getAllPurchasedOrders.bind(this);
        this.getAllAssignments = this.getAllAssignments.bind(this);
    }

    componentDidMount() {
        this.getAllAssignments();
        let isSales = UserHelper.isSales();
        if (isSales) {
            this.getAllPurchasedOrders();
        }
        if (this.props.location && this.props.location.hash) {
            this.getOrder(this.props.location.hash.substring(1));
        } else {
            this.getCurrentCart();
        }
    }

    render() {
        let cartEntries = null;

        if (this.state.isLoading) {
            cartEntries = <TableSectionLoader colSpan={8} numberOfRows={5} />
        } else {
            if (this.state.cart && this.state.cart.entries && this.state.cart.entries.length) {
                cartEntries = this.state.cart.entries.map(function(entry, key) {
                    return (
                        <tr key={entry.entryNumber}>
                            {entry.entity.rate ?
                                <>
                                    <td>{entry.entity.user ? entry.entity.user.displayName : entry.entity.externalContractorDisplayName}</td>
                                    <td>{entry.entity.manager && entry.entity.manager.department && entry.entity.manager.department.organization ? entry.entity.manager
                                    .department.organization.legalName : ''}</td>
                                    <td>{entry.entity.activeFrom}</td>
                                    <td>{entry.entity.activeTo}</td>
                                    <td>{entry.entity.rate.formatted}</td>
                                    <td>
                                        <Form.Control name="quantity" type="number" data-id={entry.entryNumber} defaultValue={entry
                                        .quantity} style={{ width: "80px" }} onChange={this.handleCartEntryInputChange}></Form.Control>
                                    </td>
                                    <td>{entry.totalAmount.formatted}</td>
                                    <td>
                                        <ButtonGroup className="float-end">
                                            <Button className="text-uppercase me-2" variant="primary" onClick={()=>this.updateCartEntryQuantity(entry.entryNumber)
                                                                            }><FontAwesomeIcon icon="floppy-disk" />
                                            </Button>
                                            <Button variant="danger" className="text-uppercase" onClick={()=>this.updateCartEntryQuantity(entry
                                        .entryNumber, 0)}><FontAwesomeIcon icon={"trash"}/>
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </>
                                :
                                <>
                                    <td colSpan="6">{entry.entity.name}</td>
                                    <td>{entry.totalAmount.formatted}</td>
                                    <td>
                                        <ButtonGroup className="float-end">
                                            <Button variant="danger" className="text-uppercase" onClick={()=>this.updateCartEntryQuantity(entry
                                        .entryNumber, 0)}><FontAwesomeIcon icon={"trash"}/>
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </>
                            }
                        </tr>);
                }, this);
            } else {
              cartEntries = <tr><td colSpan="8" className="text-center">You have no entries in your {this.props.type === 'CREDIT_NOTE' ? 'credit note' : 'debit note'}</td></tr>;
            }
        }

        let isSales = UserHelper.isSales();
        return (<>
            <Row className="mt-3">
                <Col sm={12} md={12} className="mt-3 mt-md-0">
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-uppercase fw-bold">
                                {this.state.orderCode ? 'Update ' + (this.props.type === 'CREDIT_NOTE' ? 'Credit Note' : 'Debit Note') + ' ' + this.state
                                .orderCode : 'Create New ' + (this.props.type === 'CREDIT_NOTE' ? 'Credit Note' : 'Debit Note')}
                            </Card.Title>
                            <p>Please create your new {this.props.type === 'CREDIT_NOTE' ? 'credit note' : 'debit note'}</p>
                            <Form id="orderForm" onSubmit={this.createOrUpdateOrder} validated={this.state.formIsValidated}>
                                <Row>
                                    <Col md={12} lg={12}>
                                        <Form.Group as={Row} controlId="code" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                Code*
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <InputGroup>
                                                    <Form.Control name="createOrderCode" type="text" placeholder="Code" defaultValue={this.state.orderCode || ""}
                                                    required={true} onChange={this.handleInputChange} />
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        {isSales ?
                                            <>
                                                <Form.Group as={Row} controlId="createdDate" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Created Date*
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup hasValidation>
                                                            <Form.Control name="createdDate" type="date" placeholder="Created Date" defaultValue={this.state.createdDate
                                                             || ""} className={this.state.selectedOrganization && this.state.selectedOrganization
                                                             .sendInvoiceOnLastDayOfMonth ? "border-warning" :""}
                                                            onChange={this.handleInputChange}/>
                                                              <Form.Control.Feedback type="invalid" className={this.state.selectedOrganization && this.state
                                                              .selectedOrganization.sendInvoiceOnLastDayOfMonth ? "d-block text-warning" :""} required>
                                                                This client asks for invoices to be sent on the last day of the month.
                                                              </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="suppliedDate" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Supplied Date
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup>
                                                            <Form.Control name="suppliedDate" type="date" placeholder="Supplied Date" defaultValue={this.state
                                                            .suppliedDate || ""}
                                                            onChange={this.handleInputChange}/>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="dueDate" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Due Date
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup>
                                                            <Form.Control name="dueDate" type="date" placeholder="Due Date" defaultValue={this.state
                                                            .dueDate || ""}
                                                            onChange={this.handleInputChange}/>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="client" className="mb-3">
                                                    <Form.Label column md={3} lg={3}>
                                                        Order
                                                    </Form.Label>
                                                    <Col md={9} lg={9}>
                                                        <InputGroup>
                                                            <Select name="purchasedOrder" options={this.state.purchasedOrders} classNamePrefix="react-select"
                                                            closeMenuOnSelect={true} className="w-100 react-select-container" required onChange={(event)=> {this
                                                            .setState({selectedOrder : event.value})}}
                                                           />
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                            </>
                                        :""}
                                        <Form.Group as={Row} controlId="vat" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                VAT
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <ToggleButtonGroup aria-label="VAT" name="vat">
                                                  <ToggleButton variant={!this.state.hasVat ? "outline-primary": "primary"} value={true} onChange={() => this.switchVat(true)}>Yes</ToggleButton>
                                                  <ToggleButton variant={this.state.hasVat ? "outline-primary": "primary"} value={false} onChange={() => this.switchVat(false)}>No</ToggleButton>
                                                </ToggleButtonGroup>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="assignment" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                Assignment
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <InputGroup>
                                                    <Select name="assignment" options={this.state.assignments} classNamePrefix="react-select"
                                                    closeMenuOnSelect={true} className="w-100 react-select-container" required
                                                    onChange={(event)=> {this.addToCart(event, false)}}/>
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="additional-expense" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                Additional Expense
                                            </Form.Label>
                                            <Col xs={6} md={6} lg={6}>
                                                <InputGroup>
                                                    <Form.Control name="additionalExpenseName" type="text" placeholder="Name"
                                                    onChange={this.handleInputChange}/>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={4} md={2} lg={2}>
                                                <InputGroup>
                                                    <Form.Control name="additionalExpenseValue" type="number" data-id="additionalExpenseValue"
                                                        onChange={this.handleInputChange} step={0.01} placeholder="Amount (€)">
                                                    </Form.Control>
                                                </InputGroup>
                                            </Col>
                                            <Col xs={2} md={1} lg={1}>
                                                <ButtonGroup className="float-end">
                                                    <Button className="text-uppercase float-end" variant="primary" onClick={()=>this.createAdditionalExpense()
                                                    }><FontAwesomeIcon icon="floppy-disk" />
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Form.Group>
                                        <Table striped bordered hover responsive className="table-align-middle">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Organization</th>
                                                    <th>Start</th>
                                                    <th>End</th>
                                                    <th>Rate</th>
                                                    <th>Hours</th>
                                                    <th>Total</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { cartEntries }
                                                <tr>
                                                    <td colSpan="6" className="text-end">Discount(€)</td>
                                                    <td colSpan="1">
                                                        <InputGroup>
                                                            <Form.Control name="discountValue" type="number" data-id="discount" style={{ width: "80px" }}
                                                                value={this.state.discountValue} onChange={this.handleInputChange} step={0.01}
                                                                min={0}>
                                                            </Form.Control>
                                                        </InputGroup>
                                                    </td>
                                                    <td>
                                                        <ButtonGroup className="float-end">
                                                            <Button className="text-uppercase" variant="primary" onClick={()=>this.updateDiscount()}><FontAwesomeIcon icon="floppy-disk" />
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                                {this.state.hasVat ?
                                                    <tr>
                                                        <td colSpan="5" className="text-end">VAT(%)</td>
                                                        <td colSpan="1" className="text-end">
                                                            <InputGroup>
                                                                <Form.Control name="vatValue" type="number" data-id="vatValue" style={{ width: "80px" }}
                                                                   value={this.state.vatValue} onChange={this.handleInputChange}>
                                                                </Form.Control>
                                                            </InputGroup>
                                                        </td>
                                                        <td colSpan="1">
                                                            {this.state.vatCalculated}
                                                        </td>
                                                        <td>
                                                            <ButtonGroup className="float-end">
                                                                <Button className="text-uppercase" variant="primary" onClick={()=>this.updateTax()}><FontAwesomeIcon
                                                                icon="floppy-disk" />
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>
                                                :
                                                    <tr>
                                                        <td colSpan="1">Reason for the absence of VAT:</td>
                                                        <td colSpan="4">
                                                            <Form.Control name="vatAbsenceReason" data-id="vatAbsenceReason" onChange={this
                                                            .handleInputChange} value={this.state.vatAbsenceReason ? this.state.vatAbsenceReason : ''}>
                                                            </Form.Control>
                                                        </td>
                                                        <td colSpan="1" className="text-end">VAT:</td>
                                                        <td colSpan="1">{this.state.vatCalculated}</td>
                                                        <td>
                                                            <ButtonGroup className="float-end">
                                                                <Button className="text-uppercase" variant="primary" onClick={()=>this.updateTax()}><FontAwesomeIcon
                                                                icon="floppy-disk" />
                                                                </Button>
                                                            </ButtonGroup>
                                                        </td>
                                                    </tr>

                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th colSpan="6">
                                                        <span className="w-100 float-end text-end">TOTAL:</span>
                                                    </th>
                                                    <th colSpan="2">
                                                        {this.state.cart && this.state.cart.totalAmount ? this.state.cart.totalAmount.formatted : null}
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} lg={12}>
                                        <Form.Group as={Row} controlId="code" className="mb-3">
                                            <Form.Label column md={3} lg={3}>
                                                Notes
                                            </Form.Label>
                                            <Col md={9} lg={9}>
                                                <InputGroup>
                                                    <Form.Control name="notes" as="textarea" placeholder="Notes" defaultValue={this.state.notes || ""}
                                                    onChange={this.handleInputChange}/>
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <Button variant="primary" type="submit" className="float-start text-uppercase"
                                        disabled={this.state.isLoading}>Save</Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
        )
    }

    addToCart(event, allAssignments) {
        this.setState({isLoading:true});

        let getEntriesFunc = function() {
            return new Promise((resolve, reject) => {
              resolve(
                 [
                   {
                       '@class': 'io.nemesis.platform.module.commerce.facade.order.dto.CartEntryRequestDto',
                       'entityCode':event.value,
                       'quantity': event.hours === 'null' || event.hours === "0" ? 1 : event.hours,
                       'entityName': event.entityName ? event.entityName : 'assignment',
                       'unitCode':event.unitCode ? event.unitCode : 'hours'
                   }
                 ]
              );
            });
        };

        if (allAssignments) {
            ApiCall.get("client-organization/" + event.value).then((response) => {
                this.setState({
                    selectedOrganization: response.data
                });
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                this.setState({
                    isLoading: false
                });
            });

            getEntriesFunc = function() {
                let assignments = ApiCall.get("assignment/organization/" + event.value, {isPaged:false}).then((response) => {
                    let assignments = [];
                    response.data.content.forEach(assignment => {
                        assignments.push({
                        '@class': 'io.nemesis.platform.module.commerce.facade.order.dto.CartEntryRequestDto',
                         'entityCode':`${assignment.code}`,
                         'quantity': 1,
                         'entityName':'assignment',
                         'unitCode':'hours'
                        });
                    });
                    return assignments;
                }).catch(error => {
                    UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                    this.setState({
                        isLoading: false
                    });
                });

                return assignments;
            };
        }

        getEntriesFunc().then(entries => {
            ApiCall.post("supremeCart/add", {
                code: this.state.cart ? this.state.cart.code : null,
                entries: entries
            }).then((response) => {
                if (this.props.location && this.props.location.hash) {
                    this.getOrder(this.props.location.hash.substring(1));
                } else {
                    this.getCurrentCart();
                }
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
                this.setState({
                    isLoading: false
                });
            });
        });
    }

    handleDateCreatedInputChange(event) {
        this.handleInputChange(event);
        const value = event.target.value;
        let d = new Date(value);
        d.setDate(d.getDate() + 30);

        this.handleDateSuppliedInputChange(event);

        this.setState({'dueDate': d.toISOString().slice(0,10)});
    }

    handleDateSuppliedInputChange(event) {
        this.handleInputChange(event);
        const value = event.target.value;
        this.setState({'suppliedDate' : value});
        this.getAllAssignments(value);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleCartEntryInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const code = parseInt(target.dataset.id, 10);

        let cartEntries = this.state.cartEntries;
        let specificCartEntryValues = cartEntries.get(code);
        if (specificCartEntryValues) {
            specificCartEntryValues[name] = value;

            cartEntries.set(code, specificCartEntryValues);

            this.setState({
                cartEntries : cartEntries
            });
        }
    }


    getOrder(orderCode) {
        this.setState({
            isLoading: true
        });

        ApiCall.get('supremeOrder/' + orderCode, {
        }).then((response) => {
            let cartEntries = new Map();
            if (response.data.cart.entries) {
                response.data.cart.entries.forEach(entry => {
                    cartEntries.set(entry.entryNumber, {'quantity':entry.quantity});
                });
            }
            this.setState({
                orderCode: orderCode,
                createdDate: response.data.created,
                suppliedDate: response.data.supplied,
                dueDate: response.data.dueDate,
                notes: response.data.notes,
                hasVat: response.data.cart.net,
                cart: response.data.cart,
                discountValue : response.data.cart.totalDiscounts.amount,
                vatCalculated: response.data.cart.totalTax ? response.data.cart.totalTax.formatted : '€0',
                vatAbsenceReason: response.data.cart.taxAbsenceReason,
                cartEntries: cartEntries,
                isLoading: false
            });
        }).catch(error => {
            this.setState({
                isLoading: false
            });
        });
    }

    getAllPurchasedOrders() {
        this.setState({
            isLoading: true
        });

        let defaultSize = 50;

        ApiCall.get('supremeOrder/outgoing', {
            size: defaultSize
        }).then((response) => {
            let purchasedOrders = [];
            for (const po of response.data.content) {
                purchasedOrders.push({value: `${po.code}`, label: `${po.code}`});
            }

            this.setState({
                purchasedOrders: purchasedOrders,
                isLoading: false
            });
        }).catch(error => {
            console.log(error);
            this.setState({
                purchasedOrders: null,
                isLoading: false
            });
        });
    }

    getAllAssignments() {
        let url = "assignment/company";
        let isSales = UserHelper.isSales();
        if (isSales) {
            url = "assignment/";
        }

        ApiCall.get(url,{page:0, size: 1000}).then((response) => {
            let assignments = [];
            response.data.content.forEach(assignment => {
                assignments.push({value: `${assignment.code}`, hours: `${assignment.lastMonthHours}`,label: `${assignment.user != null ? assignment.user
                .displayName : assignment.externalContractorDisplayName} - ${assignment
                .manager != null && assignment.manager.department != null ? assignment.manager.department.organization.legalName : ''}`});
            });
            this.setState({
                assignments: assignments,
                isLoading: false
            });
        }).catch(error => {

            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    switchVat(val) {
        let orderCode;
        let url = 'supremeCart/net';
        if (this.props.location && this.props.location.hash) {
            orderCode = this.props.location.hash.substring(1);
            url = 'supremeCart/net/' + orderCode;
        }

        ApiCall.put(url,{},{'net':val}).then((response) => {
            this.setState({hasVat:val});
            if (orderCode) {
                this.getOrder(orderCode);
            } else {
                this.getCurrentCart();
            }
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    updateDiscount() {
        let orderCode;
        let url = 'supremeCart/discount';
        if (this.props.location && this.props.location.hash) {
            orderCode = this.props.location.hash.substring(1);
            url = 'supremeCart/discount/' + orderCode;
        }

        ApiCall.put(url,{},{'discount':this.state.discountValue}).then((response) => {
            if (orderCode) {
                this.getOrder(orderCode);
            } else {
                this.getCurrentCart();
            }
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    updateTax() {
        let orderCode;
        let url = 'supremeCart/tax';
        if (this.props.location && this.props.location.hash) {
            orderCode = this.props.location.hash.substring(1);
            url = 'supremeCart/tax/' + orderCode;
        }

        ApiCall.put(url,{},{'tax':this.state.vatValue, 'taxAbsenceReason':this.state.vatAbsenceReason}).then((response) => {
            if (orderCode) {
                this.getOrder(orderCode);
            } else {
                this.getCurrentCart();
            }
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    updateCartEntryQuantity(entryNumber, qty) {
        this.setState({isLoading:true});

        let specificCartEntryValues = this.state.cartEntries.get(entryNumber);

        if (qty == null) {
            qty = specificCartEntryValues.quantity;
        }

        ApiCall.put('supremeCart/entry',{
            code: this.state.cart ? this.state.cart.code : null,
            entries: [
                {
                    '@class': 'io.nemesis.platform.module.commerce.facade.order.dto.CartEntryUpdateRequestDto',
                    'entryNumber':entryNumber,
                    'quantity': qty
                }
            ]
        }).then((response) => {
            if (this.state.orderCode) {
                this.getOrder(this.state.orderCode);
            } else {
                this.getCurrentCart();
            }
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    getCurrentCart() {
        ApiCall.get('cart/current').then((response) => {
            let cartEntries = new Map();
            if (response.data.entries) {
                response.data.entries.forEach(entry => {
                    cartEntries.set(entry.entryNumber, {'quantity':entry.quantity});
                });
            }
            this.setState({
                cart: response.data,
                hasVat: response.data.net,
                cartEntries: cartEntries,
                discountValue : response.data.totalDiscounts.amount,
                vatCalculated: response.data.totalTax.formatted,
                vatAbsenceReason: response.data.taxAbsenceReason,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    createOrUpdateOrder(event) {
        event.preventDefault();
        this.setState({isLoading:true, formIsValidated:true});

        let params = new Map();
        params.set("notes", this.state.notes);
        params.set("orderType", this.props.type);
        params.set("orderCode", this.state.selectedOrder);
        params.set("createdDate", this.state.createdDate);
        params.set("suppliedDate", this.state.suppliedDate);
        params.set("dueDate", this.state.dueDate);
        let url = 'supremeCart/placeOrderWithCode/' + (this.state.createOrderCode ? this.state.createOrderCode : '');
        if (this.state.orderCode) {
            url = 'supremeCart/updateOrderWithCode/' + this.state.orderCode;
            if (this.state.createOrderCode) {
                params.set("newCode", this.state.createOrderCode);
            }
        }

        ApiCall.post(url, Object.fromEntries(params)).then(() => {
            this.props.history.push({pathname:"/my-account/orders",state: { globalInfoMsgs: [this.state.orderCode ? "Order was updated" : "Order was created"]
            }});
            this.setState({isLoading:false, formIsValidated:false});
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.',
                isLoading:false,
                formIsValidated:false
            });
        });
    }

    createAdditionalExpense() {

        let params = {"name" : this.state.additionalExpenseName, "amount" : this.state.additionalExpenseValue};

        ApiCall.post("supremeCart/additional-expense", params).then((response) => {
        console.log(response.data);

            this.addToCart({"entityName": "additional_expense", "hours": 1,"value": response.data.entityCode, 'unitCode': 'pieces'}, false);

            this.setState({isLoading:false, formIsValidated:false});
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.',
                isLoading:false,
                formIsValidated:false
            });
        });
    }
}

export default withRouter(withProgressBarContext(AccountCreditNoteDetails));
