import ApiCall from './ApiCall.js';
import ToastMsg from './ToastMsg.js';
import { toast } from 'react-toastify';

export default class UserHelper {

    static isDark = () => {
        let storedTheme = localStorage.getItem("theme");
        let prefersDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

        return storedTheme === "dark" || (storedTheme === null && prefersDark);
    };

    static getTheme() {
        return this.isDark() ? 'dark' : 'light';
    }

    static toggleTheme() {
        this.setTheme(this.getTheme() === 'light');
    }

    static setTheme(isDark) {
        ApiCall.put('profile/theme', {}, {
            theme: isDark ? 'dark' : 'light'
        }).then((response) => {
            if (isDark) {
                localStorage.setItem("theme", "dark");
                document.documentElement.setAttribute("data-bs-theme", "dark");
            } else {
                localStorage.setItem("theme", "light");
                document.documentElement.setAttribute("data-bs-theme", "light");
            }
        }).catch(error => {
            this.showErrorToast(error && error.message ? error.message : this.props.i18n.t('error.occurred.please.try.again.later'));
        });
    }

    static login = async(username, password, mfaCode) =>  {
        let headers = {
            'X-Nemesis-Username': username,
            'X-Nemesis-Password': password
        };

        if (mfaCode) {
            headers['X-Nemesis-MFACode'] = mfaCode;
        }

        try {
            let response = await ApiCall.get('auth', null, null, null, headers);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("authorities", response.data.authorities);
            localStorage.setItem("expiryTime", response.data.expiryTime);
            if (response.data.settings) {
                localStorage.setItem("theme", response.data.settings.theme);
                if (response.data.settings.theme) {
                    document.documentElement.setAttribute("data-bs-theme", response.data.settings.theme);
                }
                if (response.data.settings.user) {
                    localStorage.setItem("user", JSON.stringify(response.data.settings.user));
                }
            }
            await ApiCall.post('user/login/success');
        } catch (error) {
            return error;
        }
    };


    static isEquivalent(a, b) {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }

    static register = async(customerDetails) =>  {
        try {
            await ApiCall.post('account/register', customerDetails);
        } catch (error) {
            return error;
        }
    };

    static logout(history, state) {
        localStorage.clear();
        
        if (history) {
            history.push({pathname: '/login', state: state});
            window.location.reload();
        }
    }

    static isUserLoggedIn() {
        if (localStorage.getItem("authorities") === null) {
            return false;
        }

        return !localStorage.getItem("authorities").includes("ROLE_ANONYMOUS");
    }

    static userHasRole(role){
        if (localStorage.getItem("authorities") === null) {
            return false;
        }

        return localStorage.getItem("authorities").includes(role);
    }

    static isCustomer() {
        return this.userHasRole('ROLE_CUSTOMERGROUP');
    }

    static isSales() {
        return this.userHasRole('ROLE_SALESGROUP');
    }

    static isManager() {
        return this.userHasRole('ROLE_MANAGERGROUP');
    }

    static isSuperSales() {
        return this.userHasRole('ROLE_SUPERSALESGROUP');
    }

    static isContractor() {
        return this.userHasRole('ROLE_CONTRACTORGROUP');
    }

    static isAccountant() {
        return this.userHasRole('ROLE_ACCOUNTANTGROUP');
    }

    static isIndividualContractor() {
        return this.userHasRole('ROLE_INDIVIDUAL_CONTRACTOR_GROUP');
    }

    static isClient() {
        return this.userHasRole('ROLE_CLIENTGROUP');
    }


    static getLocales = async() =>  {
        try {
            let response = await ApiCall.get('language/all', {
            });
            return {
                locales: response.data
            };
        } catch (error) {
            return {
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static getTechnologies = async() =>  {
        try {
            let response = await ApiCall.get('technology', {});
            return {
                technologies: response.data
            };
        } catch (error) {
            return {
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static getLevels = async() =>  {
        try {
            let response = await ApiCall.get('technology/levels', {
            });
            return {
                levels: response.data
            };
        } catch (error) {
            return {
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static getCustomerCode() {
        if(localStorage.getItem("user")) {
            return JSON.parse(localStorage.getItem("user")).code;
        }
        return null;
    }

    static getTitles = async() =>  {
        try {
            let response = await ApiCall.get('user/title', {
            });
            return {
                titles: response.data
            };
        } catch (error) {
            return {
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static getCountries = async() =>  {
        try {
            let response = await ApiCall.get('i18n/country', {
            });
            return {
                countries: response.data
            };
        } catch (error) {
            return {
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static getDeliveryCountries = async() =>  {
        try {
            let response = await ApiCall.get('delivery/deliveryCountries', {
            });
            return {
                countries: response.data
            };
        } catch (error) {
            return {
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static getRegions = async(countryCode) => {
        try {
            let response = await ApiCall.get('delivery/deliveryCountry/regions', {
                countryCode: countryCode
            });
            return {
                regions: response.data
            };
        } catch (error) {
            return {
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static getCurrentLocale = async() =>  {
        try {
            let response = await ApiCall.get('i18n/locale/current', {
            });

            return {
                locale: response.data
            };
        } catch (error) {
            return {
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static formatDate = (n, width, z) => {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    static updateSessionLocale = async(preferredLocale) =>  {
        try {
            await ApiCall.post('user/updateSessionLocale', null, null, {
                preferredLocale: preferredLocale,
                defaultLocale: "en_GB"
            });

            localStorage.setItem("currentLocale", preferredLocale);

            return {
                success: true
            };
        } catch (error) {
            return {
                success: false,
                errorMsg: error.response && error.response.data && error.response.data.message ? error.response.data.message : 'An error occurred. Please try again later.'
            }
        }
    };

    static downloadCV(username, initials, anonymized, progressBarContext) {
        progressBarContext.dispatch({type: 'update', progress: 20});
        fetch(process.env.REACT_APP_FACADE_URL + 'experience/cv?username=' + encodeURIComponent(username) + '&anonymized=' + anonymized, {
            method: "GET",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'CV-' + initials.split('.').join('').replace(' ','') + '.pdf';
                    a.click();
                });
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
            progressBarContext.dispatch({type: 'update', progress: 100});
        });
    }

    static showInfoToast(msg) {
        toast(<ToastMsg msg={msg} variant="info"/>, { type: "info", theme: this.getTheme() });
    }

    static showErrorToast(msg) {
        toast(<ToastMsg msg={msg} variant="error"/>, { type: "error", theme: this.getTheme() });
    }

    static showWarningToast(msg) {
        toast(<ToastMsg msg={msg} variant="warning"/>, { type: "warning", theme: this.getTheme() });
    }

    static showSuccessToast(msg) {
        toast(<ToastMsg msg={msg} variant="success"/>, { type: "success", theme: this.getTheme() });
    }

}
