import React, {Component} from 'react';
import {Row, Col, Table, Card, Form, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApiCall from '../../../Helper/ApiCall.js';
import UserHelper from '../../../Helper/UserHelper.js';
import TableSectionLoader from '../../../Loader/TableSectionLoader.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

class AccountAssignmentTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [],
            assignmentValues: new Map(),
            isLoading: true
        };

        this.getAssignmentClass = this.getAssignmentClass.bind(this);
        this.handleAssignmentInputChange = this.handleAssignmentInputChange.bind(this);
        this.saveLastMonthHours = this.saveLastMonthHours.bind(this);
        this.getAssignmentsInCurrentCompany = this.getAssignmentsInCurrentCompany.bind(this);
    }

    componentDidMount() {
        this.getAssignmentsInCurrentCompany(0);
    }

    render() {
        let assignments = null;

        if (this.state.isLoading) {
            assignments = <TableSectionLoader colSpan={7} numberOfRows={5}/>
        } else {
            if (this.state.assignments && this.state.assignments.content && this.state.assignments.content.length) {
                assignments = this.state.assignments.content.map(function(assignment, key) {
                    return (
                        <tr key={key} className={this.getAssignmentClass(assignment)}>
                            <td>{assignment.user ? assignment.user.displayName : ''}</td>
                            <td>{assignment.manager && assignment.manager.department && assignment.manager.department.organization ? assignment.manager
                            .department.organization.legalName : ''}</td>
                            <td>{assignment.activeFrom}</td>
                            <td>{assignment.activeTo}</td>
                            <td>&euro;{assignment.companyHourlyRate}</td>
                            <td>
                                <Form.Control name="lastMonthHours" type="number" data-id={assignment.code} defaultValue={assignment
                                .lastMonthHours} style={{ width: "80px" }} onChange={this.handleAssignmentInputChange}></Form.Control>
                            </td>
                            <td>
                                <Dropdown className="float-end ms-2 text-bf">
                                  <Dropdown.Toggle variant="primary">
                                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.saveLastMonthHours(assignment.code)}>Save</Dropdown.Item>
                                    <Dropdown.Item onClick={() => this.downloadAnnex(assignment.code)}>Download Annex</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>);
                }, this);
            } else {
              assignments = <tr><td colSpan="7" className="text-center">You have no assignments in your company</td></tr>;
            }
        }

        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Company Assignments
                                </Card.Title>
                                <p>Here you can see the assignments in your company</p>
                                <Table bordered hover responsive className="table-align-middle">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Organization</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Rate</th>
                                            <th>Hours</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { assignments }
                                    </tbody>
                                    {this.state.assignments && this.state.assignments.content && this.state.assignments.content.length > 0 ?
                                        <tfoot>
                                            <tr>
                                                <th colSpan="3">
                                                    <PaginationInfo page={this.state.assignments} />
                                                </th>
                                                <th colSpan="4">
                                                    <div className="float-end w-50">
                                                        <Pagination page={this.state.assignments} handleChangePage={this.getAssignmentsInCurrentCompany} isLoading={this.state
                                                        .isLoading} />
                                                    </div>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    :null}
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    getAssignmentClass(assignment) {
        if (new Date(assignment.activeTo) < new Date()) {
            return "table-danger";
        } else {
            if (new Date(assignment.activeTo) < new Date(new Date().setDate(new Date().getDate()+14))) {
                return "table-warning";
            } else {
                return "table-success";
            }
        }
    }

    handleAssignmentInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const code = target.dataset.id;

        let assignmentValues = this.state.assignmentValues;
        let specificAssignmentValues = assignmentValues.get(code);
        specificAssignmentValues[name] = value;

        assignmentValues.set(code, specificAssignmentValues);

        this.setState({
            assignmentValues : assignmentValues
        });
    }

    getAssignmentsInCurrentCompany(pageNumber) {
        ApiCall.get('assignment/company',{page:pageNumber}).then((response) => {
            let assignmentValues = new Map();
            response.data.content.forEach(assignment => {
                assignmentValues.set(assignment.code, {'lastMonthHours':0});
            });
            this.setState({
                assignments: response.data,
                assignmentValues:  assignmentValues,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }

    downloadAnnex(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        fetch(process.env.REACT_APP_FACADE_URL + 'assignment/download/' + code, {
            method: "GET",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-Annex.pdf';
                    a.click();
                });
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    saveLastMonthHours(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        let assignmentValues = this.state.assignmentValues;
        let specificAssignmentValues = assignmentValues.get(code);

        ApiCall.put('assignment/hours/' + code,null, {lastMonthHours:specificAssignmentValues.lastMonthHours}).then((response) => {
            UserHelper.showSuccessToast('Success.');
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
        });
    }
}

export default withProgressBarContext(AccountAssignmentTable);
