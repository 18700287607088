import axios from 'axios';
import qs from 'qs';
import UserHelper from '../Helper/UserHelper.js';

const instance = axios.create();
const createHistory = require("history").createBrowserHistory;
let baseUrl = null;
export default class ApiCall {

  static performGet(url, queryParams, contentType, progressBar, updateProgress, doResult, data, headers) {

    progressBar.dispatch({type: 'update', progress: 0});
    this.requestHelper(url, 'GET', data, queryParams, contentType, headers)
        .then((response) => {
            doResult(response);
            progressBar.dispatch({type: 'update', progress: 0});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            progressBar.dispatch({type: 'update', progress: 0});
        });
  }

  static get(url, queryParams, contentType, data, headers, updateProgress, increaseProgress) {
    return this.requestHelper(url, 'GET', data, queryParams, contentType, headers);
  }

  static post(url, data, contentType, queryParams) {
    return this.requestHelper(url, 'POST', data, queryParams , contentType);
  }

  static performPut(url, data, progressBar, doResult, queryParams, contentType, headers) {
    progressBar.dispatch({type: 'update', progress: 20});
    return this.requestHelper(url, 'PUT', data, queryParams, contentType, headers)
        .then((response) => {
            doResult(response);
            progressBar.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            progressBar.dispatch({type: 'update', progress: 100});
        });
  }

  static put(url, data, queryParams, contentType, headers) {
    return this.requestHelper(url, 'PUT', data, queryParams, contentType, headers);
  }

  static delete(url, queryParams, data) {
    return this.requestHelper(url, 'DELETE', data, queryParams, null);
  }

  static patch(url, data, contentType) {
    return this.requestHelper(url, 'PATCH', data, null, contentType);
  }

  static requestHelper(url, method, data, params, contentType, headers) {
    return instance({
      url: this.parseTemplatedUrl(url),
      method: method,
      baseURL: this.getRestUrl(),
      headers: this.getHeaders(contentType, headers),
      data: data,
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
  }

  static getRestUrl() {
    if (!baseUrl) {
      baseUrl = process.env.REACT_APP_FACADE_URL;
    }

    return baseUrl;
  }

  static getHeaders(contentType, headers) {
    let result = {};

    if (headers) {
      result = headers;
    }
    result['Content-Type'] = contentType || 'application/json';

    let ts = new Date().getTime();
    let expiryTime = localStorage.getItem("expiryTime");

    if (expiryTime && ts >= expiryTime) {
        UserHelper.logout(createHistory());
    }

    let nemesisToken = localStorage.getItem("token");
    if (nemesisToken) {
      result['X-Nemesis-Token'] = nemesisToken;
    }

    return result;
  }

  static parseTemplatedUrl(url) {
    return url.replace(new RegExp('({.*})', 'g'), '');
  }
}
