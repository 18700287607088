import React, {useEffect,useState} from "react";
import {Toast} from 'react-bootstrap';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import Faq from './Faq.js';
import parse from 'html-react-parser';

export default function SalesNotifications(props) {

    let [toasts,setToasts] = useState([]);

    useEffect(()=> {
        if (props.isFirstDayOfMonth) {
            toasts.push({id: 1, name: "Today is the first day of the month. Please <a href='/my-account/order/new'>create invoices</a> and send to all the clients."});
        }
        if (props.isLastDayOfMonth) {
            ApiCall.get('client-organization/preferLastDayOfMonthInvoice').then((response) => {
                let toastMsg="Today is the last day of the month. Please <a href='/my-account/order/new'>create invoices</a> and send to the following clients:";
                toastMsg+="<ul>";
                for (const [key, value] of Object.entries(response.data)) {
                    toastMsg+="<li>" + value + "</li>";
                }
                toastMsg+="</ul>";
                let newToasts = [...toasts];
                newToasts.push({id: 2, name : toastMsg});
                setToasts(newToasts);
            }).catch(error => {
                UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            });
        }
    }, [])

    const renderToasts = (t) => {
        if (t.length > 0) {
            return t.map(toast => {
                return (
                    <Toast className="seen w-100 mt-1" animation={true} key={toast.id}>
                        <Toast.Header closeButton={false}>
                            <strong className="me-auto">Customer Service</strong>
                            <small>just now</small>
                        </Toast.Header>
                        <Toast.Body>
                            <div>{parse(toast.name)}</div>
                            <p className="signature">
                                <span>Kind regards</span>
                                Customer Service
                            </p>
                        </Toast.Body>
                    </Toast>
                )
            })
        } else {
            return <Faq/>
        }
    }

    return (
        <>{ renderToasts(toasts) }</>
    );
}
