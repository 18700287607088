import React, {Component} from 'react';
import {Row, Col, Table, Card} from 'react-bootstrap';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import SectionLoader from '../../../Loader/SectionLoader.js';

class AccountProjects extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [],
            isLoading: true
        };

        this.getCurrentUser = this.getCurrentUser.bind(this);
        this.getSkillCompetences = this.getSkillCompetences.bind(this);
    }

    componentDidMount() {
        this.getCurrentUser();
        this.getSkillCompetences();
    }

    render() {
        let resources = null;

        if (this.state.isLoading) {
            resources = <tr><td><SectionLoader /></td></tr>;
        } else {
            if (this.state.skillCompetences && this.state.skillCompetences.length) {
                resources = this.state.resources.map(function(resource, key) {
                    return (
                        <tr key={key}>
                            <td></td>
                            <td>{resource.name}</td>
                            <td>{resource.role}</td>
                            <td>{resource.project}</td>
                            <td>{resource.hourlyRate}</td>
                        </tr>);
                }, this);
            } else {
              resources = <tr><td colSpan="5" className="text-center">You have no active projects in your company</td></tr>;
            }
        }


        return (
            <>
                <Row className="mt-3">
                    <Col sm={12} md={12} className="mt-3 mt-md-0">
                        <Card>
                            <Card.Body>
                                <Card.Title className="text-uppercase fw-bold">
                                    Company Projects
                                </Card.Title>
                                <p>Here you can see the projects in your company</p>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Role</th>
                                            <th>Project</th>
                                            <th>Hourly Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { resources }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    getCurrentUser() {
        ApiCall.get('user/getCurrentUser', {
            projection: "supreme.contractors.backend.facade.dto.ContractorUserDetailsDtoDefinition"
        }).then((response) => {
            let currentUser = response.data;

            this.setState({
                profileRole: currentUser.role,
                profileSummary: currentUser.summary,
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');

            this.setState({
                profileInfoMsg: null
            });
        });
    }

    getSkillCompetences() {
        ApiCall.get('skill-competence').then((response) => {
            this.setState({
                skillCompetences: response.data,
                isLoading: false
            });
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
            this.setState({
                isLoading: false
            });
        });
    }
}

export default AccountProjects;
