import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';
import ResourceLister from './ResourceLister.js';
import ResourceListerPageProvider from "./ResourceListerPageProvider";
import SearchForm from './SearchForm.js';
import './AccountSearch.scss';
import LayoutSortToolbar from '../../../Pagination/LayoutSortToolbar.js';
import PaginationToolbar from '../../../Pagination/PaginationToolbar.js';

class AccountSearch extends Component {
    render() {
        return (
            <ResourceListerPageProvider>
                <SearchForm/>
                <LayoutSortToolbar/>
                <ResourceLister/>
                <PaginationToolbar/>
            </ResourceListerPageProvider>
        )
    }
}

export default withRouter(withProgressBarContext(AccountSearch));
