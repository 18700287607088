import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import CmsPageProvider from '../../cms/CmsPageProvider.js';
import CmsTemplate from '../../cms/CmsTemplate.js';
import GlobalMessagesProvider from '../../Provider/GlobalMessagesProvider.js';
import UserHelper from '../../Helper/UserHelper.js';
import RecoverPassword from '../../User/Recover/RecoverPassword.js';

export default class RecoverPasswordPage extends Component {
    constructor(props){
        super(props);

        if (UserHelper.isUserLoggedIn()) {
            this.props.history.push('/my-account');
        }
    }

    render() {
        return (
            <CmsPageProvider props={{page:'forgottenPassword'}}>
                <GlobalMessagesProvider>
                    <CmsTemplate>
                        <main>
                            <Container fluid className="mt-3 mb-3 ps-0 pe-0">
                                <Row className="mt-3">
                                    <Col sm={12} md={12}>
                                        <RecoverPassword />
                                    </Col>
                                </Row>
                            </Container>
                        </main>
                    </CmsTemplate>
                </GlobalMessagesProvider>
            </CmsPageProvider>
        )
    }
}
