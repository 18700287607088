import React from 'react';
import "./DarkModeSwitcher.css";
import UserHelper from '../../Helper/UserHelper.js';
import { ChangeEventHandler } from "react";

// 1
const setDark = () => {

  // 2
  localStorage.setItem("theme", "dark");

  // 3
  document.documentElement.setAttribute("data-bs-theme", "dark");
};

const setLight = () => {
  localStorage.setItem("theme", "light");
  document.documentElement.setAttribute("data-bs-theme", "light");
};

// 4
const storedTheme = localStorage.getItem("theme");

const prefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const defaultDark = storedTheme === "dark" || (storedTheme === null && prefersDark);
if (defaultDark) {
  setDark();
} else {
  setLight();
}

const toggleTheme: ChangeEventHandler<HTMLInputElement> = (e) => {
    UserHelper.setTheme(e.target.checked);
};


const DarkModeSwitcher = () => {
  return (
    <div className="toggle-theme-wrapper">
      <span role="img" aria-label="Light mode">☀️</span>
      <label className="toggle-theme mb-0" htmlFor="checkbox">
        <input
          type="checkbox"
          id="checkbox"
          onChange={toggleTheme}
          defaultChecked={storedTheme === "dark"}
        />
        <div className="slider round"></div>
      </label>
      <span role="img" aria-label="Dark mode">🌒</span>
    </div>
  );
};

export default DarkModeSwitcher;
