import {Component} from "react";
import * as React from 'react';
import ApiCall from '../Helper/ApiCall.js';
import * as changeCase from "change-case";
import ContentLoader from "react-content-loader";

export default class FacetWidget extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            facetConfiguration: null
        };
    }

    render () {
        if (this.state.facetConfiguration) {
            let pascalCasePath = changeCase.pascalCase(this.state.facetConfiguration.facetProfile.type);
            try {
                let FacetTypeWidget = require('./' + pascalCasePath + '.js').default;
                return (
                    <FacetTypeWidget widgetData={this.props.widgetData}>
                        {this.props.children}
                    </FacetTypeWidget>);
            } catch (e) {
                return <div> FacetTypeWidget not found, please create a default component into ./{pascalCasePath}.js</div>
            }
        } else {
            return <div className="mb-2">
                <ContentLoader
                           height={160}
                           width={135}
                           speed={2}
                           ariaLabel={false}
                           >
                </ContentLoader>
            </div>
        }
    }

    componentDidMount() {
        this.getFacets();
    }

    getFacets() {
        this.setState({
            isLoading: true
        });

        ApiCall.get('facet/configuration', {
            facetCode: this.props.widgetData.facetCode,
            categoryCode: 'womens'
        }).then(response => {
            this.setState({
                facetConfiguration: response.data,
                isLoading: false
            });
        }).catch(error => {
            console.log(error);
            this.setState({
                isLoading: false
            });
        });
    }

}
