import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Card, Form, Row, Col, InputGroup, Button, Alert, OverlayTrigger, Tooltip} from 'react-bootstrap';
import DataObjectParser from "dataobject-parser";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../Helper/UserHelper.js';
import ApiCall from '../../Helper/ApiCall.js';
import {withProgressBarContext} from '../../Provider/ProgressBarProvider.js';

import "./Register.scss";

class Register extends Component {
    constructor(props){
        super(props);

        this.state = {
            invitationResponses : ['SUPREME-CONTRACTOR-', 'SUPREME-SALES-'],
            form: {
                invitationCode: '',
                title: {},
                firstName: null,
                lastName: null,
                username: null,
                password: null,
                repeatPassword: null,
                department : {
                    "@class": "io.nemesis.platform.module.business.facade.dto.DepartmentDto",
                    code : null
                }
            },
            titles: [],
            department: null,
            errorMsg: null,
            isLoading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRegisterErrors = this.handleRegisterErrors.bind(this);
        this.getApiResponseData = this.getApiResponseData.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.getTitles = this.getTitles.bind(this);
        this.getDepartment = this.getDepartment.bind(this);
        this.performRegister = this.performRegister.bind(this);
    }

    componentDidMount() {

        this.getTitles();

        this.setState({
            form: {
                enabled: true,
                accountNonExpired: true,
                accountNonLocked: false,
                credentialsNonExpired: true,
                department: {
                    "@class": "io.nemesis.platform.module.business.facade.dto.DepartmentDto",
                    code:this.props.location && this.props.location.hash ? this.props.location.hash.substring(1) : null
                }
            }
        });

        if (this.props.location && this.props.location.hash) {
            this.getDepartment(this.props.location.hash.substring(1));
        }

    }

    render() {
        let titleOptions = this.state.titles.map(function(title, key){
            return <option key={key} value={title.code}>{title.name}</option>;
        });

        return (
            <Card>
                <Card.Body>
                    <Card.Title className="text-uppercase fw-bold h6">
                        Create an account
                    </Card.Title>
                    {this.state.errorMsg &&
                    <Alert variant="danger"><FontAwesomeIcon className="me-1" size="lg" icon="circle-exclamation" />{this.state.errorMsg}</Alert>
                    }
                    {this.state.infoMsg &&
                    <Alert variant="success"><FontAwesomeIcon className="me-1" size="lg" icon="circle-check" />{this.state.infoMsg}</Alert>
                    }
                    {this.state.warnMsg &&
                    <Alert variant="warning"><FontAwesomeIcon className="me-1" size="lg" icon="triangle-exclamation" />{this.state.warnMsg}</Alert>
                    }
                    <Card.Text>Use this form to create an account</Card.Text>
                    <Form id="register-form" onSubmit={this.handleSubmit}>
                        {!this.state.department ?
                            <Form.Group as={Row} controlId="invitation.code" className="mb-3">
                                <Form.Label column sm={3} md={12} lg={3}>
                                    Invitation Code*
                                </Form.Label>
                                <Col sm={9} md={12} lg={9}>
                                    <InputGroup>
                                        <Form.Control name="invitationCode" type="text" placeholder="Invitation Code" required  onChange={this
                                        .handleInputChange} className="border border-dashed"/>
                                       <OverlayTrigger
                                           placement="bottom"
                                           delay={{ show: 250, hide: 400 }}
                                           overlay={<Tooltip id="button-tooltip-2">SupremeContractors is a closed network of top professionals. You need an
                                           invitation to register.</Tooltip>}>
                                            <InputGroup.Text id="inputGroupPrependQuestion"><FontAwesomeIcon icon="question" /></InputGroup.Text>
                                       </OverlayTrigger>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                        :''}
                        <Form.Group as={Row} controlId="title.code" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Title*
                            </Form.Label>
                            <Col sm={9} md={12} lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="user-tie" /></InputGroup.Text>
                                    <Form.Select name="title.code" value={this.state.profileTitle} required onChange={this.handleInputChange}
                                    disabled={this.isFieldDisabled() ? '' : 'disabled'}>
                                        { titleOptions }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="firstNameLastName" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Name*
                            </Form.Label>
                            <Form.Group as={Col} controlId="firstName" className="mb-0">
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                        <Form.Control name="firstName" type="text" placeholder="First Name" required
                                        defaultValue={this.state
                                        .firstName} onChange={this.handleInputChange} disabled={this.isFieldDisabled() ? '' :
                                        'disabled'}/>
                                    </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} controlId="lastName" className="mb-0">
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon="user" /></InputGroup.Text>
                                        <Form.Control name="lastName" type="text" placeholder="Last Name" required
                                        defaultValue={this.state.lastName} onChange={this.handleInputChange} disabled={this.isFieldDisabled() ? '' : 'disabled'}/>
                                    </InputGroup>
                            </Form.Group>
                        </Form.Group>
                        <Form.Group as={Row} controlId="username" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Email address*
                            </Form.Label>
                            <Col sm={9} md={12} lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="envelope" /></InputGroup.Text>
                                    <Form.Control name="username" type="email" placeholder="Email" required defaultValue={this.state.profileEmail}
                                    onChange={this.handleInputChange} disabled={this.isFieldDisabled() ? '' : 'disabled'}/>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="password" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Password*
                            </Form.Label>
                            <Col sm={9} md={12} lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                    <Form.Control name="password" type="password" placeholder="Password" required onChange={this.handleInputChange}
                                    disabled={this.isFieldDisabled() ? '' : 'disabled'}/>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="repeatPassword" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Repeat password*
                            </Form.Label>
                            <Col sm={9} md={12} lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                    <Form.Control name="repeatPassword" type="password" placeholder="Repeat Password" required onChange={this
                                    .handleInputChange} disabled={this.isFieldDisabled() ? '' : 'disabled'}/>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formRegisterCreateAccount" className="mb-3">
                            <Col sm={{ span: 9, offset: 3 }} md={{ span: 10, offset: 2 }} lg={{ span: 9, offset: 3 }}>
                                <Button variant="primary" type="submit" className="float-end text-uppercase" disabled={this.state.isLoading || !this.isFieldDisabled() ? 'disabled' : ''}>Create an
                                account</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let newForm = this.state.form;
        newForm[name] = value;

        this.setState({
            form: newForm
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            isLoading: true
        });

        if (!this.validateForm()) {
            this.setState({
                isLoading: false
            });
            return;
        }

        let customerDetails = DataObjectParser.transpose(this.state.form).data();
        this.performRegister(customerDetails);
    }

    validateForm() {
        if (this.state.form.password !== this.state.form.repeatPassword) {
            this.setState({
                errorMsg: 'Passwords do not match.'
            });

            return false;
        }

        return true;
    }

    getTitles() {
        UserHelper.getTitles().then(response => {
            if (response.titles && response.titles.length) {
                this.setState({
                    registerTitle: response.titles[0].code,
                    titles: response.titles,
                    errorMsg: null
                });
                this.props.progressBarContext.dispatch({type: 'increment', progress: 80});
            } else if (response.errorMsg) {
                this.setState({
                    titles: [],
                    errorMsg: response.errorMsg
                });
            }
        });
    }

    getDepartment(code) {
        ApiCall.get('department/' + code).then((response) => {
            this.setState({ warnMsg: ["You have been invited to join organization: " + response.data.organization.legalName], department:response.data, form :
            {invitationCode:'SUPREME-CONTRACTOR-', department: {"@class":"io.nemesis.platform.module.business.facade.dto.DepartmentDto",code: response.data.code}}});
        }).catch(error => {
            this.setState({
                isLoading: false
            });
        });
    }

    performRegister(customerDetails) {
        UserHelper.register(customerDetails).then(registerResult => {
            this.setState({
                isLoading: false
            });
            let registerData = this.getApiResponseData(registerResult);
            if (registerData && registerData.error) {
                this.handleRegisterErrors(registerData.message);
            } else {
                if (customerDetails.accountNonLocked) {
                    UserHelper.login(customerDetails.username, customerDetails.password).then(loginResult => {
                        let loginData = this.getApiResponseData(loginResult);
                        if (loginData && loginData.error) {
                            this.handleRegisterErrors(loginData.message);
                        } else {
                            this.setState({
                                errorMsg: null
                            });

                            if (this.props.location.state && this.props.location.state.from) {
                                this.props.history.push({
                                    pathname: this.props.location.state.from.pathname,
                                    state: { globalInfoMsgs: ["Thank you for registering."] }
                                });
                            } else {
                                this.props.history.push({
                                    pathname: '/my-account',
                                    state: { globalInfoMsgs: ["Thank you for registering."] }
                                });
                            }
                        }
                    });
                } else {
                    this.setState({
                        infoMsg: 'Success! We have sent you an email with instructions how to activate your account'
                    });
                }
            }
        });
    }

    getApiResponseData(result) {
        return result && result.response && result.response.data ? result.response.data : null;
    }

    handleRegisterErrors(error) {
        this.setState({
            errorMsg: error ? error : 'An error occurred. Please try again later.', isLoading: false
        });
    }

    isFieldDisabled() {
        return this.state.form.invitationCode && this.state.invitationResponses.some(substr => this.state.form.invitationCode.startsWith(substr));
    }
}

export default withRouter(withProgressBarContext(Register));
