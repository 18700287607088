import React from "react";
import { Button, Modal } from "react-bootstrap";

const SessionTimeoutModal = ({  show, countdown, onLogout, onContinue, onHide }) => {

    return (
        <Modal show={show} onHide={onHide} size={"l"} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Your session is about to expire</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                The current session is about to expire in <span className="text-danger">{countdown}</span> seconds.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onLogout}>
                    Logout
                </Button>
                <Button variant="primary" className="text-white" onClick={onContinue}>
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default SessionTimeoutModal;