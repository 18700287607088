import React from "react";
import {Form} from 'react-bootstrap';

import CmsWidget from './CmsWidget.js';

export default function DynamicformWidget({widgetData}) {
    let className = widgetData.styleClass;

    return (
        <div id={widgetData.code} className={className}>
            <Form onSubmit={() => {}}>
                {widgetData.inputFields && widgetData.inputFields.map(function(inputField, key) {
                    return <CmsWidget key={key} widgetData={inputField} />;
                })}
            </Form>
        </div>
    );
}
