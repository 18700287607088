import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import LayoutOptions from './LayoutOptions.js';
import SortingOptions from './SortingOptions.js';
import './PaginationToolbar.scss';
import { ListerContext } from "../Account/View/AccountSearch/ResourceListerPageProvider";

export default class LayoutSortToolbar extends Component {
    render() {
        return (
            <ListerContext.Consumer>
                {
                    (context) => (
                        <Row className="mt-3">
                            <Col lg={9} className="float-start">
                                <LayoutOptions page={context.state.searchPageData} handleGetResources={context.getResources} isLoading={context.state.isLoading}/>
                            </Col>

                            <Col lg={3} className="float-end">
                                <SortingOptions page={context.state.searchPageData} isLoading={context.state.isLoading}/>
                            </Col>
                        </Row>
                    )
                }
            </ListerContext.Consumer>
        )
    }
}
