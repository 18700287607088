import React, {Component} from 'react';
import {Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {GlobalMessagesContext} from '../Provider/GlobalMessagesProvider.js';

export default class GlobalMessages extends Component {
    render() {
        return (
            <GlobalMessagesContext.Consumer>
                {(context) => (
                    <React.Fragment>
                        {context.state.errorMsgs && context.state.errorMsgs.length > 0 &&
                            context.state.errorMsgs.map(function(msg, key) {
                                return <Alert variant="danger" key={key} className="mt-3">
                                    <div className="text-center">
                                        <FontAwesomeIcon className="me-1" size="lg" icon="circle-exclamation" /> {msg}
                                    </div>
                                </Alert>
                            })
                        }

                        {context.state.infoMsgs && context.state.infoMsgs.length > 0 &&
                            context.state.infoMsgs.map(function(msg, key) {
                                return <Alert variant="info" key={key} className="mt-3">
                                    <div className="text-center">
                                        <FontAwesomeIcon className="me-1" size="lg" icon="circle-check" />{msg}
                                    </div>
                                </Alert>
                            })
                        }

                        {context.state.warnMsgs && context.state.warnMsgs.length > 0 &&
                            context.state.warnMsgs.map(function(msg, key) {
                                return <Alert variant="warning" key={key} className="mt-3">
                                    <div className="text-center">
                                        <FontAwesomeIcon className="me-1" size="lg" icon="triangle-exclamation" />{msg}
                                    </div>
                                </Alert>
                            })
                        }
                    </React.Fragment>
                )}
            </GlobalMessagesContext.Consumer>
        )
    }
}
