import React, {Component} from 'react';
import {Row, Col, Container, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {withRouter} from 'react-router-dom';
import { Document, Page } from "react-pdf";

class PreviewDocument extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalPages: 0,
            currentPage : 1
        };
//        this.getUser = this.getUser.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    componentDidMount() {
//        let url = process.env.REACT_APP_FACADE_URL + "experience/cv/?username="+encodeURIComponent(this.props.username);
//        if (this.props.token) {
//            url += ("&token="+this.props.token);
//        }
        this.setState({username:this.props.location.hash.substring(1), pdf:{ url: this.props.url,httpHeaders: { 'X-Nemesis-Token': localStorage.getItem("token") }}});
    }

    render() {
        return (<>
            <Row className="mt-3">
                <Col sm={12} md={12} className="mt-3 mt-md-0">
                    <Container>
                        <Row>
                            <Col sm={1} className="my-auto">
                                {this.state.currentPage>1?
                                <Button size="lg" variant="primary" onClick={()=>{this.setState({currentPage:this.state.currentPage-1})
                                }}><FontAwesomeIcon
                                icon={"arrow-left"}/></Button>
                                :''}
                            </Col>
                            <Col sm={10} className="Example__container__document">
                                <Document file={this.state.pdf} onLoadSuccess={this.onDocumentLoadSuccess}>
                                    <Page pageNumber={this.state.currentPage} className="mx-auto"/>
                                </Document>
                            </Col>
                            <Col sm={1} className="my-auto">
                                {this.state.totalPages > this.state.currentPage?
                                <Button size="lg" variant="primary" className="align-middle" onClick={()=>{this.setState({currentPage:this.state
                                .currentPage+1})}}><FontAwesomeIcon icon={"arrow-right"}/></Button>
                                :null}
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
        )
    }

    onDocumentLoadSuccess(nPages) {
        this.setState({totalPages: nPages._pdfInfo.numPages});
    }

//    getUser(username) {
//        ApiCall.get('user/' + username, {
//            projection: "io.nemesis.platform.facade.user.dto.UserDetailsDtoDefinition"
//        }).then((response) => {
//            let currentUser = response.data;
//            this.setState({
//                name: currentUser.displayName
//            });
//        }).catch(error => {
//            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
//
//            this.setState({
//                profileInfoMsg: null
//            });
//        });
//    }

}

export default withRouter(PreviewDocument);
