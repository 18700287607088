import React, {Component} from 'react';
import {Form, Row, Col} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';

class OfferDetailsModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            errorMsg: null,
            form:{}
        };
    }

    render() {
        let description = this.props.offer && this.props.offer.jobRequest ? this.props.offer.jobRequest.description : "";
        return (
            <>
                {this.props.offer ?
                    <Modal centered size="lg" show={this.props.show} onHide={()=>{this.props.handleOfferModalClose(false)}}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-uppercase fw-bold">Offer Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="show-grid">
                                <Col xs={12} sm={12}>
                                    <Form.Group>
                                        <Form.Label>
                                            <h5><b>{this.props.offer.jobRequest ? this.props.offer.jobRequest.name : ""}</b></h5>
                                        </Form.Label>
                                    </Form.Group>
                                    <hr/>
                                    <Form.Group>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: description
                                          }}>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                :null}
            </>
        )
    }
}

export default withRouter(OfferDetailsModal);
