import React, {Component} from 'react';
import qs from 'qs';
import {withRouter} from 'react-router-dom';
import {Card, Form, InputGroup, Row, Col, Button, Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApiCall from '../../Helper/ApiCall.js';
import {withProgressBarContext} from '../../Provider/ProgressBarProvider.js';

class UpdatePassword extends Component {
    constructor(props){
        super(props);

        this.state = {
            password: null,
            repeatPassword: null,
            passwordsMatch:true,
            errorMsg: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        if (!queryParams || !queryParams.token) {
            this.props.history.push("/login");
        } else {
            this.setState({token:queryParams.token});
        }
        this.props.progressBarContext.dispatch({type: 'update', progress: 100});
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title className="text-uppercase fw-bold h6">
                        Set your new password
                    </Card.Title>
                    {this.state.errorMsg &&
                    <Alert variant="danger"><FontAwesomeIcon className="me-1" size="lg" icon="circle-exclamation" />{this.state.errorMsg}</Alert>
                    }
                    {this.state.infoMsg &&
                    <Alert variant="success"><FontAwesomeIcon className="me-1" size="lg" icon="circle-check" />{this.state.infoMsg}</Alert>
                    }
                    {this.state.warnMsg &&
                    <Alert variant="warning"><FontAwesomeIcon className="me-1" size="lg" icon="triangle-exclamation" />{this.state.warnMsg}</Alert>
                    }
                    <Card.Text>Please type your new password below</Card.Text>
                    <Form onSubmit={this.handleSubmit} id="login-form">
                        <Form.Group as={Row} controlId="formLoginEmail" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Password *
                            </Form.Label>
                            <Col sm={9} md={12} lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                    <Form.Control name="password" type="password" placeholder="New Password" required onChange={this.handleInputChange}
                                    />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formLoginEmail" className="mb-3">
                            <Form.Label column sm={3} md={12} lg={3}>
                                Repeat Password *
                            </Form.Label>
                            <Col sm={9} md={12} lg={9}>
                                <InputGroup>
                                    <InputGroup.Text><FontAwesomeIcon icon="lock" /></InputGroup.Text>
                                    <Form.Control name="repeatPassword" type="password" placeholder="Repeat Password" required onChange={this.handleInputChange}
                                     className={this.state.passwordsMatch ? "" : "is-invalid"}/>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formLoginRememberMeSignIn" className="mb-3">
                            <Col sm={12} md={12} lg={12} className="float-end">
                                <Button variant="primary" type="submit" className="float-end text-uppercase mt-2 mt-lg-0">Submit</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        )
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });

        let password = name === 'password' ? value : this.state.password;
        let repeatPassword = name === 'repeatPassword' ? value : this.state.repeatPassword ;
        this.setState({
            passwordsMatch: password === repeatPassword
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            errorMsg: null,
            infoMsg: null,
            warnMsg: null
        });


        ApiCall.post('account/updatePassword/', null, null, {'token': this.state.token, 'newPassword':this.state.password}).then(() => {
            this.setState({
                password:null,
                repeatPassword:null,
                errorMsg: null,
                infoMsg: "Success! You password has been changed.",
                warnMsg: null
            });
        }).catch(error => {
            this.setState({
                errorMsg: error && error.message ? error.message : 'An error occurred. Please try again later.',
                infoMsg: null,
                warnMsg: null
            });
        });

    }
}

export default withRouter(withProgressBarContext(UpdatePassword));
