import React from 'react';
import { Rating } from 'react-simple-star-rating';
import {Card, Badge, Row, Col, Button, Dropdown} from 'react-bootstrap';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SelectedResourceContext} from './SelectedResourceProvider.js';
import UserHelper from '../../../../components/Helper/UserHelper.js';

export default function ResourceListerListItem({ resource, handleResourceModalShow, handleResourceNotesModalShow, handleResourceHistoryModalShow, handleDownloadContracts, getResourcePrice, progressBarContext }) {
    let isSales = UserHelper.isSales();
    let isSuperSales = UserHelper.isSuperSales();
    let isManager = UserHelper.isManager();

    let addedToCompare = false;

    return (
        <Col lg={12} xs={12} className="mb-3">
            <Card className="shadow-hover w-100">
                <Card.Body>
                    <Row>
                        <Col lg={2} xs={9}>
                            <div className="position-relative">
                                <img src={resource.properties.imageUrl} className="rounded img-fluid w-100" alt="user"/>
                                <SelectedResourceContext.Consumer>
                                    {(context) => (
                                        <React.Fragment>
                                            { addedToCompare = context.isResourceSelected(resource.properties.code) }
                                            {isSales || isSuperSales ?
                                                <div className="position-absolute fixed-top mt-2 ms-2" style={{cursor: "pointer"}} onClick={() => {
                                                        if (addedToCompare) {
                                                            context.unselectResource(resource.properties.code);
                                                        } else {
                                                            context.selectResource(resource);
                                                        }
                                                    }}>
                                                    <FontAwesomeIcon size="lg" icon={addedToCompare ? "check" : "plus"} className={addedToCompare ? "text-secondary"
                                                     :
                                                    "text-primary"} />
                                                </div>
                                            :""}
                                            <div className="position-absolute fixed-bottom mb-2 ms-2">
                                                <FontAwesomeIcon size="lg" icon={["fas", "circle"]} className={`me-2 ${resource.properties.available ? "text-success" : "text-danger"}`}/>
                                                <FontAwesomeIcon size="lg" icon={["fas", "circle"]} className={resource.properties.assigned ?
                                                "text-secondary" : "text-primary"} />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </SelectedResourceContext.Consumer>
                            </div>
                            <Row className="my-2 mx-auto" style={{marginTop:'-15px'}}>
                                <Col className="text-center">
                                    <Rating initialValue={resource.properties.rating} emptyColor={UserHelper.isDark() ? "var(--bs-light)" : "var(--bs-primary)"} fillColor={"var(--bs-secondary)"} allowFraction={true} size={30} readonly/>
                                </Col>
                            </Row>
                            {resource.properties.organization && (isSuperSales || isManager) ?
                                <Row className="text-center mt-2 mb-0">
                                    <Col>
                                        <span className="fs-6">{resource.properties.organization && (isSuperSales || isManager) ? resource.properties.organization : " "}</span>
                                    </Col>
                                </Row>
                            :""}
                            <Row className="my-2 text-center mx-auto py-1 border border-muted rounded-pill">
                                {isSuperSales || isManager ?
                                    <Col><a href={"mailto:" + resource.properties.code} className="text-muted"><FontAwesomeIcon size="lg" icon={"envelope"}/></a></Col>
                                :null}
                                {resource.properties.phone && isSuperSales ?
                                    <Col><a href={"tel:" + resource.properties.phone} className="text-muted"><FontAwesomeIcon size="lg" icon="phone"/></a></Col>
                                :null}
                                {resource.properties.skype && isSuperSales?
                                    <Col><a href={"skype:" + resource.properties.skype + "?chat"} className="text-muted"><FontAwesomeIcon size="lg" icon={["fab", "skype"]}/></a></Col>
                                :''}
                                {resource.properties.whatsap && isSuperSales?
                                    <Col><a href={"whatsapp:" + resource.properties.phone} className="text-muted"><FontAwesomeIcon size="lg" icon={["fab", "whatsapp"]} /></a></Col>
                                :''}
                                {resource.properties.telegram && isSuperSales ?
                                    <Col><a href={"tg:" + resource.properties.phone} className="text-muted"><FontAwesomeIcon size="lg" icon={["fab",  "telegram"]}/></a></Col>
                                :''}
                                {resource.properties.viber && isSuperSales ?
                                    <Col><a href={"viber:" + resource.properties.phone} className="text-muted"><FontAwesomeIcon size="lg" icon={["fab", "viber"]} /></a></Col>
                                :''}
                            </Row>
                        </Col>
                        <Col lg={8} xs={12}>
                            <Card.Title className="text-uppercase">
                                <Button variant="link" size="lg" className="p-0" onClick={() => handleResourceModalShow(resource.properties)
                                }>{isSuperSales || isManager ? resource.properties.name + '(' + resource.properties.initials + ')' : resource.properties.initials}</Button>
                            </Card.Title>
                            <h6><b>{resource.properties.role}</b></h6>
                            {resource.properties.skills.slice(0, 10).map((skill) => {
                                return <Badge pill bg="success" className="me-2 mb-2" key={skill.value}>{skill.name.en}</Badge>
                            })}
                            <HTMLEllipsis
                                unsafeHTML={resource.properties.summary}
                                maxLine='5'
                                ellipsisHTML={'...'}
                                basedOn='letters'
                                />
                        </Col>
                        <Col lg={2} xs={3}>
                            <Dropdown className="position-absolute text-bf" style={{top:"20px", right:"20px"}}>
                              <Dropdown.Toggle variant="transparent">
                                <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleResourceModalShow(resource.properties)}>View</Dropdown.Item>
                                {isSuperSales ?
                                    <>
                                        <Dropdown.Item onClick={() => handleResourceNotesModalShow(resource.properties)}>Notes</Dropdown.Item>
                                    </>
                                :""}
                                {isSuperSales || isManager ?
                                    <>
                                        <Dropdown.Item onClick={() => handleResourceHistoryModalShow(resource.properties)}>History</Dropdown.Item>
                                        <Dropdown.Item onClick={() => UserHelper.downloadCV(resource.properties.code, resource.properties.initials, false, progressBarContext)}>Download</Dropdown.Item>
                                    </>
                                :null}
                                <Dropdown.Item onClick={e => UserHelper.downloadCV(resource.properties.code, resource.properties.initials, true, progressBarContext)}>Download Anonymous</Dropdown.Item>
                                {isSuperSales ?
                                    <>
                                        <Dropdown.Item onClick={() => handleDownloadContracts(resource.properties.code)}>Download Contracts</Dropdown.Item>
                                    </>
                                :""}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div className="position-absolute text-bf" style={{bottom:"0", right:"15px"}}>
                                <h5>{getResourcePrice(resource, isSales && !isSuperSales)}</h5>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}
