import React, {Component} from "react";
import {withRouter} from 'react-router-dom';

export const GlobalMessagesContext = React.createContext();

class GlobalMessagesProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            infoMsgs: [],
            errorMsgs: [],
            warnMsgs: []
        }

        this.addInfoMsg = this.addInfoMsg.bind(this);
        this.addErrorMsg = this.addErrorMsg.bind(this);
        this.addWarnMsg = this.addWarnMsg.bind(this);
        this.clearGlobalMsgs = this.clearGlobalMsgs.bind(this);
    }

    componentDidMount() {
        if(this.props.location.state && this.props.location.state.globalInfoMsgs) {
            this.setState({
                infoMsgs: this.props.location.state.globalInfoMsgs
            });
        }

        if(this.props.location.state && this.props.location.state.globalErrorMsgs) {
            this.setState({
                errorMsgs: this.props.location.state.globalErrorMsgs
            });
        }

        if(this.props.location.state && this.props.location.state.globalWarnMsgs) {
            this.setState({
                warnMsgs: this.props.location.state.globalWarnMsgs
            });
        }
    }

    render() {
        return (
            <GlobalMessagesContext.Provider value={{
                state: this.state,
                addInfoMsg: this.addInfoMsg,
                addErrorMsg: this.addErrorMsg,
                addWarnMsg: this.addWarnMsg,
                clearGlobalMsgs: this.clearGlobalMsgs
            }}>
                {this.props.children}
            </GlobalMessagesContext.Provider>
        );
    }

    addInfoMsg(msg, clearMsgs) {
        if (clearMsgs) {
            this.clearGlobalMsgs();
        }

        let newInfoMsgs = this.state.infoMsgs;
        newInfoMsgs.push(msg);

        this.setState({
            infoMsgs: newInfoMsgs
        });
    }

    addErrorMsg(msg, clearMsgs) {
        if (clearMsgs) {
            this.clearGlobalMsgs();
        }

        let newErrorMsgs = this.state.errorMsgs;
        newErrorMsgs.push(msg);

        this.setState({
            errorMsgs: newErrorMsgs
        });
    }

    addWarnMsg(msg, clearMsgs) {
        if (clearMsgs) {
            this.clearGlobalMsgs();
        }

        let newWarnMsgs = this.state.warnMsgs;
        newWarnMsgs.push(msg);

        this.setState({
            warnMsgs: newWarnMsgs
        });
    }

    clearGlobalMsgs() {
        this.setState({
            infoMsgs: [],
            warnMsgs: [],
            errorMsgs: []
        });
    }
}

export default withRouter(GlobalMessagesProvider);

const withGlobalMessagesContext = (Component) => {
    return (props) => (
        <GlobalMessagesContext.Consumer>
                {context => <Component {...props} globalMessagesContext={context} />}
        </GlobalMessagesContext.Consumer>
    )
}

export {withGlobalMessagesContext}
