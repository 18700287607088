import React, {Component} from "react";

export const SelectedResourceContext = React.createContext();

export default class SelectedResourceProvider extends Component {
    constructor(props) {
        super(props);

        this.maxItemsCount = 8;

        let selectedResources = JSON.parse(localStorage.getItem("selectedResources"));
        if (selectedResources && selectedResources.length > 0) {
            document.body.classList.add('has-compare');
        }

        this.state = {
            selectedResources: selectedResources,
            isLoading: true
        }

        this.selectResource = this.selectResource.bind(this);
        this.unselectResource = this.unselectResource.bind(this);
        this.isResourceSelected = this.isResourceSelected.bind(this);
        this.clearSelectedResources = this.clearSelectedResources.bind(this);
    }

    render() {
        return (
            <SelectedResourceContext.Provider value={{
                state: this.state,
                maxItemsCount: this.maxItemsCount,
                selectResource: this.selectResource,
                unselectResource: this.unselectResource,
                isResourceSelected: this.isResourceSelected,
                clearSelectedResources: this.clearSelectedResources
            }}>
                {this.props.children}
            </SelectedResourceContext.Provider>
        );
    }

    selectResource(resource) {
        let newSelectedResources = this.state.selectedResources == null ? [] : this.state.selectedResources;

        if (newSelectedResources.length === this.maxItemsCount) {
            return;
        }

        newSelectedResources.push(resource);

        this.setState({
            selectedResources: newSelectedResources
        });

        localStorage.setItem("selectedResources", JSON.stringify(newSelectedResources));
        document.body.classList.add('has-compare');
    }

    unselectResource(resourceCode) {
        let newSelectedResources = this.state.selectedResources == null ? [] : this.state.selectedResources;
        newSelectedResources = newSelectedResources.filter(resource => resource.properties.code !== resourceCode);

        this.setState({
            selectedResources: newSelectedResources
        });

        localStorage.setItem("selectedResources", JSON.stringify(newSelectedResources));
        if (newSelectedResources.length < 1) {
            document.body.classList.remove('has-compare');
        }
    }

    clearSelectedResources() {
        this.setState({
            selectedResources: []
        });

        localStorage.setItem("selectedResources", JSON.stringify([]));
        document.body.classList.remove('has-compare');
    }

    isResourceSelected(resourceCode) {
        let isSelected = false;
        if (this.state.selectedResources) {
            isSelected = this.state.selectedResources.some(function(resource) {
                return resource.properties.code === resourceCode;
              }, this);
        }

        return isSelected;
    }
}

const withSelectedResourceContext = (Component) => {
    return (props) => (
        <SelectedResourceContext.Consumer>
                {context =>  <Component {...props} selectedResourcesContext={context} />}
        </SelectedResourceContext.Consumer>
    )
}

export {withSelectedResourceContext}

