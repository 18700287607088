import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import CmsPageProvider from '../../cms/CmsPageProvider.js';
import CmsTemplate from '../../cms/CmsTemplate.js';
import GlobalMessagesProvider from '../../Provider/GlobalMessagesProvider.js';
import AccountVacation from '../../Account/View/AccountVacation/AccountVacation.js';

export default class ResourceCalendarPage extends Component {
    render() {
        return (
            <CmsPageProvider props={{page:'vacation'}}>
                <GlobalMessagesProvider>
                    <CmsTemplate>
                        <main>
                            <Container fluid className="mt-3 mb-3 ps-0 pe-0">
                                <Row className="mt-3">
                                    <Col sm={12} md={12}>
                                        <AccountVacation/>
                                    </Col>
                                </Row>
                            </Container>
                        </main>
                    </CmsTemplate>
                </GlobalMessagesProvider>
            </CmsPageProvider>
        )
    }
}
